import { interactionBaseApi } from './interactionBaseApi';

export const {
  endpoints,
  useDeviceCountQuery,
  useDeviceListQuery,
  useDeviceLimitedListMutation,
  useDeviceDetailQuery,
  useDeviceAddMutation,
  useDeviceEditMutation,
  useDeviceRemoveMutation,
  useDevicePairingSetMutation,
  useDevicePairingUnsetMutation,
  useDevicePlayMutation,
  useDeviceStopMutation,
  useDeviceScrshotListQuery,
  useDeviceGroupMoveMutation,
  useDeviceCommandMutation,
  useDeviceCommandSocketMutation,
  useDevicePowerUpdateMutation,
  useDeviceRemoteUrlMutation,
  useDeviceCoordinateUpdateMutation,
} = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    deviceCount: build.query({
      query: data => ({ url: '/device/count', data }),
      transformResponse: response => {
        const deviceCount = response.listCount;
        const pairingCount = response.pairingCount;
        return { deviceCount, pairingCount };
      },
    }),
    deviceList: build.query({
      async queryFn(arg, { dispatch, getState }, _extraOptions, fetchApi) {
        let allDeviceList = [];

        let startKey = null;
        let limit = 1000;

        // 10,000개 이상 조회시... X
        for (let loop = 0; loop < 100; loop++) {
          const {
            data: { deviceList, lastKey },
          } = await fetchApi({ url: '/device/list', data: { ...arg, startKey, limit } });
          allDeviceList = allDeviceList.concat(deviceList);
          startKey = lastKey;
          if (!startKey) {
            break;
          }
        }

        return { data: allDeviceList };
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ deviceId }) => ({ type: 'DEVICE', id: deviceId })),
        { type: 'DEVICE', id: 'LIST' },
      ],
      keepUnusedDataFor: 0,
    }),
    deviceLimitedList: build.mutation({
      query: data => ({ url: '/device/limited-list', data }),
      transformResponse: (response, meta, arg) => {
        const deviceList = response.deviceList;
        const lastKey = response.lastKey;
        return { deviceList, lastKey };
      },
    }),
    deviceDetail: build.query({
      query: data => ({ url: '/device/detail', data }),
      transformResponse: response => {
        const deviceInfo = response.deviceInfo;
        return deviceInfo;
      },
      providesTags: (result, error, payload) => [{ type: 'DEVICE', id: payload.deviceId }],
    }),
    deviceAdd: build.mutation({
      query: data => ({ url: '/device/add', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceEdit: build.mutation({
      query: data => ({ url: '/device/edit', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'DEVICE', id: payload.deviceId }],
    }),
    deviceRemove: build.mutation({
      query: data => ({ url: '/device/remove', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    devicePairingSet: build.mutation({
      query: data => ({ url: '/device/pairing', data }),
      transformResponse: response => {
        const pairingInfo = response.pairingInfo;
        return pairingInfo;
      },
      invalidatesTags: (result, error, payload) => [{ type: 'DEVICE', id: payload.deviceId }],
    }),
    devicePairingUnset: build.mutation({
      query: data => ({ url: '/device/pairing-unset', data }),
      transformResponse: response => {
        return true;
      },
      invalidatesTags: (result, error, payload) => [{ type: 'DEVICE', id: payload.deviceId }],
    }),
    devicePlay: build.mutation({
      query: data => ({ url: '/device/play', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceStop: build.mutation({
      query: data => ({ url: '/device/stop', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceScrshotList: build.query({
      query: data => ({ url: '/device/scrshot-list', data }),
      transformResponse: response => {
        const scrshotList = response.scrshotList;
        return scrshotList;
      },
    }),
    deviceGroupMove: build.mutation({
      query: data => ({ url: '/device/group-move', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceCommand: build.mutation({
      query: data => ({ url: '/device/command', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceCommandSocket: build.mutation({
      query: data => ({ url: '/device/command-socket', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    devicePowerUpdate: build.mutation({
      query: data => ({ url: '/device/power-update', data }),
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
    deviceRemoteUrl: build.mutation({
      query: data => ({ url: '/device/remote-url', data }),
      transformResponse: response => {
        const remoteUrl = response.remoteUrl;
        return remoteUrl;
      },
    }),
    deviceCoordinateUpdate: build.mutation({
      async queryFn({ deviceId, lat, lng }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const result = await fetchInteractionApi({
            url: '/device/gps-edit',
            data: { deviceId, deviceGpsLat: lat, deviceGpsLng: lng },
          });
          return result;
        } catch (err) {
          console.log('에러', err);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
      invalidatesTags: [{ type: 'DEVICE', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});
