import { interactionBaseApi } from './interactionBaseApi';

export const templateApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    tempList: build.query({
      query: data => ({ url: '/template/template-list-get' }),
      transformResponse: response => {
        const templateList = response.templateList;
        const URL = window.location.href;
        if (URL.indexOf('localhost') > 0 || URL.indexOf('eligaws') > 0) {
          return templateList;
        } else {
          return templateList.filter(template => template.devYn === 'N');
        }
      },
    }),
    tempAdd: build.mutation({
      query: data => ({ url: '/template/template-frame-add', data }),
      invalidatesTags: [
        { type: 'FRAME', id: 'LIST' },
        { type: 'LAYER', id: 'LIST' },
        { type: 'LAYERCONTENTS', id: 'LIST' },
      ],
    }),
  }),
});

export const { endpoints, useTempListQuery, useTempAddMutation } = templateApi;
