import React, { useEffect, useCallback, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { FaRegTimesCircle as RemoveIcon } from 'react-icons/fa';

import SelectInput from '../../../../newComponents/SelectInput';
import useMultiContents from '../../../../newComponents/useMultiContents';

const Logo = ({ index, logo, logoList, groupOptionList, handleChangeObject, handleChangeLogoOption, handleChangeLogoOptionRemove, formValidate }) => {
  const defaultDevice = useRef([{ title: '기본', value: 'DEFAULT' }]);

  const fitOptionList = useRef([
    { value: 'cover', title: '채우기' },
    { value: 'fill', title: '채우기 (비율무시)' },
    { value: 'contain', title: '틀 안에 맞추기' },
    { value: 'none', title: '원본크기' },
  ]);

  const { fileList, addFileList, removeFileList, handleSetOriginFileList, renderMultiContentsComponent } = useMultiContents();

  useEffect(() => {
    if (logo) {
      handleSetOriginFileList(logo.logoFile ? [logo.logoFile] : []);
    }
  }, [logo, handleSetOriginFileList, index]);

  useEffect(() => {
    if (addFileList.length > 0 || removeFileList.length > 0) {
      let logoFileId = logo?.logoFile?.fileId || '';
      let fileListId = fileList?.[0]?.fileId || '';

      if (logoFileId !== fileListId) {
        let changeLogoList = [...logoList];
        if (fileListId === '') {
          const { logoFile, ...changeLogoInfo } = logo;
          changeLogoList[index] = changeLogoInfo;
        } else {
          changeLogoList[index] = { ...logo, logoFile: fileList[0] };
        }

        handleChangeObject('contentsData', 'logoList', changeLogoList);
      }
    }
  }, [fileList, index, logo, logoList, handleChangeObject, addFileList.length, removeFileList.length]);

  return (
    <Container>
      <Box>
        <LeftArea>
          <LogoImg>
            <>{renderMultiContentsComponent('image/jpeg, image/png, image/gif', false, false)}</>
          </LogoImg>
        </LeftArea>
        <RightArea>
          {index === 0 ? (
            <SelectInput selectedValue="DEFAULT" optionList={defaultDevice.current} disable={true} />
          ) : (
            <SelectInput
              selectedValue={logo?.targetGroupId}
              optionList={groupOptionList}
              onSelectChange={value => handleChangeLogoOption(index, 'targetGroupId', value)}
              defaultTitle={logo?.targetGroupId}
              isValidate={formValidate.logoList[index] && formValidate.logoList[index].targetGroupId}
            />
          )}
          <InputError>{formValidate.logoList[index] && formValidate.logoList[index].targetGroupIdError}</InputError>
          <SelectInput
            selectedValue={logo?.fitOption}
            optionList={fitOptionList.current}
            onSelectChange={value => handleChangeLogoOption(index, 'fitOption', value)}
            isValidate={formValidate.logoList[index] && formValidate.logoList[index].fitOption}
          />
          <InputError>{formValidate.logoList[index] && formValidate.logoList[index].fitOptionError}</InputError>
          {index > 0 && (
            <ButtonWrapper>
              <StyledRoundBtn onClick={() => handleChangeLogoOptionRemove(index)}>
                <RemoveIcon color="#666666" size={25} />
              </StyledRoundBtn>
            </ButtonWrapper>
          )}
        </RightArea>
      </Box>
    </Container>
  );
};

const getColor = props => {
  if (props.isDragAccept) {
    return '#2196f3';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#00e676';
  }
  return '#999999';
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Box = styled.div`
  display: flex;
`;

const LeftArea = styled.div`
  display: flex;
  width: 130px;
  align-items: center;
`;
const RightArea = styled.div`
  flex: 1;
  align-items: center;
  padding-left: 20px;
`;

const LogoImg = styled.div`
  width: 100%;
  height: 154px;
  background: #c4c4c4;
  border: 2px dashed #999999;
  border-radius: 6px;
  border-color: ${props => getColor(props)};
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    object-fit: contain;
  }
`;

const InputError = styled.label`
  height: 1.25rem;
  padding: 0.125rem 0.25rem;
  color: #f05b5b;
  font-size: 12px;
  line-height:normal;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledRoundBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 17px;
  background: #ffffff;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #ececec;
  }
`;
export default React.memo(Logo);
