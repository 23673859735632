import React, { memo } from "react";
import styled from "styled-components";
import Scroll from "./Scroll";

const EachFloor = memo(({ floorData, contentsFileList, navFunction }) => {
  const clickCompany = (frameLinkId) => {
    if (frameLinkId !== "none" && frameLinkId) {
      navFunction.jumpToFrame(frameLinkId);
    }
  };
  return (
    <EachFloorBox>
      <div className={"each-floor-title-wrap"}>
        <span className={"each-floor-title"}>
          {floorData.floorTitle || "\u2001"}
        </span>
        <span className={"each-floor-subtitle"}>
          {floorData.floorSubTitle || "\u2001"}
        </span>
      </div>
      <ul className={"each-floor-company-wrap"}>
        {floorData.companyList.map((company, index) => {
          let logoFile = company.logoFile;
          return (
            <EachCompanyBox
              key={index}
              onClick={() => clickCompany(company.frameLinkId)}
            >
              {logoFile && (
                <div className={"logo-box"}>
                  <img
                    src={
                      process.env.REACT_APP_INTERACTION_CDN_URL +
                      contentsFileList.find(f => f.fileId === logoFile.fileId)
                        ?.filePath
                    }
                    alt={company.companyName}
                  />
                </div>
              )}
              <span className={"each-company-title"}>
                {company.companyName}
              </span>
            </EachCompanyBox>
          );
        })}
      </ul>
    </EachFloorBox>
  );
});

const BuildingInfo = ({ buildingData, contentsFileList, navFunction }) => {
  return (
    <BuildingInfoBox>
      <span className={"building-info-title"}>
        {buildingData.wholeFloorTitle}
      </span>
      <Scroll paddingBottom={40}>
        <ul className={"building-info-list"}>
          {buildingData.floorList.map((floor, index) =>
            floor.floorYn === "Y" ? (
              <EachFloor
                key={index}
                floorData={floor}
                contentsFileList={contentsFileList}
                navFunction={navFunction}
              />
            ) : null
          )}
        </ul>
      </Scroll>
    </BuildingInfoBox>
  );
};

const BuildingInfoBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  .building-info-title {
    display: block;
    font-size: 50px;
    font-weight: bold;
    margin: 40px 60px;
    flex: none;
  }
  .building-info-list {
    margin: 0 60px;
  }
`;

const EachFloorBox = styled.li`
  width: 100%;
  height: auto;
  margin-top: 40px;
  &:first-child {
    margin: 0;
  }
  .each-floor-title-wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    .each-floor-title {
      font-size: 45px;
      font-weight: bold;
    }
    .each-floor-subtitle {
      font-size: 20px;
      color: #999;
      margin: auto 10px;
    }
  }
  .each-floor-company-wrap {
    display: grid;
    grid-template-columns: repeat(3, 310px);
    grid-auto-rows: 165px;
    grid-gap: 15px;
    margin-top: 10px;
  }
`;

const EachCompanyBox = styled.li`
  width: 310px;
  height: 165px;
  background: #fff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo-box {
    width: 200px;
    height: 115px;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  .each-company-title {
    display: block;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 20px;
    margin: 7px;
  }
`;

export default React.memo(BuildingInfo);
