import * as apiLib from './api';

export const getLayerContentsList = async ({
  indexId
}) => {
  try {
    const data = {
      frameId: indexId,
    };

    const result = await apiLib.fetchInteractionApi(
      '/layer-contents/list',
      data,
    );

    if (result.resultFlag) {
      return result.layerContentsList;
    } else {
      throw Object.assign(new Error('getLayerContentsList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getLayerContents = async layerId => {
  try {
    const data = {
      layerId,
    };
    const result = await apiLib.fetchInteractionApi(
      '/layer-contents/layer-contents',
      data,
    );

    if (result.resultFlag) {
      return result.layerContents;
    }
  } catch (err) {
    throw err;
  }
};

export const getSaveLayerContents = async ({ playlistId, frameId, layerId, newLayerContents }) => {
  try {
    const data = {
      playlistId,
      frameId,
      layerId,
      ...newLayerContents,
    };

    const result = await apiLib.fetchInteractionApi('/layer-contents/layer-contents-save', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getSaveLayerContents error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getUpdateLayerContents = async updateLayerContents => {
  try {
    const data = {
      ...updateLayerContents,
    };
    const result = await apiLib.fetchInteractionApi('/layer-contents/layer-contents-update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getUpdateLayerContents error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getRemoveLayerContents = async removeLayerContents => {
  try {
    const data = {
      ...removeLayerContents,
    };
    const result = await apiLib.fetchInteractionApi('/layer-contents/layer-contents-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getRemoveLayerContents error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
