import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as DownArrowIcon } from '../../../../assets/images/common/icon-chevron-down.svg';
import { ReactComponent as RightArrowIcon } from '../../../../assets/images/common/icon-chevron-right.svg';
import { ReactComponent as UpArrowIcon } from '../../../../assets/images/common/icon-chevron-up.svg';

const ContentBox = ({ line, title, tip, style, toggle, children, display, isOpen = true, changeOpen }) => {
  return (
    <ContentsWrapper>
      <TitleDiv onClick={() => changeOpen(!isOpen)} isOpen={isOpen}>
        <TitleLeft>
          <TitleSpan>{title}</TitleSpan>
          <TipSpan isOpen={isOpen}>{tip}</TipSpan>
        </TitleLeft>
        <TitleRight>
          {toggle && toggle}
          {isOpen ? <DownArrowIcon fill="#41a1ea" /> : <UpArrowIcon />}
        </TitleRight>
      </TitleDiv>
      <ContentBoxWrap line={line} style={style} display={display} isOpen={isOpen}>
        <ChildrenWrapper isOpen={isOpen}>{children}</ChildrenWrapper>
      </ContentBoxWrap>
    </ContentsWrapper>
  );
};

const ContentsWrapper = styled.div`
  width: 90%;
  //padding: 10px 8px 0 2px;
`;

const ContentBoxWrap = styled.div`
  display: flex;
  flex-direction: ${({ display }) => (display ? display : 'column')};
  width: 100%;
  transition: 0.3s all;

  ${({ line, isOpen }) =>
    line &&
    css`
      border-bottom: 1px solid #dddddd;
    `};

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom: none;

      padding: 11px 11px 15px 11px;
    `};
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  //height: 25px;
  // margin-bottom: 10px;
  width: 100%;
  padding: 11px 5px 11px;
  align-items: center;
  cursor: pointer;

  ${({ line, isOpen }) =>
    isOpen &&
    css`
      color: rgb(65 161 234);
      //background-color: rgb(44 44 44 / 12%);
      // box-shadow: -2px 3px 20px 1px rgb(44 44 44 / 12%);
      border-bottom: 1px solid rgb(65 161 234);

      svg {
        color: rgb(65 161 234);
      }
    `};

  &:hover {
    background-color: rgb(44 44 44 / 12%);

    transition: 0.2s;
  }
`;

const TitleLeft = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-item: center;
`;

const TitleRight = styled.div`
  height: 100%;
`;

const TitleSpan = styled.span`
  // color: #333333;
  font-size: 0.85rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TipSpan = styled.span`
  color: #f05b5b;
  font-size: 12px;
  font-weight: 300;
  margin-left: 20px;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  flex: 1;
`;

const ChildrenWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;

  /* margin: 0px 2rem; */
`;

export const AppContentWrap = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
`;

export default React.memo(ContentBox);
