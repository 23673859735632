import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { ReactComponent as NoScreenIcon } from '../assets/images/dashboard/ic-noscreen.svg';

const Popup = ({ x, y, scrshotFile, scrshotDt }) => {
  return (
    <Background x={x} y={y}>
      <ScreenShotBox>
        {scrshotFile ? (
          <>
            <ScreenShotBoxImg
              src={`${process.env.REACT_APP_CORE_CDN_URL}${scrshotFile}`}
              alt=""
            />
            <ScreenShotDate>
              {dayjs(scrshotDt).format('YYYY-MM-DD HH:mm:ss')}
            </ScreenShotDate>
          </>
        ) : (
          <>
            <NoScreen>
              <NoScreenIcon />
              캡쳐된 화면이 없습니다.
            </NoScreen>
          </>
        )}
      </ScreenShotBox>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  left: ${props => props.x - 310}px;
  top: ${props => props.y + 10}px;
  width: 300px;
  height: 200px;
  z-index: 9999;
`;

const ScreenShotBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000000;
`;

const ScreenShotBoxImg = styled.img`
  position: absolute;
  width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ScreenShotDate = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  padding: 1.25rem;
  bottom: 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.4);
  letter-spacing: 0.0625rem;
`;

const NoScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15.625rem;
  background: #000000;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 1.5625rem;

  & > svg {
    margin-bottom: 0.625rem;
  }
`;

export default React.memo(Popup);
