import * as apiLib from './api';

export const getFrameList = async playlistId => {
  try {
    const data = {
      playlistId
    };

    const result = await apiLib.fetchInteractionApi('/frame/list', data);
    if (result.resultFlag) {
      return result.frameList;
    } else {
      if (result.isNotPlaylist) {
        throw Object.assign(new Error('no playlist.'), { status: 401 });
      } else {
        throw Object.assign(new Error('getFrameList error'), { status: 401 });
      }
    }
  } catch (err) {
    throw err;
  }
}

export const addFrame = async ({ frame }) => {
  try {
    const data = {
      ...frame,
    };

    const result = await apiLib.fetchInteractionApi('/frame/frame-add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const cloneFrame = async ({ cloneFrame, activeFrameId, cloneType }) => {
  try {
    const data = {
      ...cloneFrame,
      activeFrameId,
      cloneType,
    };

    const result = await apiLib.fetchInteractionApi('/frame/frame-clone', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('cloneFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const removeFrame = async frameId => {
  try {
    const data = {
      frameId,
    };

    const result = await apiLib.fetchInteractionApi(
      '/frame/frame-remove',
      data,
    );

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('removeFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const updateFrame = async ({ frameId, frameInfo }) => {
  try {
    const data = {
      frameId,
      frameInfo,
    };

    const result = await apiLib.fetchInteractionApi(
      '/frame/frame-update',
      data,
    );

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('updateFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const changePrimaryFrame = async ({ playlistId, frameId, primaryYn }) => {
  try {
    const data = {
      playlistId,
      frameId,
      primaryYn,
    };

    const result = await apiLib.fetchInteractionApi(
      '/frame/frame-primary-change',
      data,
    );

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('changePrimaryFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getLinkedFrame = async frameId => {
  try {
    const data = {
      frameId,
    };

    const result = await apiLib.fetchInteractionApi(
      '/frame/frame-link-list',
      data,
    );

    if (result.resultFlag) {
      return result.linkedFrameOrderList;
    } else {
      throw Object.assign(new Error('getLinkedFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}
