import * as apiLib from './api';

export const getPlaylistArray = async placeId => {
  try {
    const data = {
      placeId,
    };

    const result = await apiLib.fetchInteractionApi('/playlist/playlistArray-get', data);
    if (result.resultFlag) {
      return result.playlistArray;
    } else {
      throw Object.assign(new Error('getPlaylist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const addPlaylist = async playlist => {
  try {
    const data = {
      ...playlist,
    };

    const result = await apiLib.fetchInteractionApi('/playlist/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addPlaylist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const removePlaylist = async playlistId => {
  try {
    const data = {
      playlistIdList: [playlistId],
    };

    const result = await apiLib.fetchInteractionApi('/playlist/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('removePlaylist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const updatePlaylist = async ({ playlistId, updateInfo }) => {
  try {
    const data = {
      playlistId,
      updateInfo,
    };

    const result = await apiLib.fetchInteractionApi('/playlist/update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('updatePlaylist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const getFolderList = async placeId => {
  try {
    const data = {
      placeId,
    };

    const result = await apiLib.fetchInteractionApi('/playlist/folderList-get', data);
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('getFolderList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const addFolder = async folder => {
  try {
    const data = {
      ...folder,
    };

    const result = await apiLib.fetchInteractionApi('/playlist/folder-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addFolder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const updateFolder = async ({ folderId, updateInfo }) => {
  try {
    const data = {
      folderId,
      updateInfo
    };

    const result = await apiLib.fetchInteractionApi('/playlist/folder-update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('updateFolder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const removeFolder = async folderId => {
  try {
    const data = {
      folderId,
    }
    const result = await apiLib.fetchInteractionApi('/playlist/folder-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('removeFolder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const changePrimaryFrame = async ({playlistId, frameId, primaryYn}) => {
  try {
    const data = {
      playlistId,
      frameId,
      primaryYn,
    }
    const result = await apiLib.fetchInteractionApi('/playlist/playlist-change-primary', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('changePrimaryFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const previewToken = async (
  accountId,
  placeId,
  deviceId,
  playlistId,
) => {
  try {
    const data = {
      accountId,
      placeId,
      deviceId,
      playlistId,
    };

    const result = await apiLib.fetchInteractionApi('/playlist/preview-token', data);
    if (result.resultFlag) {
      return result.playToken;
    } else {
      throw Object.assign(new Error('previewToken error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
