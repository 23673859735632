import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import throttle from 'lodash/throttle';

import { editorAction, editorSelector } from '../../../module/editorSlice';

import Button from '../../../newComponents/Button';

import {
  endpoints as endpointsTouchLayerApi,
  useTouchLayerAddMutation,
  useTouchLayerRemoveMutation,
  useTouchLayerReOrderMutation,
  useTouchLayerUpdateMutation,
} from '../../../rtk/touchLayerApi';

import { FiMove as MoveIcon } from 'react-icons/fi';
import { ReactComponent as RemoveIcon } from '../../../assets/images/common/icon-trash.svg';

const TouchLayerCardList = ({ setIsDrag }) => {
  const dispatch = useDispatch();

  const playlistId = useSelector(editorSelector.playlistId);
  const frameId = useSelector(editorSelector.touchFrameId);
  const selectedTouchLayerList = useSelector(editorSelector.selectedTouchLayerList);

  const { currentData: layerList, isError } = endpointsTouchLayerApi.touchLayerList.useQueryState({ frameId });

  const [addTouchLayer] = useTouchLayerAddMutation();
  const [reOrderTouchLayer] = useTouchLayerReOrderMutation();
  const [removeTouchLayer] = useTouchLayerRemoveMutation();
  const [updateTouchLayer] = useTouchLayerUpdateMutation();

  const handleAddLayer = () => {
    const layerInfo = {
      playlistId,
      frameId,
    };
    addTouchLayerThrottle({ layerInfo });
  };

  const addTouchLayerThrottle = useMemo(
    () =>
      throttle(({ layerInfo }) => {
        addTouchLayer(layerInfo);
      }, 400),
    [addTouchLayer],
  );

  const handleClickLayer = useCallback(
    layer => {
      const { layerId, x, y, width, height } = layer;
      dispatch(
        editorAction.setState({
          key: 'selectedTouchLayerList',
          value: [{ id: layerId, type: 'LAYER', x, y, width, height }],
        }),
      );
    },
    [dispatch],
  );

  const handleRemoveLayer = useCallback(
    layer => {
      removeTouchLayer({ layerId: layer.layerId });
    },
    [removeTouchLayer],
  );

  const handleReorderLayer = useCallback(
    result => {
      if (!result.destination) return;

      let layerLen = layerList.length - 1;
      let startIndex = Math.abs(result.source.index - layerLen);
      let endIndex = Math.abs(result.destination.index - layerLen);

      let newLayerList = [...layerList];
      const [reorderLayerList] = newLayerList.splice(startIndex, 1);
      newLayerList.splice(endIndex, 0, reorderLayerList);

      const updateList = newLayerList.reduce((target, newLayer, index) => {
        if (newLayer.layerOrder !== index) {
          target.push({
            layerId: newLayer.layerId,
            layerOrder: index,
          });
        }
        return target;
      }, []);

      if (updateList.length > 0) {
        reOrderTouchLayer({ updateList });
      }
    },
    [reOrderTouchLayer, layerList],
  );

  return (
    <>
      <Wrap>
        {!layerList && !isError ? (
          <></>
        ) : isError ? (
          <></>
        ) : (
          <>
            <ToolArea>
              <Button outline onClick={() => handleAddLayer()}>
                추가
              </Button>
            </ToolArea>
            {layerList && layerList.length > 0 ? (
              <DragDropContext
                onDragStart={() => setIsDrag(true)}
                onDragEnd={e => {
                  setIsDrag(false);
                  handleReorderLayer(e);
                }}
              >
                <Droppable droppableId="touchLayerList">
                  {provided => (
                    <ListContainer {...provided.droppableProps} ref={provided.innerRef}>
                      {layerList
                        .slice(0)
                        .reverse()
                        .map((layer, index) => (
                          <Draggable key={layer.layerId} draggableId={layer.layerId} index={index}>
                            {(provided, snapshot) => (
                              <Card
                                ref={provided.innerRef}
                                onClick={() => handleClickLayer(layer)}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Layer
                                  index={index}
                                  layerInfo={layer}
                                  handleRemoveLayer={handleRemoveLayer}
                                  updateTouchLayer={updateTouchLayer}
                                  isSelect={selectedTouchLayerList.some(
                                    selectedLayer => selectedLayer.id === layer.layerId,
                                  )}
                                />
                              </Card>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </ListContainer>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <NoLayer>레이어가 없습니다</NoLayer>
            )}
          </>
        )}
      </Wrap>
    </>
  );
};

const Layer = React.memo(
  ({ index, layerInfo, contentsType, handleRemoveLayer, updateTouchLayer, isSelect, lockMode }) => {
    const nameRef = useRef(null);

    const [value, setValue] = useState(layerInfo.layerNm || '');

    useEffect(() => {
      setValue(value => layerInfo.layerNm || '');
    }, [layerInfo.layerNm]);

    useEffect(() => {
      if (!isSelect) {
        nameRef.current.blur();
      }
    }, [isSelect]);

    return (
      <>
        <Order>{index + 1}</Order>
        <Box isSelect={isSelect}>
          <LayerNm
            ref={nameRef}
            value={value}
            disabled={lockMode || !isSelect}
            placeholder="이름을 입력하세요"
            onKeyUp={e => e.keyCode === 13 && e.target.blur()}
            onChange={e => setValue(e.target.value)}
            onFocus={e => e.target.select()}
            onBlur={e =>
              updateTouchLayer({
                updateList: [{ layerId: layerInfo.layerId, updateInfo: { layerNm: e.target.value } }],
              })
            }
          />
          <Icon style={{ cursor: 'default' }}>
            <RemoveIcon
              onClick={e => {
                e.stopPropagation();
                handleRemoveLayer(layerInfo);
              }}
            />
          </Icon>
          <Icon>
            <MoveIcon className="move" color="#555" />
          </Icon>
        </Box>
      </>
    );
  },
);

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  padding: 10px 15px 20px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  flex: 1;
`;

const Order = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15px;
  height: 100%;
  font-size: 12px;
  color: #333;
  text-align: center;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  min-height: 50px;
  gap: 10px;

  cursor: default !important;
  .move {
    cursor: grab !important;
  }
`;

const Box = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 5px;
  border: 1px solid ${({ isSelect }) => (isSelect ? '#41A1EA' : '#dddddd')};
  border-radius: 8px;
  padding: 10px;
`;

const LayerNm = styled.input`
  flex: 1;
  height: 100%;
  border-radius: 6px;
  border: none;

  &:focus {
    outline-color: #dddddd;
  }

  &::placeholder {
    font-size: 11px;
    color: #999;
  }
`;

const Icon = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToolArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  min-height: 40px;
`;

const NoLayer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #666666;
`;

export default React.memo(TouchLayerCardList);
