import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import { dataURLtoFile, getImageThumb, ImagetoDataURL } from 'utils/file';

/**
 * 로컬스토리지에 최근재생목록 ID 저장
 * @param {string} playlistId
 * @param {string} accountId
 * @param {string} placeId
 */
export const fnSaveHistoryToLocalStorage = (playlistId, accountId, placeId) => {
  const historyPlaylistIdList = JSON.parse(localStorage.getItem(`historyPlaylistIdList-${accountId}-${placeId}`)) || [];

  const index = historyPlaylistIdList.findIndex(historyPlaylistId => historyPlaylistId === playlistId);

  if (index > -1) {
  } else {
    historyPlaylistIdList.unshift(playlistId);
  }
  // 히스토리 최근 10개까지만 기록
  if (historyPlaylistIdList.length > 10) {
    historyPlaylistIdList.pop();
  }

  localStorage.setItem(`historyPlaylistIdList-${accountId}-${placeId}`, JSON.stringify(historyPlaylistIdList));
};

/**
 * 로컬스토리지에서 최근 재생목록ID 삭제
 * @param {List} playlistIdList
 * @param {string} accountId
 * @param {string} placeId
 */
export const fnRemoveHistoryFromLocalStorage = (playlistIdList, accountId, placeId) => {
  let historyPlaylistIdList = JSON.parse(localStorage.getItem(`historyPlaylistIdList-${accountId}-${placeId}`)) || [];

  for (const historyPlaylistId of historyPlaylistIdList) {
    const index = playlistIdList.findIndex(playlistId => playlistId === historyPlaylistId);
    if (index > -1) {
      historyPlaylistIdList.splice(index, 1);
    }
  }

  localStorage.setItem(`historyPlaylistIdList-${accountId}-${placeId}`, JSON.stringify(historyPlaylistIdList));
};

///////////////////////////////////////////////////////////////////////////////////////////////
/**
 * 가장 첫번쨰 재생목록 id를 구하는 함수
 * @param {List} folderList
 * @returns {string} playlistId
 */
export const fnGetFirstPlaylistId = folderList => {
  let firstPlaylistId = '';
  const rootFolderList = folderList[0].childFolderList;

  if (folderList[0].childPlaylistArray.length) {
    firstPlaylistId = folderList[0].childPlaylistArray[0].playlistId;
  } else {
    for (let i = 0; i < rootFolderList.length; i++) {
      if (firstPlaylistId) {
        break;
      }
      let childFolderList = [rootFolderList[i]];
      while (childFolderList.length !== 0) {
        const node = childFolderList.shift();
        if (node.childPlaylistArray.length) {
          firstPlaylistId = node.childPlaylistArray[0].playlistId;
          break;
        }
        if (node.childFolderList.length) {
          childFolderList = [...node.childFolderList, ...childFolderList];
        }
      }
    }
  }
  return firstPlaylistId;
};

/**
 * 로컬스토리지에 최근재생목록 저장
 * @param {List} playlist
 * @param {string} accountId
 * @param {string} placeId
 */
export const fnSaveHistoryToLocalStorage2 = (playlist, accountId, placeId) => {
  const historyList = JSON.parse(localStorage.getItem(`playlistHistory-${accountId}-${placeId}`)) || [];
  let playlistHistoryList = historyList || [];

  // 중복 방지
  if (playlistHistoryList.length > 0) {
    const findIndex = playlistHistoryList.findIndex(
      historyPlaylist => historyPlaylist.playlistId === playlist.playlistId,
    );
    if (findIndex !== -1) {
      playlistHistoryList.splice(findIndex, 1);
    }
  }

  playlistHistoryList.unshift(playlist);

  // 히스토리 최근 3개까지만 기록
  if (playlistHistoryList.length > 3) {
    playlistHistoryList.pop();
  }

  localStorage.setItem(`playlistHistory-${accountId}-${placeId}`, JSON.stringify(playlistHistoryList));
};

/**
 * 로컬스토리지에서 최근 재생목록 삭제
 * @param {List} playlist
 * @param {string} accountId
 * @param {string} placeId
 */
export const fnRemoveHistoryFromLocalStorage2 = (playlistId, accountId, placeId) => {
  const historyList = JSON.parse(localStorage.getItem(`playlistHistory-${accountId}-${placeId}`)) || [];
  let playlistHistoryList = historyList || [];

  const findIndex = playlistHistoryList.findIndex(historyPlaylist => historyPlaylist.playlistId === playlistId);

  if (findIndex !== -1) {
    playlistHistoryList.splice(findIndex, 1);
  }

  localStorage.setItem(`playlistHistory-${accountId}-${placeId}`, JSON.stringify(playlistHistoryList));
};

/**
 * 로컬스토리지에서 최근 재생목록 업데이트
 * @param {List} playlist
 * @param {string} accountId
 * @param {string} placeId
 */
export const fnUpdateHistoryFromLocalStorage = (playlist, accountId, placeId) => {
  const historyList = JSON.parse(localStorage.getItem(`playlistHistory-${accountId}-${placeId}`)) || [];
  let playlistHistoryList = historyList || [];

  const findIndex = playlistHistoryList.findIndex(
    historyPlaylist => historyPlaylist.playlistId === playlist.playlistId,
  );

  if (findIndex !== -1) {
    playlistHistoryList[findIndex] = playlist;
  }

  localStorage.setItem(`playlistHistory-${accountId}-${placeId}`, JSON.stringify(playlistHistoryList));
};

//프레임 썸네일 생성
export const createFrameThumbNail = async ({ frameId, path, type = 'frame' }) => {
  const fileName = dayjs().unix() + v4().substr(0, 8) + '.jpg';
  let url = '';
  let param = '';
  let file;
  let layerList = [];

  if (type === 'frame') {
    //layer에 overflow hidden이 있을 경우 html2canvas 이미지 잘림 현상 일어남
    layerList = document.getElementsByClassName('layer');
    for (let i = 0; i < layerList.length; i++) {
      layerList[i].style.overflow = 'unset';
    }
  }
  if (path) {
    url = await ImagetoDataURL(`${process.env.REACT_APP_INTERACTION_CDN_URL}${path}`);
  } else {
    const element = document.getElementById(type === 'touch' ? 'touch-player-area' : 'player-area');

    try {
      await html2canvas(element, {
        useCORS: true,
        scale: 1,
        logging: false,
      }).then(async function (canvas) {
        url = canvas.toDataURL('image/jpeg', 0.1);
      });
    } catch (error) {
      throw error;
    }
  }
  file = await dataURLtoFile(url, fileName);
  if (file.size >= 1048576) {
    file = await getImageThumb(file);
  }
  param = {
    fileData: file,
    filePath: url,
    fileType: file.type,
    fileSize: file.size,
    fileNm: type + '-' + fileName,
    fileId: frameId,
  };
  if (type === 'frame') {
    for (let i = 0; i < layerList.length; i++) {
      layerList[i].style.overflow = 'hidden';
    }
  }
  return { param, frameId, url, layerList };
};
