import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { editorAction, editorSelector } from '../../module/editorSlice';

import { useLayerAddListMutation, useLayerRemoveListMutation, useLayerUpdateListMutation } from '../../rtk/layerApi';
import {
  useOverlayAddListMutation,
  useOverlayRemoveListMutation,
  useOverlayUpdateListMutation,
} from '../../rtk/overlayApi';

const HistoryManager = forwardRef(({ selectoRef }, ref) => {
  const dispatch = useDispatch();

  const isFrameClickLoading = useSelector(editorSelector.isFrameClickLoading);

  const [layerAddListMutation] = useLayerAddListMutation();
  const [layerRemoveListMutation] = useLayerRemoveListMutation();
  const [layerUpdateListMutation] = useLayerUpdateListMutation();

  const [overlayAddListMutation] = useOverlayAddListMutation();
  const [overlayRemoveListMutation] = useOverlayRemoveListMutation();
  const [overlayUpdateListMutation] = useOverlayUpdateListMutation();

  const redoHistoryStack = useRef([]);
  const undoHistoryStack = useRef([]);

  useEffect(() => {
    if (isFrameClickLoading) {
      redoHistoryStack.current = [];
      undoHistoryStack.current = [];
    }
  }, [isFrameClickLoading]);

  const addLayer = ({ infos, prevSelectedLayerList }) => {
    const layerAddList = [];
    const overlayAddList = [];

    for (const info of infos) {
      if (info.layerId) {
        layerAddList.push(info);
      } else if (info.overlayId) {
        overlayAddList.push(info);
      }
    }

    if (layerAddList.length > 0) {
      layerAddListMutation({ addList: layerAddList }).then(() => {
        if (overlayAddList.length <= 0) {
          dispatch(editorAction.setState({ key: 'selectedLayerList', value: prevSelectedLayerList || [] }));
        }
      });
    }

    if (overlayAddList.length > 0) {
      overlayAddListMutation({ addList: overlayAddList }).then(() => {
        dispatch(editorAction.setState({ key: 'selectedLayerList', value: prevSelectedLayerList || [] }));
      });
    }
  };

  const deleteLayer = ({ infos, nextSelectedLayerList }) => {
    const layerRemoveList = [];
    const overlayRemoveList = [];

    for (const info of infos) {
      if (info.layerId) {
        layerRemoveList.push(info);
      } else if (info.overlayId) {
        overlayRemoveList.push(info);
      }
    }

    if (layerRemoveList.length > 0) {
      layerRemoveListMutation({ removeList: layerRemoveList }).then(() => {
        if (overlayRemoveList.length <= 0) {
          dispatch(editorAction.setState({ key: 'selectedLayerList', value: nextSelectedLayerList || [] }));
        }
      });
    }

    if (overlayRemoveList.length > 0) {
      overlayRemoveListMutation({ removeList: overlayRemoveList }).then(() => {
        dispatch(editorAction.setState({ key: 'selectedLayerList', value: nextSelectedLayerList || [] }));
      });
    }
  };

  const undoUpdateLayer = ({ prevInfos }) => {
    const layerUpdateList = [];
    const overlayUpdateList = [];

    for (const info of prevInfos) {
      if (info.layerId) {
        layerUpdateList.push(info);
      } else if (info.overlayId) {
        overlayUpdateList.push(info);
      }
    }

    if (layerUpdateList.length > 0) {
      layerUpdateListMutation({ updateList: layerUpdateList });
    }

    if (overlayUpdateList.length > 0) {
      overlayUpdateListMutation({ updateList: overlayUpdateList });
    }

    dispatch(editorAction.setState({ key: 'selectedLayerList', value: [] }));
  };

  const redoUpdateLayer = ({ nextInfos }) => {
    const layerUpdateList = [];
    const overlayUpdateList = [];

    for (const info of nextInfos) {
      if (info.layerId) {
        layerUpdateList.push(info);
      } else if (info.overlayId) {
        overlayUpdateList.push(info);
      }
    }

    if (layerUpdateList.length > 0) {
      layerUpdateListMutation({ updateList: layerUpdateList });
    }

    if (overlayUpdateList.length > 0) {
      overlayUpdateListMutation({ updateList: overlayUpdateList });
    }

    dispatch(editorAction.setState({ key: 'selectedLayerList', value: [] }));
  };

  const undoSelectLayer = ({ prevSelectedLayerList }) => {
    dispatch(editorAction.setState({ key: 'selectedLayerList', value: prevSelectedLayerList }));
  };
  const redoSelectLayer = ({ nextSelectedLayerList }) => {
    dispatch(editorAction.setState({ key: 'selectedLayerList', value: nextSelectedLayerList }));
  };

  const types = useRef({
    'ADD-LAYER': { undo: deleteLayer, redo: addLayer },
    'REMOVE-LAYER': { undo: addLayer, redo: deleteLayer },
    'UPDATE-LAYER': { undo: undoUpdateLayer, redo: redoUpdateLayer },
    'SELECT-LAYER': { undo: undoSelectLayer, redo: redoSelectLayer },
  });

  const addHistory = ({ type, props }) => {
    undoHistoryStack.current.push({ type, props });
    redoHistoryStack.current = [];
    dispatch(editorAction.setState({ key: 'undoHistoryLength', value: undoHistoryStack.current.length }));
    dispatch(editorAction.setState({ key: 'redoHistoryLength', value: redoHistoryStack.current.length }));
  };

  const redoHistory = () => {
    const redoAction = redoHistoryStack.current.pop();
    dispatch(editorAction.setState({ key: 'redoHistoryLength', value: redoHistoryStack.current.length }));

    if (!redoAction) {
      console.log('no redo!');
      return;
    }

    types.current[redoAction.type].redo(redoAction.props);
    undoHistoryStack.current.push(redoAction);
    dispatch(editorAction.setState({ key: 'undoHistoryLength', value: undoHistoryStack.current.length }));
  };

  const undoHistory = () => {
    const undoAction = undoHistoryStack.current.pop();
    dispatch(editorAction.setState({ key: 'undoHistoryLength', value: undoHistoryStack.current.length }));

    if (!undoAction) {
      console.log('no undo!');
      return;
    }

    types.current[undoAction.type].undo(undoAction.props);
    redoHistoryStack.current.push(undoAction);
    dispatch(editorAction.setState({ key: 'redoHistoryLength', value: redoHistoryStack.current.length }));
  };

  useImperativeHandle(ref, () => ({
    addHistory,
    redoHistory,
    undoHistory,
  }));

  return <></>;
});

export default HistoryManager;
