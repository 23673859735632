import React, { useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import { useTimer } from '../../../utils/timerUtils';
import { TRANSITION } from './transitionProp';

const Media = ({ mediaList, contentsFileList, muteYn }) => {
  const [activeMediaIndex, setActiveMediaIndex] = useState(0);
  // const [isLoading, setIsLoading] = useState(true);
  const activeMedia = mediaList[activeMediaIndex];
  const activeMediaFile = contentsFileList.find(file => file?.fileId === activeMedia?.fileId);

  useTimer(
    () => setActiveMediaIndex((activeMediaIndex + 1) % mediaList.length),
    // isLoading ? null : Number(activeMedia.changeTime) * 1000,
    Number(activeMedia?.changeTime) * 1000,
    [activeMediaIndex, mediaList],
  );

  /* 미디어 트랜지션 설정 */
  /* 첫번째 prop => 넘겨줄 데이터 */
  /* 두번째 prop => 감시하고 있을 데이터 */
  const transitions = useTransition(
    { activeMedia, activeMediaFile },
    { ...TRANSITION?.[activeMedia?.animation || 'none'], key: activeMediaIndex },
  );

  return transitions((props, item) => {
    const mediaPath = `${process.env.REACT_APP_INTERACTION_CDN_URL}${
      item.activeMediaFile?.fileThumb || item.activeMediaFile?.filePath
    }`;
    return (
      <MediaBox key={props.key} option={item.activeMedia?.fitOption} style={props}>
        {/* {item.activeMediaFile?.fileType.includes('image') ? (
          <img src={mediaPath} alt="" />
        ) : item.activeMediaFile?.fileType.includes('video') ? (
          <video src={mediaPath} muted={muteYn === 'Y'} autoPlay preload="auto" loop />
        ) : null} */}
        <img src={mediaPath} alt="" />
      </MediaBox>
    );
  });
};

const MediaBox = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: ${props => props.option || 'none'};
  }
  /* ${({ $isLoading }) => {
    if ($isLoading) {
      return css`
        filter: blur(15px);
        -webkit-filter: blur(15px);
        img,
        video {
          visibility: hidden;
        }
      `;
    }
  }};
  ${({ $thumbUrl, props }) => {
    if ($thumbUrl) {
      return css`
        background-image: url(${$thumbUrl});
        background-repeat: no-repeat;
        background-size: ${props => props.option || 'none'};
      `;
    }
  }}; */
`;

export default React.memo(Media);
