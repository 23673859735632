import React from 'react';
import styled from 'styled-components';

const SettingTab = ({ tabIndex, tabList, tabChange }) => {
  return (
    <Container>
      <TabButtonArea>
        {tabList?.map((tab, index) => (
          <TabButton key={`tabIndex-${index}`} isSelected={tabIndex === index} onClick={e => tabChange(index)}>
            {tab.title}
          </TabButton>
        ))}
      </TabButtonArea>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  background: #ffffff;
  border-bottom: 1px solid #dddddd;
`;

const TabButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 0 16px;
  gap: 16px;
`;

const TabButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  color: ${props => (props.isSelected ? '#41a1ea' : '#000000')};
  font-weight: 500;
  font-size: 15px;

  cursor: pointer;

  &:hover {
    color: #41a1ea;
  }
`;

export default React.memo(SettingTab);
