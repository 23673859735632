import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import RadioCheckBox from '../../../newComponents/RadioCheckbox';

import Loading from '../../../newComponents/Loading';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppTitle from './components/AppTitle';
import useForm from './components/useForm';
import Weather from './components/Weather';
import AppButtonArea from './components/AppButtonArea';
import { useDispatch, useSelector } from 'react-redux';

import { editorAction, editorSelector } from '../../../module/editorSlice';

import qs from 'qs';
import { INIT_WEATEHRAPP_INFO } from '../constants/contentApp';
import { endpoints as endpointsGroupApi, useGroupListQuery } from '../../../rtk/groupApi';

const WeatherApp = ({ selectLayerContentsInfo = INIT_WEATEHRAPP_INFO, handleCancelLayerContents, selectedLayerType, appLoading }) => {
  const placeId = useSelector(editorSelector.placeId);
  const { data: groupList } = endpointsGroupApi.groupList.useQueryState({ placeId: placeId, groupPid: placeId });

  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_WEATEHRAPP_INFO);
  const [formValidate, setFormValidate] = useState({ locationList: [] });
  const [groupOptionList, setGroupOptionList] = useState([]);
  const [optionListChangeFlag, setOptinoListChangeFlag] = useState(false);

  const [isDetailOpen, setIsDetailOpen] = useState(true);
  const [isLocationOpen, setIsLocationOpen] = useState(true);

  useEffect(() => {
    if (groupList) {
      setGroupOptionList(groupList.map(group => ({ title: group.groupNm, value: group.groupId })));
    }
  }, [groupList]);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const appContentWrapRef = useRef(null);
  const weatherListRef = useRef([]);


  const prevScrollListLength = useRef(formData.contentsData.locationList.length);

  const handleChangeWeatherOption = useCallback(
    (index, key, value) => {
      let changeFormData = [...formData.contentsData.locationList];
      changeFormData[index][key] = value;

      handleChangeObject('contentsData', 'locationList', changeFormData);
    },
    [formData.contentsData.locationList, handleChangeObject],
  );

  const handleChangeWeatherOptionRemove = useCallback(
    index => {
      let changeFormData = [...formData.contentsData.locationList].filter((data, idx) => idx !== index);
      handleChangeObject('contentsData', 'locationList', changeFormData);
      setOptinoListChangeFlag(true);
    },
    [formData.contentsData.locationList, handleChangeObject],
  );

  const handleChangeWeatherOptionAdd = useCallback(() => {
    let changeFormData = [...formData.contentsData.locationList];
    changeFormData.push({ targetGroupId: '', location: '전국 날씨 (자동 전환)' });
    handleChangeObject('contentsData', 'locationList', changeFormData);
    setOptinoListChangeFlag(true);
  }, [formData.contentsData.locationList, handleChangeObject]);

  const checkValidDate = useCallback(() => {
    let validate = {
      locationList: [],
    };
    let validFlag = false;

    formData.contentsData.locationList.forEach((location, index) => {
      let locationValidate = {};

      if (location.targetGroupId === '') {
        validFlag = true;
        locationValidate.targetGroupId = false;
        locationValidate.targetGroupIdError = '타겟 그룹을 지정하세요.';
        setIsLocationOpen(true);
      } else {
        locationValidate.targetGroupId = true;
        locationValidate.targetGroupIdError = '';
      }

      if (location.location === '') {
        validFlag = true;
        locationValidate.location = false;
        locationValidate.locationError = '지역을 설정하세요.';
        setIsLocationOpen(true);
      } else {
        locationValidate.location = true;
        locationValidate.locationError = '';
      }

      validate.locationList.push(locationValidate);
    });

    setFormValidate(validate);
    
    if(validFlag){
      const locationList = validate.locationList;
      for(let index = 0; index < locationList.length; index++ ){

        if(!locationList[index].targetGroupId || !locationList[index].location){
          setTimeout(() => {
            appContentWrapRef.current.scrollTo({ top:weatherListRef.current[index].scrollIntoView({block: "center"})});
          }, 100); 
          break;
        }
      }
    }

    return validFlag;
  }, [formData.contentsData.locationList]);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };
      if (!layerContentsInfo.contentsData.locationList) {
        layerContentsInfo.contentsData.locationList = [{ targetGroupId: 'DEFAULT', location: '서울특별시' }];
      }
      if (!layerContentsInfo.contentsData.detail) {
        layerContentsInfo.contentsData.detail = 'degree';
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_WEATEHRAPP_INFO);
    }
  }, [selectLayerContentsInfo]);

  useEffect(() => {
    if (optionListChangeFlag) {
      if (prevScrollListLength.current < formData.contentsData.locationList.length) {
        appContentWrapRef.current.scrollTo(0, appContentWrapRef.current.scrollHeight);
      }
      prevScrollListLength.current = formData.contentsData.locationList.length;
      setOptinoListChangeFlag(false);
    }
  }, [formData.contentsData.locationList.length, optionListChangeFlag]);

  return (
    <Container>
      <AppTitle text="날씨 App" />
      <AppContentWrap ref={appContentWrapRef}>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox title="세부 설정" isOpen={isDetailOpen} changeOpen={setIsDetailOpen} line>
              <RadioCheckBoxArea>
                <RadioCheckBoxWrapper>
                  <RadioCheckBox
                    name="detailCheck"
                    id="detailId-0"
                    checked={formData.contentsData.detail === 'degree'}
                    onChange={() => handleChangeObject('contentsData', 'detail', 'degree')}
                    label='온도'
                  />
                </RadioCheckBoxWrapper>
                <RadioCheckBoxWrapper >
                  <RadioCheckBox
                    name="detailCheck"
                    id="detailId-1"
                    checked={formData.contentsData.detail === 'degreeAndDust'}
                    onChange={() => handleChangeObject('contentsData', 'detail', 'degreeAndDust')}
                    label='온도, 미세먼지'
                  />
                </RadioCheckBoxWrapper>
              </RadioCheckBoxArea>
            </ContentBox>
            <ContentBox title="위치" isOpen={isLocationOpen} changeOpen={setIsLocationOpen}>
              {formData.contentsData.locationList &&
                formData.contentsData.locationList.map((group, index) => (
                  <WeatherWrapper       key={`wetherWrapper-app-${index}`} ref={ref =>  weatherListRef.current[index] = ref }>
                  <Weather
                    key={`wether-app-${index}`}
                    index={index}
                    group={group}
                    groupOptionList={groupOptionList}
                    handleChangeWeatherOption={handleChangeWeatherOption}
                    handleChangeWeatherOptionRemove={handleChangeWeatherOptionRemove}
                    formValidate={formValidate}
                  />
                  </WeatherWrapper>
                ))}

              <AddButton onClick={() => handleChangeWeatherOptionAdd()}>추가</AddButton>
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea formData={formData} checkValidDate={checkValidDate} cancelApp={handleCancelLayerContents} selectedLayerType={selectedLayerType} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const RadioCheckBoxArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  font-size: 14px;

  & > div:not(:last-child) {
    margin-right: 15px;
  }
`;

const RadioCheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RadioLabel = styled.label`
  margin-left: 5px;
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 100%;
  height: 40px;
  border: 1px solid #2a91df;
  background: #ffffff;
  border-radius: 6px;
  color: #2a91df;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background: #f1f1f1;
  }
`;

const WeatherWrapper = styled.div``;

export default React.memo(WeatherApp);
