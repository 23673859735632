import React, { useRef, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { SketchPicker } from 'react-color';

import Loading from '../../../newComponents/Loading';
import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';
import Toggle from '../../../newComponents/Toggle';

import { INIT_TEXT_INFO } from '../constants/contentApp';

import SelectInput from '../../../newComponents/SelectInput';

import { ReactComponent as FontColorIcon } from '../../../assets/images/fontOption/icon-font-color.svg';
import { ReactComponent as BoldIcon } from '../../../assets/images/fontOption/icon-font-option-bold.svg';
import { ReactComponent as ItalicIcon } from '../../../assets/images/fontOption/icon-font-option-italic.svg';
import { ReactComponent as LineThroughIcon } from '../../../assets/images/fontOption/icon-font-option-line-through.svg';
import { ReactComponent as ShadowIcon } from '../../../assets/images/fontOption/icon-font-option-shadow.svg';
import { ReactComponent as UnderlineIcon } from '../../../assets/images/fontOption/icon-font-option-underline.svg';
import { ReactComponent as AlignLeftIcon } from '../../../assets/images/fontOption/icon-font-position-left.svg';
import { ReactComponent as AlignCenterIcon } from '../../../assets/images/fontOption/icon-font-position-center.svg';
import { ReactComponent as AlignRightIcon } from '../../../assets/images/fontOption/icon-font-position-right.svg';
import { ReactComponent as AlignJustifyIcon } from '../../../assets/images/fontOption/icon-font-position-justify.svg';
import { ReactComponent as AlignBottomIcon } from '../../../assets/images/fontOption/icon-font-vector-bottom.svg';
import { ReactComponent as AlignMiddleIcon } from '../../../assets/images/fontOption/icon-font-vector-middle.svg';
import { ReactComponent as AlignTopIcon } from '../../../assets/images/fontOption/icon-font-vector-top.svg';
import { ReactComponent as LineHeightIcon } from '../../../assets/images/fontOption/icon-font-line-height.svg';
import ClickOutside from '../../../components/ClickOutside';

const TextApp = ({ selectLayerContentsInfo = INIT_TEXT_INFO, handleCancelLayerContents, selectedLayerType, appLoading }) => {
  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_TEXT_INFO);

  const { formData, handleChangeObject, handleChange } = useForm({ initialValues: layerContentsInfo });

  const appContentWrapRef = useRef(null);
  const textRef = useRef(null);

  const [isFontColorOpen, setIsFontColorOpen] = useState(false);
  const [formValidate, setFormValidate] = useState({
    text: true,
    textError: '',
  });

  const [isTextOpen, setIsTextOpen] = useState(true);
  const [isFontOpen, setIsFontOpen] = useState(true);

  const InitFontSizeOptionList = useRef([
    { value: '8', title: '8' },
    { value: '9', title: '9' },
    { value: '10', title: '10' },
    { value: '11', title: '11' },
    { value: '12', title: '12' },
    { value: '14', title: '14' },
    { value: '16', title: '16' },
    { value: '18', title: '18' },
    { value: '19', title: '19' },
    { value: '20', title: '20' },
    { value: '24', title: '24' },
    { value: '28', title: '28' },
    { value: '32', title: '32' },
    { value: '36', title: '36' },
    { value: '40', title: '40' },
    { value: '44', title: '44' },
    { value: '48', title: '48' },
    { value: '50', title: '50' },
    { value: '54', title: '54' },
    { value: '60', title: '60' },
    { value: '70', title: '70' },
    { value: '80', title: '80' },
    { value: '90', title: '90' },
  ]);
  const InitLineHeightOptionList = useRef([
    { value: '1.0', title: '1.0' },
    { value: '1.5', title: '1.5' },
    { value: '2.0', title: '2.0' },
    { value: '2.5', title: '2.5' },
    { value: '3.0', title: '3.0' },
  ]);

  const checkValidDate = useCallback(() => {
    let validate = {};
    let validFlag = false;

    if (formData.contentsData.text.trim() === '') {
      validFlag = true;
      validate.text = false;
      validate.textError = '내용을 입력하세요.';
      setIsTextOpen(true);
    } else {
      validate.text = true;
      validate.textError = '';
    }
    setFormValidate(validate);
    if (validFlag) {
      setTimeout(() => {
        appContentWrapRef.current.scrollTo({ top: textRef.current.scrollIntoView({ block: 'center' }) });
      }, 100);
    }
    return validFlag;
  }, [formData.contentsData.text]);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };

      if (!layerContentsInfo.contentsData.text) {
        layerContentsInfo.contentsData.text = '';
      }
      if (!layerContentsInfo.contentsData.color) {
        layerContentsInfo.contentsData.color = { r: '102', g: '102', b: '102', a: '100' };
      }
      if (!layerContentsInfo.contentsData.lineHeight) {
        layerContentsInfo.contentsData.lineHeight = '1.0';
      }
      if (!layerContentsInfo.contentsData.fontSize) {
        layerContentsInfo.contentsData.fontSize = '10';
      }
      if (!layerContentsInfo.contentsData.boldYn) {
        layerContentsInfo.contentsData.boldYn = 'N';
      }
      if (!layerContentsInfo.contentsData.italicYn) {
        layerContentsInfo.contentsData.italicYn = 'N';
      }
      if (!layerContentsInfo.contentsData.underlineYn) {
        layerContentsInfo.contentsData.underlineYn = 'N';
      }
      if (!layerContentsInfo.contentsData.lineThroughYn) {
        layerContentsInfo.contentsData.lineThroughYn = 'N';
      }
      if (!layerContentsInfo.contentsData.textShadowYn) {
        layerContentsInfo.contentsData.textShadowYn = 'N';
      }
      if (!layerContentsInfo.contentsData.scrollYn) {
        layerContentsInfo.contentsData.scrollYn = 'N';
      }
      if (!layerContentsInfo.contentsData.textAlign) {
        layerContentsInfo.contentsData.textAlign = {
          vertical: 'flex-start',
          horizontal: 'left',
        };
      }

      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_TEXT_INFO);
    }
  }, [selectLayerContentsInfo]);

  return (
    <Container>
      <AppTitle text="텍스트 App" />
      <AppContentWrap ref={appContentWrapRef}>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox title="내용" isOpen={isTextOpen} changeOpen={setIsTextOpen} line>
              <TextArea
                ref={textRef}
                placeholder="* 내용을 입력하세요"
                rows="5"
                value={formData.contentsData.text || ''}
                isValidate={formValidate.text}
                onChange={e => handleChangeObject('contentsData', 'text', e.target.value)}
              />
              <InputError>{formValidate.textError}</InputError>
            </ContentBox>
            <ContentBox title="폰트 옵션" isOpen={isFontOpen} changeOpen={setIsFontOpen}>
              <FontWrapper>
                <FontColorWrap>
                  <FontBtn fontColor={formData.contentsData.color} onClick={() => setIsFontColorOpen(!isFontColorOpen)}>
                    <FontColorIcon />
                  </FontBtn>
                  <ClickOutside onClickOutside={() => setIsFontColorOpen(false)}>
                    <FontColorModal style={isFontColorOpen ? { display: 'block', zIndex: '99' } : { display: 'none' }}>
                      <SketchPicker color={formData.contentsData.color} onChange={color => handleChangeObject('contentsData', 'color', color.rgb)} />
                    </FontColorModal>
                  </ClickOutside>
                </FontColorWrap>
                <FontBtn
                  className={formData.contentsData.boldYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'boldYn', formData.contentsData.boldYn === 'N' ? 'Y' : 'N')}
                >
                  <BoldIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.italicYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'italicYn', formData.contentsData.italicYn === 'N' ? 'Y' : 'N')}
                >
                  <ItalicIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.underlineYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'underlineYn', formData.contentsData.underlineYn === 'N' ? 'Y' : 'N')}
                >
                  <UnderlineIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.textShadowYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'textShadowYn', formData.contentsData.textShadowYn === 'N' ? 'Y' : 'N')}
                >
                  <ShadowIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.lineThroughYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'lineThroughYn', formData.contentsData.lineThroughYn === 'N' ? 'Y' : 'N')}
                >
                  <LineThroughIcon />
                </FontBtn>
                <CustomSelectInput
                  selectedValue={formData.contentsData.fontSize}
                  optionList={InitFontSizeOptionList.current}
                  onSelectChange={value => handleChangeObject('contentsData', 'fontSize', value)}
                  width={'60px'}
                />
                <FontBtn
                  className={formData.contentsData.textAlign.horizontal === 'left' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'textAlign', { ...formData.contentsData.textAlign, horizontal: 'left' })}
                >
                  <AlignLeftIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.textAlign.horizontal === 'center' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'textAlign', { ...formData.contentsData.textAlign, horizontal: 'center' })}
                >
                  <AlignCenterIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.textAlign.horizontal === 'right' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'textAlign', { ...formData.contentsData.textAlign, horizontal: 'right' })}
                >
                  <AlignRightIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.textAlign.horizontal === 'justify' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'textAlign', { ...formData.contentsData.textAlign, horizontal: 'justify' })}
                >
                  <AlignJustifyIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.textAlign.vertical === 'flex-start' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'textAlign', { ...formData.contentsData.textAlign, vertical: 'flex-start' })}
                >
                  <AlignTopIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.textAlign.vertical === 'center' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'textAlign', { ...formData.contentsData.textAlign, vertical: 'center' })}
                >
                  <AlignMiddleIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.textAlign.vertical === 'flex-end' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'textAlign', { ...formData.contentsData.textAlign, vertical: 'flex-end' })}
                >
                  <AlignBottomIcon />
                </FontBtn>
                <CustomSelectInput
                  selectedValue={formData.contentsData.lineHeight}
                  optionList={InitLineHeightOptionList.current}
                  onSelectChange={value => handleChangeObject('contentsData', 'lineHeight', value)}
                  width={'60px'}
                />
              </FontWrapper>
              <Box>
                <LeftArea>
                  <Title>스크롤</Title>
                </LeftArea>
                <RightArea>
                  <Toggle
                    // style={{ marginRight: '310px' }}
                    // width={50}
                    // height={25}
                    isChecked={formData.contentsData.scrollYn === 'Y' ? true : false}
                    onChange={e => {
                      let changeTextAlign = { ...formData.contentsData.textAlign, vertical: 'flex-start' };
                      let changeScrollYn = formData.contentsData.scrollYn === 'Y' ? 'N' : 'Y';
                      handleChange('contentsData', { ...formData.contentsData, textAlign: changeTextAlign, scrollYn: changeScrollYn });
                    }}
                  />
                </RightArea>
              </Box>
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea formData={formData} checkValidDate={checkValidDate} cancelApp={handleCancelLayerContents} selectedLayerType={selectedLayerType} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const TextArea = styled.textarea`
  width: 100%;
  border-radius: 6px;
  padding: 20px;
  border: 0.0625rem solid ${props => (props.isValidate === undefined || props.isValidate === true ? '#DDDDDD' : '#F05B5B')};
  background-color: #ffffff;
  font-size: 14px;
  color: #999999;
  &:focus {
    outline: none;
  }
  resize: vertical;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
`;
const InputError = styled.label`
  height: 1.25rem;
  padding: 0.125rem 0.25rem;
  color: #f05b5b;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const LeftArea = styled.div`
  flex: 1;
  align-items: center;
`;
const RightArea = styled.div`
  flex: 3;
  align-items: center;
`;
const Title = styled.div`
  width: 100%;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
`;
const FontWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FontBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: none;
  margin-right: 10px;
  margin-bottom: 10px;

  &.active {
    color: #2a91df;
    background-color: #e3f0fa;
  }

  &:hover {
    background-color: #efeff4;
  }

  &:disabled {
    background-color: #efeff4;
  }

  svg {
    & .font-color {
      fill: ${({ fontColor }) => fontColor && `rgb(${fontColor.r}, ${fontColor.g}, ${fontColor.b})`};
      stroke: ${({ fontColor }) => fontColor && `rgb(${fontColor.r}, ${fontColor.g}, ${fontColor.b})`};
    }
  }
`;

const FontColorWrap = styled.div`
  position: relative;
`;

const FontColorModal = styled.div`
  position: absolute;
`;
const CustomSelectInput = styled(SelectInput)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

export default React.memo(TextApp);
