import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const WebView = ({ webViewData }) => {
  const [webUrl, setWebUrl] = useState(webViewData.url);

  useEffect(() => {
    if (!webViewData.url.startsWith("http")) {
      setWebUrl("https://" + webViewData.url);
    }
  }, [webViewData.url]);

  return (
    <WebViewBox onClick={e => e.preventDefault()}>
      <iframe src={webUrl} title="WebViewBox" />
    </WebViewBox>
  );
};

const WebViewBox = styled.div`
  width: 100%;
  height: 100%;
  /* overflow: hidden; */

  & > iframe {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  * {
    -webkit-user-drag: none;
    pointer-events: none;
  }
`;

export default React.memo(WebView);
