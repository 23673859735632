import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';

import Toggle from '../../../newComponents/Toggle';
import RadioCheckBox from '../../../newComponents/RadioCheckbox';
import ClickOutside from '../../../components/ClickOutside';
import DurationButton from '../../../newComponents/DurationButton';
import SelectInput from '../../../newComponents/SelectInput';
import InputText from '../../../newComponents/InputText';
import debounce from 'lodash/debounce';
import { endpoints as endpointsFrameApi } from '../../../rtk/frameApi';
import { endpoints as endpointsTouchFrameApi } from '../../../rtk/touchFrameApi';

import { FiEye as HideIcon, FiEyeOff as ActiveHideIcon } from 'react-icons/fi';
import { TiStarOutline as PrimaryIcon, TiStarFullOutline as ActivePrimaryIcon } from 'react-icons/ti';
import { useFramePrimaryChangeMutation, useFrameUpdateMutation } from '../../../rtk/frameApi';
import { usePlaylistUpdateMutation } from '../../../rtk/playlistApi';
import { useEffect } from 'react';
import ConfirmModal from '../../../newComponents/ConfirmModal';
import { toast } from 'react-toastify';

export const FrameRemoveModal = memo(({ isOpen, frameLinkList, closeModal, frameId, index, offset, removeFrame }) => {
  return (
    <ConfirmModal
      isOpen={isOpen}
      type="warn"
      size="small"
      left={offset.left}
      top={offset.top}
      titleTxt="프레임 삭제"
      body={
        <>
          {frameLinkList?.length ? (
            <div>
              <p>삭제된 프레임은 복구되지 않으며,</p>
              <p>연결된 프레임 링크가 해제되면서 {frameLinkList.map(item => item.data).join(',')} 번 프레임이</p>
              <p>오작동 할 수 있습니다.</p>
              <p>그래도 프레임 삭제하시겠습니까?</p>
            </div>
          ) : (
            <p>삭제 된 프레임은 복구 되지 않습니다. 그래도 프레임을 삭제 하시겠습니까?</p>
          )}
        </>
      }
      closeTxt="취소"
      confirmTxt="삭제"
      handleConfirm={e => removeFrame()}
      handleClose={() => closeModal()}
    />
  );
});

export const FrameSetModal = memo(
  ({ openHideFrameModal, closeModal, frameInfo, modalInfo, frameLinkList, setFrameDurationObj }) => {
    const { currentData: frameList } = endpointsFrameApi.frameList.useQueryState({
      playlistId: frameInfo.playlistId,
    });
    const { currentData: touchFrameList } = endpointsTouchFrameApi.touchFrameList.useQueryState({
      playlistId: frameInfo.playlistId,
    });
    const [frameUpdate] = useFrameUpdateMutation();
    const [framePrimaryChange] = useFramePrimaryChangeMutation();
    const [playlistUpdate] = usePlaylistUpdateMutation();

    const [nextFrameOption, setNextFrameOption] = useState([]);
    const [touchFrameOption, setTouchFrameOption] = useState([]);
    const [frameSetModalInfo, setFrameSetModalInfo] = useState(frameInfo);

    useEffect(() => {
      let array = [{ title: '없음', value: '' }];

      frameList.map((item, index) => {
        const obj = {};
        obj.title = `${index + 1}) ${item.frameNm || ''}`;
        obj.value = item.frameId;
        array.push(obj);
        return '';
      });

      setNextFrameOption(array);
    }, [frameList]);

    useEffect(() => {
      let array = [{ title: '없음', value: '' }];

      touchFrameList.map(item => {
        const obj = {};
        obj.title = item.frameNm;
        obj.value = item.frameId;
        array.push(obj);
        return '';
      });

      setTouchFrameOption(array);
    }, [touchFrameList]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setModalDebounce = useCallback(
      debounce(({ key, value }) => {
        frameUpdate({ frameId: frameInfo.frameId, frameInfo: { [key]: value } });
      }, 1000),
      [],
    );

    // 프레임 설정 모달 변경 이벤트
    const changeFrameSetModalInfo = useCallback(
      (e, key, value) => {
        const playlistId = frameInfo.playlistId;
        const frameId = frameInfo.frameId;

        if (value === 'Y' && frameInfo.hideYn === 'Y' && key === 'primaryYn') {
          toast.error('숨긴 프레임은 대표로 지정할 수 없습니다.');
          return false;
        }
        if (value === 'Y' && frameInfo.primaryYn === 'Y' && key === 'hideYn') {
          toast.error('대표 프레임은 숨길 수 없습니다.');
          return false;
        } else if (key === 'hideYn') {
          if (frameLinkList?.length > 0 && value === 'Y') {
            // 체크 할때 연결된 프레임 링크가 있을때 숨기기 모달 오픈
            openHideFrameModal(e, 'hide', frameInfo);
            return false;
          }
        }
        setFrameSetModalInfo(frameSetModalInfo => ({
          ...frameSetModalInfo,
          [key]: value,
        }));
        if (key === 'primaryYn') {
          framePrimaryChange({ playlistId, frameId, primaryYn: value }).then(() => {
            playlistUpdate({ playlistId, updateInfo: { primaryFrameId: value === 'Y' ? frameId : '' } });
          });
        } else if (key === 'frameNm') {
          setModalDebounce({ key, value });
        } else {
          if (key === 'frameDuration') {
            setFrameDurationObj(value);
          }
          frameUpdate({ frameId: frameInfo.frameId, frameInfo: { [key]: value } });
        }
      },
      [
        frameInfo,
        frameLinkList?.length,
        framePrimaryChange,
        frameUpdate,
        openHideFrameModal,
        playlistUpdate,
        setFrameDurationObj,
        setModalDebounce,
      ],
    );

    return (
      <ClickOutside onClickOutside={() => closeModal(frameInfo.frameId, frameSetModalInfo)}>
        <Modal top={modalInfo.offset.top} left={modalInfo.offset.left} width={340}>
          <ModalTitle>
            <span>프레임 설정</span>
            <ModalAction>
              <FrameHideBtn
                onClick={e => changeFrameSetModalInfo(e, 'hideYn', frameSetModalInfo.hideYn === 'Y' ? 'N' : 'Y')}
              >
                {frameSetModalInfo.hideYn === 'Y' ? (
                  <ActiveHideIcon size={20} color="#f7c139" />
                ) : (
                  <HideIcon size={20} color="#666666" />
                )}
              </FrameHideBtn>
              <FramePrimaryBtn
                onClick={e => changeFrameSetModalInfo(e, 'primaryYn', frameSetModalInfo.primaryYn === 'Y' ? 'N' : 'Y')}
              >
                {frameSetModalInfo.primaryYn === 'Y' ? (
                  <ActivePrimaryIcon size={20} color="#f7c139" />
                ) : (
                  <PrimaryIcon size={20} color="#666666" />
                )}
              </FramePrimaryBtn>
            </ModalAction>
          </ModalTitle>
          <ModalBody>
            <Row className="js">
              <Label>이름</Label>
              <Item>
                <InputText
                  height={'100%'}
                  width={'100%'}
                  placeholder="프레임 이름을 입력하세요"
                  value={frameSetModalInfo.frameNm || ''}
                  onChange={e => changeFrameSetModalInfo(e, 'frameNm', e.target.value)}
                />
              </Item>
            </Row>
            <Row className="js">
              <Label>자동 넘김</Label>
              <Item>
                <Toggle
                  width={40}
                  height={20}
                  isChecked={frameSetModalInfo.autoNextYn === 'Y' ? true : false}
                  onChange={e => changeFrameSetModalInfo(e, 'autoNextYn', e.target.checked ? 'Y' : 'N')}
                />
              </Item>
            </Row>
            <Row className="js">
              <Label>자동 넘김 옵션</Label>
              <ItemGroup>
                <Item>
                  <RadioCheckBox
                    name="autoNextType"
                    id="autoNextType-0"
                    label="다음 프레임"
                    disabled={frameSetModalInfo.autoNextYn !== 'Y'}
                    checked={frameSetModalInfo.autoNextType === 'next'}
                    onChange={e => changeFrameSetModalInfo(e, 'autoNextType', 'next')}
                  />
                </Item>
                <Item>
                  <RadioCheckBox
                    name="autoNextType"
                    id="autoNextType-1"
                    label="대표 프레임"
                    disabled={frameSetModalInfo.autoNextYn !== 'Y'}
                    checked={frameSetModalInfo.autoNextType === 'primary'}
                    onChange={e => changeFrameSetModalInfo(e, 'autoNextType', 'primary')}
                  />
                </Item>
                <Item>
                  <RadioCheckBox
                    name="autoNextType"
                    id="autoNextType-2"
                    label="지정 프레임"
                    disabled={frameSetModalInfo.autoNextYn !== 'Y'}
                    checked={frameSetModalInfo.autoNextType === 'frameId'}
                    onChange={e => changeFrameSetModalInfo(e, 'autoNextType', 'frameId')}
                  />
                </Item>
                <Item>
                  <SelectInput
                    height={'30px'}
                    onSelectChange={value => changeFrameSetModalInfo('', 'autoNextFrameId', value)}
                    selectedValue={frameSetModalInfo.autoNextFrameId || ''}
                    optionList={nextFrameOption}
                    itemLimit={nextFrameOption.length > 3 ? 3 : null}
                    disabled={frameSetModalInfo.autoNextYn !== 'Y' || frameSetModalInfo.autoNextType !== 'frameId'}
                  />
                </Item>
                <Item>
                  <RadioCheckBox
                    name="autoNextType"
                    id="autoNextType-3"
                    label="직전 프레임"
                    disabled={frameSetModalInfo.autoNextYn !== 'Y'}
                    checked={frameSetModalInfo.autoNextType === 'prev'}
                    onChange={e => changeFrameSetModalInfo(e, 'autoNextType', 'prev')}
                  />
                </Item>
              </ItemGroup>
            </Row>
            {frameSetModalInfo.autoNextYn === 'Y' && <div></div>}
          </ModalBody>
          <ModalFooter>
            <Row>
              <Label>재생시간</Label>
              <Item>
                <DurationButton
                  isDisabled={frameSetModalInfo.autoNextYn === 'N'}
                  duration={frameSetModalInfo.frameDuration}
                  onChangeDuration={time => changeFrameSetModalInfo('', 'frameDuration', time)}
                />
              </Item>
            </Row>
            <Row className="js">
              <Label>터치 영역</Label>
              <Item>
                <SelectInput
                  height={'30px'}
                  onSelectChange={value => changeFrameSetModalInfo('', 'touchFrameId', value)}
                  selectedValue={frameSetModalInfo.touchFrameId || ''}
                  optionList={touchFrameOption}
                  itemLimit={touchFrameOption.length > 3 ? 3 : null}
                />
              </Item>
            </Row>
          </ModalFooter>
        </Modal>
      </ClickOutside>
    );
  },
);

export const FrameHideModal = memo(({ isOpen, frameLinkList, closeModal, frameId, offset }) => {
  const [frameUpdate] = useFrameUpdateMutation();
  const clickHideOk = useCallback(() => {
    for (const obj of frameId) {
      const frameId = obj.frameId;
      frameUpdate({ frameId, frameInfo: { hideYn: 'Y' } });
    }
    closeModal();
  }, [closeModal, frameId, frameUpdate]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      type="warn"
      size="small"
      left={offset.left}
      top={offset.top}
      titleTxt="프레임 링크 연결 안내"
      body={
        <>{`프레임 숨기기 처리 시 기존에 연결되었던 ${frameLinkList
          ?.map(item => item.data)
          .join(',')}번 프레임이 오작동 할 수 있습니다.  
        그래도 프레임을 숨기시겠습니까?`}</>
      }
      closeTxt="취소"
      confirmTxt="확인"
      handleConfirm={e => clickHideOk()}
      handleClose={e => closeModal()}
    />
  );
});

const Modal = styled.div`
  position: fixed;
  left: ${({ left }) => left && `${left}px`};
  top: ${({ top }) => top && `${top}px`};
  width: ${({ width }) => width && `${width}px`};
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(38, 50, 56, 0.12);
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  z-index: 30;
  cursor: default;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  font-weight: 500;
  color: #666666 !important;
`;

const ModalAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const FrameHideBtn = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: none;
`;

const FramePrimaryBtn = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: none;
`;

const ModalBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  float: right;
  font-size: 14px;
  font-weight: 500;
  width: 30%;
  text-align: left;
  color: #666666 !important;
  &.fwn {
    font-weight: normal;
  }
`;

const ItemGroup = styled.div`
  padding: 10px;
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  border-radius: 6px;
`;

const Item = styled.div`
  width: 70%;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ModalFooter = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-top: 1px solid #dddddd;
  color: #666666 !important;
  font-size: 14px;
  font-weight: 500;

  & label {
    color: #666666;
  }

  & > label + label {
    margin-top: 2px;
    margin-left: 10px;
  }
`;
