import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as apiLib from '../lib/api';
import * as overlayApiLib from '../lib/overlayApi';
import * as contentsApiLib from '../lib/contentsApi';

import { overlayAction } from './overlaySlice';
import { accountSelector } from './accountSlice';
import { activeItemAction } from './activeItemSlice';
import { overlayContentsAction } from './overlayContentsSlice';

function* getOverlayList({ payload: playlistId}) {
  try {
    const overlayList = yield call(overlayApiLib.getOverlayList, playlistId);
    yield put(overlayAction.getOverlayListSuccess(overlayList));
  } catch (error) {
    yield put(overlayAction.getOverlayListFailure('getOverlayList error'));
  }
}

function* addOverlay({ payload: { overlay } }) {
  try {
    yield call(overlayApiLib.addOverlay, { overlay });
    yield put(overlayAction.addOverlaySuccess());

    yield put(activeItemAction.setActiveLayerId(overlay.overlayId));
    yield put(activeItemAction.setLayerType('OVERLAY'));
    yield put(overlayContentsAction.getOverlayContents(overlay.overlayId));
  } catch (error) {
    yield put(overlayAction.addOverlayFailure('addOverlay error'));
  }
}

function* updateOverlay({ payload: { overlayId, updateOverlay } }) {
  try {
    yield call(overlayApiLib.updateOverlay, { overlayId, updateOverlay });
    yield put(overlayAction.updateOverlaySuccess());
  } catch (error) {
    yield put(overlayAction.updateOverlayFailure('updateOverlay error'));
  }
}

function* removeOverlay({ payload }) {
  try {
    yield call(overlayApiLib.removeOverlay, payload);
    yield put(overlayAction.removeOverlaySuccess());
    yield put(activeItemAction.setActiveLayerId(''));
    yield put(overlayContentsAction.initOverlayContentsState());
  } catch (error) {
    yield put(overlayAction.removeOverlayFailure('Remove Overlay Error'));
  }
}

function* uploadLogoFile({ payload: { logo, logoFile, onUploadProgress } }) {
  try {
    const { viewPlaceId: placeId } = yield select(accountSelector.accountInfo);

    const folderData = {
      placeId: placeId,
      folderPid: placeId,
      folderId: `${placeId}-app`,
      folderNm: 'app-file',
    };

    yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder
    const data = {
      placeId: placeId,
      folderId: `${placeId}-app`,
      fileNm: logoFile.name,
      fileSize: logoFile.size,
      fileType: logoFile.type,
    };

    const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3

    const presignUrl = result.url;
    const fileId = result.fileInfo.fileId;
    const filePath = result.fileInfo.filePath;

    logoFile.fileId = fileId;
    logoFile.fileNm = logoFile.name;
    logoFile.fileSize = logoFile.size;
    logoFile.fileType = logoFile.type;
    logoFile.filePath = filePath;
    delete logoFile.preview;

    // overlayFileList 데이터 생성
    const overlayFileList = [{
      fileId: fileId,
      fileNm: logoFile.name,
      fileSize: logoFile.size,
      fileType: logoFile.type,
      filePath: filePath
    }];

    yield call(apiLib.fetchUploadApi, presignUrl, logoFile, onUploadProgress); // file upload

    yield call(overlayApiLib.updateOverlay, { overlayId: logo.overlayId, updateOverlay: { overlayFileList, overlayData: { logoFile } } });

    yield put(overlayAction.uploadLogoFileSuccess({ overlayId: logo.overlayId, logoFile, overlayFileList }));
  } catch (error) {
    yield put(overlayAction.uploadLogoFileFailure('uploadLogoFile error'));
  }
}

export function* watchGetOverlayList() {
  yield takeLatest(overlayAction.getOverlayList, getOverlayList);
}

export function* watchAddOverlay() {
  yield takeLatest(overlayAction.addOverlay, addOverlay);
}

export function* watchUpdateOverlay() {
  yield takeLatest(overlayAction.updateOverlay, updateOverlay);
}

export function* watchRemoveOverlay() {
  yield takeLatest(overlayAction.removeOverlay, removeOverlay);
}

export function* watchUploadLogoFile() {
  yield takeLatest(overlayAction.uploadLogoFile, uploadLogoFile);
}

function* rootSaga() {
  yield all([
    fork(watchGetOverlayList),
    fork(watchAddOverlay),
    fork(watchRemoveOverlay),
    fork(watchUpdateOverlay),
    fork(watchUploadLogoFile),
  ]);
}

export default rootSaga;
