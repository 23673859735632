import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface OverlayContentsState {
  playlistId: string;
  overlayId: string;
  contentsId: string;
  contentsType: string;
  contentsData: any;
  contentsFileList: Array<any>;
  editingMode: string;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

interface OnSubmitPayload {
  contentsId?: string;
  contentsType: string;
  contentsData: any;
}

const initialState: OverlayContentsState = {
  playlistId: '',
  overlayId: '',
  contentsId: '',
  contentsType: '',
  contentsData: {},
  contentsFileList: [],
  overlayContentsList: [],
  editingMode: 'N',
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'overlayContents',
  initialState,
  reducers: {
    initOverlayContentsState(state: OverlayContentsState) {
      state.playlistId = '';
      state.overlayId = '';
      state.contentsId = '';
      state.contentsType = '';
      state.contentsData = {};
      state.contentsFileList = [];
      state.editingMode = 'N';
      state.actionResult = '';
    },
    getOverlayContentsList(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'GET_CONTENTS_LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getOverlayContentsListSuccess(state: LayerState, { payload: { overlayContentsList }}: PayloadAction<LayerState>) {
      state.overlayContentsList = overlayContentsList;

      state.actionResult = 'GET_CONTENTS_LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    getOverlayContentsListFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'GET_CONTENTS_LIST_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    getOverlayContents(state: OverlayContentsState) {
      state.actionResult = 'GET_CONTENTS_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getOverlayContentsSuccess(state: OverlayContentsState, { payload: { playlistId, overlayId, contentsId = '', contentsType = '', contentsData = {}, contentsFileList = [] } }: PayloadAction<OverlayContentsState>) {
      state.playlistId = playlistId;
      state.overlayId = overlayId;
      state.contentsId = contentsId;
      state.contentsType = contentsType;
      state.contentsData = contentsData;
      state.contentsFileList = contentsFileList;

      state.editingMode = 'N';
      state.actionResult = 'GET_CONTENTS_OK';
      state.isLoading = false;
      state.error = null;
    },
    getOverlayContentsFailure(state: OverlayContentsState, { payload: error}: PayloadAction<string>) {
      state.actionResult = 'GET_CONTENTS_ERR';
      state.error = error;
      state.isLoading = false;
    },
    saveOverlayContents(state: OverlayContentsState) {
      state.actionResult = 'CONTENTS_SAVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    saveOverlayContentsSuccess(state: OverlayContentsState, { payload: { contentsId, contentsType, contentsData, contentsFileList } }: PayloadAction<OnSubmitPayload>) {
      state.contentsId = contentsId;
      state.contentsType = contentsType;
      state.contentsData = contentsData;
      state.contentsFileList = contentsFileList;
      state.editingMode = 'N';
      state.actionResult = 'CONTENTS_SAVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    saveOverlayContentsFailure(state: OverlayContentsState, { payload: error }: PayloadAction<string>) {
      state.editingMode = 'N';
      state.actionResult = 'CONTENTS_SAVE_ERR';
      state.isLoading = false;
      state.error = error;
    },
    updateOverlayContents(state: OverlayContentsState) {
      state.actionResult = 'CONTENTS_UPDATE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    updateOverlayContentsSuccess(state: OverlayContentsState, { payload: { contentsType, contentsData, contentsFileList } }: PayloadAction<OnSubmitPayload>) {
      state.contentsType = contentsType;
      state.contentsData = contentsData;
      state.contentsFileList = contentsFileList;
      state.editingMode = 'N';
      state.actionResult = 'CONTENTS_UPDATE_OK';
      state.isLoading = false;
      state.error = null;
    },
    updateOverlayContentsFailure(state: OverlayContentsState, { payload: error }: PayloadAction<string>) {
      state.editingMode = 'N';
      state.actionResult = 'CONTENTS_UPDATE_ERR';
      state.isLoading = false;
      state.error = error;
    },
    removeOverlayContents(state: OverlayContentsState) {
      state.actionResult = 'CONTENTS_REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeOverlayContentsSuccess(state: OverlayContentsState) {
      state.editingMode = 'N';
      state.contentsId = initialState.contentsId;
      state.contentsType = initialState.contentsType;
      state.contentsData = initialState.contentsData;
      state.contentsFileList = initialState.contentsFileList;
      state.actionResult = 'CONTENTS_REMOVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    removeOverlayContentsFailure(state: OverlayContentsState, { payload: error }: PayloadAction<string>) {
      state.editingMode = 'N';
      state.actionResult = 'CONTENTS_REMOVE_ERR';
      state.isLoading = false;
      state.error = error;
    },
    editingModeoverlayContents(state: OverlayContentsState) {
      state.editingMode = 'Y';
    },
  },
});

const selectOverlayContentsList = createDraftSafeSelector(
  (state: OverlayContentsState) => state.overlayContentsList,
  overlayContentsState => overlayContentsState,
);

const selectOverlayContents = createDraftSafeSelector(
  (state: OverlayContentsState) => state.playlistId,
  (state: OverlayContentsState) => state.overlayId,
  (state: OverlayContentsState) => state.contentsId,
  (state: OverlayContentsState) => state.contentsType,
  (state: OverlayContentsState) => state.contentsData,
  (state: OverlayContentsState) => state.contentsFileList,
  (playlistId, overlayId, contentsId, contentsType, contentsData, contentsFileList) => ({ playlistId, overlayId, contentsId, contentsType, contentsData, contentsFileList }),
);

const selectStatus = createDraftSafeSelector(
  (state: OverlayContentsState) => state.editingMode,
  (state: OverlayContentsState) => state.actionResult,
  (state: OverlayContentsState) => state.isLoading,
  (state: OverlayContentsState) => state.error,
  (editingMode, actionResult, isLoading, error) => ({ editingMode, actionResult, isLoading, error }),
);

export const overlayContentsSelector = {
  overlayContentsList: state => selectOverlayContentsList(state[OVERLAYCONTENTS]),
  overlayContents: state => selectOverlayContents(state[OVERLAYCONTENTS]),
  status: state => selectStatus(state[OVERLAYCONTENTS]),
};

export const OVERLAYCONTENTS = slice.name;
export const overlayContentsReducer = slice.reducer;
export const overlayContentsAction = slice.actions;
