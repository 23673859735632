import { editorAction, editorSelector } from 'module/editorSlice';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import { useChannelContentsListAllQuery } from 'rtk/channelContentsApi';
import styled from 'styled-components';
import { useTimer } from '../../../utils/timerUtils';
import { TRANSITION } from './transitionProp';

const Channel = ({ channelId, muteYn = 'Y' }) => {
  const placeId = useSelector(editorSelector.placeId);
  const [activeMediaIndex, setActiveMediaIndex] = useState(0);
  const [activeMedia, setActiveMedia] = useState();
  const [activeMediaFile, setActiveMediaFile] = useState();
  const channelContentsList = useSelector(editorSelector.channelContentsList);
  useEffect(() => {
    if (!channelContentsList) return;

    if (Object.keys(channelContentsList)?.length > 0) {
      const activeMediaContents = channelContentsList[channelId]?.[activeMediaIndex]?.fileData;
      const activeMediaFile = channelContentsList[channelId]?.find(
        file => file?.fileData?.fileId === activeMedia?.fileId,
      );
      setActiveMedia(activeMediaContents);
      setActiveMediaFile(activeMediaFile);
    }
  }, [activeMedia?.fileId, activeMediaIndex, channelContentsList, channelId]);

  useTimer(
    () => setActiveMediaIndex((activeMediaIndex + 1) % channelContentsList?.[channelId].length),
    Number(activeMediaFile?.contentsDuration) * 1000,
    [activeMediaIndex, channelContentsList?.[channelId]],
  );

  /* 미디어 트랜지션 설정 */
  /* 첫번째 prop => 넘겨줄 데이터 */
  /* 두번째 prop => 감시하고 있을 데이터 */
  const transitions = useTransition(
    { activeMedia, activeMediaFile },
    { ...TRANSITION?.['none'], key: activeMediaIndex },
  );

  return transitions((props, item) => {
    const mediaPath = `${process.env.REACT_APP_INTERACTION_CDN_URL}${item?.activeMediaFile?.fileData?.fileThumb}`;
    return (
      <MediaBox key={props.key} option={'contain'} style={props}>
        {/* {item.activeMediaFile?.fileData?.fileType?.includes('image') ? (
          <img src={mediaPath} alt="" />
        ) : item.activeMediaFile?.fileData?.fileType?.includes('video') ? (
          <video src={mediaPath} muted={true} autoPlay preload="auto" loop />
        ) : null} */}
        <img src={mediaPath} alt="" />{' '}
      </MediaBox>
    );
  });
};

const MediaBox = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: ${props => props.option || 'none'};
  }
`;

export default React.memo(Channel);
