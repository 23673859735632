import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface FolderPayload {
  folderPid: String;
  folderId: String;
  folderNm: String;
  useYn: String;
  childFolderList: Array<any>;
}

interface FilePayload {
  folderId: String;
  fileDetail: String;
  files: Array<any>;
  onUploadProgress: () => void;
}

interface FileListPayload {
  fileList: Array<FilePayload>;
}

interface ContentsState {
  publicFolderPath: FolderPayload;
  publicFolderPathList: Array<FolderPayload>;
  publicSFolderList: Array<FolderPayload>;
  publicFileList: Array<FilePayload>;
  allFolderList: Array<FolderPayload>;
  publicFileUploadMode: number;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const contentsInitialState: ContentsState = {
  publicFolderPath: {},
  publicFolderPathList: [],
  publicFolderList: [],
  publicFileList: [],
  // storageFolderLastUpdate: 0,
  // storageFileLastUpdate: [],
  publicFileUploadMode: 0,
  allFolderList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const initPublicStorageState = (
  state: ContentsState,
  { payload }: PayloadAction<FolderPayload>,
) => {
  state.publicFolderPath = {};
  state.publicFolderPathList = [];
  state.publicFolderList = [];
  state.publicFileList = [];
  // state.actionResult = '';
};

const publicFolderPathInit = (
  state: ContentsState,
  { payload }: PayloadAction<FolderPayload>,
) => {
  state.publicFolderPath = payload;
  state.publicFolderPathList = [payload];
};

const publicFolderPathBack = (state: ContentsState) => {
  const idx = state.publicFolderPathList.length - 1;
  if (idx > 0) {
    state.publicFolderPathList = state.publicFolderPathList.slice(0, idx);
    state.publicFolderPath = state.publicFolderPathList[idx - 1];
  } else {
    state.publicFolderPath = state.publicFolderPathList[0];
  }
};

const publicFolderPathUpper = (
  state: ContentsState,
  { payload }: PayloadAction<FolderPayload>,
) => {
  const idx = state.publicFolderPathList.findIndex((folderPath) => {
    return folderPath.folderId === payload.folderId;
  });
  if (idx >= 0) {
    state.publicFolderPathList = state.publicFolderPathList.slice(0, idx + 1);
    state.publicFolderPath = state.publicFolderPathList[idx];
  } else {
    state.publicFolderPath = state.publicFolderPathList[0];
  }
};

const publicFolderPathLower = (
  state: ContentsState,
  { payload }: PayloadAction<FolderPayload>,
) => {
  state.publicFolderPathList.push(payload);
  state.publicFolderPath = payload;
};


const publicFolderList = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.actionResult = 'FOLDER_LIST_REQ';
  state.isLoading = true;
  state.error = null;
};

const publicFolderListSuccess = (
  state: ContentsState,
  { payload }: PayloadAction<ContentsState>,
) => {
  state.actionResult = 'FOLDER_LIST_OK';
  state.publicFolderList = payload.publicFolderList;
  state.isLoading = false;
  state.error = null;
};

const publicFolderListFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.actionResult = 'FOLDER_LIST_ERR';
  state.isLoading = false;
  state.error = payload;
};

const publicFolderAdd = (
  state: ContentsState,
  { payload }: PayloadAction<FolderPayload>,
) => {
  state.isLoading = true;
  state.error = null;
};

const publicFolderAddSuccess = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = null;
};

const publicFolderAddFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = payload;
};

const publicFolderEdit = (
  state: ContentsState,
  { payload }: PayloadAction<FolderPayload>,
) => {
  state.isLoading = true;
  state.error = null;
};

const publicFolderEditSuccess = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = null;
};

const publicFolderEditFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = payload;
};

const publicFolderRemove = (
  state: ContentsState,
  { payload }: PayloadAction<FolderPayload>,
) => {
  state.isLoading = true;
  state.error = null;
};

const publicFolderRemoveSuccess = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = null;
};

const publicFolderRemoveFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = payload;
};

const publicFileList = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.actionResult = 'FILE_LIST_REQ';
  state.isLoading = true;
  state.error = null;
};

const publicFileListSuccess = (
  state: ContentsState,
  { payload }: PayloadAction<ContentsState>,
) => {
  state.publicFileList = payload.publicFileList;
  state.actionResult = 'FILE_LIST_OK';
  state.isLoading = false;
  state.error = null;
};

const publicFileListFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.actionResult = 'FILE_LIST_ERR';
  state.isLoading = false;
  state.error = payload;
};

const publicFileUploadMode = (
  state: ContentsState,
  { payload }: PayloadAction<Number>,
) => {
  state.publicFileUploadMode = payload;
  state.isLoading = true;
  state.error = null;
};

const publicFileAdd = (
  state: ContentsState,
  { payload }: PayloadAction<FilePayload>,
) => {
  state.actionResult = 'UPLOAD_REQ';
  state.isLoading = true;
  state.error = null;
};

const publicFileAddRequest = (
  state: ContentsState,
  { payload }: PayloadAction<FilePayload>,
) => {
  state.actionResult = 'UPLOAD_REQ';
  state.isLoading = true;
  state.error = null;
};

const publicFileAddSuccess = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.actionResult = 'UPLOAD_OK';
  state.isLoading = false;
  state.error = null;
};

const publicFileAddFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.actionResult = 'UPLOAD_ERR';
  state.isLoading = false;
  state.error = payload;
};

const publicFileDownloadRequest = (
  state: ContentsState,
  { payload }: PayloadAction<FilePayload>,
) => {
  state.actionResult = 'DOWNLOAD_REQ';
  state.isLoading = true;
  state.error = null;
};

const publicFileDownloadSuccess = (
  state: ContentsState,
) => {
  state.actionResult = 'DOWNLOAD_OK';
  state.isLoading = false;
  state.error = null;
};

const publicFileDownloadFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.actionResult = 'DOWNLOAD_ERR';
  state.isLoading = false;
  state.error = payload;
};

const publicFileRemove = (
  state: ContentsState,
  { payload }: PayloadAction<FilePayload>,
) => {
  state.isLoading = true;
  state.error = null;
};

const publicFileRemoveSuccess = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = null;
};

const publicFileRemoveFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = payload;
};

const publicFileListRemove = (
  state: ContentsState,
  { payload }: PayloadAction<FileListPayload>,
) => {
  state.isLoading = true;
  state.error = null;
};

const publicFileListRemoveSuccess = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = null;
};

const publicFileListRemoveFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = payload;
};

const publicFolderCheckAndMake = (
  state: ContentsState,
  { payload }: PayloadAction<FolderPayload>,
) => {
  state.isLoading = true;
  state.error = null;
};

const publicFolderCheckAndMakeSuccess = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = null;
};

const publicFolderCheckAndMakeFailure = (
  state: ContentsState,
  { payload }: PayloadAction<String>,
) => {
  state.isLoading = false;
  state.error = payload;
};

const getFolderListAll = (
  state: ContentsState,
  { payload }: PayloadAction<string>,
) => {
  state.actionResult = 'ALL_FOLDERLIST_REQ';
  state.isLoading = true;
  state.error = null;
}

const getFolderListAllSuccess = (
  state: ContentsState,
  { payload: { folderList } }: PayloadAction<string>,
) => {
  state.allFolderList = folderList;
  state.actionResult = 'ALL_FOLDERLIST_OK';
  state.isLoading = false;
  state.error = null;
}

const getFolderListAllFailure = (
  state: ContentsState,
  { payload }: PayloadAction<string>,
) => {
  state.actionResult = 'ALL_FOLDERLIST_ERR';
  state.isLoading = false;
  state.error = payload;
}

const changeUseYn = (
  state: ContentsState,
  { payload }: PayloadAction<string>,
) => {
  state.actionResult = 'CHANGE_USEYN_REQ';
  state.isLoading = true;
  state.error = null;
}

const changeUseYnSuccess = (
  state: ContentsState,
  { payload: { folderList } }: PayloadAction<string>,
) => {
  state.allFolderList = folderList;
  state.actionResult = 'CHANGE_USEYN_OK';
  state.isLoading = false;
  state.error = null;
}

const changeUseYnFailure = (
  state: ContentsState,
  { payload }: PayloadAction<string>,
) => {
  state.actionResult = 'CHANGE_USEYN_ERR';
  state.isLoading = false;
  state.error = payload;
}

const actionResultClear = (state: ContentsState) => {
  state.actionResult = '';
};

const slice = createSlice({
  name: 'publicContents',
  initialState: contentsInitialState,
  reducers: {
    initPublicStorageState,
    publicFolderPathInit,
    publicFolderPathBack,
    publicFolderPathUpper,
    publicFolderPathLower,
    publicFolderList,
    publicFolderListSuccess,
    publicFolderListFailure,
    publicFolderAdd,
    publicFolderAddSuccess,
    publicFolderAddFailure,
    publicFolderEdit,
    publicFolderEditSuccess,
    publicFolderEditFailure,
    publicFolderRemove,
    publicFolderRemoveSuccess,
    publicFolderRemoveFailure,
    publicFileList,
    publicFileListSuccess,
    publicFileListFailure,
    publicFileUploadMode,
    publicFileAdd,
    publicFileAddRequest,
    publicFileAddSuccess,
    publicFileAddFailure,
    publicFileDownloadRequest,
    publicFileDownloadSuccess,
    publicFileDownloadFailure,
    publicFileRemove,
    publicFileRemoveSuccess,
    publicFileRemoveFailure,
    publicFileListRemove,
    publicFileListRemoveSuccess,
    publicFileListRemoveFailure,
    publicFolderCheckAndMake,
    publicFolderCheckAndMakeSuccess,
    publicFolderCheckAndMakeFailure,
    getFolderListAll,
    getFolderListAllSuccess,
    getFolderListAllFailure,
    actionResultClear,
    changeUseYn,
    changeUseYnSuccess,
    changeUseYnFailure
  },
});

const selectPublicFolderPath = createDraftSafeSelector(
  (state: ContentsState) => state.publicFolderPath,
  (publicFolderPath) => publicFolderPath,
);

const selectPublicFolderPathList = createDraftSafeSelector(
  (state: ContentsState) => state.publicFolderPathList,
  (publicFolderPathList) => publicFolderPathList,
);

const selectPublicFolderList = createDraftSafeSelector(
  (state: ContentsState) => state.publicFolderList,
  (publicFolderList) => publicFolderList,
);
const selectPublicFileList = createDraftSafeSelector(
  (state: ContentsState) => state.publicFileList,
  (publicFileList) => publicFileList,
);

const selectFolderListAll = createDraftSafeSelector(
  (state: ContentsState) => state.allFolderList,
  (allFolderList) => allFolderList,
);

const selectStatus = createDraftSafeSelector(
  (state: ContentsState) => state.actionResult,
  (state: ContentsState) => state.uploadResult,
  (state: ContentsState) => state.isLoading,
  (state: ContentsState) => state.error,
  (actionResult, uploadResult, isLoading, error) => ({ actionResult, uploadResult, isLoading, error }),
);

export const publicContentsSelector = {
  publicFolderPath: (state) => selectPublicFolderPath(state[PUBLICCONTENTS]),
  publicFolderPathList: (state) => selectPublicFolderPathList(state[PUBLICCONTENTS]),
  publicFolderList: (state) => selectPublicFolderList(state[PUBLICCONTENTS]),
  publicFileList: (state) => selectPublicFileList(state[PUBLICCONTENTS]),
  allFolderList: (state) => selectFolderListAll(state[PUBLICCONTENTS]),
  status: (state) => selectStatus(state[PUBLICCONTENTS]),
};

export const PUBLICCONTENTS = slice.name;
export const publicContentsReducer = slice.reducer;
export const publicContentsAction = slice.actions;
