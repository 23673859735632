import React, { useCallback, useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as DownArrowIcon } from '../assets/images/common/icon-chevron-down.svg';
import { ReactComponent as UpArrowIcon } from '../assets/images/common/icon-chevron-up.svg';

import ClickOutside from '../components/ClickOutside';

const SelectInput = ({
  defaultTitle = '',
  selectedValue,
  optionList,
  onSelectChange,
  disabled,
  isValidate,
  className,
  up,
  displayOptionCnt = 10,
  width,
  height,
  itemLimit,
}) => {
  const [focusIndex, setFocusIndex] = useState(-1);
  const optionListRef = useRef();
  const optionFocus = useRef([]);
  const [isShow, setIsShow] = useState(false);

  const ContainerRef = useRef(null);

  useEffect(() => {
    if (isShow && focusIndex > -1) {
      optionListRef.current.scrollTo({ top: focusIndex * optionFocus.current[focusIndex].offsetHeight });
    }
  }, [focusIndex, isShow]);

  const closeSelectInput = useCallback(() => {
    if (isShow) {
      setIsShow(false);
    }
  }, [isShow]);

  useEffect(() => {
    const optionIndex = optionList.findIndex(option => option.value === selectedValue);
    // setFocusIndex(optionIndex);
  }, [selectedValue, optionList]);

  const getSelectedTitle = useCallback(
    value => {
      const findItem = optionList?.find(option => (option.value === value ? option : false));
      // const findItem = optionList?.find(option => (option.value === value));
      return findItem ? findItem.title : defaultTitle;
    },
    [optionList, defaultTitle],
  );

  const handleChangeOption = useCallback(
    option => {
      onSelectChange(option.value);
      setIsShow(false);
    },
    [onSelectChange],
  );

  return (
    <ClickOutside onClickOutside={closeSelectInput} style={!width ? { flex: 1 } : {}}>
      <Container
        ref={ContainerRef}
        className={className}
        isValidate={isValidate}
        disabled={disabled}
        $isShow={isShow}
        width={width}
        height={height}
      >
        <SelectBtn onClick={() => setIsShow(!isShow)}>
          <SelectTitle $isSelect={selectedValue ? true : false} disabled={disabled}>
            {getSelectedTitle(selectedValue)}
          </SelectTitle>
          {isShow ? <UpArrowIcon /> : <DownArrowIcon />}
        </SelectBtn>
        <OptionWrapper
          style={isShow ? { display: 'block' } : { display: 'none' }}
          height={ContainerRef.current?.offsetHeight}
          up={up}
          $displayOptionCnt={displayOptionCnt}
          ref={optionListRef}
          itemLimit={itemLimit}
        >
          <OptionList>
            {optionList?.map((option, index) => (
              <OptionItem
                key={`option-${index}`}
                onClick={() => onSelectChange && handleChangeOption(option)}
                className={selectedValue === option.value && 'active'}
                height={ContainerRef.current?.offsetHeight}
                ref={elem => (optionFocus.current[index] = elem)}
              >
                <span>{option.title}</span>
              </OptionItem>
            ))}
          </OptionList>
        </OptionWrapper>
      </Container>
    </ClickOutside>
  );
};

const Container = styled.div`
  flex: 1;
  position: relative;
  box-sizing: border-box;
  border-radius: ${props => (props.$isShow ? '0.3125rem 0.3125rem 0 0' : '0.5rem')};
  border: 0.0625rem solid ${({ isValidate }) => (isValidate === undefined || isValidate ? '#DEDEDE' : '#F05B5B')};
  background: ${({ disabled, theme }) => (disabled ? theme.disabled : '#FFFFFF')};

  width: ${props => props.width};
  height: ${props => props.height};
  & + & {
    margin-right: 20px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const SelectBtn = styled.button`
  width: 100%;
  height: inherit;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 0;
  background: transparent;

  padding: 7px 14px;
  outline: 0;
  & > svg {
    margin-left: auto;
  }
`;

const SelectTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  color: ${({ $isSelect, disabled, theme }) => (disabled ? theme.gray700 : $isSelect ? '#7C7C7C' : '#999999')};
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -1px;
  top: ${({ height }) => (height ? height - 2 : 36)}px;
  background: #ffffff;
  width: calc(100% + 2px);
  max-height: ${({ height, $displayOptionCnt }) => (height ? height * $displayOptionCnt + 2 : 192)}px;
  border-radius: 0 0 5px 5px;
  border: 1px solid #cfcfcf;
  z-index: 1;

  ${({ height, itemLimit }) =>
    itemLimit > 0
      ? css`
          overflow: auto;
          height: ${height * itemLimit}px;
        `
      : css`
          overflow: hidden overlay;
        `}

  ${({ up }) =>
    up &&
    css`
      top: ${({ height }) => (height ? -(height * 5 + 2 - height) : -154)}px;
      border-radius: 5px 5px 0 0;
    `}

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
`;

const OptionList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const OptionItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  height: ${({ height }) => (height ? height : 38)}px;
  padding: 0 10px 0 15px;
  font-size: 14px;
  cursor: pointer;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:not(:first-child) {
    border-top: 0.0625rem solid #eeeeee;
  }

  &:hover {
    background: #f9f9f9;
    color: #333333;
  }

  background: #ffffff;
  color: #555555;

  &.active {
    color: #2a91df;
    background: #f1f9ff;
  }
`;

export default React.memo(SelectInput);
