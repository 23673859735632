import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import InfiniteViewer from 'react-infinite-viewer';
import Selecto from 'react-selecto';
import throttle from 'lodash/throttle';
import {
  EDITOR_COL_PADDING,
  EDITOR_ROW_PADDING,
  EDITOR_SPLITBAR_SIZE,
  EDITOR_RIGHT_WIDTH,
  EDITOR_LEFT_WIDTH,
  EDITOR_HEADER_HEIGHT,
  EDITOR_GRID_SIZE,
  CANVAS_MIN_ZOOMLEVEL,
} from './constants/editor';

import Layer from './Layer';
import MoveableManager from './MoveableManager';
import useLayerKeyEvent from './useLayerKeyEvent';
import { createFrameThumbNail } from 'utils/playlist';

import { editorAction, editorSelector } from '../../module/editorSlice';

import useCheckEditingMode from './app/components/modal/useCheckEditingMode';

import { playlistApi } from '../../rtk/playlistApi';
import { endpoints as endpointsLayerContentsApi } from '../../rtk/layerContentsApi';
import { endpoints as endpointsOverlayContentsApi } from '../../rtk/overlayContentsApi';
import {
  endpoints as endpointsLayerApi,
  useLayerAddListMutation,
  useLayerReOrderMutation,
  useLayerUpdateListMutation,
} from '../../rtk/layerApi';
import {
  endpoints as endpointsOverlayApi,
  useOverlayAddListMutation,
  useOverlayReOrderMutation,
  useOverlayUpdateListMutation,
} from '../../rtk/overlayApi';
import { useUploadThumbnailMutation } from '../../rtk/frameApi';

import gridlineImg from '../../assets/images/layer/gridlineImg.png';

const LayerList = ({ moveableRef, infiniteViewerRef, historyRef, selectoRef, clipboardRef }) => {
  const dispatch = useDispatch();

  const viewportRef = useRef(null);
  const playerAreaRef = useRef(null);

  const { editingCheck, LayerContentsWarnModal } = useCheckEditingMode();
  useLayerKeyEvent({ viewportRef, infiniteViewerRef, historyRef, clipboardRef });

  const playlistId = useSelector(editorSelector.playlistId);
  const frameId = useSelector(editorSelector.frameId);
  const selectedLayerList = useSelector(editorSelector.selectedLayerList);
  const { overlayShowMode, gridMode, lockMode, dragMode, cursorType, zoom } = useSelector(editorSelector.canvasInfo);

  const [layerAddListMutation] = useLayerAddListMutation();
  const [layerUpdateListMutation] = useLayerUpdateListMutation();
  const [reOrderLayer] = useLayerReOrderMutation();

  const [overlayAddListMutation] = useOverlayAddListMutation();
  const [overlayUpdateListMutation] = useOverlayUpdateListMutation();
  const [reOrderOverlay] = useOverlayReOrderMutation();

  const [uploadThumbNail] = useUploadThumbnailMutation();

  const scale = useMemo(() => zoom / 100, [zoom]);

  useEffect(() => {
    infiniteViewerRef.current.setZoom(scale);
  }, [infiniteViewerRef, scale]);

  const { currentData: layerList } = endpointsLayerApi.layerList.useQueryState({ frameId });
  const { currentData: layerContentsList } = endpointsLayerContentsApi.layerContentsList.useQueryState({ frameId });
  const { currentData: overlayList } = endpointsOverlayApi.overlayList.useQueryState({ playlistId });
  const { currentData: overlayContentsList } = endpointsOverlayContentsApi.overlayContentsList.useQueryState({
    playlistId,
  });

  const {
    data: { horizonResolution, verticalResolution },
  } = playlistApi.endpoints.playlistDetail.useQueryState({ playlistId });

  // snap 위한 guideLine
  const verticalGuidelines = useMemo(() => {
    const horizon = Number(horizonResolution);
    const array = [0, horizon];

    if (gridMode) {
      for (let i = 0; i < horizon / EDITOR_GRID_SIZE; i++) {
        const point = i * EDITOR_GRID_SIZE;
        array.push(point);
      }
    }
    return array;
  }, [horizonResolution, gridMode]);

  const horizontalGuidelines = useMemo(() => {
    const vertical = Number(verticalResolution);
    const array = [0, vertical];

    if (gridMode) {
      for (let i = 0; i < vertical / EDITOR_GRID_SIZE; i++) {
        const point = i * EDITOR_GRID_SIZE;
        array.push(point);
      }
    }
    return array;
  }, [verticalResolution, gridMode]);

  const [elementGuidelines, setElementGuidelines] = useState([]);

  useEffect(() => {
    if (layerList && overlayList) {
      const layerElementList = [];
      const overlayElementList = [];

      const updateLayerOrderList = layerList.reduce((target, layer, index) => {
        if (layer.layerOrder !== index) {
          target.push({
            layerId: layer.layerId,
            layerOrder: index,
          });
        }

        layerElementList.push(document.getElementById(layer.layerId));
        return target;
      }, []);

      const updateOverlayOrderList = overlayList.reduce((target, overlay, index) => {
        if (overlay.overlayOrder !== index) {
          target.push({
            overlayId: overlay.overlayId,
            overlayOrder: index,
          });
        }

        overlayElementList.push(document.getElementById(overlay.overlayId));
        return target;
      }, []);

      if (updateLayerOrderList.length > 0) {
        reOrderLayer({ updateList: updateLayerOrderList });
      }

      if (updateOverlayOrderList.length > 0) {
        reOrderOverlay({ updateList: updateOverlayOrderList });
      }

      setElementGuidelines([...layerElementList, ...overlayElementList]);
    }
  }, [reOrderLayer, reOrderOverlay, layerList, overlayList]);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const horizon = Number(horizonResolution || 1); // 가로 해상도
    const vertical = Number(verticalResolution || 1); // 세로 해상도
    const scalex =
      (windowWidth - EDITOR_LEFT_WIDTH - EDITOR_RIGHT_WIDTH - EDITOR_SPLITBAR_SIZE * 2 - EDITOR_ROW_PADDING * 2) /
      horizon;
    const scaley = (windowHeight - EDITOR_HEADER_HEIGHT - EDITOR_COL_PADDING * 2) / vertical;
    const ratio = scalex < scaley ? scalex : scaley; // 현재 화면에 맞는 비율 정보

    let zoom = ratio * 100;
    zoom = zoom < CANVAS_MIN_ZOOMLEVEL ? CANVAS_MIN_ZOOMLEVEL : zoom;
    dispatch(editorAction.updateCanvasInfo({ zoom, originZoom: zoom }));
    infiniteViewerRef.current?.scrollCenter();
  }, [dispatch, infiniteViewerRef, horizonResolution, verticalResolution]);

  const [layerTargetList, setLayerTargetList] = useState([]);

  useEffect(() => {
    const elementList = [];
    const removeIdList = [];

    for (const selectedLayer of selectedLayerList) {
      const layerElement = document.getElementById(selectedLayer.id);
      if (layerElement) {
        elementList.push(layerElement);
      } else {
        removeIdList.push(selectedLayer.id);
      }
    }
    selectoRef.current.setSelectedTargets(elementList);
    setLayerTargetList(elementList);

    if (removeIdList.length > 0) {
      const newSelectedLayerList = selectedLayerList.filter(
        layer => !removeIdList.find(removeId => removeId === layer.id),
      );
      dispatch(editorAction.setState({ key: 'selectedLayerList', value: newSelectedLayerList }));
    }
  }, [dispatch, selectoRef, selectedLayerList]);

  // 화면 드래그
  const handleDragViewport = throttle(
    useCallback(
      e => {
        if (dragMode || cursorType === 'DRAG') {
          infiniteViewerRef.current.scrollBy(-e.deltaX * 10, -e.deltaY * 10);
        }
      },
      [infiniteViewerRef, dragMode, cursorType],
    ),
    60,
  );

  // 선택 레이어 state 업데이트
  const handleUpdateLayerState = throttle(
    useMemo(
      () => updateInfo => {
        dispatch(editorAction.updateSelectedLayerInfo(updateInfo));
      },
      [dispatch],
    ),
    60,
  );

  const handleCreateFrameThumbNail = useMemo(
    () =>
      throttle(frameId => {
        createFrameThumbNail({ frameId }).then(res => {
          uploadThumbNail({ addFileList: [res.param], frameId: res.frameId });
          dispatch(editorAction.updateThumbnail({ url: res.url, thumbId: res.frameId }));
        });
      }, 1000),
    [dispatch, uploadThumbNail],
  );

  // 레이어 db 업데이트
  const handleUpdateLayerDB = useCallback(
    updateList => {
      const layerUpdateList = [];
      const overlayUpdateList = [];

      const prevInfos = [];
      const nextInfos = [];

      for (const update of updateList) {
        const { updateInfo, id } = update;
        const selectedLayer = selectedLayerList.find(selectedLayer => selectedLayer.id === id);

        if (selectedLayer.type === 'LAYER') {
          layerUpdateList.push({ layerId: id, updateInfo });

          const layer = layerList.find(layer => layer.layerId === id);
          prevInfos.push({
            layerId: layer.layerId,
            updateInfo: { x: layer.x, y: layer.y, width: layer.width, height: layer.height },
          });
        } else if (selectedLayer.type === 'OVERLAY') {
          overlayUpdateList.push({ overlayId: id, updateInfo });

          const overlay = overlayList.find(overlay => overlay.overlayId === id);
          prevInfos.push({
            overlayId: overlay.overlayId,
            updateInfo: { x: overlay.x, y: overlay.y, width: overlay.width, height: overlay.height },
          });
        }
      }

      if (layerUpdateList.length > 0 || overlayUpdateList.length > 0) {
        if (layerUpdateList.length > 0) {
          layerUpdateListMutation({ updateList: layerUpdateList });
          nextInfos.push(...layerUpdateList);
        }

        if (overlayUpdateList.length > 0) {
          overlayUpdateListMutation({ updateList: overlayUpdateList });
          nextInfos.push(...overlayUpdateList);
        }
        historyRef.current.addHistory({ type: 'UPDATE-LAYER', props: { prevInfos, nextInfos } });
      }

      if (frameId) {
        handleCreateFrameThumbNail(frameId);
      }
    },
    [
      frameId,
      handleCreateFrameThumbNail,
      selectedLayerList,
      layerList,
      overlayList,
      historyRef,
      layerUpdateListMutation,
      overlayUpdateListMutation,
    ],
  );

  // 레이어 생성 or 레이어 선택시 레이어 스테이트 변경
  const handleSelectEnd = e => {
    const { currentTarget, isDragStart, selected, inputEvent, rect } = e;

    if (cursorType === 'DRAG' || dragMode) {
      viewportRef.current.classList.remove('graabing');
      currentTarget.target.classList.remove('selecto-drag');
      return false;
    } else if (cursorType === 'SELECT') {
      document.activeElement.blur();

      const prevSelectedLayerList = selectedLayerList;
      const nextSelectedLayerList = [];
      for (const layerElement of selected) {
        const id = layerElement.id;
        const layer = layerList.find(layer => layer.layerId === id);
        if (layer) {
          nextSelectedLayerList.push({ type: 'LAYER', id, ...layer });
        }

        const overlay = overlayList.find(layer => layer.overlayId === id);
        if (overlay) {
          nextSelectedLayerList.push({ type: 'OVERLAY', id, ...overlay });
        }
      }

      const compareArray = function (arr1, arr2) {
        var i = arr1.length;
        if (i !== arr2.length) return false;

        for (const obj of arr1) {
          const id = obj.id;

          if (!arr2.find(obj2 => obj2.id === id)) {
            return false;
          }
        }
        return true;
      };

      const isSameArray = compareArray(prevSelectedLayerList, nextSelectedLayerList);

      if (!isSameArray) {
        editingCheck(() => {
          dispatch(editorAction.setState({ key: 'selectedLayerList', value: nextSelectedLayerList }));
          historyRef.current.addHistory({
            type: 'SELECT-LAYER',
            props: { prevSelectedLayerList, nextSelectedLayerList },
          });
        });
      }

      // const moveable = moveableRef.current;
      if (isDragStart) {
        inputEvent.preventDefault();

        // setTimeout(() => {
        //   moveable.dragStart(inputEvent);
        // }, 1);
      }
    } else if (cursorType === 'LAYER-ADD') {
      const layerInfo = {
        playlistId,
        frameId,
        width: Math.round(rect.width / scale),
        height: Math.round(rect.height / scale),
        y: Math.round((rect.top - EDITOR_HEADER_HEIGHT) / scale + infiniteViewerRef.current.getScrollTop()),
        x: Math.round((rect.left - EDITOR_LEFT_WIDTH) / scale + infiniteViewerRef.current.getScrollLeft()),
      };

      layerAddListMutation({ addList: [layerInfo] }).then(({ data }) => {
        if (data.resultFlag) {
          const layerInfo = data.addList[0];
          const selectedLayerList = [{ id: layerInfo.layerId, type: 'LAYER', ...layerInfo }];

          historyRef.current.addHistory({
            type: 'ADD-LAYER',
            props: { infos: [layerInfo], prevSelectedLayerList: selectedLayerList },
          });
          dispatch(editorAction.setState({ key: 'selectedLayerList', value: selectedLayerList }));
          dispatch(editorAction.updateCanvasInfo({ cursorType: 'SELECT' }));
        }
      });

      return false;
    } else if (cursorType === 'OVERLAY-ADD') {
      const layerInfo = {
        playlistId,
        width: Math.round(rect.width / scale),
        height: Math.round(rect.height / scale),
        y: Math.round((rect.top - EDITOR_HEADER_HEIGHT) / scale + infiniteViewerRef.current.getScrollTop()),
        x: Math.round((rect.left - EDITOR_LEFT_WIDTH) / scale + infiniteViewerRef.current.getScrollLeft()),
      };

      overlayAddListMutation({ addList: [layerInfo] }).then(({ data }) => {
        if (data.resultFlag) {
          const overlayInfo = data.addList[0];
          const selectedLayerList = [{ id: overlayInfo.overlayId, type: 'OVERLAY', ...overlayInfo }];

          historyRef.current.addHistory({
            type: 'ADD-LAYER',
            props: { infos: [overlayInfo], prevSelectedLayerList: selectedLayerList },
          });
          dispatch(editorAction.updateCanvasInfo({ cursorType: 'SELECT' }));
          dispatch(editorAction.setState({ key: 'selectedLayerList', value: selectedLayerList }));
        }
      });

      return false;
    }
  };

  return (
    <Container ref={viewportRef} dragMode={cursorType === 'DRAG' || dragMode}>
      <InfiniteViewer ref={infiniteViewerRef} className="infinite-viewer">
        <PlayerArea
          ref={playerAreaRef}
          gridMode={gridMode}
          className="player-area"
          id="player-area"
          width={horizonResolution || 1}
          height={verticalResolution || 1}
        >
          {layerList?.map((layer, index) => (
            <Layer
              key={layer.layerId}
              id={layer.layerId}
              index={index}
              layerType="LAYER"
              layerProps={layer}
              layerContents={layerContentsList?.find(layercontents => layercontents.layerId === layer.layerId)}
            />
          ))}
          {overlayShowMode &&
            overlayList?.map((overlay, index) => (
              <Layer
                key={overlay.overlayId}
                id={overlay.overlayId}
                index={index}
                layerType="OVERLAY"
                layerProps={overlay}
                layerContents={overlayContentsList?.find(
                  overlayContents => overlayContents.overlayId === overlay.overlayId,
                )}
              />
            ))}
          <MoveableManager
            moveableRef={moveableRef}
            selectoRef={selectoRef}
            lockMode={lockMode}
            dragMode={dragMode || cursorType === 'DRAG'}
            targets={layerTargetList}
            elementGuidelines={elementGuidelines}
            verticalGuidelines={verticalGuidelines}
            horizontalGuidelines={horizontalGuidelines}
            updateLayerDB={handleUpdateLayerDB}
            updateLayerState={handleUpdateLayerState}
            cursorType={cursorType}
            isAbleShow={true}
          />
        </PlayerArea>
      </InfiniteViewer>

      <Selecto
        ref={selectoRef}
        dragContainer=".infinite-viewer"
        selectableTargets={['.player-area .layer']}
        selectByClick={true}
        preventDefault={true}
        selectFromInside={cursorType === 'LAYER-ADD' || cursorType === 'OVERLAY-ADD'}
        hitRate={0}
        toggleContinueSelect={['ctrl']}
        scrollOptions={
          infiniteViewerRef.current
            ? {
                container: infiniteViewerRef.current.getContainer(),
                threshold: 30,
                throttleTime: 30,
                getScrollPosition: () => {
                  const current = infiniteViewerRef.current;
                  return [current.getScrollLeft(), current.getScrollTop()];
                },
              }
            : undefined
        }
        onDragStart={e => {
          if (cursorType === 'DRAG' || dragMode || e.inputEvent.which === 2) {
            if (e.inputEvent.which === 2) {
              dispatch(editorAction.updateCanvasInfo({ dragMode: true }));
            }
            viewportRef.current.classList.add('graabing'); // 커서모양 변경 클래스
            e.currentTarget.target.classList.add('selecto-drag'); // 드래그 박스 안생기게하는 클래스
          }

          const moveable = moveableRef.current;
          const target = e.inputEvent.target;

          if (
            (moveable.isMoveableElement(target) || layerTargetList.some(t => t === target || t.contains(target))) &&
            cursorType !== 'LAYER-ADD' &&
            cursorType !== 'OVERLAY-ADD'
          ) {
            e.stop();
          }
        }}
        onDrag={e => handleDragViewport(e)}
        onDragEnd={e => e.inputEvent.which === 2 && dispatch(editorAction.updateCanvasInfo({ dragMode: false }))}
        onScroll={({ direction }) =>
          cursorType !== 'DRAG' && !dragMode && infiniteViewerRef.current.scrollBy(direction[0] * 10, direction[1] * 10)
        }
        onSelectEnd={e => handleSelectEnd(e)}
      />
      {LayerContentsWarnModal()}
      {lockMode && <WarnText>* 잠금모드일때는 레이어를 수정할수 없습니다 (잠금풀기: shift + ~)</WarnText>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  cursor: ${({ dragMode }) => (dragMode ? 'grab !important' : 'default')};

  &.graabing {
    cursor: grabbing !important;
  }

  .infinite-viewer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ccc;
  }

  .selecto-drag {
    display: none !important;
  }
`;
const PlayerArea = styled.div`
  ${({ width, height, gridMode }) =>
    css`
      width: ${width}px;
      height: ${height}px;
      background: black;
      background-image: ${gridMode ? `url(${gridlineImg})` : 'initial'};
      background-repeat: ${gridMode ? 'repeat' : 'initial'};
    `}
`;
const WarnText = styled.div`
  position: absolute;
  color: #f05b5b;
  font-size: 14px;
  font-weight: bold;
  right: 20px;
  top: 20px;
`;

export default React.memo(LayerList);
