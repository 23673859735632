const loadGapiInsideDOM = async function () {
  return new Promise(resolve => {
    const element = document.getElementsByTagName('script')[0];
    if (document.getElementById('google-platform')) {
      resolve(window.google);
      return;
    }
    const js = document.createElement('script');
    js.id = 'google-platform';
    js.src = '//accounts.google.com/gsi/client';
    js.async = true;
    js.defer = true;
    element.parentNode.insertBefore(js, element);
    js.onload = async () => {
      resolve(window.google);
    };
  });
};

export const login = async () => {
  const google = await loadGapiInsideDOM();
  const signIn = await new Promise((resolve, reject) => {
    console.log('google sign in');
    google.accounts.id.initialize({
      client_id: '304419746719-d8l2b3j3gvujkg4d6tbe7bq5k08bp32i.apps.googleusercontent.com',
      callback: response => {
        console.log(response.credential);
      },
    });
    google.accounts.id.prompt(notification => {
      console.log(notification);
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        reject();
      }
    });
  });

  return await signIn();
};

export const logout = async () => {
  const google = await loadGapiInsideDOM();
  const signOut = await new Promise((resolve, reject) => google.accounts.id.disableAutoSelect());

  return await signOut();
};
