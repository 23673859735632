import { interactionBaseApi } from './interactionBaseApi';

export const touchLayerApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    touchLayerList: build.query({
      query: data => ({ url: '/touch-layer/list', data }),
      transformResponse: response => {
        const layerList = response.layerList;

        const sortLayerList = layerList.sort((lhs, rhs) => lhs.layerOrder - rhs.layerOrder);

        return sortLayerList;
      },
      providesTags: (result, error, payload) => [...result.map(({ layerId }) => ({ type: 'TOUCH-LAYER', id: layerId })), { type: 'TOUCH-LAYER', id: 'LIST' }],
      keepUnusedDataFor: 0,
    }),
    touchLayerAdd: build.mutation({
      query: data => ({ url: '/touch-layer/add', data }),
      invalidatesTags: [{ type: 'TOUCH-LAYER', id: 'LIST' }],
    }),
    touchLayerUpdate: build.mutation({
      query: data => ({ url: '/touch-layer/update', data }),
      invalidatesTags: [{ type: 'TOUCH-LAYER', id: 'LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const touchFrameId = getState().editor.touchFrameId;

        const patchResultList = [];
        for (const update of updateList) {
          const { layerId, updateInfo } = update;

          patchResultList.push(
            dispatch(
              touchLayerApi.util.updateQueryData('touchLayerList', { frameId: touchFrameId }, draft => {
                const index = draft.findIndex(layer => layer.layerId === layerId);
                draft[index] = { ...draft[index], ...updateInfo };
              }),
            ),
          );
        }
        try {
          await queryFulfilled;
        } catch {
          patchResultList.forEach(patchResult => patchResult.undo());
        }
      },
    }),
    touchLayerReOrder: build.mutation({
      query: data => ({ url: '/touch-layer/layer-order', data }),
      invalidatesTags: [{ type: 'TOUCH-LAYER', id: 'LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const touchFrameId = getState().editor.touchFrameId;

        const patchResult = dispatch(
          touchLayerApi.util.updateQueryData('touchLayerList', { frameId: touchFrameId }, draft => {
            for (const update of updateList) {
              const layerId = update.layerId;
              const layerOrder = update.layerOrder;
              const index = draft.findIndex(layer => layer.layerId === layerId);
              draft[index].layerOrder = layerOrder;
            }
            draft = draft.sort((lhs, rhs) => lhs.layerOrder - rhs.layerOrder);
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    touchLayerRemove: build.mutation({
      async queryFn({ layerId }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const touchFrameId = getState().editor.touchFrameId;

          let orderUpdateList = [];
          patchResult = dispatch(
            touchLayerApi.util.updateQueryData('touchLayerList', { frameId: touchFrameId }, draft => {
              const index = draft.findIndex(layer => layer.layerId === layerId);
              draft.splice(index, 1);
              orderUpdateList = draft.reduce((target, newLayer, index) => {
                if (newLayer.layerOrder !== index) {
                  draft[index].layerOrder = index;
                  target.push({
                    layerId: newLayer.layerId,
                    layerOrder: index,
                  });
                }
                return target;
              }, []);

              draft = draft.sort((lhs, rhs) => lhs.layerOrder - rhs.layerOrder);
            }),
          );

          if (orderUpdateList.length > 0) {
            await fetchInteractionApi({ url: '/touch-layer/layer-order', data: { updateList: orderUpdateList } });
          }

          const result = await fetchInteractionApi({ url: '/touch-layer/remove', data: { layerId } });
          return result;
        } catch (error) {
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
      invalidatesTags: [{ type: 'TOUCH-LAYER', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, useTouchLayerListQuery, useTouchLayerAddMutation, useTouchLayerUpdateMutation, useTouchLayerRemoveMutation, useTouchLayerReOrderMutation } =
  touchLayerApi;
