import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';

import { editorAction } from '../../../../../module/editorSlice';
import Modal from '../../../../../newComponents/Modal';
import { ReactComponent as WarnIcon } from '../../../../../assets/images/common/icon-alert-triangle-red.svg';

import { useLayerContentsRemoveMutation } from '../../../../../rtk/layerContentsApi';
import { useOverlayContentsRemoveMutation } from '../../../../../rtk/overlayContentsApi';

const RemoveConfirmModal = ({ contentsId, isOpen, closeModal, selectedLayerType }) => {
  const dispatch = useDispatch();
  const [removeLayerContents] = useLayerContentsRemoveMutation({ fixedCacheKey: 'layer-app-remove' });
  const [removeOverlayContents] = useOverlayContentsRemoveMutation({ fixedCacheKey: 'overlay-app-remove' });
  const removeApp = useCallback(() => {
    if (selectedLayerType === 'OVERLAY') {
      removeOverlayContents({ contentsId });
    } else {
      removeLayerContents({ contentsId });
    }
    closeModal();
    dispatch(editorAction.editingModeLayerContents(false));
  }, [selectedLayerType, closeModal, dispatch, removeOverlayContents, contentsId, removeLayerContents]);

  return (
    <Modal
      title={
        <Title>
          <WarnIcon />
          경고 App 삭제
        </Title>
      }
      isLine
      onConfirm={removeApp}
      onBackdropClick={closeModal}
      onCancel={closeModal}
      onClose={closeModal}
      visible={isOpen}
      size="large"
    >
      <Notice>Layer App을 삭제하시겠습니까?</Notice>
    </Modal>
  );
};

const Title = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  color: #f05b5b;
  font-size: 14px;
  font-weight: 500;
`;

const Notice = styled.div`
  font-size: 14px;
  color: #333333;
`;

export default React.memo(RemoveConfirmModal);
