import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';

import * as apiLib from '../lib/api';
import * as overlayContentsApiLib from '../lib/overlayContentsApi';
import * as contentsApiLib from '../lib/contentsApi';
import * as publicContentsApiLib from '../lib/publicContentsApi';
import { activeItemSelector } from './activeItemSlice';
import { placeSelector } from './placeSlice';
import { contentsAction } from './contentsSlice';
import { overlayAction } from './overlaySlice';
import { overlayContentsAction } from './overlayContentsSlice';

function* getOverlayContentsListSaga({ payload: playlistId }) {
  try {
    const overlayContentsList = yield call(overlayContentsApiLib.getOverlayContentsList, playlistId);

    yield put(overlayContentsAction.getOverlayContentsListSuccess({ overlayContentsList }));
  } catch (error) {
    console.log(error);
    yield put(overlayContentsAction.getOverlayContentsListFailure('Layer Contents List Error'));
  }
}

function* getOverlayContents({ payload: overlayId }) {
  try {
    const playlistId = yield select(activeItemSelector.playlistId);
    const overlayContents = yield call(overlayContentsApiLib.getOverlayContents, overlayId);
    if (overlayContents) {
      if (overlayContents.contentsType === 'media') {
        const mediaList = overlayContents.contentsData.mediaList;
        for (let i = 0; i < mediaList.length; i++) {
          const data = {
            fileId: mediaList[i].fileId,
          };

          let result = '';
          if (mediaList[i].fromPublic && mediaList[i].fromPublic === 'Y') {
            result = yield call(publicContentsApiLib.getPublicFileExistenceCheck, data);
          } else {
            result = yield call(contentsApiLib.getStorageFileExistenceCheck, data);
          }
          let file = result.fileData;
          if (file === undefined) { //file이 DB에 존재하지 않는 경우, filePath 수정
            file = {
              fileId: mediaList[i].fileData.fileId,
              fileNm: mediaList[i].fileData.fileNm,
              filePath: mediaList[i].fileData?.filePath || '',
              fileSize: mediaList[i].fileData.fileSize,
              fileType: mediaList[i].fileData.fileType,
              fileThumb: mediaList[i].fileData?.fileThumb || '',
            }

            mediaList[i].fileData = file;
            overlayContents.contentsFileList[i] = file;
          }
        }
      }
      yield put(overlayContentsAction.getOverlayContentsSuccess(overlayContents));
    } else {
      yield put(overlayContentsAction.getOverlayContentsSuccess({ playlistId, overlayId }));
    }
  } catch (error) {
    yield put(overlayContentsAction.getOverlayContentsFailure('getOverlayContents error'));
  }
}

function* saveOverlayContents({ payload: { newOverlayContents, onUploadProgress }}) {
  try {
    const placeInfo = yield select(placeSelector.placeInfo);
    const playlistId = yield select(activeItemSelector.playlistId);
    const overlayId = yield select(activeItemSelector.layerId);

    if (newOverlayContents.contentsType === 'media') {
      yield put(contentsAction.storageFileAddRequest());
      for (let i = 0; i< newOverlayContents.contentsData.mediaList.length; i++) {
        let mediaFile = newOverlayContents.contentsData.mediaList[i];
        try {
          // file DB에 저장되어 있는 파일의 경우
          if (mediaFile.fileData.name === undefined) {
            newOverlayContents.contentsFileList= newOverlayContents.contentsFileList.concat({
              fileId: mediaFile.fileId,
              filePath: mediaFile.fileData.filePath,
              fileType: mediaFile.fileData.fileType,
              fileNm: mediaFile.fileData.fileNm,
              fileSize: mediaFile.fileData.fileSize,
              fileThumb: mediaFile.fileData?.fileThumb || '',
            });
            continue;
          }

          let data = {
            placeId: mediaFile.filePlaceId,
            folderId: mediaFile.fileFolderId,
            fileNm: mediaFile.fileData.name,
            fileSize: mediaFile.fileData.size,
            fileType: mediaFile.fileData.type,
            fileDuration: mediaFile.fileData.type.includes('video') ? mediaFile.changeTime : '0',
          };

          if (mediaFile.fileThumb) {
            const thumbNm = mediaFile.fileThumb.name;
            const thumbType = mediaFile.fileThumb.type;
            const thumbData = {
              folderId: mediaFile.fileFolderId,
              thumbNm: thumbNm,
              thumbType: thumbType,
            }
            const thumbResult = yield call(contentsApiLib.getStorageThumbAdd, thumbData);
            const thumbPresignUrl = thumbResult.url;
            yield call(apiLib.fetchUploadApi, thumbPresignUrl, mediaFile.fileThumb);
            data.fileThumbId = thumbResult.thumbInfo.thumbId;
            data.fileThumb = thumbResult.thumbInfo.thumbPath;
          }

          const result = yield call(contentsApiLib.getStorageFileAdd, data);
          const presignUrl = result.url;
          yield call(apiLib.fetchUploadApi, presignUrl, mediaFile.fileData, onUploadProgress, i);

          const fileId = result.fileInfo.fileId;
          const filePath = result.fileInfo.filePath;
          const fileTumb = result.fileInfo.fileThumb;

          mediaFile.fileId = fileId;
          mediaFile.fileData.fileId = fileId;
          mediaFile.fileData.fileNm = mediaFile.fileData.name;
          mediaFile.fileData.fileSize = mediaFile.fileData.size;
          mediaFile.fileData.fileType = mediaFile.fileData.type;
          mediaFile.fileData.filePath = filePath;
          mediaFile.fileData.fileThumb = fileTumb;
          delete mediaFile.filePlaceId;
          delete mediaFile.fileFolderId;
          delete mediaFile.filePreview;
          delete mediaFile.fileThumb;
          delete mediaFile.fileThumbPreview;

          // 새로운 파일을 추가한 경우, file DB에 저장 후 해당 정보를 contentsFileList에 추가
          newOverlayContents.contentsFileList= newOverlayContents.contentsFileList.concat({
            fileId: result.fileInfo.fileId,
            filePath: result.fileInfo.filePath,
            fileType: result.fileInfo.fileType,
            fileNm: result.fileInfo.fileNm,
            fileSize: result.fileInfo.fileSize,
            fileThumb: result.fileInfo?.fileThumb || '',
          });

          // const uploadData = new FormData();
          // uploadData.append('file', file, file.fileNm);
          
        } catch (error) {
          // yield put(contentsAction.storageFileUploadMode(5));
          yield put(contentsAction.storageFileAddFailure());
          return;
        }
      }
      yield put(contentsAction.storageFileAddSuccess());
      yield put(contentsAction.getPlaceFileInfo());

    } else if (newOverlayContents.contentsType === 'oneFloor') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        placeId: placeInfo.placeId,
        folderPid: placeInfo.placeId,
        folderId: `${placeInfo.placeId}-app`,
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(newOverlayContents);
      let uploadCnt = 0;
      for (let company of newOverlayContents.contentsData.companyList) {
        // console.log(company.logoFile); // 실제 파일 객체
        try {
          if (company.logoFile) {
            uploadCnt++;

            const data = {
              placeId: placeInfo.placeId,
              folderId: `${placeInfo.placeId}-app`,
              fileNm: company.logoFile.name,
              fileSize: company.logoFile.size,
              fileType: company.logoFile.type,
            };

            const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
            const presignUrl = result.url;
            const fileId = result.fileInfo.fileId;
            const filePath = result.fileInfo.filePath;

            // eliga-interaction-layer-contents DB용 데이터 가공
            company.logoFile.fileId = fileId;
            company.logoFile.fileNm = company.logoFile.name;
            company.logoFile.fileSize = company.logoFile.size;
            company.logoFile.fileType = company.logoFile.type;
            company.logoFile.filePath = filePath;
            delete company.logoFile.preview;

            // contentsFileList 데이터 생성
            newOverlayContents.contentsFileList = newOverlayContents.contentsFileList.concat({
              fileId: fileId,
              fileNm: company.logoFile.name,
              fileSize: company.logoFile.size,
              fileType: company.logoFile.type,
              filePath: filePath
            });

            const uploadData = new FormData();
            uploadData.append('file', company.logoFile, company.logoFile.name);
            yield call(apiLib.fetchUploadApi, presignUrl, company.logoFile, onUploadProgress, uploadCnt); // file upload
          }
        } catch (error) {
          console.log(error);
          yield put(contentsAction.storageFileAddFailure(error));
          return;
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
      yield put(contentsAction.getPlaceFileInfo());
    } else if (newOverlayContents.contentsType === 'wholeFloor') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        placeId: placeInfo.placeId,
        folderPid: placeInfo.placeId,
        folderId: `${placeInfo.placeId}-app`,
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(newOverlayContents);
      let uploadCnt = 0;
      for (let floor of newOverlayContents.contentsData.floorList) {
        for (let company of floor.companyList) {
          // console.log(company.logoFile); // 실제 파일 객체
          try {
            if (company.logoFile) {
              uploadCnt++;

              const data = {
                placeId: placeInfo.placeId,
                folderId: `${placeInfo.placeId}-app`,
                fileNm: company.logoFile.name,
                fileSize: company.logoFile.size,
                fileType: company.logoFile.type,
              };

              const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
              const presignUrl = result.url;
              const fileId = result.fileInfo.fileId;
              const filePath = result.fileInfo.filePath;

              // eliga-interaction-layer-contents DB용 데이터 가공
              company.logoFile.fileId = fileId;
              company.logoFile.fileNm = company.logoFile.name;
              company.logoFile.fileSize = company.logoFile.size;
              company.logoFile.fileType = company.logoFile.type;
              company.logoFile.filePath = filePath;
              delete company.logoFile.preview;

              // contentsFileList 데이터 생성
              newOverlayContents.contentsFileList = newOverlayContents.contentsFileList.concat({
                fileId: fileId,
                fileNm: company.logoFile.name,
                fileSize: company.logoFile.size,
                fileType: company.logoFile.type,
                filePath: filePath
              });

              const uploadData = new FormData();
              uploadData.append('file', company.logoFile, company.logoFile.name);
              yield call(apiLib.fetchUploadApi, presignUrl, company.logoFile, onUploadProgress, uploadCnt); // file upload
            }
          } catch (error) {
            console.log(error);
            yield put(contentsAction.storageFileAddFailure(error));
            return;
          }
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
      yield put(contentsAction.getPlaceFileInfo());
    } else if (newOverlayContents.contentsType === 'logo') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        placeId: placeInfo.placeId,
        folderPid: placeInfo.placeId,
        folderId: `${placeInfo.placeId}-app`,
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(newOverlayContents);
      let uploadCnt = 0;
      for (let logo of newOverlayContents.contentsData.logoList) {
        // console.log(logo.logoFile); // 실제 파일 객체
        try {
          if (logo.logoFile) {
            uploadCnt++;

            const data = {
              placeId: placeInfo.placeId,
              folderId: `${placeInfo.placeId}-app`,
              fileNm: logo.logoFile.name,
              fileSize: logo.logoFile.size,
              fileType: logo.logoFile.type,
            };

            const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
            const presignUrl = result.url;
            const fileId = result.fileInfo.fileId;
            const filePath = result.fileInfo.filePath;

            // eliga-interaction-layer-contents DB용 데이터 가공
            logo.logoFile.fileId = fileId;
            logo.logoFile.fileNm = logo.logoFile.name;
            logo.logoFile.fileSize = logo.logoFile.size;
            logo.logoFile.fileType = logo.logoFile.type;
            logo.logoFile.filePath = filePath;
            delete logo.logoFile.preview;

            // contentsFileList 데이터 생성
            newOverlayContents.contentsFileList = newOverlayContents.contentsFileList.concat({
              fileId: fileId,
              fileNm: logo.logoFile.name,
              fileSize: logo.logoFile.size,
              fileType: logo.logoFile.type,
              filePath: filePath
            });

            const uploadData = new FormData();
            uploadData.append('file', logo.logoFile, logo.logoFile.name);
            yield call(apiLib.fetchUploadApi, presignUrl, logo.logoFile, onUploadProgress, uploadCnt); // file upload
          }
        } catch (error) {
          console.log(error);
          yield put(contentsAction.storageFileAddFailure(error));
          return;
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
      yield put(contentsAction.getPlaceFileInfo());
    }

    yield call(overlayContentsApiLib.getSaveOverlayContents, { playlistId, overlayId, newOverlayContents }); // insert to eliga-interaction-layer-contents DB
    yield put(overlayContentsAction.saveOverlayContentsSuccess(newOverlayContents));
    yield put(contentsAction.actionResultClear());
    yield put(overlayContentsAction.getOverlayContentsList(playlistId)); // 레이어 콘텐츠 리스트 갱신
    yield put(overlayAction.updateOverlay({ overlayId, updateOverlay: { overlayType: newOverlayContents.contentsType } }));
  } catch (error) {
    yield put(overlayContentsAction.saveOverlayContentsFailure('saveOverlayContents error'));
  }
}

function* updateOverlayContents({ payload: { updateOverlayContents, onUploadProgress }}) {
  try {
    const playlistId = yield select(activeItemSelector.playlistId);
    const placeInfo = yield select(placeSelector.placeInfo);

    if (updateOverlayContents.contentsType === 'media') {
      yield put(contentsAction.storageFileAddRequest());
      //삭제된 컨텐츠가 있을 경우, contentsFileList에서도 삭제
      updateOverlayContents.contentsFileList = updateOverlayContents.contentsFileList.filter(fileList => updateOverlayContents.contentsData.mediaList.includes(fileList.fileId));

      for (let i = 0; i< updateOverlayContents.contentsData.mediaList.length; i++) {
        let mediaFile = updateOverlayContents.contentsData.mediaList[i];
        try {
          // file DB에 저장되어 있는 파일의 경우
          if (mediaFile.fileData.name === undefined) {
              updateOverlayContents.contentsFileList = updateOverlayContents.contentsFileList.concat({
                fileId: mediaFile.fileId,
                filePath: mediaFile.fileData.filePath,
                fileType: mediaFile.fileData.fileType,
                fileNm: mediaFile.fileData.fileNm,
                fileSize: mediaFile.fileData.fileSize,
                fileThumb: mediaFile.fileData?.fileThumb || '',
              });
            continue;
          }

          let data = {
            placeId: mediaFile.filePlaceId,
            folderId: mediaFile.fileFolderId,
            fileNm: mediaFile.fileData.name,
            fileSize: mediaFile.fileData.size,
            fileType: mediaFile.fileData.type,
            fileDuration: mediaFile.fileData.type.includes('video') ? mediaFile.changeTime : '0',
          };

          if (mediaFile.fileThumb) {
            const thumbNm = mediaFile.fileThumb.name;
            const thumbType = mediaFile.fileThumb.type;
            const thumbData = {
              folderId: mediaFile.fileFolderId,
              thumbNm: thumbNm,
              thumbType: thumbType,
            }
            const thumbResult = yield call(contentsApiLib.getStorageThumbAdd, thumbData);
            const thumbPresignUrl = thumbResult.url;
            yield call(apiLib.fetchUploadApi, thumbPresignUrl, mediaFile.fileThumb);
            data.fileThumbId = thumbResult.thumbInfo.thumbId;
            data.fileThumb = thumbResult.thumbInfo.thumbPath;
          }
          
          const result = yield call(contentsApiLib.getStorageFileAdd, data);
          const presignUrl = result.url;
          yield call(apiLib.fetchUploadApi, presignUrl, mediaFile.fileData, onUploadProgress, i);
          
          const fileId = result.fileInfo.fileId;
          const filePath = result.fileInfo.filePath;
          const fileTumb = result.fileInfo.fileThumb;

          mediaFile.fileId = fileId;
          mediaFile.fileData.fileId = fileId;
          mediaFile.fileData.fileNm = mediaFile.fileData.name;
          mediaFile.fileData.fileSize = mediaFile.fileData.size;
          mediaFile.fileData.fileType = mediaFile.fileData.type;
          mediaFile.fileData.filePath = filePath;
          mediaFile.fileData.fileThumb = fileTumb;
          delete mediaFile.filePlaceId;
          delete mediaFile.fileFolderId;
          delete mediaFile.filePreview;
          delete mediaFile.fileThumb;
          delete mediaFile.fileThumbPreview;

          // 새로운 파일을 추가한 경우, file DB에 저장 후 해당 정보를 contentsFileList에 추가
          updateOverlayContents.contentsFileList = updateOverlayContents.contentsFileList.concat({
            fileId: result.fileInfo.fileId,
            filePath: result.fileInfo.filePath,
            fileType: result.fileInfo.fileType,
            fileNm: result.fileInfo.fileNm,
            fileSize: result.fileInfo.fileSize,
            fileThumb: result.fileInfo?.fileThumb || '',
          });

          // const uploadData = new FormData();
          // uploadData.append('file', file, file.fileNm);
          // yield call(apiLib.fetchUploadApi, presignUrl, mediaFile.fileData, onUploadProgress);
        } catch (error) {
          // yield put(contentsAction.storageFileUploadMode(5));
          yield put(contentsAction.storageFileAddFailure());
          return;
        }
      }
      yield put(contentsAction.storageFileAddSuccess());
      yield put(contentsAction.getPlaceFileInfo());

    } else if (updateOverlayContents.contentsType === 'oneFloor') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        placeId: placeInfo.placeId,
        folderPid: placeInfo.placeId,
        folderId: `${placeInfo.placeId}-app`,
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(updateOverlayContents);
      let uploadCnt = 0;
      for (let company of updateOverlayContents.contentsData.companyList) {
        // console.log(company.logoFile); // 실제 파일 객체
        try {
          if (company.logoFile) {
            if (!company.logoFile.fileId) { // 파일에 변경이 생긴 경우
              uploadCnt++;

              const data = {
                placeId: placeInfo.placeId,
                folderId: `${placeInfo.placeId}-app`,
                fileNm: company.logoFile.name,
                fileSize: company.logoFile.size,
                fileType: company.logoFile.type,
              };
  
              const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
              const presignUrl = result.url;
              const fileId = result.fileInfo.fileId;
              const fileNm = result.fileInfo.fileNm;
              const fileSize = result.fileInfo.fileSize;
              const fileType = result.fileInfo.fileType;
              const filePath = result.fileInfo.filePath;
  
              // eliga-interaction-layer-contents DB용 데이터 가공
              company.logoFile.fileId = fileId;
              company.logoFile.fileNm = fileNm;
              company.logoFile.fileSize = fileSize;
              company.logoFile.fileType = fileType;
              company.logoFile.filePath = filePath;
              delete company.logoFile.preview;
  
              const uploadData = new FormData();
              uploadData.append('file', company.logoFile, company.logoFile.name);
              yield call(apiLib.fetchUploadApi, presignUrl, company.logoFile, onUploadProgress, uploadCnt); // file upload
            }

            // contentsFileList 데이터 생성
            // console.log(company.logoFile);
            updateOverlayContents.contentsFileList = updateOverlayContents.contentsFileList.concat({
              fileId: company.logoFile.fileId,
              fileNm: company.logoFile.fileNm,
              fileSize: company.logoFile.fileSize,
              fileType: company.logoFile.fileType,
              filePath: company.logoFile.filePath
            });
          }
        } catch (error) {
          console.log(error);
          yield put(contentsAction.storageFileAddFailure(error));
          return;
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
      yield put(contentsAction.getPlaceFileInfo());
    } else if (updateOverlayContents.contentsType === 'wholeFloor') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        placeId: placeInfo.placeId,
        folderPid: placeInfo.placeId,
        folderId: `${placeInfo.placeId}-app`,
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(updateOverlayContents);
      let uploadCnt = 0;
      for (let floor of updateOverlayContents.contentsData.floorList) {
        for (let company of floor.companyList) {
          // console.log(company.logoFile); // 실제 파일 객체
          try {
            if (company.logoFile) {
              if (!company.logoFile.fileId) { // 파일에 변경이 생긴 경우
                uploadCnt++;

                const data = {
                  placeId: placeInfo.placeId,
                  folderId: `${placeInfo.placeId}-app`,
                  fileNm: company.logoFile.name,
                  fileSize: company.logoFile.size,
                  fileType: company.logoFile.type,
                };
    
                const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
                const presignUrl = result.url;
                const fileId = result.fileInfo.fileId;
                const fileNm = result.fileInfo.fileNm;
                const fileSize = result.fileInfo.fileSize;
                const fileType = result.fileInfo.fileType;
                const filePath = result.fileInfo.filePath;
    
                // eliga-interaction-layer-contents DB용 데이터 가공
                company.logoFile.fileId = fileId;
                company.logoFile.fileNm = fileNm;
                company.logoFile.fileSize = fileSize;
                company.logoFile.fileType = fileType;
                company.logoFile.filePath = filePath;
                delete company.logoFile.preview;
    
                const uploadData = new FormData();
                uploadData.append('file', company.logoFile, company.logoFile.name);
                yield call(apiLib.fetchUploadApi, presignUrl, company.logoFile, onUploadProgress, uploadCnt); // file upload
              }

              // contentsFileList 데이터 생성
              // console.log(company.logoFile);
              updateOverlayContents.contentsFileList = updateOverlayContents.contentsFileList.concat({
                fileId: company.logoFile.fileId,
                fileNm: company.logoFile.fileNm,
                fileSize: company.logoFile.fileSize,
                fileType: company.logoFile.fileType,
                filePath: company.logoFile.filePath
              });
            }
          } catch (error) {
            console.log(error);
            yield put(contentsAction.storageFileAddFailure(error));
            return;
          }
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
      yield put(contentsAction.getPlaceFileInfo());
    } else if (updateOverlayContents.contentsType === 'logo') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        placeId: placeInfo.placeId,
        folderPid: placeInfo.placeId,
        folderId: `${placeInfo.placeId}-app`,
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(updateOverlayContents);
      let uploadCnt = 0;
      for (let logo of updateOverlayContents.contentsData.logoList) {
        // console.log(logo.logoFile); // 실제 파일 객체
        try {
          if (logo.logoFile) {
            if (!logo.logoFile.fileId) { // 파일에 변경이 생긴 경우
              uploadCnt++;

              const data = {
                placeId: placeInfo.placeId,
                folderId: `${placeInfo.placeId}-app`,
                fileNm: logo.logoFile.name,
                fileSize: logo.logoFile.size,
                fileType: logo.logoFile.type,
              };
  
              const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
              const presignUrl = result.url;
              const fileId = result.fileInfo.fileId;
              const fileNm = result.fileInfo.fileNm;
              const fileSize = result.fileInfo.fileSize;
              const fileType = result.fileInfo.fileType;
              const filePath = result.fileInfo.filePath;
  
              // eliga-interaction-layer-contents DB용 데이터 가공
              logo.logoFile.fileId = fileId;
              logo.logoFile.fileNm = fileNm;
              logo.logoFile.fileSize = fileSize;
              logo.logoFile.fileType = fileType;
              logo.logoFile.filePath = filePath;
              delete logo.logoFile.preview;
  
              const uploadData = new FormData();
              uploadData.append('file', logo.logoFile, logo.logoFile.name);
              yield call(apiLib.fetchUploadApi, presignUrl, logo.logoFile, onUploadProgress, uploadCnt); // file upload
            }

            // contentsFileList 데이터 생성
            // console.log(logo.logoFile);
            updateOverlayContents.contentsFileList = updateOverlayContents.contentsFileList.concat({
              fileId: logo.logoFile.fileId,
              fileNm: logo.logoFile.fileNm,
              fileSize: logo.logoFile.fileSize,
              fileType: logo.logoFile.fileType,
              filePath: logo.logoFile.filePath
            });
          }
        } catch (error) {
          console.log(error);
          yield put(contentsAction.storageFileAddFailure(error));
          return;
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
      yield put(contentsAction.getPlaceFileInfo());
    }

    yield call(overlayContentsApiLib.getUpdateOverlayContents, updateOverlayContents);
    yield put(overlayContentsAction.updateOverlayContentsSuccess(updateOverlayContents));
    yield put(overlayContentsAction.getOverlayContentsList(playlistId)); // 레이어 콘텐츠 리스트 갱신
    yield put(contentsAction.actionResultClear());
    yield put(overlayAction.updateOverlay({ overlayId: updateOverlayContents.overlayId, updateOverlay: { overlayType: updateOverlayContents.contentsType } }));
  } catch (error) {
    console.log(error);
    yield put(overlayContentsAction.updateOverlayContentsFailure('updateOverlayContents error'));
  }
}

function* removeOverlayContents({ payload: { removeOverlayContents }}) {
  try {
    const playlistId = yield select(activeItemSelector.playlistId);
    const overlayId = yield select(activeItemSelector.layerId);

    yield call(overlayContentsApiLib.getRemoveOverlayContents, removeOverlayContents);
    yield put(overlayContentsAction.removeOverlayContentsSuccess());
    yield put(overlayContentsAction.getOverlayContentsList(playlistId)); // 레이어 콘텐츠 리스트 갱신
    yield put(overlayAction.updateOverlay({ overlayId, updateOverlay: { overlayType: '' } }));
  } catch (error) {
    yield put(overlayContentsAction.removeOverlayContentsFailure('removeOverlayContents error'));
  }
}

export function* watchGetOverlayContentsList() {
  yield takeLatest(overlayContentsAction.getOverlayContentsList, getOverlayContentsListSaga);
}

export function* watchgetOverlayContents() {
  yield takeLatest(overlayContentsAction.getOverlayContents, getOverlayContents);
}

export function* watchSaveOverlayContents() {
  yield takeLatest(overlayContentsAction.saveOverlayContents, saveOverlayContents);
}

export function* watchUpdateOverlayContents() {
  yield takeLatest(overlayContentsAction.updateOverlayContents, updateOverlayContents);
}

export function* watchRemoveOverlayContents() {
  yield takeLatest(overlayContentsAction.removeOverlayContents, removeOverlayContents);
}

function* rootSaga() {
  yield all([
    fork(watchGetOverlayContentsList),
    fork(watchgetOverlayContents),
    fork(watchSaveOverlayContents),
    fork(watchUpdateOverlayContents),
    fork(watchRemoveOverlayContents),
  ]);
}

export default rootSaga;
