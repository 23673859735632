import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { createPortal } from 'react-dom';

import { layerContentsAction, layerContentsSelector } from '../module/layerContentsSlice';

import { FiAlertTriangle as AlertIcon } from 'react-icons/fi';
import Button from './Button';

const useCheckEditingMode = () => {
  const dispatch = useDispatch();
  const { editingMode } = useSelector(layerContentsSelector.status);

  const [showModal, setShowModal] = useState(false);
  const [afterFn, setAfterFn] = useState();

  const bgRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      document.getElementById('root').parentNode.style.overflow = 'hidden';
      document.getElementById('root').parentNode.style.paddingRight = '21px';
    } else {
      document.getElementById('root').parentNode.style = 'none';
    }
  }, [showModal]);

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? 'translateY(0%)' : `translateY(-20%)`,
  });

  const editingCheck = useCallback(
    afterAction => {
      if (editingMode) {
        setShowModal(true);
        setAfterFn(afterFn => afterAction);
      } else {
        return afterAction();
      }
    },
    [editingMode],
  );

  const onClickOk = useCallback(() => {
    setShowModal(false);
    afterFn();
    dispatch(layerContentsAction.resetEditingModeLayerContents());
  }, [dispatch, afterFn]);

  const onClickCancel = useCallback(() => {
    setShowModal(false);
    dispatch(layerContentsAction.editingModeLayerContents());
    return false;
  }, [dispatch]);

  const LayerContentsWarnModal = () => {
    return createPortal(
      <>
        {showModal && (
          <Background ref={bgRef}>
            <animated.div style={animation}>
              <WarnModalWrapper showModal={showModal}>
                <ModalHeader>레이어 콘텐츠 변경 안내</ModalHeader>
                <ModalBody>
                  <div style={{ color: '#f05b5b', marginBottom: '15px' }}>
                    <AlertIcon size={15} color="#f05b5b" style={{ marginRight: '10px' }} />
                    경고
                  </div>
                  레이어 콘텐츠 내용에 변경 사항이 존재합니다.
                  <br />
                  저장하지 않은 상태에서 이동할 경우 변경 사항이 사라집니다.
                  <br />
                  그래도 진행하시겠습니까?
                </ModalBody>
                <ModalFooter>
                  <StyledButton gray width={80} height={38} fontSize={15} onClick={() => onClickCancel()}>
                    취소
                  </StyledButton>
                  <StyledButton red width={80} height={38} fontSize={15} onClick={() => onClickOk()}>
                    확인
                  </StyledButton>
                </ModalFooter>
              </WarnModalWrapper>
            </animated.div>
          </Background>
        )}
      </>,
      document.getElementById('modal-root'),
    );
  };

  return {
    editingCheck,
    LayerContentsWarnModal,
    isEditingChecking: showModal,
  };
};

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
`;

const WarnModalWrapper = styled.div`
  position: relative;
  width: 450px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #666;
  z-index: 1050;
  border-radius: 6px;
  font-size: 1.25rem;
  font-weight: 300;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1.6rem;
  border-bottom: 1px solid #dee2e6;
  font-size: 18px;
  color: #666666;
`;

const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1.3rem 1.6rem;
  font-size: 15px;
  line-height: 17px;
  & .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .mt {
    margin-top: 10px;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem 1.6rem;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

export default useCheckEditingMode;
