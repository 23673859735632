import React, { memo } from "react";
import styled from "styled-components";
import Scroll from "./Scroll";

const EachCompany = memo(({ companyData, contentsFileList, clickCompany }) => {
  let logoFile = companyData.logoFile;
  return (
    <EachCompanyBox onClick={() => clickCompany(companyData.frameLinkId)}>
      <div className={"logo-box"}>
        {logoFile && (
          <img
            src={
              process.env.REACT_APP_INTERACTION_CDN_URL +
              contentsFileList.find((f) => f.fileId === logoFile.fileId)
                ?.filePath
            }
            alt={companyData.companyName}
          />
        )}
      </div>
      <div className={"each-company-info"}>
        {/* 공백문자를 이용해서 영역 잡아줌 */}
        <span className={"each-company-title"}>
          {companyData.companyName || "\u2001"}
        </span>
        <dl>
          <dt>설립연도</dt>
          <dd>{companyData.companyYear}</dd>
          <dt>대표자명</dt>
          <dd>{companyData.bossName}</dd>
          <dt>대표전화</dt>
          <dd>{companyData.telephone}</dd>
          <dt>사업분야</dt>
          <dd>{companyData.businessArea}</dd>
        </dl>
      </div>
    </EachCompanyBox>
  );
});

const FloorInfo = ({ floorData, navFunction, contentsFileList }) => {
  const clickCompany = (frameLinkId) => {
    if (frameLinkId !== "none" && frameLinkId) {
      navFunction.jumpToFrame(frameLinkId);
    }
  };
  return (
    <FloorInfoBox>
      <span className={"floor-info-title"}>{floorData.oneFloorTitle}</span>
      <Scroll paddingBottom={40}>
        <ul className={"floor-info-list"}>
          {floorData.companyList.map((company, index) => (
            <EachCompany
              key={index}
              companyData={company}
              contentsFileList={contentsFileList}
              clickCompany={clickCompany}
            />
          ))}
        </ul>
      </Scroll>
    </FloorInfoBox>
  );
};

const FloorInfoBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  .floor-info-title {
    display: block;
    font-size: 50px;
    font-weight: bold;
    margin: 40px 60px;
    flex: none;
  }
  .floor-info-list {
    margin: 0 60px;
  }
`;
const EachCompanyBox = styled.li`
  width: 950px;
  height: 245px;
  background: #fff;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 40px;
  &:first-child {
    margin: 0;
  }
  .logo-box {
    margin: auto 30px;
    background: #f9f9f9;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 15px;
    width: 300px;
    height: 195px;
    display: flex;
    flex: none;
    img {
      margin: auto;
      width: 205px;
      height: 85px;
      object-fit: scale-down;
    }
  }
  .each-company-info {
    margin: auto 30px auto 0;
    overflow: hidden;
    span {
      font-size: 30px;
      font-weight: bold;
    }
    dl {
      display: grid;
      grid-template-columns: 75px 1fr;
      grid-auto-rows: 30px;
      grid-gap: 5px 15px;
      font-size: 20px;
      margin: 6px 0;
      dt {
        color: #999;
      }
      dt,
      dd {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export default React.memo(FloorInfo);
