import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
// import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';

import SelectInput from '../../../newComponents/SelectInput';
// import ClickOutside from '../../../components/ClickOutside';
import Button from '../../../newComponents/Button';
// import transparencyBg from '../../../assets/images/background-color-none.svg';

// import { hexToRgb, rgbToHex } from '../../../utils/color';

import { editorAction, editorSelector } from '../../../module/editorSlice';
import { useTouchLayerRemoveMutation, useTouchLayerUpdateMutation } from '../../../rtk/touchLayerApi';
import { endpoints as endPointsFrameApi } from '../../../rtk/frameApi';
import { playlistApi } from '../../../rtk/playlistApi';

const TouchModalLayerProperty = ({ moveableRef, selectedTouchLayerInfo, frameAngle }) => {
  const dispatch = useDispatch();
  const frameId = useSelector(editorSelector.touchFrameId);
  const playlistId = useSelector(editorSelector.playlistId);
  const {
    data: { horizonResolution, verticalResolution },
  } = playlistApi.endpoints.playlistDetail.useQueryState({ playlistId });

  const [updateTouchLayer] = useTouchLayerUpdateMutation();
  const [removeTouchLayer] = useTouchLayerRemoveMutation();

  /**
   * 레이어 n번회전 후 좌표 및 크기
   * @param {number} x x좌표
   * @param {number} y y좌표
   * @param {number} width width
   * @param {number} height height
   * @param {number} originAngle 회전 기준 각도
   * @param {number} rotateCnt 90도 회전 수
   */
  const celMatrixRotate = useCallback(
    ({ x, y, width, height, originAngle, rotateCnt }) => {
      const vertical = Number(verticalResolution);
      const horizon = Number(horizonResolution);

      // 반시계 방향 레이어 꼭지점 좌표
      const rotateLayerMatrix = [
        [x, -y],
        [x, -y - height],
        [x + width, -y - height],
        [x + width, -y],
      ];

      const MathSin = [0, 1, 0, -1]; // [sin0, sin90, sin180, sin270]
      const MathCos = [1, 0, -1, 0]; // [cos0, cos90, cos180, cos270]

      const setaIndex = rotateCnt < 0 ? (4 + rotateCnt) % 4 : rotateCnt % 4;

      // 좌표 회전후 해상도만큼 이동
      const moveQuadrantMatrix = [
        [0, 0], // 4사분면
        [vertical, 0], // 3사분면
        [horizon, -vertical], // 2사분면
        [0, -horizon], // 1사분면
      ];
      const currentQuadrantIdx = (originAngle / 90) % 4; // 현재 사분면
      const afterQuadrantIdx =
        (rotateCnt < 0 ? currentQuadrantIdx + (4 + (rotateCnt % 4)) : currentQuadrantIdx + (rotateCnt % 4)) % 4; // 이동후 사분면

      /*
        좌표 회전
        세타 = 각도 * Math.PI / 180
        x` = x * cos세타 + y * sin세타
        y` = -x * sin세타 + y * cos세타
      */
      for (const point of rotateLayerMatrix) {
        const tempPoint = [...point];
        tempPoint[0] -= moveQuadrantMatrix[currentQuadrantIdx][0]; // 원점 이동
        tempPoint[1] -= moveQuadrantMatrix[currentQuadrantIdx][1]; // 원점 이동

        point[0] = tempPoint[0] * MathCos[setaIndex] + tempPoint[1] * MathSin[setaIndex]; // 좌표회전
        point[1] = -tempPoint[0] * MathSin[setaIndex] + tempPoint[1] * MathCos[setaIndex]; // 좌표회전

        point[0] += moveQuadrantMatrix[afterQuadrantIdx][0]; // 원점 이동
        point[1] += moveQuadrantMatrix[afterQuadrantIdx][1]; // 원점 이동
      }

      if (rotateCnt >= 0) {
        // 시계방향 회전
        for (let i = 0; i < rotateCnt; i++) {
          const firstPoint = rotateLayerMatrix.shift();
          rotateLayerMatrix.push(firstPoint);
        }
      } else {
        // 반시계방향 회전
        for (let i = 0; i > rotateCnt; i--) {
          const lastPoint = rotateLayerMatrix.pop();
          rotateLayerMatrix.unshift(lastPoint);
        }
      }

      const returnOffset = {
        x: Math.abs(rotateLayerMatrix[0][0]),
        y: Math.abs(rotateLayerMatrix[0][1]),
        width: Math.abs(rotateLayerMatrix[3][0] - rotateLayerMatrix[0][0]),
        height: Math.abs(rotateLayerMatrix[1][1] - rotateLayerMatrix[0][1]),
      };

      return returnOffset;
    },
    [horizonResolution, verticalResolution],
  );

  // 사용자에게 보여질 임시 좌표
  const touchLayerInfo = useMemo(() => {
    if (selectedTouchLayerInfo.layerId) {
      const x = selectedTouchLayerInfo.x;
      const y = selectedTouchLayerInfo.y;
      const width = selectedTouchLayerInfo.width;
      const height = selectedTouchLayerInfo.height;

      return celMatrixRotate({
        x,
        y,
        width,
        height,
        originAngle: 0,
        rotateCnt: frameAngle / 90,
      });
    } else {
      return {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
    }
  }, [
    celMatrixRotate,
    selectedTouchLayerInfo.x,
    selectedTouchLayerInfo.y,
    selectedTouchLayerInfo.width,
    selectedTouchLayerInfo.height,
    selectedTouchLayerInfo.layerId,
    frameAngle,
  ]);

  const handleUpdateTouchLayerDB = useCallback(
    (updateInfo, flag) => {
      if (selectedTouchLayerInfo.layerId) {
        if (flag === 'x' || flag === 'y' || flag === 'width' || flag === 'height') {
          const x = Number(flag === 'x' ? updateInfo.x : touchLayerInfo.x);
          const y = Number(flag === 'y' ? updateInfo.y : touchLayerInfo.y);
          const width = Number(flag === 'width' ? updateInfo.width : touchLayerInfo.width);
          const height = Number(flag === 'height' ? updateInfo.height : touchLayerInfo.height);

          const originLayerInfo = celMatrixRotate({
            x,
            y,
            width,
            height,
            originAngle: frameAngle,
            rotateCnt: -(frameAngle / 90),
          });

          updateInfo = {
            ...updateInfo,
            x: originLayerInfo.x,
            y: originLayerInfo.y,
            width: originLayerInfo.width,
            height: originLayerInfo.height,
          };
          switch (flag) {
            case 'width':
              moveableRef.current.request('resizable', {
                offsetHeight: updateInfo.height,
                offsetWidth: updateInfo.width,
                isInstant: true,
              });
              break;
            case 'height':
              moveableRef.current.request('resizable', {
                offsetWidth: updateInfo.width,
                offsetHeight: updateInfo.height,
                isInstant: true,
              });
              break;
            case 'x':
              moveableRef.current.request('draggable', { x: updateInfo.x, y: updateInfo.y, isInstant: true });
              break;
            case 'y':
              moveableRef.current.request('draggable', { x: updateInfo.x, y: updateInfo.y, isInstant: true });
              break;
            default:
              break;
          }
        } else {
          updateTouchLayer({ updateList: [{ layerId: selectedTouchLayerInfo.layerId, updateInfo }] });
        }
      }
    },
    [updateTouchLayer, moveableRef, selectedTouchLayerInfo.layerId, touchLayerInfo, frameAngle, celMatrixRotate],
  );

  // const handleUpdateLayerState = useCallback(
  //   updateInfo => {
  //     handleUpdateLayerInfo(updateInfo);
  //   },
  //   [handleUpdateLayerInfo],
  // );

  const handleRemoveTouchLayerDB = useCallback(() => {
    removeTouchLayer({ layerId: selectedTouchLayerInfo.layerId }).then(() => {
      dispatch(editorAction.setState({ key: 'selectedTouchLayerList', value: [] }));
    });
  }, [dispatch, removeTouchLayer, selectedTouchLayerInfo.layerId]);

  return (
    <Component style={selectedTouchLayerInfo.layerId ? { display: 'flex' } : { display: 'none' }}>
      <Box>
        <Row>
          <Input title="X" value={touchLayerInfo.x} name="x" handleUpdateTouchLayerDB={handleUpdateTouchLayerDB} />
          <Input title="Y" value={touchLayerInfo.y} name="y" handleUpdateTouchLayerDB={handleUpdateTouchLayerDB} />
        </Row>
        <Row>
          <Input
            title="W"
            value={touchLayerInfo.width}
            name="width"
            handleUpdateTouchLayerDB={handleUpdateTouchLayerDB}
          />
          <Input
            title="H"
            value={touchLayerInfo.height}
            name="height"
            handleUpdateTouchLayerDB={handleUpdateTouchLayerDB}
          />
        </Row>
      </Box>
      <Box>
        <Row>
          <Input
            title="이름"
            value={selectedTouchLayerInfo.layerNm || ''}
            name="layerNm"
            handleUpdateTouchLayerDB={handleUpdateTouchLayerDB}
          />
        </Row>
        {/* <Row>
            <BackgroundColor
              title="배경"
              backgroundColor={selectedTouchLayerInfo.backgroundColor}
              handleUpdateTouchLayerDB={handleUpdateTouchLayerDB}
              handleUpdateLayerState={handleUpdateLayerState}
            />
          </Row> */}
        <Row>
          <FrameLink
            title="링크"
            playlistId={playlistId}
            frameLinkId={selectedTouchLayerInfo.frameLinkId}
            handleUpdateTouchLayerDB={handleUpdateTouchLayerDB}
          />
        </Row>
      </Box>
      <Box>
        <Row>
          <Button style={{ marginLeft: 'auto' }} color="red" outline onClick={handleRemoveTouchLayerDB}>
            삭제
          </Button>
        </Row>
      </Box>
    </Component>
  );
};

const Input = React.memo(({ title, value, handleUpdateTouchLayerDB, name }) => {
  const inputRef = useRef(null);

  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    inputRef.current.value = value;
  }, [value]);

  const handleBlurInput = (e, name) => {
    if ((name === 'x' || name === 'y') && Number(e.target.value) < 0) {
      e.target.value = 0;
    } else if ((name === 'width' || name === 'height') && Number(e.target.value) < 20) {
      e.target.value = 20;
    }
    handleUpdateTouchLayerDB({ [name]: e.target.value }, name);
  };

  return (
    <InputLabel isFocus={isFocus}>
      <LabelSpan>{title}</LabelSpan>
      <input
        ref={inputRef}
        placeholder={name === 'layerNm' ? '이름을 입력하세요' : ''}
        onInput={e => (name !== 'layerNm' ? (e.target.value = e.target.value.replace(/[^0-9]/g, '')) : e.target.value)}
        onFocus={e => {
          setIsFocus(true);
          e.target.select();
        }}
        onBlur={e => {
          setIsFocus(false);
          handleBlurInput(e, name);
        }}
        onKeyUp={e => e.keyCode === 13 && e.target.blur()}
      />
    </InputLabel>
  );
});

// const BackgroundColor = React.memo(({ title, backgroundColor = { r: 255, g: 255, b: 255, a: 0 }, handleUpdateTouchLayerDB, handleUpdateLayerState }) => {
//   const [isFocus, setIsFocus] = useState(false);
//   const [hexCode, setHexCode] = useState('FFFFFF');
//   const [alpha, setAlpha] = useState('0%');

//   const [isOpen, setIsOpen] = useState(false);

//   useEffect(() => {
//     const hex = rgbToHex(backgroundColor);
//     setHexCode(hex);
//     setAlpha(Number(backgroundColor.a) * 100 + '%');
//   }, [backgroundColor]);

//   const handleChangeHex = value => {
//     if (value.length === 0) {
//       setHexCode(value);
//     } else {
//       var pattern = /^([a-f0-9]{1,6})$/i;
//       var regex = new RegExp(pattern);

//       if (regex.test(value)) {
//         setHexCode(value);
//       }
//     }
//   };

//   const handleBlurHex = value => {
//     var pattern = /^([a-f0-9]{6}|[a-f0-9]{3})$/i;
//     var regex = new RegExp(pattern);

//     if (regex.test(value)) {
//       const { r, g, b } = hexToRgb(value);
//       const backgroundColor = { r, g, b, a: Number(alpha.replace('%', '')) / 100 };
//       handleUpdateTouchLayerDB({ backgroundColor });
//     } else {
//       setHexCode(rgbToHex(backgroundColor));
//     }
//     setIsFocus(false);
//   };

//   const handleFocusAlpha = e => {
//     e.target.select();
//     setAlpha(alpha.replace('%', ''));
//     setIsFocus(true);
//   };

//   const handleChangeAlpha = value => {
//     if (value.length === 0) {
//       setAlpha(value);
//     } else {
//       var pattern = /^[0-9]{1}$|^[1-9]{1}[0-9]{1}$|^[1]{1}[0]{1}[0]{1}$/;
//       var regex = new RegExp(pattern);

//       if (regex.test(value)) {
//         setAlpha(value);
//       }
//     }
//   };

//   const handleBlurAlpha = value => {
//     var pattern = /^[0-9]{1}$|^[1-9]{1}[0-9]{1}$|^[1]{1}[0]{1}[0]{1}$/;
//     var regex = new RegExp(pattern);

//     if (regex.test(value)) {
//       handleUpdateTouchLayerDB({ backgroundColor: { ...backgroundColor, a: Number(value) / 100 } });
//     } else {
//       setAlpha(Number(backgroundColor.a) * 100 + '%');
//     }
//     setIsFocus(false);
//   };

//   return (
//     <BackgroundBox isFocus={isFocus}>
//       <LabelSpan>{title}</LabelSpan>
//       <ClickOutside
//         style={{ display: 'flex', alignItems: 'center', width: 'auto', position: 'relative', gap: '5px' }}
//         onClickOutside={() => {
//           if (isOpen) {
//             const { r, g, b } = hexToRgb(hexCode);
//             const backgroundColor = { r, g, b, a: Number(alpha.replace('%', '')) / 100 };
//             handleUpdateTouchLayerDB({ backgroundColor });
//           }
//           setIsOpen(false);
//           setIsFocus(false);
//         }}
//       >
//         <BackgroundColorModal isOpen={isOpen}>
//           <SketchPicker
//             color={backgroundColor}
//             onChange={color => {
//               handleUpdateLayerState({ backgroundColor: color.rgb });
//             }}
//           />
//         </BackgroundColorModal>
//         <Color
//           backgroundColor={backgroundColor}
//           onClick={() => {
//             setIsFocus(true);
//             setIsOpen(true);
//           }}
//         >
//           <Alpha opacity={1 - backgroundColor.a} />
//         </Color>
//         <input
//           className="background-input"
//           style={{ width: '60px', textAlign: 'center' }}
//           onFocus={e => {
//             e.target.select();
//             setIsFocus(true);
//           }}
//           onChange={e => handleChangeHex(e.target.value)}
//           onBlur={e => handleBlurHex(e.target.value)}
//           value={hexCode}
//         />
//         <input
//           className="background-input input-left"
//           style={{ width: '50px' }}
//           onFocus={e => handleFocusAlpha(e)}
//           onBlur={e => handleBlurAlpha(e.target.value)}
//           onChange={e => handleChangeAlpha(e.target.value)}
//           value={alpha}
//         />
//       </ClickOutside>
//     </BackgroundBox>
//   );
// });

const FrameLink = React.memo(({ title, playlistId, frameLinkId, handleUpdateTouchLayerDB }) => {
  const [frameIdOptionList, setFrameIdOptionList] = useState([
    {
      title: '없음',
      value: 'none',
    },
  ]);
  const [isFocus, setIsFocus] = useState(false);
  const { data: frameList } = endPointsFrameApi.frameList.useQueryState({ playlistId });

  useEffect(() => {
    if (frameList?.length > -1) {
      setFrameIdOptionList(frameIdOptionList => [
        {
          title: '없음',
          value: 'none',
        },
        ...frameList.map(frame => ({
          title: `${Number(frame.frameOrder) + 1}) ${frame.frameNm ? frame.frameNm : ''}`,
          value: frame.frameId,
        })),
      ]);
    }
  }, [frameList]);

  return (
    <FrameLinkBox isFocus={isFocus} onFocus={() => setIsFocus(true)} onBlur={() => setIsFocus(false)}>
      <LabelSpan>{title}</LabelSpan>
      <SelectInput
        selectedValue={frameLinkId}
        optionList={frameIdOptionList}
        onSelectChange={value => handleUpdateTouchLayerDB({ frameLinkId: value })}
        height="28px"
      />
    </FrameLinkBox>
  );
});

const Component = styled.div`
  position: relative;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  gap: 10px;

  & + & {
    border-top: 1px solid #dddddd;
  }
`;

const InputLabel = styled.label`
  user-select: none;
  display: flex;
  align-items: center;
  border: 1px solid ${({ isFocus }) => (isFocus ? '#41a1ea' : 'transparent')};
  width: 100%;
  height: 28px;
  border-radius: 2px;
  position: relative;
  color: #333;
  cursor: text;

  &:hover {
    border-color: ${({ isFocus }) => (isFocus ? '#41a1ea' : '#dddddd')};
  }

  & > input {
    display: flex;
    align-items: center;
    color: #666;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Noto Sans KR';

    width: 100%;
    padding: 0 0 0 7px;
    line-height: 10px;
    border: 1px solid transparent;
    margin-bottom: 2px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #dedede;
    }
  }
`;

const LabelSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  width: 32px;
  height: 100%;
  color: #b3b3b3;
  font-size: 12px;
  text-align: center;
`;

// const BackgroundBox = styled.div`
//   display: flex;
//   align-items: center;
//   width: auto;
//   position: relative;
//   height: 28px;
//   border: 1px solid ${({ isFocus }) => (isFocus ? '#41a1ea' : 'transparent')};

//   & .input-left {
//     border-left-color: ${({ isFocus }) => isFocus && '#41a1ea !important'};
//   }

//   &:hover {
//     border-color: ${({ isFocus }) => (isFocus ? '#41a1ea' : '#dddddd')};
//     .input-left {
//       border-left-color: ${({ isFocus }) => (isFocus ? '#41a1ea' : '#dddddd')};
//     }
//   }

//   & .background-input {
//     display: flex;
//     align-items: center;
//     color: #666;
//     font-weight: 500;
//     font-size: 12px;
//     font-family: 'Noto Sans KR';

//     width: 100%;
//     padding: 0 0 2px 7px;
//     line-height: 10px;
//     border: 1px solid transparent;

//     &:focus {
//       outline: none;
//     }

//     &::placeholder {
//       color: #dedede;
//     }
//   }
// `;

const FrameLinkBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  border: 1px solid ${({ isFocus }) => (isFocus ? '#41a1ea' : 'transparent')};

  &:hover {
    border-color: ${({ isFocus }) => (isFocus ? '#41a1ea' : '#dddddd')};
  }
`;

// const Color = styled.div`
//   position: relative;
//   flex: 0 0 18px;
//   width: 18px;
//   height: 18px;
//   border-radius: 1px;
//   margin-left: 7px;
//   overflow: hidden;
//   background: ${({ backgroundColor }) => `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})`};

//   &:after {
//     content: '';
//     position: absolute;
//     top: -50%;
//     left: -50%;
//     right: -50%;
//     bottom: -50%;
//     box-shadow: inset 0 0 0 1px #0000001a;
//     transform-origin: center center;
//     transform: scale(0.5);
//   }
// `;

// const Alpha = styled.div`
//   right: 0;
//   top: 0;
//   width: 50%;
//   height: 100%;
//   position: absolute;
//   border-top-right-radius: 1px;
//   border-bottom-right-radius: 1px;
//   background-image: url(${transparencyBg});
//   opacity: ${({ opacity }) => opacity};
// `;

// const BackgroundColorModal = styled.div`
//   position: absolute;
//   display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
//   top: 25px;
//   left: -32px;
//   z-index: 999;
// `;

export default React.memo(TouchModalLayerProperty);
