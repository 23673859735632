import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface GroupPayload {
  placeId: string;
  groupPid: string;
  groupId: string;
  groupNm: string;
  groupList: Array<GroupInfo>;
}

interface GroupState {
  groupInfo: GroupInfo;
  groupList: Array<GroupInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const groupInitialState: GroupState = {
  groupInfo: {},
  groupList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  groupList: (state: GroupState, { payload }: PayloadAction<GroupPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  groupListSuccess: (
    state: GroupState,
    { payload }: PayloadAction<GroupState>,
  ) => {
    state.actionResult = 'LIST_OK';
    state.groupList = payload.groupList;
    state.isLoading = false;
    state.error = null;
  },
  groupListFailure: (state: GroupState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  groupInfo: (state: GroupState, { payload }: PayloadAction<GroupPayload>) => {
    state.actionResult = 'INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  groupInfoSuccess: (
    state: GroupState,
    { payload }: PayloadAction<GroupState>,
  ) => {
    state.groupInfo = payload.groupInfo;
    state.actionResult = 'INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  groupInfoFailure: (state: GroupState, action: PayloadAction<string>) => {
    state.actionResult = 'INFO_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  groupSave: (state: GroupState, { payload }: PayloadAction<GroupPayload>) => {
    state.actionResult = 'SAVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  groupSaveSuccess: (
    state: GroupState,
    { payload }: PayloadAction<GroupState>,
  ) => {
    state.actionResult = 'SAVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  groupSaveFailure: (state: GroupState, action: PayloadAction<string>) => {
    state.actionResult = 'SAVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  groupAdd: (state: GroupState, { payload }: PayloadAction<GroupPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  groupAddSuccess: (
    state: GroupState,
    { payload }: PayloadAction<GroupState>,
  ) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  groupAddFailure: (state: GroupState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  groupRemove: (
    state: GroupState,
    { payload }: PayloadAction<GroupPayload>,
  ) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  groupRemoveSuccess: (state: GroupState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  groupRemoveFailure: (state: GroupState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  groupSort: (state: GroupState, { payload }: PayloadAction<GroupPayload>) => {
    state.actionResult = 'SORT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  groupSortSuccess: (state: GroupState) => {
    state.actionResult = 'SORT_OK';
    state.isLoading = false;
    state.error = null;
  },
  groupSortFailure: (state: GroupState, action: PayloadAction<string>) => {
    state.actionResult = 'SORT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: GroupState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'group',
  initialState: groupInitialState,
  reducers: reducers,
});

const selectGroupInfo = createDraftSafeSelector(
  (state: GroupState) => state.groupInfo,
  groupInfo => groupInfo,
);

const selectGroupList = createDraftSafeSelector(
  (state: GroupState) => state.groupList,
  groupList => groupList,
);

const selectStatus = createDraftSafeSelector(
  (state: GroupState) => state.actionResult,
  (state: GroupState) => state.isLoading,
  (state: GroupState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const groupSelector = {
  groupInfo: state => selectGroupInfo(state[GROUP]),
  groupList: state => selectGroupList(state[GROUP]),
  status: state => selectStatus(state[GROUP]),
};

export const GROUP = slice.name;
export const groupReducer = slice.reducer;
export const groupAction = slice.actions;
