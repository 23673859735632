import { interactionBaseApi } from './interactionBaseApi';

export const {
  endpoints,
  useGroupListQuery,
  useGroupAddMutation,
  useGroupEditMutation,
  useGroupRemoveMutation,
  useGroupCoordinateUpdateMutation,
} = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    groupList: build.query({
      query: data => ({ url: '/group/list', data }),
      transformResponse: response => {
        const groupList = response.groupList;
        groupList.sort(function (lhs: any, rhs: any) {
          if (lhs.sortSeq === rhs.sortSeq) {
            return 0;
          }
          return lhs.sortSeq > rhs.sortSeq ? 1 : -1;
        });
        return groupList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ groupId }) => ({ type: 'GROUP', id: groupId })),
        { type: 'GROUP', id: 'LIST' },
      ],
    }),
    groupAdd: build.mutation({
      query: data => ({ url: '/group/add', data }),
      invalidatesTags: [{ type: 'GROUP', id: 'LIST' }],
    }),
    groupEdit: build.mutation({
      query: data => ({ url: '/group/edit', data }),
      invalidatesTags: [{ type: 'GROUP', id: 'LIST' }],
    }),
    groupRemove: build.mutation({
      query: data => ({ url: '/group/remove', data }),
      invalidatesTags: [{ type: 'GROUP', id: 'LIST' }],
    }),
    groupCoordinateUpdate: build.mutation({
      async queryFn({ groupId, lat, lng, zoom }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const result = await fetchInteractionApi({
            url: '/group/gps-edit',
            data: { groupId, groupGpsLat: lat, groupGpsLng: lng, zoom },
          });
          return result;
        } catch (err) {
          console.log('에러', err);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
      invalidatesTags: [{ type: 'GROUP', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});
