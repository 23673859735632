import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from "react-router-dom";

const buttonStyle = css`
  width: ${(props) => (props.width ? props.width : 100)}px;
  height: ${(props) => (props.height ? props.height : 40)}px;
  border-radius: ${(props) => (props.height ? props.height / 2 : 20)}px;
  padding: 0.65rem 0;
  text-align: center;
  &:focus {
    outline: none;
  }

  ${props =>
    props.blue &&
    css`
      border: none;
      background: #41a1ea;
      color: #ffffff;
      &:hover {
        background: #2a91df;
      }
    `}

  ${props =>
    props.blueLine &&
    css`
      border: 1px solid #41a1ea;
      background: #ffffff;
      color: #2a91df;
      &:hover {
        background: #41a1ea;
        color: #ffffff;
      }
    `}

  ${props =>
    props.white &&
    css`
      border: 1px solid #cfcfcf;
      background: #ffffff;
      color: #666666;
      &:hover {
        background: #f9f9f9;
      }
    `}

  ${props =>
    props.gray &&
    css`
      border: 1px solid #cccccc;
      background: #ffffff;
      color: #666666;
      &:hover {
        background: #f1f1f1;
      }
    `}

  ${props =>
    props.red &&
    css`
      border: 1px solid #f05b5b;
      background: #ffffff;
      color: #f05b5b;
      &:hover {
        background: #ffd1d1;
      }
    `}

  ${props =>
    props.fontSize === 13 &&
    css`
      font-size: 13px;
      line-height: 1.38;
      letter-spacing: -0.33px;
    `}

  ${props =>
    props.fontSize === 15 &&
    css`
      font-size: 15px;
      line-height: 1.2;
    `}

  ${props =>
    props.fontSize === 16 &&
    css`
      font-size: 16px;
      line-height: 1.13;
    `}
`;

const StyledButton = styled.button`
  ${buttonStyle}
`;

const StyledLink = styled(Link)`
  ${buttonStyle}
`;

const Button = props => {
  return props.to ? (
    <StyledLink {...props} blue={props.blue ? 1 : 0} />
  ) : (
    <StyledButton {...props} />
  )
};

export default Button;
