import { interactionBaseApi } from './interactionBaseApi';

export const channelContentsApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    channelContentsList: build.query({
      query: data => ({ url: '/channel-contents/list', data }),
      transformResponse: response => {
        const channelList = response.channelList;
        if (!channelList) return [];
        const sortChannelList = channelList.sort((lhs, rhs) => lhs.contentsOrder - rhs.contentsOrder);
        return sortChannelList;
      },
      providesTags: [{ type: 'CHANNELCONTENTS', id: 'LIST' }],
    }),
    channelContentsListAll: build.query({
      query: data => ({ url: '/channel-contents/list-all', data }),
      transformResponse: response => {
        const channelInfo = response.resultList;
        return channelInfo;
      },
      providesTags: (result, error, payload) => [{ type: 'CHANNELCONTENTSALL', id: 'LIST' }],
    }),
    channelContentsAdd: build.mutation({
      query: data => ({ url: '/channel-contents/add', data }),
      invalidatesTags: [
        { type: 'CHANNEL', id: 'LIST' },
        { type: 'CHANNELCONTENTS', id: 'LIST' },
        { type: 'CHANNELINFO', id: 'INFO' },
      ],
    }),
    channelContentsRemove: build.mutation({
      query: data => ({ url: '/channel-contents/remove', data }),
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useChannelContentsAddMutation,
  useChannelContentsListAllQuery,
  useChannelContentsListQuery,
  useChannelContentsRemoveMutation,
} = channelContentsApi;
