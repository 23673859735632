import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface LayerDataState {
  layerId: string;
  layerOrder: number;
  width: number;
  height: number;
  x: number;
  y: number;
  title: number;
  color: string;
  baseYn: string;
  muteYn: string;
  frameLinkId: string;
  lockYn: string;
}

interface ClipboardState {
  copiedLayer: LayerDataState;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: ClipboardState = {
  copiedLayer: {},
  copiedLayerContents: {},
  copiedLayerList :{},
  copiedLayerContentsList:{},
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'clipboard',
  initialState,
  reducers: {
    copyLayer(state: ClipboardState, { payload: activeLayer }: PayloadAction<LayerDataState>) {
      state.copiedLayer = activeLayer;
    },
    initCopyLayerContents(state: ClipboardState, { payload }: PayloadAction<String>) {
      state.copiedLayerContents = {};
    },
    copyLayerContents(state: ClipboardState, { payload: layerContents }: PayloadAction<LayerDataState>) {
      state.copiedLayerContents = layerContents;
    },
    copyLayerList(state: ClipboardState, { payload: copyLayerList }: PayloadAction<LayerDataState>) {
      state.copiedLayerList = copyLayerList;
    },
    initCopyLayerContentsList(state: ClipboardState, { payload }: PayloadAction<String>) {
      state.copiedLayerContentsList = {};
    },
    copyLayerContentsList(state: ClipboardState, { payload: copyLayerContentsList }: PayloadAction<LayerDataState>) {
      state.copiedLayerContentsList = copyLayerContentsList;
    },
  },
});

const selectCopiedLayer = createDraftSafeSelector(
  (state: ClipboardState) => state.copiedLayer,
  copiedLayer => copiedLayer,
);

const selectCopiedLayerContents = createDraftSafeSelector(
  (state: ClipboardState) => state.copiedLayerContents,
  copiedLayerContents => copiedLayerContents,
);

const selectCopiedLayerList = createDraftSafeSelector(
  (state: ClipboardState) => state.copiedLayerList,
  copiedLayerList => copiedLayerList,
);

const selectCopiedLayerContentsList = createDraftSafeSelector(
  (state: ClipboardState) => state.copiedLayerContentsList,
  copiedLayerContentsList => copiedLayerContentsList,
);

const selectStatus = createDraftSafeSelector(
  (state: LayerState) => state.actionResult,
  (state: LayerState) => state.isLoading,
  (state: LayerState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const clipboardSelector = {
  copiedLayer: state => selectCopiedLayer(state[CLIPBOARD]),
  copiedLayerContents : state => selectCopiedLayerContents(state[CLIPBOARD]),
  copiedLayerList: state => selectCopiedLayerList(state[CLIPBOARD]),
  copiedLayerContentsList : state => selectCopiedLayerContentsList(state[CLIPBOARD]),
  status: state => selectStatus(state[CLIPBOARD]),
};

export const CLIPBOARD = slice.name;
export const clipboardReducer = slice.reducer;
export const clipboardAction = slice.actions;
