import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ClearSkyDay } from '../../../assets/images/preview/weather/clear-sky-day.svg';
import { ReactComponent as ClearSkyNight } from '../../../assets/images/preview/weather/clear-sky-night.svg';
import { ReactComponent as FewCloudsDay } from '../../../assets/images/preview/weather/few-clouds-day.svg';
import { ReactComponent as FewCloudsNight } from '../../../assets/images/preview/weather/few-clouds-night.svg';
import { ReactComponent as ScatteredCloudsDay } from '../../../assets/images/preview/weather/scattered-clouds-day.svg';
import { ReactComponent as ScatteredCloudsNight } from '../../../assets/images/preview/weather/scattered-clouds-night.svg';
import { ReactComponent as BrokenClouds } from '../../../assets/images/preview/weather/broken-clouds.svg';
import { ReactComponent as Rain } from '../../../assets/images/preview/weather/rain.svg';
import { ReactComponent as ShowerRain } from '../../../assets/images/preview/weather/shower-rain.svg';
import { ReactComponent as Thunderstorm } from '../../../assets/images/preview/weather/thunderstorm.svg';
import { ReactComponent as Snow } from '../../../assets/images/preview/weather/snow.svg';
import { ReactComponent as Mist } from '../../../assets/images/preview/weather/mist.svg';

import { useTimer } from '../../../utils/timerUtils';
import { roundOff } from '../../../utils/commonUtils';

import { useQuery } from 'react-query';
import { getWeather } from '../../../lib/playerApi';
import { previewToken } from '../../../lib/playlistApi';

import { calculateRatio } from '../../../utils/cssUtils';

const Weather = ({ accountInfo, weatherMetadata, layerContents, layerProps }) => {
  const [loading, setLoading] = useState(true);
  const [weather, setWeather] = useState('날씨 데이터를 불러오는 중 입니다.');
  const [weatherInfo, setWeatherInfo] = useState('날씨 데이터를 불러오는 중 입니다.');
  const [weatherIndex, setWeatherIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [ratio, setRatio] = useState(1);
  const [fontColor, setFontColor] = useState('#ffffff');

  const { data, error } = useQuery(
    ['weather', weatherMetadata.locationList[0].location],
    async () => {
      const deviceToken = await previewToken(accountInfo.accountId, accountInfo.placeId, 'preview', layerContents.playlistId);
      const weatherId = weatherMetadata.locationList[0].location && weatherMetadata.locationList[0].location.includes('전국') ? null : weatherMetadata.locationList[0].location;

      const data = await getWeather({
        deviceToken: deviceToken,
        weatherId: weatherId,
      });
      return data;
    },
    {
      refetchInterval: 60 * 60 * 1000,
    },
  );

  useEffect(() => {
    if (!weatherInfo || !weatherInfo.weatherData || !weatherInfo.airData) {
      return;
    } else {
      setLoading(false);
    }

    if (data) {
      const oneCallResponse = data[weatherIndex]?.weatherData || data.weatherData;
      const airPollutionResponse = data[weatherIndex]?.airData || data.airData;
      const newWeather = {
        status: oneCallResponse.current.weather[0].main,
        iconCode: oneCallResponse.current.weather[0].icon,
        temp: oneCallResponse.current.temp,
        minTemp: oneCallResponse.daily[0].temp.min,
        maxTemp: oneCallResponse.daily[0].temp.max,
        pm10: airPollutionResponse.list[0].components.pm10,
        pm2_5: airPollutionResponse.list[0].components.pm2_5,
        o3: airPollutionResponse.list[0].components.o3 / 214, // 1 ppm O3 = 214 µg/m³
      };
      setWeather(newWeather);
    }
  }, [weatherInfo, data, weatherIndex]);

  const changeWeatherInfo = useCallback(() => {
    if (!weatherMetadata.locationList[0].location || weatherMetadata.locationList[0].location.includes('전국')) {
      setWeatherIndex((weatherIndex + 1) % data.length);
    }
  }, [data, weatherIndex, weatherMetadata]);

  useEffect(() => {
    if (data) {
      if (!weatherMetadata.locationList[0].location || weatherMetadata.locationList[0].location.includes('전국')) {
        setWeatherInfo(data[weatherIndex]);
      } else {
        setWeatherInfo(data);
      }
    }
  }, [weatherMetadata, data, weatherIndex]);

  useTimer(changeWeatherInfo, 5 * 1000, [weatherIndex, data]);

  const getWeatherIcon = iconCode => {
    switch (iconCode) {
      case '01d':
        return <ClearSkyDay />;
      case '01n':
        return <ClearSkyNight />;
      case '02d':
        return <FewCloudsDay />;
      case '02n':
        return <FewCloudsNight />;
      case '03d':
        return <ScatteredCloudsDay />;
      case '03n':
        return <ScatteredCloudsNight />;
      case '04d':
      case '04n':
        return <BrokenClouds />;
      case '09d':
      case '09n':
        return <ShowerRain />;
      case '10d':
      case '10n':
        return <Rain />;
      case '11d':
      case '11n':
        return <Thunderstorm />;
      case '13d':
      case '13n':
        return <Snow />;
      case '50d':
      case '50n':
        return <Mist />;
      default:
        return null;
    }
  };

  const getAirCondition = (type, value) => {
    const criteria = {
      pm10: [30, 50, 100],
      pm2_5: [15, 25, 50],
      o3: [0.03, 0.09, 0.15],
    };
    if (value <= criteria[type][0]) return 'good';
    else if (value <= criteria[type][1]) return 'normal';
    else if (value <= criteria[type][2]) return 'bad';
    else return 'exBad';
  };

  const STATUS_INFO = {
    good: {
      message: '좋음',
      color: '#4FAAFF',
    },
    normal: {
      message: '보통',
      color: '#4DC76F',
    },
    bad: {
      message: '나쁨',
      color: 'orange',
    },
    exBad: {
      message: '매우 나쁨',
      color: 'red',
    },
  };

  useEffect(() => {
    const ORIGIN_SIZE = {
      degree: {
        width: 183,
        height: 130,
      },
      degreeAndDust: {
        width: 500,
        height: 100,
      },
    };

    setWidth(ORIGIN_SIZE[weatherMetadata.detail]?.width || layerProps.width);
    setHeight(ORIGIN_SIZE[weatherMetadata.detail]?.height || layerProps.height);
    setRatio(
      calculateRatio(
        ORIGIN_SIZE[weatherMetadata.detail]?.width || layerProps.width,
        ORIGIN_SIZE[weatherMetadata.detail]?.height || layerProps.height,
        layerProps.width,
        layerProps.height,
        'contain',
      ),
    );

    if (layerProps.fontColor) {
      setFontColor(`rgba(${layerProps.fontColor.r},${layerProps.fontColor.g},${layerProps.fontColor.b},${layerProps.fontColor.a})`);
    }
  }, [weatherMetadata, layerProps]);

  return (
    <>
      {loading ? (
        <WarningBox>날씨 데이터를 불러오는 중입니다.</WarningBox>
      ) : error ? (
        <WarningBox>날씨 데이터를 가져오지 못하였습니다.</WarningBox>
      ) : (
        <WeatherBox width={width} height={height} ratio={ratio} fontColor={fontColor}>
          <IconBox>
            <span>{weatherInfo.city}</span>
            {getWeatherIcon(weather.iconCode)}
          </IconBox>
          <TempBox>
            <span className="current-temp">{roundOff(weather.temp)}℃</span>
            <span className="min-max-temp">
              <span className="min-temp">{roundOff(weather.minTemp)}℃</span>
              <span className="max-temp">{roundOff(weather.maxTemp)}℃</span>
            </span>
          </TempBox>
          {weatherMetadata.detail !== 'degree' && (
            <AirBox>
              <ul>
                <li>
                  <span>미세먼지</span>
                  <span
                    style={{
                      color: STATUS_INFO[getAirCondition('pm10', weather.pm10)].color,
                    }}
                  >
                    {roundOff(weather.pm10)}㎍/㎥ {STATUS_INFO[getAirCondition('pm10', weather.pm10)].message}
                  </span>
                </li>
                <li>
                  <span>초미세먼지</span>
                  <span
                    style={{
                      color: STATUS_INFO[getAirCondition('pm2_5', weather.pm2_5)].color,
                    }}
                  >
                    {roundOff(weather.pm2_5)}㎍/㎥ {STATUS_INFO[getAirCondition('pm2_5', weather.pm2_5)].message}
                  </span>
                </li>
                <li>
                  <span>오존지수</span>
                  <span
                    style={{
                      color: STATUS_INFO[getAirCondition('o3', weather.o3)].color,
                    }}
                  >
                    {roundOff(weather.o3, 3)}ppm {STATUS_INFO[getAirCondition('o3', weather.o3)].message}
                  </span>
                </li>
              </ul>
            </AirBox>
          )}
        </WeatherBox>
      )}
    </>
  );
};

const WarningBox = styled.div`
  width: auto;
  height: auto;
  margin: 25px;
  overflow: hidden;
  font-size: 20px;
  color: #333333;
  display: flex;
  align-items: center;
`;

const WeatherBox = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  transform-origin: top left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: ${props => `scale(${props.ratio})`} translate(-50%, -50%);
  transform-origin: top left;
  color: ${props => props.fontColor};
`;

const IconBox = styled.div`
  width: 70px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
  }
  span {
    font-size: 10px;
    margin-bottom: 5px;
    letter-spacing: -1px;
  }
`;

const TempBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  .current-temp {
    font-size: 50px;
    font-weight: 700;
  }
  .min-max-temp {
    font-size: 20px;
    .min-temp {
      color: #4faaff;
      &::after {
        content: '│';
      }
    }
    .max-temp {
      color: red;
    }
  }
`;

const AirBox = styled.div`
  margin-left: 10px;
  ul {
    font-size: 20px;
    li {
      margin: 9px;
      width: 290px;
      display: flex;
      white-space: nowrap;
      span:first-child {
        flex: 2;
      }
      span:last-child {
        flex: 3;
      }
    }
  }
`;

export default React.memo(Weather);
