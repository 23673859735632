import { interactionBaseApi } from './interactionBaseApi';

export const appApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    appList: build.query({
      query: data => ({ url: '/app/app-list-get', data }),
      transformResponse: response => {
        const appList = response.appList;
        const URL = window.location.href;

        if (URL.indexOf('localhost') > 0 || URL.indexOf('eligaws') > 0) {
          return appList;
        } else {
          return appList.filter(app => app.devYn === 'N');
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, useAppListQuery } = appApi;
