import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Frame from './Frame';
import styled from 'styled-components';
import classNames from 'classnames';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ContextMenu, ContextMenuTrigger, MenuItem, SubMenu } from 'react-contextmenu';
import {
  endpoints as endpointsFrameApi,
  useAddFrameListMutation,
  useFrameUpdateMutation,
  useFramePrimaryChangeMutation,
  useCloneFrameListMutation,
  useRemoveFrameListGroupMutation,
  useFrameLinkListQuery,
  useFrameReOrderMutation,
} from '../../rtk/frameApi';

import { useUploadThumbnailMutation } from '../../rtk/frameApi';

import useCheckEditingMode from './app/components/modal/useCheckEditingMode';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import throttle from 'lodash/throttle';
import { useDispatch, useSelector } from 'react-redux';
import ContextMenuWrapper from '../../newComponents/ContextMenuWrapper';

import { useLayerAddListMutation } from '../../rtk/layerApi';
import ClickOutside from '../../components/ClickOutside';

import { editorAction, editorSelector } from '../../module/editorSlice';
import TemplateModal from '../../pages/editor/modal/TemplateModal';
import { useTempAddMutation } from '../../rtk/templateApi';
import { authSelector } from '../../module/authSlice';
import { FrameHideModal, FrameRemoveModal } from '../../pages/editor/modal/FrameModal';
import { usePlaylistUpdateMutation } from '../../rtk/playlistApi';
import TouchModal from './modal/TouchModal';
import PlayModal from './modal/PlayModal';
import {
  MdOutlineLibraryAdd as AddFrameIcon,
  MdOutlineTableChart as AddTemplateIcon,
  MdOutlineQueuePlayNext as AddTouchFrameIcon,
  MdOutlineAirplay as PlayListIcon,
} from 'react-icons/md';
import BackgroundLoading from '../../newComponents/BackgroundLoading';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { createFrameThumbNail } from 'utils/playlist';

const FrameList = () => {
  const dispatch = useDispatch();
  const playlistId = useSelector(editorSelector.playlistId);

  const { editingCheck, LayerContentsWarnModal } = useCheckEditingMode();

  //state
  const [isContextVisible, setIsContextVisible] = useState(true);
  const [activeFrameId, setActiveFrameId] = useState([]);
  const [tempOpen, setTempOpen] = useState(false);
  const [isTouchModalOpen, setIsTouchModalOpen] = useState(false);
  const [isPlayModalOpen, setIsPlayModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState('');

  //rtk
  const { currentData: frameList, isLoading } = endpointsFrameApi.frameList.useQueryState({ playlistId });

  const { accountId } = useSelector(authSelector.userInfo);
  const [framePrimaryChange] = useFramePrimaryChangeMutation();
  const [playlistUpdate] = usePlaylistUpdateMutation();
  const [frameUpdate] = useFrameUpdateMutation();
  const [addFrameList, { isLoading: isFrameAddLoading }] = useAddFrameListMutation();
  const [layerAddListMutation, { isLoading: isLayerAddLoading }] = useLayerAddListMutation();
  const [cloneFrameList, { isLoading: isFrameCloneLoading }] = useCloneFrameListMutation();
  const [removeFrameListGroup, { isLoading: isFrameRemoveLoading }] = useRemoveFrameListGroupMutation();
  const [frameReOrder] = useFrameReOrderMutation();
  const [templateAdd, { isLoading: isTemplateAddLoading }] = useTempAddMutation();
  const { data: frameLinkList } = useFrameLinkListQuery(
    { frameId: activeFrameId },
    { refetchOnMountOrArgChange: true },
  );
  const thumbnail = useSelector(editorSelector.thumbnail);
  const [uploadThumbNail] = useUploadThumbnailMutation();

  // ref
  const contextMenuRef = useRef(null);
  const FrameScroll = useRef(null);
  const firstInit = useRef(false);
  const multiBaseFrameId = useRef('');
  const beforeFrameList = useRef([]);

  // 삭제, 숨김 모달관련 정보
  const initModalInfo = useRef({
    removeModalInfo: {
      isOpen: false,
      offset: {
        left: '',
        top: '',
      },
      link: [],
    },
    hideModalInfo: {
      isOpen: false,
      offset: {
        left: '',
        top: '',
      },
      link: [],
    },
  });

  const [removeModalInfo, setRemoveModalInfo] = useState(initModalInfo.current.removeModalInfo);
  const [hideModalInfo, setHideModalInfo] = useState(initModalInfo.current.hideModalInfo);

  const handleClickFrame = useCallback(
    frameId => {
      dispatch(editorAction.setState({ key: 'frameId', value: frameId }));
      if (frameId) {
        dispatch(editorAction.frameClickLoading());
      }
      if (isEdit.length > 0) {
        setIsEdit('');
      }
    },
    [dispatch, isEdit.length],
  );

  // 프레임 삭제 모달 open
  const openRemoveFrameModal = useCallback(
    e => {
      editingCheck(() => {
        const { innerHeight: height } = window;

        let top = e.pageY + 8;
        let left = e.pageX + 20;

        const isOveflow = top + 200;

        if (isOveflow > height) {
          top = height - 200;
        }

        setRemoveModalInfo(removeModalInfo => ({
          ...removeModalInfo,
          isOpen: true,
          offset: {
            left: left,
            top: top,
          },
          link: frameLinkList,
        }));
      });
    },

    [frameLinkList, editingCheck],
  );

  // 프레임 숨기기 모달 close
  const openHideFrameModal = useCallback(
    e => {
      const { innerHeight: height } = window;

      let top = e.nativeEvent.pageY + 8;
      let left = e.nativeEvent.pageX + 20;

      const isOveflow = top + 200;

      if (isOveflow > height) {
        top = height - 200;
      }

      setHideModalInfo(hideModalInfo => ({
        ...hideModalInfo,
        isOpen: true,
        offset: {
          left: left,
          top: top,
        },
        link: frameLinkList,
      }));
    },
    [frameLinkList],
  );

  // 프레임 숨기기 모달 닫기 이벤트
  const closeHideModal = useCallback(() => {
    setHideModalInfo(hideModalInfo => ({
      ...hideModalInfo,
      ...initModalInfo.current.hideModalInfo,
    }));
  }, []);

  // 프레임 삭제 모달 닫기 이벤트
  const closeRemoveModal = useCallback(() => {
    setRemoveModalInfo(removeModalInfo => ({
      ...removeModalInfo,
      ...initModalInfo.current.removeModalInfo,
    }));
  }, []);

  // 프레임 클릭
  const handleActiveId = useCallback(
    (frameId, frameList, isMultiKey = false) => {
      if (!isMultiKey) {
        if (multiBaseFrameId.current !== frameId) {
          multiBaseFrameId.current = frameId; //다중 선택 첫 기준 frameId
          handleClickFrame(frameId); //레이어로 전송할 frameId
        }
      }
      setActiveFrameId(frameList); // setActiveFrameId
    },
    [handleClickFrame],
  );

  useEffect(() => {
    // 첫 실행 시 프레임 첫번째꺼 선택
    if (!firstInit.current && frameList?.length > 0) {
      handleActiveId(frameList[0].frameId, [frameList[0]]);
      firstInit.current = true;
      beforeFrameList.current = frameList;
    }

    // 프레임 추가 시 스크롤바 아래로 이동
    if (beforeFrameList?.current.length < frameList?.length && frameList?.length > 0) {
      FrameScroll.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      beforeFrameList.current = frameList;
    }
  }, [frameList, handleActiveId]);

  // 프레임 클릭
  const onClickFrame = useCallback(
    (e, frame, selectIndex) => {
      const frameId = frame.frameId;
      let findFrameIdx = activeFrameId.findIndex(obj => obj.frameId === frameId);
      let baseActiveList = [];
      if (e.ctrlKey) {
        if (findFrameIdx > 0) {
          if (activeFrameId.length > 1) baseActiveList = activeFrameId.filter(obj => obj.frameId !== frameId);
        } else if (multiBaseFrameId.current === frame.frameId) {
          if (activeFrameId.length > 1) {
            baseActiveList = activeFrameId.filter(obj => obj.frameId !== frameId);
            handleActiveId(baseActiveList[0].frameId, [baseActiveList[0]]);
          } else {
            baseActiveList = activeFrameId;
          }
        } else {
          baseActiveList = activeFrameId.concat(frame);
        }
        handleActiveId(frameId, baseActiveList, true);
      } else if (e.shiftKey) {
        const baseIndex = frameList.findIndex(frame => frame.frameId === multiBaseFrameId.current);
        let startIndex = 0,
          endIndex = 0;
        if (baseIndex !== -1) {
          if (baseIndex < selectIndex) {
            startIndex = baseIndex;
            endIndex = selectIndex;
          } else if (baseIndex > selectIndex) {
            startIndex = selectIndex;
            endIndex = baseIndex;
          } else {
            if (activeFrameId.length > 1) {
              let newArray = activeFrameId.filter(obj => obj.frameId === multiBaseFrameId.current);
              handleActiveId(frameId, [...newArray], true);
            }
            return;
          }
          let frameArray = [];
          for (let i = startIndex; i <= endIndex; i++) {
            frameArray.push(frameList[i]);
          }
          handleActiveId(frameId, frameArray, true);
        }
      } else {
        if (multiBaseFrameId.current !== frame.frameId) {
          editingCheck(() => handleActiveId(frameId, [frame]));
        }
      }
    },
    [activeFrameId, handleActiveId, frameList, editingCheck],
  );

  // 프레임 설정 관련
  const handleSetOption = useCallback(
    (e, option, frame) => {
      const frameId = frame.frameId;
      if (option === 'primary') {
        if (frame.hideYn === 'Y' && frame.primaryYn === 'N') {
          toast.error('숨긴 프레임은 대표로 지정할 수 없습니다.');
          return false;
        }
        let primaryYn = frame.primaryYn === 'Y' ? 'N' : 'Y';
        framePrimaryChange({ playlistId, frameId, primaryYn }).then(() => {
          playlistUpdate({ playlistId, updateInfo: { primaryFrameId: primaryYn === 'Y' ? frameId : '' } });
        });
      } else if (option === 'hide') {
        if (frame.primaryYn === 'Y' && frame.hideYn === 'N') {
          toast.error('대표 프레임은 숨길 수 없습니다.');
          return false;
        }
        let hideYn = frame.hideYn === 'Y' ? 'N' : 'Y';

        if (hideYn === 'Y' && frameLinkList?.length > 0) {
          openHideFrameModal(e);
        } else {
          frameUpdate({ frameId, frameInfo: { hideYn } });
        }
      } else {
      }
    },
    [framePrimaryChange, playlistId, playlistUpdate, frameLinkList, openHideFrameModal, frameUpdate],
  );

  // 템플릿
  const handleSaveHistory = useCallback(
    activeTemplateList => {
      const history = JSON.parse(localStorage.getItem('layerContents.history' + accountId)) || {};
      let templateHistoryList = history.templateHistoryList || [];

      for (const activeTemplate of activeTemplateList) {
        // 중복 방지
        if (templateHistoryList.length > 0) {
          const findIndex = templateHistoryList.findIndex(
            template => template.templateId === activeTemplate.templateId,
          );
          if (findIndex !== -1) {
            templateHistoryList.splice(findIndex, 1);
          }
        }
        templateHistoryList.unshift(activeTemplate);

        // 히스토리 최근 4개까지만 기록
        if (templateHistoryList.length > 4) {
          templateHistoryList.pop();
        }
      }
      localStorage.setItem('layerContents.history' + accountId, JSON.stringify({ ...history, templateHistoryList }));
    },
    [accountId],
  );

  const handleCloseTemplateModal = useCallback(() => {
    setTempOpen(false);
  }, []);

  const handleCloseTouchModal = useCallback(() => {
    setIsTouchModalOpen(false);
  }, []);

  const handleClosePlayModal = useCallback(() => {
    setIsPlayModalOpen(false);
  }, []);

  // throttle : 이벤트 발생 즉시 실행, 지정한 시간동안 발생X
  // debounce : 지정 시간동안 실행X 버티다가 지정 시간 지나면 발생

  // 프레임 생성
  const addFrameThrottle = useMemo(
    () =>
      throttle(({ initFrame, initLayer, frameId }) => {
        addFrameList(initFrame).then(res => {
          let frameInfo = res.data.frame;
          layerAddListMutation({ addList: [initLayer] }).then(() => {
            handleActiveId(frameInfo.frameId, [frameInfo]);
          });
          dispatch(editorAction.updateThumbnail({ url: '', thumbId: '' }));
        });
      }, 400),
    [addFrameList, layerAddListMutation, dispatch, handleActiveId],
  );

  const handleCreateFrame = useCallback(
    async (e, param, ...arg) => {
      const frameId = dayjs().unix() + v4().substr(0, 8);
      if (param === 'add' || param === 'template') {
        const initFrame = {
          frameId: frameId,
          frameNm: '',
          frameDuration: {
            hour: '00',
            minute: '00',
            second: '30',
          },
          primaryYn: 'N',
          hideYn: 'N',
          autoNextYn: 'Y',
          autoNextType: 'next',
          autoNextFrameId: '',
          playlistId: playlistId,
        };

        const initLayer = {
          playlistId: playlistId,
          frameId: frameId,
          baseYn: 'Y',
        };

        if (param === 'add') {
          addFrameThrottle({ initFrame, initLayer, frameId });
        } else {
          let templateArray = arg.flat();

          if (templateArray.length <= 0) {
            toast.error('선택된 템플릿이 없습니다.');
            return false;
          }
          setTempOpen(false);

          for (let obj of templateArray) {
            const templateId = obj.templateId;
            let item = { ...initFrame };
            item.frameNm = obj.templateNm;
            item.frameId = dayjs().unix() + v4().substr(0, 8);
            await templateAdd({ templateId, frame: item }).then(async res => {
              if (templateArray[templateArray.length - 1].templateId === templateId) {
                handleActiveId(item.frameId, [item]);
              }
              await createFrameThumbNail({ frameId: item.frameId, path: obj.thumbnailPath }).then(res => {
                uploadThumbNail({ addFileList: [res.param], frameId: res.frameId });
                dispatch(editorAction.updateThumbnail({ url: res.url, thumbId: res.frameId }));
              });
            });
          }
          handleSaveHistory(templateArray);
        }
      }
    },
    [playlistId, addFrameThrottle, handleSaveHistory, templateAdd, uploadThumbNail, dispatch, handleActiveId],
  );

  // 컨텍스트 메뉴
  const onHideContextMenu = useCallback(e => {
    contextMenuRef.current.handleHide(e);
  }, []);

  const handleCreateFrameThumbNail = useMemo(
    () =>
      throttle(frameId => {
        createFrameThumbNail({ frameId }).then(res => {
          uploadThumbNail({ addFileList: [res.param], frameId: res.frameId });
          dispatch(editorAction.updateThumbnail({ url: res.url, thumbId: res.frameId }));
        });
      }, 1000),
    [dispatch, uploadThumbNail],
  );

  const handleFrameThumb = useCallback(
    e => {
      for (const obj of activeFrameId) {
        const frameId = obj.frameId;
        handleCreateFrameThumbNail(frameId);
      }
    },
    [activeFrameId, handleCreateFrameThumbNail],
  );

  // 프레임 order 정렬
  const handleContextFrameOrder = useCallback(
    (e, option) => {
      let beforeFrameList = [...frameList];
      for (const obj of activeFrameId) {
        beforeFrameList = beforeFrameList.filter(frame => frame.frameId !== obj.frameId);
      }
      if (option === 'front') {
        beforeFrameList = activeFrameId.concat(beforeFrameList);
      } else if (option === 'back') {
        beforeFrameList = beforeFrameList.concat(activeFrameId);
      }

      const updateList = beforeFrameList.map((item, idx) => {
        return {
          frameId: item.frameId,
          frameOrder: idx,
          frameNm: item.frameNm,
        };
      });

      if (updateList.length > 0) {
        frameReOrder({ updateList });
        FrameScroll.current.scrollIntoView({ behavior: 'smooth', block: option === 'front' ? 'start' : 'end' });
      }
    },
    [activeFrameId, frameList, frameReOrder],
  );

  // 프레임 클론 (레이어, 레이어&콘텐츠)
  const handleContextFrameClone = useCallback(
    (e, option) => {
      let frameOrder = frameList.length;
      let cloneData = [];
      let newFrameId;
      let cloneFrameData;

      for (const obj of activeFrameId) {
        const frameId = obj.frameId;
        newFrameId = dayjs().unix() + v4().substr(0, 8);
        const frameInfo = frameList.find(frame => frame.frameId === frameId);

        cloneFrameData = {
          ...frameInfo,
          frameId: newFrameId,
          primaryYn: 'N',
          frameOrder: frameOrder++,
          cloneType: '',
          activeFrameId: frameId,
        };
        if (option === 'lc') {
          cloneFrameData.cloneType = 'ALL';
          if (obj.thumbPath)
            createFrameThumbNail({ frameId: newFrameId, path: obj.thumbPath }).then(res => {
              uploadThumbNail({ addFileList: [res.param], frameId: res.frameId });
              dispatch(editorAction.updateThumbnail({ url: res.url, thumbId: res.frameId }));
            });
        }
        cloneData.push(cloneFrameData);
      }

      cloneFrameList({ data: cloneData }).then(async res => {
        const frameResult = res.data.frameList;
        if (frameResult[frameResult.length - 1].frameId === newFrameId) {
          handleActiveId(newFrameId, [cloneFrameData]);
        }

        await FrameScroll.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      });
    },
    [activeFrameId, cloneFrameList, dispatch, frameList, handleActiveId, uploadThumbNail],
  );

  // 컨텍스트 메뉴 open
  const onContextMenu = useCallback(
    (e, frame) => {
      if (typeof frame !== 'undefined') {
        if (activeFrameId.length === 1) handleActiveId(frame.frameId, [frame]);
        setIsContextVisible(isContextVisible => true);
      } else {
        e.preventDefault();
      }
    },
    [activeFrameId.length, handleActiveId],
  );

  // 프레임 드래그 이벤트
  const onDragFrame = useCallback(
    result => {
      if (!result.destination) return;

      const startIdx = result.source.index;
      const endIdx = result.destination.index;

      let newFrameListData = [...frameList];
      const [rearange] = newFrameListData.splice(startIdx, 1);
      newFrameListData.splice(endIdx, 0, rearange);

      const updateList = newFrameListData.reduce((target, newFrame, index) => {
        if (newFrame.frameOrder !== index) {
          target.push({
            frameId: newFrame.frameId,
            frameOrder: index,
          });
        }
        return target;
      }, []);

      if (updateList.length > 0) {
        frameReOrder({ updateList });
      }
    },
    [frameList, frameReOrder],
  );

  // 프레임 삭제 이벤트
  const removeFrame = useCallback(() => {
    removeFrameListGroup({ frameId: activeFrameId }).then(res => {
      const frameResponse = res.data.frameList;
      const primaryObject = activeFrameId.find(data => data.primaryYn === 'Y');
      if (primaryObject?.primaryYn === 'Y') {
        playlistUpdate({ playlistId, updateInfo: { primaryFrameId: '' } });
      }

      if (frameResponse.length > 0) handleActiveId(frameResponse[0]['frameId'], [frameResponse[0]]);
      else if (frameResponse.length === 0) handleActiveId('', []);
      FrameScroll.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      beforeFrameList.current = frameResponse;
    });
    closeRemoveModal();
  }, [removeFrameListGroup, activeFrameId, closeRemoveModal, handleActiveId, playlistUpdate, playlistId]);

  // 멀티선택 해제
  const onDeselectMultiFrame = useCallback(
    e => {
      if (!removeModalInfo.isOpen && !hideModalInfo.isOpen && activeFrameId.length > 1) {
        const obj = activeFrameId.find(item => item.frameId === multiBaseFrameId.current);
        handleActiveId(multiBaseFrameId.current, [obj]);
      }
      if (isEdit.length > 0) {
        setIsEdit('');
      }
    },
    [removeModalInfo.isOpen, hideModalInfo.isOpen, activeFrameId, isEdit, handleActiveId],
  );

  if (isLoading) {
    return null;
  }

  return (
    <FrameContainer>
      <FrameManageContainer>
        <IconButton
          onClick={e => editingCheck(() => handleCreateFrame(e, 'add'))}
          data-tooltip-id="frameTool"
          data-tooltip-content="프레임 추가"
        >
          <AddFrameIcon size={'24'} />
        </IconButton>
        <IconButton
          onClick={e => editingCheck(() => setTempOpen(true))}
          data-tooltip-id="frameTool"
          data-tooltip-content="템플릿 추가"
        >
          <AddTemplateIcon size={'24'} />
        </IconButton>
        <IconButton
          onClick={e => setIsTouchModalOpen(true)}
          data-tooltip-id="frameTool"
          data-tooltip-content="터치영역 설정"
        >
          <AddTouchFrameIcon size={'24'} />
        </IconButton>
        <IconButton
          onClick={e => setIsPlayModalOpen(true)}
          data-tooltip-id="frameTool"
          data-tooltip-content="디바이스 베포"
        >
          <PlayListIcon size={'24'} />
        </IconButton>
      </FrameManageContainer>
      <Container>
        <ClickOutside onClickOutside={e => onDeselectMultiFrame(e)}>
          <ContextMenuTrigger id="frameContextMenu" holdToDisplay={1000}>
            <ParentContainer data-key="parent" ref={FrameScroll}>
              <DragDropContext onDragEnd={onDragFrame}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <WrapItem {...provided.droppableProps} ref={provided.innerRef}>
                      {frameList.map((frame, index) => {
                        return (
                          <Draggable key={frame.frameId} draggableId={frame.frameId} index={index}>
                            {(provided, snapshot) => (
                              <Item
                                onContextMenu={e => onContextMenu(e, frame)}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={e => onClickFrame(e, frame, index)}
                              >
                                <Frame
                                  className={classNames({
                                    active: activeFrameId.find(obj => obj?.frameId === frame.frameId),
                                  })}
                                  key={index}
                                  index={index}
                                  handleSetOption={handleSetOption}
                                  frameInfo={frame}
                                  openRemoveFrameModal={openRemoveFrameModal}
                                  openHideFrameModal={openHideFrameModal}
                                  setIsEdit={setIsEdit}
                                  isEdit={isEdit === frame.frameId ? isEdit : ''}
                                  frameLinkList={frameLinkList}
                                  thumbnail={frame.frameId === thumbnail.thumbId ? thumbnail : null}
                                >
                                  {frame.frameId}
                                </Frame>
                              </Item>
                            )}
                          </Draggable>
                        );
                      })}
                      {/* 프레임 로딩 */}
                      {(isTemplateAddLoading || isFrameAddLoading || isFrameCloneLoading || isLayerAddLoading) && (
                        <Item>
                          <Frame isLoading={true} key={-1} index={-1} frameLinkList={[]} frameInfo={[]}></Frame>
                        </Item>
                      )}
                      {provided.placeholder}
                    </WrapItem>
                  )}
                </Droppable>
              </DragDropContext>
            </ParentContainer>
          </ContextMenuTrigger>

          <ClickOutside onClickOutside={e => onHideContextMenu(e)}>
            <ContextMenuWrapper isContextVisible={isContextVisible}>
              <ContextMenu ref={contextMenuRef} id="frameContextMenu" hideOnLeave={false}>
                <MenuItem divider />
                <MenuItem onClick={e => handleFrameThumb(e)}>썸네일 만들기</MenuItem>
                <MenuItem divider />
                <MenuItem divider />
                <MenuItem onClick={e => openRemoveFrameModal(e)}>프레임 삭제</MenuItem>
                <MenuItem divider />
                <SubMenu title="프레임 복사">
                  <MenuItem onClick={e => handleContextFrameClone(e, 'l')}>레이어만 복사</MenuItem>
                  <MenuItem onClick={e => handleContextFrameClone(e, 'lc')}>레이어&amp;콘텐츠 복사</MenuItem>
                </SubMenu>
                <MenuItem divider />
                <MenuItem onClick={e => handleContextFrameOrder(e, 'front')}>맨 앞으로 이동</MenuItem>
                <MenuItem divider />
                <MenuItem onClick={e => handleContextFrameOrder(e, 'back')}>맨 뒤로 이동</MenuItem>
                <MenuItem divider />
              </ContextMenu>
            </ContextMenuWrapper>
          </ClickOutside>
        </ClickOutside>
      </Container>

      <TemplateModal
        handleCreateFrame={handleCreateFrame}
        isOpen={tempOpen}
        closeModal={handleCloseTemplateModal}
        playlistId={playlistId}
        length={frameList.length}
        accountId={accountId}
      />
      <TouchModal isOpen={isTouchModalOpen} closeModal={handleCloseTouchModal} />
      <PlayModal isOpen={isPlayModalOpen} closeModal={handleClosePlayModal} />
      <FrameRemoveModal
        isOpen={removeModalInfo.isOpen}
        frameLinkList={frameLinkList}
        closeModal={closeRemoveModal}
        frameId={activeFrameId}
        offset={removeModalInfo.offset}
        removeFrame={removeFrame}
      />
      <FrameHideModal
        isOpen={hideModalInfo.isOpen}
        frameLinkList={frameLinkList}
        closeModal={closeHideModal}
        frameId={activeFrameId}
        offset={hideModalInfo.offset}
      />
      {isFrameRemoveLoading && <BackgroundLoading />}
      <Tooltip id="frameTool" place="bottom" type="dark" effect="solid" multiline={true} />
      {LayerContentsWarnModal()}
    </FrameContainer>
  );
};

const IconButton = styled.div`
  width: 40px;
  color: white;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.1s, opacity 0.1s;
  &:hover {
    background: rgb(34 34 34);
    color: #2491df;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(244 246 255);

  /* 스크롤바 */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(98 98 98);
    border-radius: 2.5px;
  }
  .react-contextmenu-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const WrapItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: normal;
  align-items: center;
  flex-direction: column;
`;

const ParentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FrameManageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px;
  box-shadow: inset -2px 4px 8px #0000002e;
  background-color: rgb(44 44 44);
  border-radius: -6px -3px 4px 6px #bababa85;
  z-index: 4;
`;

export default React.memo(FrameList);
