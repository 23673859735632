import React from 'react';
import styled from 'styled-components';
const Audio = ({ audioData, contentsFileList }) => {
  const activeMedia = audioData.mediaList[0];
  const activeMediaFile = contentsFileList.find(file => file?.fileId === activeMedia?.fileId);

  return (
    <AudioBox key={activeMediaFile?.fileId}>
      <audio loop={activeMedia.sound === 'always'} style={{ width: '100%' }} src={process.env.REACT_APP_INTERACTION_CDN_URL + activeMediaFile?.filePath} controls />
    </AudioBox>
  );
};

const AudioBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default React.memo(Audio);
