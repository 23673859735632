import { fork, call, put, takeLatest, all, select } from 'redux-saga/effects';
import * as playlistApiLib from '../lib/playlistApi';

import {
  fnGetFirstPlaylistId,
  fnRemoveHistoryFromLocalStorage2,
  fnSaveHistoryToLocalStorage2,
  fnUpdateHistoryFromLocalStorage,
} from '../utils/playlist';

import { accountSelector } from './accountSlice';
import { activeItemAction, activeItemSelector } from './activeItemSlice';
import { playlistAction, playlistSelector } from './playlistSlice';

function* getPlaylistArray({ payload: { placeId } }) {
  try {
    const playlistArray = yield call(playlistApiLib.getPlaylistArray, placeId);

    yield put(playlistAction.getPlaylistArraySuccess(playlistArray));
  } catch (error) {
    yield put(playlistAction.getPlaylistArrayFailure('getPlaylistArray failure'));
  }
}

function* addPlaylist({ payload: { playlist, navigate } }) {
  try {
    const { accountId, viewPlaceId: placeId } = yield select(accountSelector.accountInfo);
    yield call(playlistApiLib.addPlaylist, playlist);

    const playlistArray = yield call(playlistApiLib.getPlaylistArray, placeId);
    const folderList = yield call(playlistApiLib.getFolderList, placeId);

    yield put(playlistAction.addPlaylistSuccess({ playlistArray, folderList }));

    yield put(activeItemAction.setActivePlaylistId(playlist.playlistId));
    yield put(activeItemAction.setActiveLayerId('')); // 잔상 남는 이슈로 추가

    fnSaveHistoryToLocalStorage2(playlist, accountId, placeId);
    const proxy = new Date().getTime();
    sessionStorage.setItem(proxy, JSON.stringify({ playlistId: playlist.playlistId }));
    navigate('/interaction/management/layout?proxy=' + proxy);
  } catch (error) {
    yield put(playlistAction.addPlaylistFailure('addPlaylist failure'));
  }
}

function* removePlaylist({ payload: { playlistId, navigate } }) {
  try {
    const { accountId, viewPlaceId: placeId } = yield select(accountSelector.accountInfo);
    const activePlaylistId = yield select(activeItemSelector.playlistId);

    yield call(playlistApiLib.removePlaylist, playlistId);

    const playlistArray = yield call(playlistApiLib.getPlaylistArray, placeId);
    const folderList = yield call(playlistApiLib.getFolderList, placeId);

    fnRemoveHistoryFromLocalStorage2(playlistId, accountId, placeId);

    yield put(playlistAction.removePlaylistSuccess({ playlistArray, folderList }));

    // 선택한 재생목록을 삭제할때 첫번째 재생목록 다시 선택
    if (activePlaylistId === playlistId) {
      yield put(activeItemAction.setActiveLayerId('')); // 잔상 남는 이슈로 추가

      let firstPlaylistId = fnGetFirstPlaylistId(folderList);

      if (firstPlaylistId) {
        fnSaveHistoryToLocalStorage2(
          playlistArray.find(playlist => playlist.playlistId === firstPlaylistId),
          accountId,
          placeId,
        );

        const proxy = new Date().getTime();
        sessionStorage.setItem(proxy, JSON.stringify({ playlistId: firstPlaylistId }));
        navigate('/interaction/management/layout?proxy=' + proxy, { replace: true });
      } else {
        navigate('/interaction/management', { replace: true });
      }
    }
  } catch (error) {
    yield put(playlistAction.removePlaylistFailure('removePlaylist failure'));
  }
}

function* updatePlaylist({ payload: { playlistId, updateInfo } }) {
  try {
    const { accountId, viewPlaceId: placeId } = yield select(accountSelector.accountInfo);
    yield call(playlistApiLib.updatePlaylist, { playlistId, updateInfo });

    const playlistArray = yield call(playlistApiLib.getPlaylistArray, placeId);
    const folderList = yield call(playlistApiLib.getFolderList, placeId);

    fnUpdateHistoryFromLocalStorage(
      playlistArray.find(playlist => playlist.playlistId === playlistId),
      accountId,
      placeId,
    );

    yield put(playlistAction.updatePlaylistSuccess({ playlistArray, folderList }));
  } catch (error) {
    yield put(playlistAction.updatePlaylistFailure('updatePlaylist failure'));
  }
}

function* getFolderList({ payload: { placeId } }) {
  try {
    const folderList = yield call(playlistApiLib.getFolderList, placeId);

    yield put(playlistAction.getFolderListSuccess(folderList));
  } catch (error) {
    yield put(playlistAction.getFolderListFailure('getFolderList failure'));
  }
}

function* addFolder({ payload: folder }) {
  try {
    const { viewPlaceId: placeId } = yield select(accountSelector.accountInfo);

    yield call(playlistApiLib.addFolder, folder);

    const playlistArray = yield call(playlistApiLib.getPlaylistArray, placeId);
    const folderList = yield call(playlistApiLib.getFolderList, placeId);

    yield put(playlistAction.addFolderSuccess({ playlistArray, folderList }));
  } catch (error) {
    yield put(playlistAction.addFolderFailure('addFolder failure'));
  }
}

function* updateFolder({ payload: { folderId, updateInfo } }) {
  try {
    const { viewPlaceId: placeId } = yield select(accountSelector.accountInfo);

    yield call(playlistApiLib.updateFolder, { folderId, updateInfo });

    const playlistArray = yield call(playlistApiLib.getPlaylistArray, placeId);
    const folderList = yield call(playlistApiLib.getFolderList, placeId);

    yield put(playlistAction.updateFolderSuccess({ playlistArray, folderList }));
  } catch (error) {
    yield put(playlistAction.updateFolderFailure('updateFolder failure'));
  }
}

function* removeFolder({ payload: { folderId, navigate }}) {
  try {
    const { accountId, viewPlaceId: placeId } = yield select(accountSelector.accountInfo);
    const activePlaylistId = yield select(activeItemSelector.playlistId);
    const folderList = yield select(playlistSelector.folderList);

    yield call(playlistApiLib.removeFolder, folderId);

    const playlistArray = yield call(playlistApiLib.getPlaylistArray, placeId);
    const newFolderList = yield call(playlistApiLib.getFolderList, placeId);

    yield put(
      playlistAction.removeFolderSuccess({
        playlistArray,
        folderList: newFolderList,
      }),
    );

    // 폴더 내 선택한 재생목록이 있을경우
    let folderInPlaylistId = '';
    for (const folder of folderList) {
      const playlist = folder.childPlaylistArray.find(playlist => playlist.playlistId === activePlaylistId);
      if (playlist) {
        folderInPlaylistId = playlist.playlistId;
      }
    }

    if (folderInPlaylistId !== '') {
      fnRemoveHistoryFromLocalStorage2(folderInPlaylistId, accountId, placeId);

      yield put(activeItemAction.setActiveLayerId('')); // 잔상 남는 이슈로 추가

      if (playlistArray.length > 0) {
        let firstPlaylistId = fnGetFirstPlaylistId(folderList);

        if (firstPlaylistId) {
          fnSaveHistoryToLocalStorage2(
            playlistArray.find(playlist => playlist.playlistId === firstPlaylistId),
            accountId,
            placeId,
          );

          const proxy = new Date().getTime();
          sessionStorage.setItem(proxy, JSON.stringify({ playlistId: firstPlaylistId }));
          navigate('/interaction/management/layout?proxy=' + proxy, { replace: true });
        }
      } else {
        navigate('/interaction/management', { replace: true });
      }
    }

    // yield put(layerContentsAction.initLayerContentsState()); // 잔상 남는 이슈로 추가
  } catch (error) {
    yield put(playlistAction.removeFolderFailure('removeFolder failure'));
  }
}

export function* watchGetPlaylistArray() {
  yield takeLatest(playlistAction.getPlaylistArray, getPlaylistArray);
}

export function* watchaAddPlaylist() {
  yield takeLatest(playlistAction.addPlaylist, addPlaylist);
}

export function* watchRemovePlaylist() {
  yield takeLatest(playlistAction.removePlaylist, removePlaylist);
}

export function* watchUpdatePlaylist() {
  yield takeLatest(playlistAction.updatePlaylist, updatePlaylist);
}

export function* watchGetFolderList() {
  yield takeLatest(playlistAction.getFolderList, getFolderList);
}

export function* watchAddFolder() {
  yield takeLatest(playlistAction.addFolder, addFolder);
}

export function* watchUpdateFolder() {
  yield takeLatest(playlistAction.updateFolder, updateFolder);
}

export function* watchRemoveFolder() {
  yield takeLatest(playlistAction.removeFolder, removeFolder);
}

function* rootSaga() {
  yield all([
    fork(watchGetPlaylistArray),
    fork(watchaAddPlaylist),
    fork(watchRemovePlaylist),
    fork(watchUpdatePlaylist),
    fork(watchGetFolderList),
    fork(watchAddFolder),
    fork(watchRemoveFolder),
    fork(watchUpdateFolder),
  ]);
}

export default rootSaga;
