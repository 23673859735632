import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../newComponents/Loading';
import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';
import Logo from './components/Logo';

import { editorAction, editorSelector } from '../../../module/editorSlice';

import useUploadFileSizeCheck from 'utils/useUploadFileSizeCheck';

import { INIT_LOGO_INFO } from '../constants/contentApp';
import { endpoints as endpointPlaceApi } from '../../../rtk/placeApi';
import { endpoints as endpointContentsApi } from '../../../rtk/contentsApi';
import { endpoints as endpointsGroupApi, useGroupListQuery } from '../../../rtk/groupApi';

const LogoApp = ({ selectLayerContentsInfo = INIT_LOGO_INFO, handleCancelLayerContents, selectedLayerType, appLoading }) => {
  const placeId = useSelector(editorSelector.placeId);
  const { data: groupList } = endpointsGroupApi.groupList.useQueryState({ placeId: placeId, groupPid: placeId });
  const { data: placeInfo } = endpointPlaceApi.placeDetail.useQueryState({ placeId });
  const { data: placeFileInfo } = endpointContentsApi.contentsFileDetail.useQuery({ placeId });

  const { fnUploadFileSizeCheck } = useUploadFileSizeCheck();
  const [uploadFileSize, setUploadFileSize] = useState(0);

  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_LOGO_INFO);
  const [formValidate, setFormValidate] = useState({ logoList: [] });
  const [groupOptionList, setGroupOptionList] = useState([]);
  const [optionListChangeFlag, setOptinoListChangeFlag] = useState(false);

  const [isOptionOpen, setIsOptionOpen] = useState(true);

  // const [beforeFileList,setBefoFileList] = useState([]);
  const [addFileList, setAddFileList] = useState([]);
  const [removeFileList, setRemoveFileList] = useState([]);

  useEffect(() => {
    if (groupList) {
      setGroupOptionList(groupList.map(group => ({ title: group.groupNm, value: group.groupId })));
    }
  }, [groupList]);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const appContentWrapRef = useRef(null);
  const logoListRef = useRef([]);
  const prevScrollListLength = useRef(formData.contentsData.logoList.length);

  const handleChangeLogoOption = useCallback(
    (index, key, value) => {
      let changeFormData = [...formData.contentsData.logoList];
      changeFormData[index][key] = value;

      handleChangeObject('contentsData', 'logoList', changeFormData);
    },
    [formData.contentsData.logoList, handleChangeObject],
  );

  const handleChangeLogoOptionRemove = useCallback(
    index => {
      let changeFormData = [...formData.contentsData.logoList].filter((data, idx) => idx !== index);
      handleChangeObject('contentsData', 'logoList', changeFormData);
      setOptinoListChangeFlag(true);
    },
    [formData.contentsData.logoList, handleChangeObject],
  );

  const handleChangeLogoOptionAdd = useCallback(() => {
    let changeFormData = [...formData.contentsData.logoList];
    changeFormData.push({ targetGroupId: '', fitOption: 'cover' });
    handleChangeObject('contentsData', 'logoList', changeFormData);
    setOptinoListChangeFlag(true);
  }, [formData.contentsData.logoList, handleChangeObject]);

  const checkValidDate = useCallback(() => {
    let validate = {
      logoList: [],
    };
    let validFlag = false;

    formData.contentsData.logoList.forEach((logo, index) => {
      let logoValidate = {};

      if (!logo.targetGroupId || logo.targetGroupId.trim() === '') {
        validFlag = true;
        logoValidate.targetGroupId = false;
        logoValidate.targetGroupIdError = '타겟 그룹을 지정하세요.';
        setIsOptionOpen(true);
      } else {
        logoValidate.targetGroupId = true;
        logoValidate.targetGroupIdError = '';
      }

      if (!logo.fitOption || logo.fitOption.trim() === '') {
        validFlag = true;
        logoValidate.fitOption = false;
        logoValidate.fitOptionError = '맞춤 옵션을 설정하세요.';
        setIsOptionOpen(true);
      } else {
        logoValidate.fitOption = true;
        logoValidate.fitOptionError = '';
      }

      validate.logoList.push(logoValidate);
    });

    setFormValidate(validate);

    // let beforeFileList = [];
    let addFileList = [];
    let removeFileList = [...formData.contentsFileList];

    for (const afterLogoFile of formData.contentsData.logoList) {
      if (afterLogoFile.logoFile) {
        const index = removeFileList.findIndex(beforeFile => beforeFile.fileId === afterLogoFile.logoFile.fileId);
        if (index > -1) {
          removeFileList.splice(index, 1);
        } else {
          addFileList.push(afterLogoFile.logoFile);
        }
      }
    }

    setAddFileList(addFileList);
    setRemoveFileList(removeFileList);


    if(validFlag){
      const logoList = validate.logoList;
      for(let index =0; index <  logoList.length; index++ ){

        if(!logoList[index].targetGroupId || !logoList[index].fitOption){
          setTimeout(() => {
            appContentWrapRef.current.scrollTo({ top:logoListRef.current[index].scrollIntoView({block: "center"})});
          }, 100); 
          break;
        }


      }

    }



    return validFlag;
  }, [formData]);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };
      if (!layerContentsInfo.contentsData.logoList) {
        layerContentsInfo.contentsData.logoList = [{ targetGroupId: 'DEFAULT', fitOption: 'cover' }];
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_LOGO_INFO);
    }
  }, [selectLayerContentsInfo]);

  useEffect(() => {
    if (optionListChangeFlag) {
      if (prevScrollListLength.current < formData.contentsData.logoList.length) {
        appContentWrapRef.current.scrollTo(0, appContentWrapRef.current.scrollHeight);
      }
      prevScrollListLength.current = formData.contentsData.logoList.length;
      setOptinoListChangeFlag(false);
    }
  }, [formData.contentsData.logoList.length, optionListChangeFlag]);

  return (
    <Container>
      <AppTitle text="로고 App" />
      <AppContentWrap ref={appContentWrapRef}>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox title="설정" isOpen={isOptionOpen} changeOpen={setIsOptionOpen}>
              {formData.contentsData.logoList &&
                formData.contentsData.logoList.map((logo, index) => (
                  <LogoWrapper       key={`logoWrapper-app-${index}`} ref={ref =>  logoListRef.current[index] = ref }>
                  <Logo
                    key={`logo-app-${index}`}
                    index={index}
                    logo={logo}
                    logoList={formData.contentsData.logoList}
                    groupOptionList={groupOptionList}
                    handleChangeLogoOption={handleChangeLogoOption}
                    handleChangeLogoOptionRemove={handleChangeLogoOptionRemove}
                    formValidate={formValidate}
                    handleChangeObject={handleChangeObject}
                  />
                  </LogoWrapper>
                ))}

              <AddButton onClick={() => handleChangeLogoOptionAdd()}>추가</AddButton>
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        checkValidDate={checkValidDate}
        addFileList={addFileList}
        removeFileList={removeFileList}
        cancelApp={handleCancelLayerContents}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 100%;
  height: 40px;
  border: 1px solid #2a91df;
  background: #ffffff;
  border-radius: 6px;
  color: #2a91df;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background: #f1f1f1;
  }
`;

const LogoWrapper = styled.div``;

export default React.memo(LogoApp);
