import { Contrast } from 'konva/lib/filters/Contrast';
import { fnRemoveHistoryFromLocalStorage, fnSaveHistoryToLocalStorage } from '../utils/playlist';
import { interactionBaseApi } from './interactionBaseApi';

export const playlistApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    playlistCount: build.query({
      query: data => ({ url: '/playlist/count', data }),
      transformResponse: response => {
        const listCount = response.listCount;
        return listCount;
      },
    }),
    playlistList: build.query({
      async queryFn(arg, { dispatch, getState }, _extraOptions, fetchApi) {
        let allPlaylistList = [];

        let startKey = null;
        let limit = 100;

        // 10,000개 이상 조회시... X
        for (let loop = 0; loop < 100; loop++) {
          const {
            data: { playlistList, lastKey },
          } = await fetchApi({ url: '/playlist/list', data: { ...arg, startKey, limit } });
          allPlaylistList = allPlaylistList.concat(playlistList);
          startKey = lastKey;
          if (!startKey) {
            break;
          }
        }

        return { data: allPlaylistList };
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ playlistId }) => ({ type: 'PLAYLIST', id: playlistId })),
        { type: 'PLAYLIST', id: 'LIST' },
      ],
      keepUnusedDataFor: 0,
    }),
    playlistDetail: build.query({
      query: data => ({ url: '/playlist/detail', data }),
      transformResponse: response => {
        if (response.resultFlag) {
          return response.playlistInfo;
        } else {
          return response.resultFlag;
        }
      },
      // providesTags: (result, error, payload) => [{ type: 'PLAYLIST', id: result.playlistId }],
      providesTags: (result, error, payload) =>
        result ? [{ type: 'PLAYLIST', id: result.playlistId }] : ['UNKNOWN_PAGE'],
    }),
    playlistAdd: build.mutation({
      query: data => ({ url: '/playlist/add', data }),
      invalidatesTags: [{ type: 'PLAYLIST', id: 'LIST' }],
      async onQueryStarted(payload, { dispatch, queryFulfilled, getState }) {
        try {
          const response = await queryFulfilled;

          const { viewPlaceId: placeId, accountId } = getState().account.accountInfo;
          const playlistId = response.data.playlistId;
          fnSaveHistoryToLocalStorage(playlistId, accountId, placeId);
        } catch {}
      },
    }),
    playlistUpdate: build.mutation({
      query: data => ({ url: '/playlist/update', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'PLAYLIST', id: payload.playlistId }],
    }),
    playlistCopy: build.mutation({
      async queryFn(data, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        try {
          const playlistId = data.playlistId;
          const placeId = data.placeId;

          const {
            data: { newPlaylistId },
          } = await fetchInteractionApi({ url: '/playlist/copy', data });

          const {
            data: { frameIdInfo },
          } = await fetchInteractionApi({ url: '/frame/copy', data: { newPlaylistId, playlistId, placeId } });

          const {
            data: { layerIdInfo },
          } = await fetchInteractionApi({ url: '/layer/copy', data: { newPlaylistId, playlistId, frameIdInfo } });

          const resultData = await fetchInteractionApi({
            url: '/layer-contents/copy',
            data: { newPlaylistId, playlistId, frameIdInfo, layerIdInfo },
          });

          const {
            data: { resultFlag: touchResultFlag, touchFrameIdInfo },
          } = await fetchInteractionApi({ url: '/touch-frame/copy', data: { newPlaylistId, playlistId, placeId } });

          if (touchResultFlag) {
            await fetchInteractionApi({
              url: '/touch-layer/copy',
              data: { newPlaylistId, playlistId, frameIdInfo, touchFrameIdInfo },
            });
          }

          return { data: resultData };
        } catch (err) {
          return { error: err };
        }
      },
      invalidatesTags: (result, error, payload) => [{ type: 'PLAYLIST', id: 'LIST' }],
    }),
    playlistRemove: build.mutation({
      query: data => ({ url: '/playlist/remove', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'PLAYLIST', id: 'LIST' }],
      async onQueryStarted({ playlistIdList }, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;

          const { viewPlaceId: placeId, accountId } = getState().account.accountInfo;
          fnRemoveHistoryFromLocalStorage(playlistIdList, accountId, placeId);
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  usePlaylistCountQuery,
  usePlaylistListQuery,
  usePlaylistDetailQuery,
  usePlaylistAddMutation,
  usePlaylistUpdateMutation,
  usePlaylistRemoveMutation,
  usePlaylistCopyMutation,
} = playlistApi;
