import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  font-size: ${props => props.fontSize}px;
  color: #666666;
  outline: none;
  float: right;
  cursor: pointer;

  background: #ffffff;
  /* &:hover {
    color: #ffffff;
    background: #cccccc;
  } */

  &:focus {
    outline: 0;
  }
`;

const SquareButton = props => {
  return (
    <StyledButton {...props}></StyledButton>
  );
};

export default SquareButton;