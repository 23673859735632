export const TRANSITION = {
  none: {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
  },
  changeOpacity: {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  rightToLeft: {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(-100%)' },
  },
  leftToRight: {
    from: { transform: 'translateX(-100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(100%)' },
  },
  bottomToTop: {
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(-100%)' },
  },
  topToBottom: {
    from: { transform: 'translateY(-100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(100%)' },
  },
  scaleUp: {
    from: { transform: 'scale(0,0)' },
    enter: { transform: 'scale(1,1)' },
    leave: { transform: 'scale(1,1)' },
  },
  rotateX: {
    from: { transform: 'rotateX(180deg)' },
    enter: { transform: 'rotateX(0deg)' },
    leave: { transform: 'rotateX(180deg)' },
  },
  rotateY: {
    from: { transform: 'rotateY(180deg)' },
    enter: { transform: 'rotateY(0deg)' },
    leave: { transform: 'rotateY(180deg)' },
  },
  skew: {
    from: { transform: 'skew(90deg,90deg)' },
    enter: { transform: 'skew(0deg, 0deg)' },
    leave: { transform: 'skew(90deg,90deg)' },
  },
};
