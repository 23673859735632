import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

import {
  CANVAS_MAX_ZOOMLEVEL,
  CANVAS_MIN_ZOOMLEVEL,
  INIT_CANVAS_INFO,
  INIT_THUMB_INFO,
} from '../pages/editor/constants/editor';
import { layerApi } from '../rtk/layerApi';

import { playlistApi } from '../rtk/playlistApi';

interface EditorState {
  playlistId: string;
  playlistNm: string;
  frameId: string;
  selectedLayerList: { type: 'LAYER' | 'OVERLAY', id: string }[];
  selectedTouchLayerList: { type: 'LAYER', id: string }[];
  layerContentsId: string;
  touchFrameId: string;
  touchLayerId: string;
  isSideMenuShow: boolean;
  isFrameClickLoading: boolean;
  thumbnail: {
    url: string,
    thumbId: string,
  };
  touchThumbnail: {
    url: string,
    thumbId: String,
  };
  canvasInfo: {
    x: number | string,
    y: number | string,
    zoom: number,
    originZoom: number,
    dragMode: boolean,
    gridMode: boolean,
    snapMode: boolean,
    lockMode: boolean,
    overlayShowMode: boolean,
    cursorType: 'SELECT' | 'LAYER-ADD' | 'OVERLAY-ADD' | 'DRAG',
  };
  editingMode: boolean;
  channelContentsList: any[];
  undoHistoryLength: number;
  redoHistoryLength: number;
}

const InitialState: EditorState = {
  placeId: '',
  playlistId: '',
  playlistNm: '',
  frameId: '',
  selectedLayerList: [],
  selectedTouchLayerList: [],
  layerContentsId: '',
  touchFrameId: '',
  canvasInfo: INIT_CANVAS_INFO,
  isSideMenuShow: true,
  isFrameClickLoading: false, // frame 클릭시 로딩
  editingMode: false,
  thumbnail: INIT_THUMB_INFO,
  touchThumbnail: INIT_THUMB_INFO,
  channelContentsList: [],
  undoHistoryLength: 0,
  redoHistoryLength: 0,
};

const slice = createSlice({
  name: 'editor',
  initialState: InitialState,
  reducers: {
    setState: (state: EditorState, { payload: { key, value } }: PayloadAction<EditorState>) => {
      state[key] = value;
    },
    updateCanvasInfo: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      state.canvasInfo = { ...state.canvasInfo, ...payload };
    },
    zoomInCanvas: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      const zoom = state.canvasInfo.zoom * 2;
      if (zoom <= CANVAS_MAX_ZOOMLEVEL) {
        state.canvasInfo = { ...state.canvasInfo, zoom };
      } else {
        state.canvasInfo = { ...state.canvasInfo, zoom: CANVAS_MAX_ZOOMLEVEL };
      }
    },
    zoomOutCanvas: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      const zoomOutLevel = state.canvasInfo.zoom / 2;
      if (zoomOutLevel <= CANVAS_MIN_ZOOMLEVEL) {
        state.canvasInfo = { ...state.canvasInfo, zoom: CANVAS_MIN_ZOOMLEVEL };
      } else {
        state.canvasInfo = { ...state.canvasInfo, zoom: zoomOutLevel };
      }
    },
    filterSelectedLayerList: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      state.selectedLayerList = state.selectedLayerList.filter(
        seletedLayer => !payload.removeList.find(remove => remove.id === seletedLayer.id),
      );
    },
    channelContentsList: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      state.channelContentsList = payload.channelContentsList;
    },
    updateThumbnail: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      state.thumbnail = payload;
    },
    updateTouchThumbnail: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      state.touchThumbnail = payload;
    },
    updateSelectedLayerInfo: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      state.selectedLayerList[0] = { ...state.selectedLayerList[0], ...payload };
    },
    updateSelectedTouchLayerInfo: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      state.selectedTouchLayerList[0] = { ...state.selectedTouchLayerList[0], ...payload };
    },
    toggleSideMenu: (state: EditorState, { payload }: PayloadAction<EditorState>) => {
      state.isSideMenuShow = !state.isSideMenuShow;
    },
    frameClickLoading: (state: LayerHistoryState, { payload }: PayloadAction<String>) => {
      state.isFrameClickLoading = true;
      state.selectedLayerList = [];
    },
    editingModeLayerContents(state: LayerContentsState, { payload }: PayloadAction<Boolean>) {
      state.editingMode = payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(playlistApi.endpoints.playlistDetail.matchFulfilled, (state, { payload }) => {
      if (payload) {
        state.playlistId = payload.playlistId;
        state.playlistNm = payload.playlistNm;
        state.placeId = payload.placeId;
      }
    });
    builder.addMatcher(layerApi.endpoints.layerList.matchFulfilled, (state, { payload: layerList }) => {
      if (state.isFrameClickLoading) {
        if (layerList.length > 0) {
          const baseLayer = layerList.find(layer => layer.baseYn === 'Y');
          if (baseLayer) {
            state.selectedLayerList = [{ type: 'LAYER', id: baseLayer.layerId, ...baseLayer }];
          }
        } else {
          state.selectedLayerList = [];
        }
        state.isFrameClickLoading = false;
      }
    });
  },
});

const selectIsSideMenuShow = createDraftSafeSelector(
  (state: EditorState) => state.isSideMenuShow,
  isSideMenuShow => isSideMenuShow,
);

const selectPlaceId = createDraftSafeSelector(
  (state: EditorState) => state.placeId,
  placeId => placeId,
);

const selectPlaylistId = createDraftSafeSelector(
  (state: EditorState) => state.playlistId,
  playlistId => playlistId,
);

const selectPlaylistNm = createDraftSafeSelector(
  (state: EditorState) => state.playlistNm,
  playlistNm => playlistNm,
);

const selectFrameId = createDraftSafeSelector(
  (state: EditorState) => state.frameId,
  frameId => frameId,
);
const selectThumbNail = createDraftSafeSelector(
  (state: EditorState) => state.thumbnail,
  thumbnail => thumbnail,
);
const selectTouchThumbNail = createDraftSafeSelector(
  (state: EditorState) => state.touchThumbnail,
  touchThumbnail => touchThumbnail,
);

const selectSelectedLayerList = createDraftSafeSelector(
  (state: EditorState) => state.selectedLayerList,
  selectedLayerList => selectedLayerList,
);

const selectSelectedTouchLayerList = createDraftSafeSelector(
  (state: EditorState) => state.selectedTouchLayerList,
  selectedTouchLayerList => selectedTouchLayerList,
);

const selectLayerContentsId = createDraftSafeSelector(
  (state: EditorState) => state.layerContentsId,
  layerContentsId => layerContentsId,
);

const selectTouchFrameId = createDraftSafeSelector(
  (state: EditorState) => state.touchFrameId,
  touchFrameId => touchFrameId,
);

const selectIsFrameClickLoading = createDraftSafeSelector(
  (state: EditorState) => state.isFrameClickLoading,
  isFrameClickLoading => isFrameClickLoading,
);

const selectCanvasInfo = createDraftSafeSelector(
  (state: EditorState) => state.canvasInfo,
  canvasInfo => canvasInfo,
);

const selectEditingMode = createDraftSafeSelector(
  (state: EditorState) => state.editingMode,
  editingMode => editingMode,
);

const selectChannelContentsList = createDraftSafeSelector(
  (state: EditorState) => state.channelContentsList,
  channelContentsList => channelContentsList,
);

const selectUndoHistoryLength = createDraftSafeSelector(
  (state: EditorState) => state.undoHistoryLength,
  undoHistoryLength => undoHistoryLength,
);

const selectRedoHistoryLength = createDraftSafeSelector(
  (state: EditorState) => state.redoHistoryLength,
  redoHistoryLength => redoHistoryLength,
);

export const editorSelector = {
  placeId: state => selectPlaceId(state[EDITOR]),
  playlistId: state => selectPlaylistId(state[EDITOR]),
  playlistNm: state => selectPlaylistNm(state[EDITOR]),
  frameId: state => selectFrameId(state[EDITOR]),
  selectedLayerList: state => selectSelectedLayerList(state[EDITOR]),
  selectedTouchLayerList: state => selectSelectedTouchLayerList(state[EDITOR]),
  layerContentsId: state => selectLayerContentsId(state[EDITOR]),
  touchFrameId: state => selectTouchFrameId(state[EDITOR]),
  canvasInfo: state => selectCanvasInfo(state[EDITOR]),
  isSideMenuShow: state => selectIsSideMenuShow(state[EDITOR]),
  isFrameClickLoading: state => selectIsFrameClickLoading(state[EDITOR]),
  editingMode: state => selectEditingMode(state[EDITOR]),
  thumbnail: state => selectThumbNail(state[EDITOR]),
  touchThumbnail: state => selectTouchThumbNail(state[EDITOR]),
  channelContentsList: state => selectChannelContentsList(state[EDITOR]),
  undoHistoryLength: state => selectUndoHistoryLength(state[EDITOR]),
  redoHistoryLength: state => selectRedoHistoryLength(state[EDITOR]),
};

export const EDITOR = slice.name;
export const editorReducer = slice.reducer;
export const editorAction = slice.actions;
