import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const loading = () => <div className="text-center"></div>;

const Open = props => {
  return (
    <Container>
      <Suspense fallback={loading()}>
        <Outlet />
      </Suspense>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export default Open;
