import * as apiLib from './api';

export const photoDetail = async ({ photoId }) => {
  try {
    const data = {
      photoId,
    };

    const result = await apiLib.fetchInteractionApi('/open/photo-detail', data);
    if (result.resultFlag) {
      return result.photo;
    } else {
      throw Object.assign(new Error('Photo Detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
