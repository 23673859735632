import React, { useRef, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import SelectInput from '../../../newComponents/SelectInput';
import RadioCheckBox from '../../../newComponents/RadioCheckbox';

import Loading from '../../../newComponents/Loading';
import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';

import { INIT_DATETIME_INFO } from '../constants/contentApp';

const DateTimeApp = ({ selectLayerContentsInfo = INIT_DATETIME_INFO, handleCancelLayerContents, selectedLayerType, appLoading }) => {
  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_DATETIME_INFO);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const [isDateOpen, setIsDateOpen] = useState(true);
  const [isTimeOpen, setIsTimeOpen] = useState(true);
  const [isThemeOpen, setIsThemeOpen] = useState(true);

  const dateInfo = useRef([
    { value: 'mdw', title: '월, 일(요일)' },
    { value: 'ymdw', title: '년, 월, 일 (요일)' },
    { value: 'md', title: '월, 일' },
    { value: 'ymd', title: '년, 월, 일' },
  ]);

  const timeInfo = useRef([
    { value: 'hmap', title: '00 : 00 (AM/PM)' },
    { value: 'hmsap', title: '00 : 00 : 00 (AM/PM)' },
    { value: 'hm', title: '00 : 00' },
    { value: 'hms', title: '00 : 00 : 00' },
  ]);

  const themeInfo = useRef([
    { horizon: '수평 배치' },
    { dateOnly: '날짜만 배치' },
    { timeOnly: '시간만 배치' },
    { dateTop: '날짜 상 / 시간 하 배치' },
    { timeTop: '시간 상 / 날짜 하 배치' },
  ]);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };

      if (!layerContentsInfo.contentsData.date) {
        layerContentsInfo.contentsData.date = 'mdw';
      }
      if (!layerContentsInfo.contentsData.time) {
        layerContentsInfo.contentsData.time = 'hmap';
      }
      if (!layerContentsInfo.contentsData.theme) {
        layerContentsInfo.contentsData.theme = 'horizon';
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_DATETIME_INFO);
    }
  }, [selectLayerContentsInfo]);

  return (
    <Container>
      <AppTitle text="날짜 & 시간 App" />
      <AppContentWrap>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox title="날짜 형식" line isOpen={isDateOpen} changeOpen={setIsDateOpen}>
              <SelectInput selectedValue={formData.contentsData.date} optionList={dateInfo.current} onSelectChange={value => handleChangeObject('contentsData', 'date', value)} />
            </ContentBox>
            <ContentBox title="시간 형식" line isOpen={isTimeOpen} changeOpen={setIsTimeOpen}>
              <SelectInput selectedValue={formData.contentsData.time} optionList={timeInfo.current} onSelectChange={value => handleChangeObject('contentsData', 'time', value)} />
            </ContentBox>
            <ContentBox title="테마" isOpen={isThemeOpen} changeOpen={setIsThemeOpen}>
              <RadioCheckBoxWrapper>
                {themeInfo.current.map(theme => (
                  <RadioCheckBoxArea key={Object.keys(theme)}>
                    <RadioCheckBox
                      id={Object.keys(theme) + 'Id'}
                      name={Object.keys(theme)}
                      checked={formData.contentsData.theme === Object.keys(theme)[0]}
                      onChange={() => handleChangeObject('contentsData', 'theme', Object.keys(theme)[0])}
                      label={Object.values(theme)}
                    />
                  </RadioCheckBoxArea>
                ))}
              </RadioCheckBoxWrapper>
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea formData={formData} cancelApp={handleCancelLayerContents} selectedLayerType={selectedLayerType} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const RadioCheckBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RadioCheckBoxArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
`;
const RadioLabel = styled.label`
  margin-left: 5px;
`;

export default React.memo(DateTimeApp);
