import React, { useRef, useCallback, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { HiPlus } from 'react-icons/hi';
import { BiImages as ImagesIcon } from 'react-icons/bi';

import { FiUpload, FiImage, FiXSquare, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { getDuration, getImageThumb, getVideoThumb } from '../../../utils/file';

import useUploadFileSizeCheck from 'utils/useUploadFileSizeCheck';

import { endpoints as endpointPlaceApi } from '../../../rtk/placeApi';
import { endpoints as endpointContentsApi } from '../../../rtk/contentsApi';

import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
// import ContentBox from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';

import SquareButton from '../../../components/SquareButton';
import SelectInput from '../../../newComponents/SelectInput';

import contentsError from '../../../assets/images/media/contents-error.svg';
import Loading from '../../../newComponents/Loading';
import Button from '../../../newComponents/Button';
import { INIT_MEDIA_INFO, INIT_AUDIO_INFO } from '../constants/contentApp';
import PublicStorageModal from '../modal/PublicStorageModal';
import StorageModal from '../modal/StorageModal';
import ContentDragModal from '../modal/ContentDragModal';
import RemoveConfirmModal from './components/modal/RemoveConfirmModal';
import { editorAction, editorSelector } from '../../../module/editorSlice';
import { AppContentWrap } from './components/ContentBox';
import { AiFillSound as SoundIcon } from 'react-icons/ai';

const MediaApp = ({
  handleCancelLayerContents,
  selectedLayerType,
  appLoading,
  appType,
  selectLayerContentsInfo = appType === 'audio' ? INIT_AUDIO_INFO : INIT_MEDIA_INFO,
}) => {
  const dispatch = useDispatch();

  const [isPublicStorageModal, setIspublicStorageModal] = useState(false);
  const [isStorageModal, setIsStorageModal] = useState(false);
  const [isContentsDragModal, setIsContentsDragModal] = useState(false);
  const [isFolderSelected, setIsFolderSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLayerContentsConfirmModal, setIsRemoveLayerContentsConfirmModal] = useState(false);
  const placeId = useSelector(editorSelector.placeId);
  const editingMode = useSelector(editorSelector.editingMode);
  const { data: placeInfo } = endpointPlaceApi.placeDetail.useQueryState({ placeId });
  const { data: placeFileInfo, refetch: placeFileInfoRefetch } = endpointContentsApi.contentsFileDetail.useQuery({
    placeId,
  });

  const { fnUploadFileSizeCheck } = useUploadFileSizeCheck();
  const [uploadFileSize, setUploadFileSize] = useState(0);
  const [layerContentsInfo, setLayerContentsInfo] = useState(appType === 'audio' ? INIT_AUDIO_INFO : INIT_MEDIA_INFO);
  const [formValidate, setFormValidate] = useState({});

  const [activeFileIndex, setActiveFileIndex] = useState(0);

  const [addFileList, setAddFileList] = useState([]);
  const [waitUploadFileList, setWaitUploadFileList] = useState([]);
  const [contentsFileList, setContentsFileList] = useState([]);

  //오디오 애니메이션
  const [audioPlay, setAudioPlay] = useState(false);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const soundRef = useRef([
    { value: 'start', title: '들어올때' },
    { vlaue: 'always', title: '항상' },
    { value: 'end', title: '나갈때' },
  ]);

  const fitRef = useRef([
    { value: 'cover', title: '채우기' },
    { value: 'fill', title: '채우기 (비율무시)' },
    { value: 'contain', title: '틀 안에 맞추기' },
    { value: 'none', title: '원본크기' },
  ]);

  const aniRef = useRef([
    { value: 'none', title: '효과 없음' },
    { value: 'changeOpacity', title: '나타나기' },
    { value: 'leftToRight', title: '왼쪽에서 오른쪽으로 슬라이드' },
    { value: 'rightToLeft', title: '오른쪽에서 왼쪽으로 슬라이드' },
    { value: 'bottomToTop', title: '아래에서 위로 슬라이드' },
    { value: 'topToBottom', title: '위에서 아래로 슬라이드' },
  ]);

  const timeRef = useRef([
    { value: '0', title: '없음' },
    { value: '3', title: '3' },
    { value: '5', title: '5' },
    { value: '7', title: '7' },
    { value: '10', title: '10' },
    { value: '15', title: '15' },
    { value: '20', title: '20' },
    { value: '25', title: '25' },
    { value: '30', title: '30' },
  ]);

  const handleChangeMediaOption = useCallback(
    (index, key, value) => {
      let changeFormData = [...formData.contentsData.mediaList];
      changeFormData[index][key] = value;

      handleChangeObject('contentsData', 'mediaList', changeFormData);
    },
    [formData.contentsData.mediaList, handleChangeObject],
  );

  const checkValidDate = useCallback(() => {}, []);

  const closeModal = useCallback(type => {
    setIspublicStorageModal(false);
    setIsStorageModal(false);
    setIsFolderSelected(false);
    setIsContentsDragModal(false);
    setIsRemoveLayerContentsConfirmModal(false);

    if (type === 'remove') {
      setWaitUploadFileList([]);
    }
  }, []);

  const handleChangeStorageAdd = useCallback(
    addMediaList => {
      let changeFormData = [...formData.contentsData.mediaList];
      changeFormData = changeFormData.concat(addMediaList);
      handleChangeObject('contentsData', 'mediaList', changeFormData);
      setActiveFileIndex(changeFormData.length - 1);
    },
    [formData.contentsData.mediaList, handleChangeObject],
  );

  const onChangeAddfileList = useCallback(
    async folder => {
      setIsLoading(true);

      const uploadFiles = fnUploadFileSizeCheck(waitUploadFileList, '', uploadFileSize, placeInfo.storageMax);
      let changeMediaList = [...formData.contentsData.mediaList];
      let changeAddFileList = [];
      let changeForm = { ...folder };

      if (addFileList[folder.folderId]) {
        changeAddFileList = [...addFileList[folder.folderId].fileList];
      }

      for (var i = 0; i < uploadFiles.length; i++) {
        let file = uploadFiles[i];

        const fileId = dayjs().unix() + v4().substr(0, 8);
        let addFileInfo = {
          fileData: file,
          fileId: fileId,
          fileNm: file.name,
          fileSize: file.size,
          fileType: file.type,
          filePath: folder.folderId,
          fileOrder: changeMediaList.length + 1,
        };
        // file.fileType= file.type;
        // file.fileId = fileId;

        let contentsFileInfo = {
          fileId: fileId,
          fileNm: file.name,
          filePath: URL.createObjectURL(file),
          fileSize: file.size,
          fileType: file.type,
          fileThumb: URL.createObjectURL(file),
        };

        let fileOption = {
          filePlaceId: placeId, //DB 저장 전 필요한 파일 정보
          fileFolderId: folder.folderId, //DB 저장 전 필요한 파일 정보
          filePreview: URL.createObjectURL(file), //DB 저장 전 필요한 파일 정보
          fileId: fileId,
          fileDragId: fileId,
          fileData: file,
          // animation: 'none',
          // fitOption: 'contain',
          fileType: file.type,
          fileNm: file.name,
          fromPublic: 'Y',
        };
        if (appType === 'audio') {
          const duration = await getDuration(file);
          fileOption.changeTime = String(duration);
          fileOption.sound = 'start';
          addFileInfo.fileDuration = String(duration);
        } else {
          fileOption.animation = 'none';
          fileOption.fitOption = 'contain';

          const typeIdx = file.type.indexOf('/');
          if (file.type.substring(0, typeIdx) === 'video') {
            const duration = await getDuration(file);
            const videoThumb = await getVideoThumb(file);
            fileOption.changeTime = String(duration);
            fileOption.fileThumb = videoThumb;
            fileOption.fileThumbPreview = URL.createObjectURL(fileOption.fileThumb);
            contentsFileInfo.fileThumb = URL.createObjectURL(fileOption.fileThumb);
            addFileInfo.fileDuration = String(duration);
            // contentsFileInfo.fileThumb = videoThumb;
          } else {
            const imgThumb = await getImageThumb(file);
            fileOption.changeTime = '3';
            addFileInfo.fileDuration = '0';
            if (file.size > imgThumb.size) {
              fileOption.fileThumb = imgThumb;
              fileOption.fileThumbPreview = URL.createObjectURL(fileOption.fileThumb);
              contentsFileInfo.fileThumb = URL.createObjectURL(fileOption.fileThumb);
              // contentsFileInfo.fileThumb = imgThumb;
            }
          }
        }

        changeMediaList.push(fileOption);
        changeAddFileList.push(addFileInfo);
        contentsFileList.push(contentsFileInfo);
        setUploadFileSize(uploadFileSize => uploadFileSize + file.size);
      }

      changeForm.fileList = changeAddFileList;

      handleChangeObject('contentsData', 'mediaList', changeMediaList);
      setAddFileList({ ...addFileList, [folder.folderId]: changeForm });
      setContentsFileList(contentsFileList);
      setActiveFileIndex(changeMediaList.length - 1);

      setIsLoading(false);
    },
    [
      addFileList,
      placeId,
      waitUploadFileList,
      formData.contentsData.mediaList,
      contentsFileList,
      fnUploadFileSizeCheck,
      uploadFileSize,
      placeInfo.storageMax,
      appType,
      handleChangeObject,
    ],
  );

  const onClickDelete = useCallback(() => {
    if (formData.contentsData.mediaList[activeFileIndex].fileFolderId) {
      let fileFolderId = formData.contentsData.mediaList[activeFileIndex].fileFolderId;
      let removeFileId = formData.contentsData.mediaList[activeFileIndex].fileId;

      let changeAddFileForm = { ...addFileList[fileFolderId] };
      let changeAddfileList = [...addFileList[fileFolderId].fileList];
      changeAddfileList = changeAddfileList.filter(fileInfo => fileInfo.fileId !== removeFileId);
      changeAddFileForm.fileList = changeAddfileList;
      setAddFileList({ ...addFileList, [fileFolderId]: changeAddFileForm });
    }

    let changeFormData = [...formData.contentsData.mediaList];
    let changeContentFileList = [...contentsFileList];
    changeFormData.splice(activeFileIndex, 1);
    changeContentFileList.splice(activeFileIndex, 1);

    if (formData.contentsData.mediaList.length - 2 < activeFileIndex && activeFileIndex > 0) {
      setActiveFileIndex(activeFileIndex => activeFileIndex - 1);
    }

    if (changeFormData.length === 0) {
      if (formData.contentsId === '') {
        handleCancelLayerContents();
      } else {
        setIsRemoveLayerContentsConfirmModal(true);
      }
    } else {
      handleChangeObject('contentsData', 'mediaList', changeFormData);
      setContentsFileList(changeContentFileList);
    }
  }, [
    activeFileIndex,
    formData.contentsData.mediaList,
    formData.contentsId,
    handleChangeObject,
    addFileList,
    contentsFileList,
    handleCancelLayerContents,
  ]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections && fileRejections.length === 0) {
        setWaitUploadFileList(acceptedFiles);
        setIsFolderSelected(true);
        setIsStorageModal(true);
        placeFileInfoRefetch();
      } else {
        toast.error('지원하지 않는 파일 형식입니다.');
      }
    },
    [placeFileInfoRefetch],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: appType === 'audio' ? 'audio/*' : 'image/jpeg, image/png, image/gif, video/mp4',
  });

  const getTitle = useCallback(() => {
    if (formData.contentsData.mediaList.length > 0) {
      return (
        (appType === 'audio' ? '오디오' : '미디어') +
        ' APP ' +
        (activeFileIndex + 1) +
        '/' +
        formData.contentsData.mediaList.length
      );
    } else {
      return appType === 'audio' ? '오디오 APP' : '미디어 APP';
    }
  }, [activeFileIndex, formData.contentsData.mediaList.length, appType]);

  useEffect(() => {
    if (!editingMode) {
      if (selectLayerContentsInfo) {
        let layerContentsInfo = { ...selectLayerContentsInfo };

        if (!layerContentsInfo.contentsData.mediaList) {
          layerContentsInfo.contentsData.mediaList = [];
        }
        setLayerContentsInfo(layerContentsInfo);
      } else {
        setLayerContentsInfo(INIT_MEDIA_INFO);
      }
      dispatch(editorAction.editingModeLayerContents(false));
      setAddFileList([]);
      setActiveFileIndex(0);
    }
  }, [selectLayerContentsInfo, dispatch, editingMode]);

  useEffect(() => {
    if (formData.contentsFileList) {
      setContentsFileList(formData.contentsFileList);
    } else {
      setContentsFileList([]);
    }
  }, [formData.contentsFileList]);

  useEffect(() => {
    if (placeFileInfo) {
      setUploadFileSize(placeFileInfo.totalFileSize);
    }
  }, [placeFileInfo]);

  return (
    <Container>
      <AppTitle text={getTitle()} />
      <AppContentWrap>
        {isLoading || appLoading ? (
          <Loading />
        ) : (
          <>
            <ButtonWrapper>
              <Button
                color={'gray700'}
                outline="true"
                onClick={() => {
                  setIspublicStorageModal(true);
                  placeFileInfoRefetch();
                }}
              >
                공용 파일보관함
              </Button>
              <Button
                color={'gray700'}
                outline="true"
                onClick={() => {
                  setIsStorageModal(true);
                  placeFileInfoRefetch();
                }}
              >
                파일보관함
              </Button>
            </ButtonWrapper>
            <ContentBox>
              {contentsFileList.length === 0 ? (
                // formData.contentsData.mediaList.length === 0  ? (
                <StyledTextDiv {...getRootProps({ isDragAccept, isDragReject })}>
                  <div className="icon">
                    <FiUpload size="17px" color="#999999" />
                  </div>
                  <input {...getInputProps()} />
                  사용할 {appType === 'audio' ? '오디오' : '이미지 or 동영상'}을
                  <br /> Drag & Drop / 클릭하여 삽입
                </StyledTextDiv>
              ) : (
                <FileUploadArea style={{ paddingTop: '0px' }}>
                  {appType !== 'audio' && (
                    <>
                      <FilePreviewArea>
                        {contentsFileList[activeFileIndex]?.filePath === null ? (
                          <img
                            style={{ position: 'relative', width: '100%', height: '14.75rem', objectFit: 'contain' }}
                            src={contentsError}
                            alt="preview"
                          ></img>
                        ) : contentsFileList[activeFileIndex]?.fileType.includes('image') ? (
                          <img
                            style={{ position: 'relative', width: '100%', height: '14.75rem', objectFit: 'contain' }}
                            src={
                              contentsFileList[activeFileIndex]?.filePath.includes('blob:')
                                ? contentsFileList[activeFileIndex]?.filePath
                                : process.env.REACT_APP_INTERACTION_CDN_URL +
                                  contentsFileList[activeFileIndex]?.filePath
                            }
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = contentsError;
                            }}
                            alt="preview"
                          ></img>
                        ) : (
                          <video
                            style={{
                              position: 'relative',
                              width: '100%',
                              height: '14.75rem',
                              objectFit: 'contain',
                              outline: 'none',
                            }}
                            preload="auto"
                            controls
                            muted
                            src={
                              contentsFileList[activeFileIndex]?.filePath?.includes('blob:')
                                ? contentsFileList[activeFileIndex]?.filePath
                                : process.env.REACT_APP_INTERACTION_CDN_URL +
                                  contentsFileList[activeFileIndex]?.filePath
                            }
                          ></video>
                        )}
                        <FileInfoArea>
                          <FiImage size="1.1875rem" style={{ marginRight: '0.625rem' }} />
                          <FileInfoText>{contentsFileList[activeFileIndex]?.fileNm || ''}</FileInfoText>
                          <FiXSquare size="1.125rem" style={{ cursor: 'pointer' }} onClick={() => onClickDelete()} />
                        </FileInfoArea>
                      </FilePreviewArea>
                      <PlusButtonArea>
                        <div style={{ display: 'flex' }}>
                          <StyledSquareButton
                            isLastItem={activeFileIndex === 0 ? true : false}
                            style={{ marginRight: '0.625rem' }}
                            onClick={() => {
                              if (activeFileIndex !== 0) {
                                setActiveFileIndex(activeFileIndex - 1);
                              }
                            }}
                          >
                            <FiChevronLeft size="1.25rem" color={'#999999'} />
                          </StyledSquareButton>
                          <StyledSquareButton
                            isLastItem={activeFileIndex >= formData.contentsData.mediaList.length - 1 ? true : false}
                            style={{ marginRight: '0.625rem' }}
                            onClick={() => {
                              if (activeFileIndex < formData.contentsData.mediaList.length - 1) {
                                setActiveFileIndex(activeFileIndex + 1);
                              }
                            }}
                          >
                            <FiChevronRight size="1.25rem" color={'#999999'} />
                          </StyledSquareButton>
                        </div>
                        <StyledSquareButton>
                          <HiPlus size="1.25rem" color="#999999" />
                          <FileInput
                            type="file"
                            accept={'image/jpeg, image/png, image/gif, video/mp4'}
                            onChange={e => {
                              setWaitUploadFileList(Array.prototype.slice.call(e.target.files));
                              setIsFolderSelected(true);
                              setIsStorageModal(true);
                              placeFileInfoRefetch();
                            }}
                            multiple
                          />
                        </StyledSquareButton>
                      </PlusButtonArea>

                      <PlusButtonArea>
                        <div style={{ display: 'flex' }}>
                          <StyledSquareButton dragModal onClick={() => setIsContentsDragModal(true)}>
                            <ImagesIcon size="1.25rem" color="#999999" style={{ marginRight: '0.625rem' }} />
                            미디어 순서 변경
                          </StyledSquareButton>
                        </div>
                      </PlusButtonArea>
                    </>
                  )}

                  {appType === 'audio' && (
                    <>
                      <FilePreviewArea>
                        <AudioArea audioPlay={audioPlay}>
                          <SoundIcon
                            id="soundIcon"
                            style={{ width: '40%', height: '100%', marginTop: '2.5rem' }}
                            fill="gray"
                          />
                          <audio
                            onPlay={() => setAudioPlay(true)}
                            onPause={() => setAudioPlay(false)}
                            controls
                            style={{ width: '100%' }}
                            src={
                              contentsFileList[activeFileIndex]?.filePath?.includes('blob:')
                                ? contentsFileList[activeFileIndex]?.filePath
                                : process.env.REACT_APP_INTERACTION_CDN_URL +
                                  contentsFileList[activeFileIndex]?.filePath
                            }
                          />
                        </AudioArea>
                        <FileInfoArea>
                          <FiImage size="1.1875rem" style={{ marginRight: '0.625rem' }} />
                          <FileInfoText>{contentsFileList[activeFileIndex]?.fileNm || ''}</FileInfoText>
                          <FiXSquare size="1.125rem" style={{ cursor: 'pointer' }} onClick={() => onClickDelete()} />
                        </FileInfoArea>
                      </FilePreviewArea>
                      <Box>
                        <LeftArea>
                          <Title>맞춤 옵션</Title>
                        </LeftArea>
                        <RightArea>
                          <SelectInput
                            selectedValue={formData.contentsData.mediaList[activeFileIndex]?.sound}
                            optionList={soundRef.current}
                            onSelectChange={value => handleChangeMediaOption(activeFileIndex, 'sound', value)}
                          />
                        </RightArea>
                      </Box>
                    </>
                  )}
                  {appType === 'media' && (
                    <>
                      <Box>
                        <LeftArea>
                          <Title>맞춤 옵션</Title>
                        </LeftArea>
                        <RightArea>
                          <SelectInput
                            selectedValue={formData.contentsData.mediaList[activeFileIndex]?.fitOption}
                            optionList={fitRef.current}
                            onSelectChange={value => handleChangeMediaOption(activeFileIndex, 'fitOption', value)}
                          />
                        </RightArea>
                      </Box>
                      <Box>
                        <LeftArea>
                          <Title>애니메이션 효과</Title>
                        </LeftArea>
                        <RightArea>
                          <SelectInput
                            selectedValue={formData.contentsData.mediaList[activeFileIndex]?.animation}
                            optionList={aniRef.current}
                            onSelectChange={value => handleChangeMediaOption(activeFileIndex, 'animation', value)}
                          />
                        </RightArea>
                      </Box>
                    </>
                  )}
                  {contentsFileList[activeFileIndex]?.fileType.includes('image') && (
                    <Box>
                      <LeftArea>
                        <Title>전환 시간</Title>
                      </LeftArea>
                      <RightArea>
                        <SelectInput
                          selectedValue={formData.contentsData.mediaList[activeFileIndex]?.changeTime}
                          optionList={timeRef.current}
                          onSelectChange={value => handleChangeMediaOption(activeFileIndex, 'changeTime', value)}
                        />
                      </RightArea>
                    </Box>
                  )}
                </FileUploadArea>
              )}
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        cancelApp={handleCancelLayerContents}
        addFileList={addFileList}
        selectedLayerType={selectedLayerType}
        noConform={contentsFileList.length === 0}
      />
      <PublicStorageModal
        isOpen={isPublicStorageModal}
        closeModal={closeModal}
        handleChangeStorageAdd={handleChangeStorageAdd}
        contentsFileList={contentsFileList}
        setContentsFileList={setContentsFileList}
        appType={appType}
        multiSelect={appType === 'audio' ? false : true}
      />
      <StorageModal
        isOpen={isStorageModal}
        closeModal={closeModal}
        handleChangeStorageAdd={handleChangeStorageAdd}
        placeId={placeId}
        isFolderSelected={isFolderSelected}
        onChangeAddfileList={onChangeAddfileList}
        contentsFileList={contentsFileList}
        setContentsFileList={setContentsFileList}
        appType={appType}
        multiSelect={appType === 'audio' ? false : true}
      />
      <ContentDragModal
        isOpen={isContentsDragModal}
        closeModal={closeModal}
        contentsFileList={contentsFileList}
        mediaList={formData.contentsData.mediaList}
        handleChangeObject={handleChangeObject}
        setContentsFileList={setContentsFileList}
      />
      <RemoveConfirmModal
        contentsId={formData.contentsId}
        isOpen={isRemoveLayerContentsConfirmModal}
        closeModal={closeModal}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const getColor = props => {
  if (props.isDragAccept) {
    return '#2a91df';
  }
  if (props.isDragReject) {
    return '#f05b5b';
  }
  return '#cfcfcf';
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;
const audioBoxAnimation = keyframes`
  from{
     fill: gray;
  }
  to{
    fill: rgb(85 85 85 );
  }
`;
const AudioArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${props =>
    props.audioPlay
      ? css`
          #soundIcon {
            animation: ${audioBoxAnimation} 2s infinite;
          }
        `
      : css`
          #soundIcon {
            animation: none;
          }
        `}
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin: 0 19px 19px 0;
`;

const StorageModalButton = styled.button`
  display: flex;
  flex: 1;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

const ContentBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 80%;
  //margin: 0 19px 19px 0;
  // padding-left: 26px;
`;

//Media--------------------------------
const StyledTextDiv = styled.div`
  /* height: 31.25rem; */
  display: flex;
  min-height: 200px;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  font-size: 15px;
  // width: 272px;
  line-height: 1.2;
  letter-spacing: -0.3808px;
  text-align: center;
  color: #999999;
  // margin: 0 19px 10px 26px;
  // padding-top: 216px;
  justify-content: center;
  background-color: #f6f8f9;
  border: dashed 1px;
  border-color: ${props => getColor(props)};
  cursor: pointer;
  outline: none;

  & .icon {
    height: 1.0625rem;
    margin-bottom: 0.9375rem;
  }
`;

const FileUploadArea = styled.div`
  /* position: relative; */

  display: flex;
  flex-direction: column;
`;

const FilePreviewArea = styled.div`
  position: relative;
  height: 14.875rem;
  border: solid 0.0625rem #dddddd;
  margin-bottom: 0.625rem;
`;

const FileInfoArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  font-size: 0.8125rem;
  color: #ffffff;
  background-color: #313131;
  justify-content: space-between;
  opacity: 0.7;
  padding: 0.6875rem 1.125rem;
  // z-index: 2;

  /* top: -1px;
  left: -1px; */
`;

const FileInfoText = styled.div`
  max-width: 18.125rem;
  padding-top: 0.1875rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex: 1;
  height: 100%;
`;

const PlusButtonArea = styled.div`
  display: flex;
  position: relative;
  height: 2.5rem;
  justify-content: space-between;
  margin-bottom: 0.625rem;
`;

const StyledSquareButton = styled(SquareButton)`
  display: flex;
  width: ${({ dragModal }) => (dragModal ? '170px' : '3.75rem')};
  height: 2.5rem;
  border-radius: 0.375rem;
  border: solid 0.0625rem #cfcfcf;
  color: #999999;
  background-color: ${({ isLastItem }) => (isLastItem ? '#f1f1f1' : '#ffffff')};
  padding: 0.25rem 0.375rem;
  float: right;
  align-items: center;
  justify-content: center;
`;

const FileInput = styled.input`
  position: absolute;
  width: 3.75rem;
  height: 2.5rem;
  right: 0;
  top: 0;
  opacity: 0;
  overflow: hidden;
  /* z-index: -1; */
  cursor: pointer;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const LeftArea = styled.div`
  flex: 1;
  align-items: center;
`;
const RightArea = styled.div`
  flex: 3;
  align-items: center;
`;
const Title = styled.div`
  width: 100%;
  font-size: 14px;
`;

export default React.memo(MediaApp);
