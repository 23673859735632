import React from 'react';
import styled from 'styled-components';

const RadioCheckbox = ({ width, checked, onChange, disabled, id, name, label }) => {
  return (
    <>
      <Container width={width} checked={checked} disabled={disabled}>
        <input id={id} name={name} type="checkbox" checked={checked} onChange={e => onChange(id)} />
        <span className="checked-icon" />
      </Container>
      <RadioLabel onClick={() => (disabled ? null : onChange(id))}>{label}</RadioLabel>
    </>
  );
};

const Container = styled.label`
  position: relative;
  min-width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  background: #ffffff;
  border: 1px solid ${({ checked, disabled }) => (checked ? (disabled ? '#999999' : '#41a1ea') : '#cccccc')};
  border-radius: 50%;
  padding: 1px;
  overflow: hidden;
  cursor: pointer;

  & > input {
    position: absolute;
    visibility: hidden;
    padding: 0;
    margin: 0;
  }

  .checked-icon {
    width: ${({ width }) => width / 2 - 2}px;
    height: ${({ width }) => width / 2 - 2}px;
    border-radius: 50%;

    background: ${({ checked, disabled }) => (checked ? (disabled ? '#999999' : '#41a1ea') : '#ffffff')};
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 0;
  }

  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

const RadioLabel = styled.label`
  margin-left: 5px;
  cursor: pointer;
`;
RadioCheckbox.defaultProps = {
  width: 22,
  checked: false,
  onChange: () => {},
  disabled: false,
};

export default React.memo(RadioCheckbox);
