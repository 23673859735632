import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { openAction } from './openSlice';

import * as openApiLib from '../lib/openApi';

function* photoDetail({ payload }) {
  try {
    const photo = yield call(openApiLib.photoDetail, payload);

    yield put(openAction.photoDetailSuccess({ photo }));
  } catch (err) {
    yield put(openAction.photoDetailFailure('Photo Detail Error'));
  }
}

export function* watchPhotoDetail() {
  yield takeLatest(openAction.photoDetail, photoDetail);
}

function* rootSaga() {
  yield all([fork(watchPhotoDetail)]);
}

export default rootSaga;
