import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../../newComponents/Modal';

import TouchModalFrameList from './TouchModalFrameList';
import TouchModalLayerList from './TouchModalLayerList';
import TouchModalLayerProperty from './TouchModalLayerProperty';
import TouchModalLayerCardList from './TouchModalLayerCardList';
import { editorAction, editorSelector } from '../../../module/editorSlice';

import {
  endpoints as endpointsTouchFrameApi,
  useTouchFrameUpdateMutation,
  useUploadTouchThumbNailMutation,
} from '../../../rtk/touchFrameApi';
import { endpoints as endpointsTouchLayerApi } from '../../../rtk/touchLayerApi';

import { FiRotateCcw as RotateLeftIcon } from 'react-icons/fi';
import { FiRotateCw as RotateRightIcon } from 'react-icons/fi';
import Loading from '../../../newComponents/Loading';
import { createFrameThumbNail } from 'utils/playlist';

const TouchModal = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const playlistId = useSelector(editorSelector.playlistId);
  const touchFrameId = useSelector(editorSelector.touchFrameId);
  const selectedTouchLayerList = useSelector(editorSelector.selectedTouchLayerList);
  const [uploadThumbNail] = useUploadTouchThumbNailMutation();

  const { currentData: touchFrameList, isLoading: isFrameLoading } =
    endpointsTouchFrameApi.touchFrameList.useQueryState({ playlistId });
  const { currentData: layerList } = endpointsTouchLayerApi.touchLayerList.useQueryState({ frameId: touchFrameId });

  const [updateTouchFrame] = useTouchFrameUpdateMutation();
  const [isDrag, setIsDrag] = useState(false); // 프레임 or 레이어 드래그도중 모달 안닫히게 하기

  const moveableRef = useRef(null);

  const [selectedTouchLayerInfo, setSelectedTouchLayerInfo] = useState();

  const frameAngle = useMemo(() => {
    return touchFrameList?.find(frame => frame.frameId === touchFrameId)?.angle || 0;
  }, [touchFrameId, touchFrameList]);

  useEffect(() => {
    if (!isOpen) {
      dispatch(editorAction.setState({ key: 'selectedTouchLayerList', value: [] }));
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (layerList) {
      if (selectedTouchLayerList.length === 1) {
        const { id, type, x, y, width, height } = selectedTouchLayerList[0];

        const layer = layerList.find(layer => layer.layerId === id);
        if (layer) {
          setSelectedTouchLayerInfo({ id, type, ...layer, x, y, width, height });
        }
      } else {
        setSelectedTouchLayerInfo();
      }
    } else {
      setSelectedTouchLayerInfo();
    }
  }, [selectedTouchLayerList, layerList]);

  const handleCreateFrameThumbNail = useCallback(() => {
    console.log('handleCreateFrameThumbNail');
    createFrameThumbNail({ frameId: touchFrameId, type: 'touch' }).then(res => {
      uploadThumbNail({ addFileList: [res.param], frameId: res.frameId });
      dispatch(editorAction.updateTouchThumbnail({ url: res.url, thumbId: res.frameId }));
    });
  }, [touchFrameId, dispatch, uploadThumbNail]);

  return (
    <>
      <StyledModal
        title={
          <Title>
            <span>터치 영역 설정</span>
            <span className="warn">* 센서 방향을 기준으로 터치 영역이 일치하도록 설정하세요.</span>
            <button onClick={e => handleCreateFrameThumbNail()}>썸네일</button>
          </Title>
        }
        onClose={closeModal}
        onBackdropClick={!isDrag && closeModal}
        hideButton={true}
        visible={isOpen}
        height="90%"
        isLine
      >
        <Wrap>
          {isFrameLoading ? (
            <Loading />
          ) : (
            <>
              <Left>
                <TouchModalFrameList setIsDrag={setIsDrag} />
              </Left>
              <Middle>
                {touchFrameList?.length > 0 ? (
                  <>
                    <ButtonArea>
                      <Angle>각도 {frameAngle}°</Angle>
                      <Icon
                        onClick={() => {
                          updateTouchFrame({
                            frameId: touchFrameId,
                            frameInfo: { angle: frameAngle === 0 ? 270 : frameAngle - 90 },
                          });
                        }}
                      >
                        <RotateLeftIcon />
                      </Icon>
                      <Icon
                        onClick={() => {
                          updateTouchFrame({
                            frameId: touchFrameId,
                            frameInfo: { angle: frameAngle === 270 ? 0 : frameAngle + 90 },
                          });
                        }}
                      >
                        <RotateRightIcon />
                      </Icon>
                    </ButtonArea>
                    <TouchModalLayerList moveableRef={moveableRef} frameAngle={frameAngle} />
                  </>
                ) : (
                  <EmptyFrame>터치 프레임이 없습니다 프레임을 추가해 주세요</EmptyFrame>
                )}
              </Middle>
              <Right>
                {touchFrameList?.length > 0 ? (
                  <HorizonLayoutBox orientation="horizontal">
                    <HorizonBox flex={1} minSize={300}>
                      <TouchModalLayerCardList setIsDrag={setIsDrag} />
                    </HorizonBox>
                    <HorizonSplitBar propagate={true} />
                    <HorizonBox flex={1} minSize={300}>
                      {selectedTouchLayerInfo && (
                        <TouchModalLayerProperty
                          selectedTouchLayerInfo={selectedTouchLayerInfo}
                          frameAngle={frameAngle}
                          moveableRef={moveableRef}
                        />
                      )}
                    </HorizonBox>
                  </HorizonLayoutBox>
                ) : (
                  <></>
                )}
              </Right>
            </>
          )}
        </Wrap>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .body {
    padding: 0px;
  }
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #3333333;
  font-size: 14px;
  font-weight: 500;

  & > .warn {
    font-size: 12px;
    color: #f05b5b;
  }
`;

const Wrap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const Left = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 250px;
  border-right: 1px solid #dddddd;
  height: 100%;
`;

const Middle = styled.div`
  position: relative;
  flex: 1;
  min-width: 250px;
  height: 100%;
  overflow: hidden;
`;

const Right = styled.div`
  flex: 0 0 300px;
  border-left: 1px solid #dddddd;
  height: 100%;
`;

const HorizonLayoutBox = styled(ReflexContainer)`
  display: flex;
  width: 100%;
  height: 100%;
  background: #ffffff;
  flex-direction: column;
  overflow: hidden;
`;

const HorizonSplitBar = styled(ReflexSplitter)`
  background-color: #fff;
  width: 100%;
  height: 3px;
  border: solid #aaa;

  border-width: 1px 0;
  z-index: 1;
  cursor: row-resize;
`;

const HorizonBox = styled(ReflexElement)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  z-index: 1;
  background: #ffffff;
`;

const ButtonArea = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  padding: 10px;
  display: flex;
  gap: 10px;
  z-index: 1;
`;

const Icon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #41a1ea;
`;

const Angle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  text-align: left;
`;

const EmptyFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #333;
`;

export default React.memo(TouchModal);
