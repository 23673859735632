import React, { useEffect, useRef } from 'react';
import { memo } from 'react';
import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';

import Modal from '../../../newComponents/Modal';
import { ROOT_FOLDER_INFO } from '../constants/contentApp';
import Loading from '../../../newComponents/Loading';
import StorageInfoBar from '../app/components/StorageInfoBar';

import { accountSelector } from '../../../module/accountSlice';
import { editorAction } from '../../../module/editorSlice';

import { MdFolderOpen } from 'react-icons/md';
import { FiImage, FiFilm } from 'react-icons/fi';
import { FiChevronRight as RightIcon } from 'react-icons/fi';

import { endpoints as endpointsFolderApi } from '../../../rtk/folderApi';
import { endpoints as endpointsFileApi } from '../../../rtk/fileApi';
import { AiFillSound as SoundIcon } from 'react-icons/ai';
const StorageModal = ({
  multiSelect,
  isOpen,
  closeModal,
  handleChangeStorageAdd,
  placeId,
  isFolderSelected,
  onChangeAddfileList,
  contentsFileList,
  setContentsFileList,
  appType,
}) => {
  const dispatch = useDispatch();

  const accountInfo = useSelector(accountSelector.accountInfo);

  const [folderPathList, setFolderPathList] = useState([
    {
      folderId: placeId,
      folderNm: '파일 보관함',
      folderPid: placeId,
      placeId: placeId,
    },
  ]);
  const [currentFolder, setCurrentFolder] = useState({
    folderId: placeId,
    folderNm: '파일 보관함',
    folderPid: placeId,
    placeId: placeId,
  });

  const [isActiveFolder, setIsActiveFolder] = useState();
  const [activeFile, setActiveFile] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [fileFilter, setFileFilter] = useState('');

  const { currentData: folderList } = endpointsFolderApi.folderList.useQuery({ folderId: currentFolder.folderId });
  const { currentData: fileList, refetch: fileListRefetch } = endpointsFileApi.fileList.useQuery({ folderId: currentFolder.folderId });

  // 상위 폴더로 이동
  const onFolderUpper = useCallback(
    folder => {
      const index = folderPathList.findIndex(folderPath => folderPath.folderId === folder.folderId);
      setCurrentFolder(folder);
      setFolderPathList(folderPathList.slice(0, index + 1));
      setIsActiveFolder();
      setActiveFile([]);
      setFileData([]);
    },
    [folderPathList],
  );

  // 하위 폴더로 이동
  const onClickLower = useCallback(
    (folder, index) => {
      let changeFolderPathList = [...folderPathList];
      changeFolderPathList.push(folder);
      setCurrentFolder(folder);
      setFolderPathList(changeFolderPathList);
      setIsActiveFolder();
      setActiveFile([]);
      setFileData([]);
    },
    [folderPathList],
  );

  // 파일보관함 관련 (fromStorage)--------
  // 파일보관함 > 파일 선택
  const onClickFileRow = useCallback(
    file => {
      const activeFileIdx = activeFile.findIndex(active => active === file.fileId);
      if (multiSelect) {
        if (activeFileIdx < 0) {
          setActiveFile(activeFile => activeFile.concat(file.fileId));
          setFileData(fileData => fileData.concat(file));
        } else {
          setActiveFile(activeFile => activeFile.filter(active => active !== file.fileId));
          setFileData(fileData => fileData.filter(data => data.fileId !== file.fileId));
        }
      } else {
        setActiveFile([file.fileId]);
        setFileData([file]);
      }
      setIsActiveFolder();
    },
    [activeFile, multiSelect],
  );

  // 파일보관함 > 파일 선택 > 불러오기
  const onClickFileNext = useCallback(() => {
    if (fileData.length === 0) {
      toast.error('선택된 파일이 없습니다.');
    } else {
      let addMediaList = [];
      // let changeContentsFileList = [...contentsFileList];
      let data = {};
      let fileOption = {};

      for (let i = 0; i < activeFile.length; i++) {
        data = {
          fileId: fileData[i].fileId,
          fileNm: fileData[i].fileNm,
          fileSize: fileData[i].fileSize, //kb
          fileType: fileData[i].fileType,
          filePath: fileData[i].filePath,
          fileThumb: fileData[i].fileThumb,
        };
        fileOption = {
          fileId: data.fileId,
          fileDragId: dayjs().unix() + v4().substr(0, 8),
          fileData: data,
          fileType: fileData[i].fileType,
          // animation: 'none',
          // fitOption: 'contain',
          changeTime: fileData[i].fileDuration && fileData[i].fileDuration !== '0' ? fileData[i].fileDuration : '3',
          fromPublic: 'Y',
          fileNm: fileData[i].fileNm,
        };
        if (appType === 'audio') {
          fileOption.sound = 'change';
        } else {
          fileOption.animation = 'none';
          fileOption.fitOption = 'contain';
        }

        addMediaList.push(fileOption);
        contentsFileList.push(data);
      }
      if (appType === 'audio') {
        setContentsFileList([data]);
        handleChangeStorageAdd([fileOption]);
      } else {
        setContentsFileList(contentsFileList);
        handleChangeStorageAdd(addMediaList);
      }
      closeModal();
    }
  }, [activeFile.length, closeModal, fileData, handleChangeStorageAdd, contentsFileList, setContentsFileList, appType]);

  const onClickFolderNext = useCallback(() => {
    let selectFolder = currentFolder;
    // let selectFolderId = currentFolder.folderId;
    if (isActiveFolder) {
      // selectFolderId = isActiveFolder;
      selectFolder = folderList.find(folder => isActiveFolder === folder.folderId);
    }

    dispatch(editorAction.editingModeLayerContents(true));
    onChangeAddfileList(selectFolder);

    closeModal();
  }, [dispatch, closeModal, currentFolder, isActiveFolder, onChangeAddfileList, folderList]);

  useEffect(() => {
    if (isOpen) {
      let defulatFilePathInfo = {
        folderId: placeId,
        folderNm: '파일 보관함',
        folderPid: placeId,
        placeId: placeId,
      };
      setFolderPathList([defulatFilePathInfo]);
      setCurrentFolder(defulatFilePathInfo);
      setIsActiveFolder();
      setActiveFile([]);
      setFileData([]);
      fileListRefetch();
      if (appType === 'audio') {
        setFileFilter(['audio']);
      } else {
        setFileFilter(['video', 'image']);
      }
    }
  }, [isOpen, placeId, fileListRefetch, appType]);

  return (
    <StyledModal
      hideButton={false}
      title={
        <Title>
          <span>파일 보관함</span>
        </Title>
      }
      onConfirm={isFolderSelected ? onClickFolderNext : onClickFileNext}
      onCancel={() => closeModal('remove')}
      onClose={() => closeModal('remove')}
      onBackdropClick={() => closeModal('remove')}
      width={'80%'}
      height={'90%'}
      size={'xxlarge'}
      isLine={true}
      visible={isOpen}
      confirmText={isFolderSelected ? '다음' : '불러오기'}
    >
      <ModalContainer>
        <FilePathWrapper>
          <FilePathArea>
            {folderPathList.map((folder, index) => (
              <React.Fragment key={folder.folderId}>
                {folder.folderId === currentFolder.folderId ? (
                  <StoragePathName>{folder.folderNm}</StoragePathName>
                ) : (
                  <>
                    <StoragePathName index={index} onClick={() => onFolderUpper(folder)}>
                      {folder.folderNm}{' '}
                    </StoragePathName>
                    <RightIcon size="1.25rem" color="#666666" />
                  </>
                )}
              </React.Fragment>
            ))}
          </FilePathArea>
          <StorageInfoBar />
        </FilePathWrapper>

        {folderList && fileList ? (
          <DataAreaBox>
            <FolderFileArea>
              {folderList.map((folder, index) => (
                <FolderCardArea
                  className={isActiveFolder === folder.folderId && 'active-row'}
                  key={`folder-${index}`}
                  index={index}
                  onClick={e => {
                    e.stopPropagation();
                    setIsActiveFolder(folder.folderId);
                    setActiveFile([]);
                    setFileData([]);
                  }}
                  onDoubleClick={() => onClickLower(folder, index)}
                  onTouchEnd={() => onClickLower(folder, index)}
                >
                  <IconArea>
                    <MdFolderOpen color={isActiveFolder === folder.folderId ? '#2a91df' : ''} size="0.875rem" />
                  </IconArea>
                  <FileInfoText>{folder.folderNm}</FileInfoText>
                </FolderCardArea>
              ))}
            </FolderFileArea>
            <FileArea>
              {fileList
                .filter(file => fileFilter && fileFilter?.some(value => value.includes(file.fileType.split('/')[0])))
                .map((file, index) => {
                  return (
                    <FileCardArea
                      className={activeFile.find(active => active === file.fileId) && !isFolderSelected && 'active-row'}
                      key={`file-${index}`}
                      onClick={e => {
                        e.stopPropagation();
                        isFolderSelected ? e.preventDefault() : onClickFileRow(file);
                      }}
                      // onDoubleClick={() => onClickFileNext()}
                      // onTouchEnd={() => onClickFileNext()}
                    >
                      {file.fileType.includes('image') ? (
                        <>
                          <img
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '0.2rem' }}
                            src={file.fileThumb ? process.env.REACT_APP_INTERACTION_CDN_URL + file.fileThumb : process.env.REACT_APP_INTERACTION_CDN_URL + file.filePath}
                            alt="preview"
                          ></img>
                          <FileInfoArea className={activeFile.find(active => active === file.fileId) && 'active-row'}>
                            <div style={{ display: 'flex' }}>
                              <FiImage size="0.9375rem" style={{ marginTop: '0.0625rem', marginRight: '0.3125rem' }} />
                              <FileInfoText>{file.fileNm}</FileInfoText>
                            </div>
                          </FileInfoArea>
                        </>
                      ) : file.fileType.includes('video') ? (
                        <>
                          {file.fileThumb ? (
                            <img
                              style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '0.2rem' }}
                              src={process.env.REACT_APP_INTERACTION_CDN_URL + file.fileThumb}
                              alt="video preview"
                            ></img>
                          ) : (
                            <video
                              style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '0.2rem' }}
                              preload="auto"
                              muted
                              src={process.env.REACT_APP_INTERACTION_CDN_URL + file.filePath}
                              alt="video preview"
                            ></video>
                          )}
                          <FileInfoArea className={activeFile.find(active => active === file.fileId) && 'active-row'}>
                            <div style={{ display: 'flex' }}>
                              <FiFilm size="0.9375rem" style={{ marginTop: '0.0625rem', marginRight: '0.3125rem' }} />
                              <FileInfoText>{file.fileNm}</FileInfoText>
                            </div>
                          </FileInfoArea>
                        </>
                      ) : file.fileType.includes('audio') ? (
                        <>
                          <SoundIcon fill="gray" style={{ width: '100%', height: '100%' }} />
                          <FileInfoArea className={activeFile.find(active => active === file.fileId) && 'active-row'}>
                            <div style={{ display: 'flex' }}>
                              <FiFilm size="0.9375rem" style={{ marginTop: '0.0625rem', marginRight: '0.3125rem' }} />
                              <FileInfoText>{file.fileNm}</FileInfoText>
                            </div>
                          </FileInfoArea>
                        </>
                      ) : (
                        <></>
                      )}
                    </FileCardArea>
                  );
                })}
            </FileArea>
          </DataAreaBox>
        ) : (
          <Loading />
        )}
      </ModalContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .body {
    padding: 0px;
  }
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #3333333;
  font-size: 14px;
  font-weight: 500;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  padding: 1em;
`;

const FilePathWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #666666;
  margin-bottom: 1em;
`;

const FilePathArea = styled.div`
  display: flex;
  color: #666666;

  flex: 1;
  flex-wrap: wrap;

  & > div:last-child {
    color: #2a91df;
  }
`;

const StoragePathName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  padding-bottom: 12px;
  max-width: 150px;
`;

const DataAreaBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FolderFileArea = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 20px;
`;

const FileArea = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 20px;
`;

const FolderCardArea = styled.div`
  display: flex;

  width: 215px;
  height: 60px;
  align-items: center;
  color: #666666;
  background-color: #ffffff;
  border: 1px solid #999999;
  border-radius: 4px;
  margin: 5px;
  padding: 9px 5px;
  cursor: pointer;

  &.active-row {
    color: #2a91df;
    background-color: #e3f0fa;
    border: 3px solid #2a91df;
  }
`;

const FileCardArea = styled.div`
  position: relative;
  width: 215px;
  height: 120px;
  border: solid 1px #999999;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;

  &.active-row {
    border: 3px solid #2a91df;
  }
`;

const IconArea = styled.div`
  align-items: center;
  margin-right: 10px;
`;

const FileInfoText = styled.div`
  width: '21.875rem';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileInfoArea = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 210px;
  height: 24px;
  font-size: 13px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: space-between;
  border-radius: 0px 0px 3.04px 3.04px;
  /* opacity: 0.7; */
  padding: 3px 4px;
  // z-index: 2;
  top: 5.875rem;

  &.active-row {
    background-color: rgba(42, 145, 223, 0.5);
  }
`;

export default React.memo(StorageModal);
