import * as apiLib from './api';

export const getAppList = async () => {
  try {
    const result = await apiLib.fetchInteractionApi('/app/app-list-get', {});

    if (result.resultFlag) {
      return result.appList;
    } else {
      throw Object.assign(new Error('getAppList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
