import * as apiLib from './api';

export const noticeList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchInteractionApi('/board/notice-list', data);
    if (result.resultFlag) {
      return result.noticeList;
    } else {
      throw Object.assign(new Error('notice list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const noticeDetail = async ({ noticeId }) => {
  try {
    const data = {
      noticeId,
    };

    const result = await apiLib.fetchInteractionApi(
      '/board/notice-detail',
      data,
    );
    if (result.resultFlag) {
      return result.notice;
    } else {
      throw Object.assign(new Error('notice detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const releaseList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchInteractionApi(
      '/board/release-list',
      data,
    );
    if (result.resultFlag) {
      return result.releaseList;
    } else {
      throw Object.assign(new Error('release list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const releaseDetail = async ({ releaseId }) => {
  try {
    const data = {
      releaseId,
    };

    const result = await apiLib.fetchInteractionApi(
      '/board/release-detail',
      data,
    );
    if (result.resultFlag) {
      return result.release;
    } else {
      throw Object.assign(new Error('release detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaList = async ({ accountId }) => {
  try {
    const data = {
      accountId,
    };

    const result = await apiLib.fetchInteractionApi('/board/qna-list', data);
    if (result.resultFlag) {
      return result.qnaList;
    } else {
      throw Object.assign(new Error('qna list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaDetail = async ({ qnaId }) => {
  try {
    const data = {
      qnaId,
    };

    const result = await apiLib.fetchInteractionApi('/board/qna-detail', data);
    if (result.resultFlag) {
      return result.qna;
    } else {
      throw Object.assign(new Error('qna detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaAdd = async ({
  accountId,
  placeId,
  placeNm,
  userId,
  userNm,
  userTel,
  qTitle,
  qDetail,
}) => {
  try {
    const data = {
      accountId,
      placeId,
      placeNm,
      userId,
      userNm,
      userTel,
      qTitle,
      qDetail,
    };

    const result = await apiLib.fetchInteractionApi('/board/qna-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('qna add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaEdit = async ({
  qnaId,
  accountId,
  placeId,
  placeNm,
  userId,
  userNm,
  userTel,
  qTitle,
  qDetail,
}) => {
  try {
    const data = {
      qnaId,
      accountId,
      placeId,
      placeNm,
      userId,
      userNm,
      userTel,
      qTitle,
      qDetail,
    };

    const result = await apiLib.fetchInteractionApi('/board/qna-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('qna edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaCancel = async ({ qnaId }) => {
  try {
    const data = {
      qnaId,
    };

    const result = await apiLib.fetchInteractionApi('/board/qna-cancel', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('qna cancel error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaRemove = async ({ qnaId }) => {
  try {
    const data = {
      qnaId,
    };

    const result = await apiLib.fetchInteractionApi('/board/qna-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('qna remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchInteractionApi('/board/guide-list', data);
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('guide list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideFileList = async ({ guideId }) => {
  try {
    const data = {
      guideId,
    };
    
    const result = await apiLib.fetchInteractionApi('/board/guide-file-list', data);
    if (result.resultFlag) {
      return result.guideFileList;
    } else {
      throw Object.assign(new Error('guide file list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getGuideFileDownloadRequest = async ({
  fileId,
}) => {
  try {
    const data = {
      fileId: fileId,
    };

    console.log(data);

    const result = await apiLib.fetchInteractionApi(
      '/board/guide-file-presign-download',
      data,
    );
    if (result.resultFlag) {
      const url = result.url;
      const fileInfo = result.fileInfo;

      const response = await fetch(url);

      console.log(response);

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      let element = document.createElement('a');
      element.setAttribute('href', blobUrl);
      element.setAttribute('download', fileInfo.fileNm);
      element.style.display = '';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } else {
      throw Object.assign(new Error('file error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getGuideFileListDownloadRequest = async ({
  guideId,
}) => {
  try {
    const data = {
      guideId: guideId,
    };

    console.log(data);

    const result = await apiLib.fetchInteractionApi(
      '/board/guide-file-list-presign-download',
      data,
    );
    if (result.resultFlag) {
      console.log(result);
      // result.urlList.map(
      //   const url = result.url;
      //   const fileInfo = result.fileInfo;
  
      //   const response = await fetch(url);
  
      //   console.log(response);
  
      //   const blob = await response.blob();
      //   const blobUrl = window.URL.createObjectURL(blob);
      //   let element = document.createElement('a');
      //   element.setAttribute('href', blobUrl);
      //   element.setAttribute('download', fileInfo.fileNm);
      //   element.style.display = '';
      //   document.body.appendChild(element);
      //   element.click();
      //   document.body.removeChild(element);
      //   return true;
      // );
    } else {
      throw Object.assign(new Error('file error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideDetail = async ({ guideId }) => {
  try {
    const data = {
      guideId,
    };

    const result = await apiLib.fetchInteractionApi('/board/guide-detail', data);
    if (result.resultFlag) {
      return result.guide;
    } else {
      throw Object.assign(new Error('guide detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
