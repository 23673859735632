import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';

import { usePlaceDetailQuery } from '../../rtk/placeApi';
import { useLayerListQuery } from '../../rtk/layerApi';
import { useLayerContentsListQuery } from '../../rtk/layerContentsApi';
import { useOverlayListQuery } from '../../rtk/overlayApi';
import { useOverlayContentsListQuery } from '../../rtk/overlayContentsApi';
import { usePlaylistDetailQuery } from '../../rtk/playlistApi';
import { useFrameListQuery } from '../../rtk/frameApi';
import { useAppListQuery } from '../../rtk/appApi';
import { useGroupListQuery } from '../../rtk/groupApi';
import { useConfirmTokenQuery } from '../../rtk/authApi';
import { useAccountDetailQuery } from '../../rtk/accountApi';
import { useTouchLayerListQuery } from '../../rtk/touchLayerApi';
import { useTouchFrameListQuery } from '../../rtk/touchFrameApi';

import { editorAction, editorSelector } from '../../module/editorSlice';
import { ReactComponent as NoPage } from '../../assets/images/eliguy/no-page-eliguy.svg';
import { channelContentsAction } from 'module/channelContentsSlice';
import { useChannelContentsListAllQuery } from 'rtk/channelContentsApi';

const DataFetch = ({ setIsLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const placeId = useSelector(editorSelector.placeId);
  const playlistId = useSelector(editorSelector.playlistId);
  const frameId = useSelector(editorSelector.frameId);
  const touchFrameId = useSelector(editorSelector.touchFrameId);

  const { data: userInfo, isSuccess: isLogin, isError } = useConfirmTokenQuery('', { refetchOnMountOrArgChange: true });
  useAppListQuery('', { skip: !isLogin });
  useAccountDetailQuery({ accountId: userInfo?.accountId }, { refetchOnMountOrArgChange: true, skip: !isLogin });

  usePlaceDetailQuery({ placeId }, { refetchOnMountOrArgChange: true, skip: !isLogin || !placeId });
  useGroupListQuery({ placeId: placeId, groupPid: placeId }, { skip: !isLogin || !placeId });

  const { data: playlistInfo, isError: playlistError } = usePlaylistDetailQuery(
    {
      playlistId: qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }).playlistId,
    },
    { refetchOnMountOrArgChange: true, skip: !isLogin },
  );
  useFrameListQuery({ playlistId }, { refetchOnMountOrArgChange: true, skip: !playlistId });
  useOverlayListQuery({ playlistId }, { refetchOnMountOrArgChange: true, skip: !playlistId });
  useOverlayContentsListQuery({ playlistId }, { refetchOnMountOrArgChange: true, skip: !playlistId });

  useLayerListQuery({ frameId }, { refetchOnMountOrArgChange: true, skip: frameId ? false : true });
  useLayerContentsListQuery({ frameId }, { refetchOnMountOrArgChange: true, skip: frameId ? false : true });

  useTouchLayerListQuery(
    { frameId: touchFrameId },
    { refetchOnMountOrArgChange: true, skip: touchFrameId ? false : true },
  );
  useTouchFrameListQuery({ playlistId }, { refetchOnMountOrArgChange: true, skip: !playlistId });
  const { currentData: channelContentsList, isSuccess: isChannelContentsListSuccess } = useChannelContentsListAllQuery({
    placeId,
  });

  useEffect(() => {
    if (isError) {
      navigate('/account/login');
    }
  }, [dispatch, navigate, isError]);

  useEffect(() => {
    if (isChannelContentsListSuccess) {
      dispatch(editorAction.channelContentsList({ channelContentsList }));
    }
  }, [channelContentsList, dispatch, isChannelContentsListSuccess]);

  useEffect(() => {
    if (playlistInfo) {
      document.title = `ELIGA - ${playlistInfo.playlistNm}`;
      setIsLoading(false);
    } else if (playlistInfo === false) {
      setIsLoading(false);
    }
  }, [setIsLoading, playlistInfo, playlistError, navigate]);

  return (
    <>
      {playlistInfo === false && (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <NoPage width="80%" height="40%" />
        </div>
      )}
    </>
  );
};

export default React.memo(DataFetch);
