import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface CalendarAppDataState {
  calendarName: string;
  dateCheck: string;
  iCalendarUrlYn: string;
  iCalendarUrl: string;
}

interface WeatherAppDataState {
  location: string;
  degreeCheck: string;
  timeForecastYn: string;
  dayForecastYn: string;
}

interface TextAppDataState {
  fontSize: string;
  text: string;
  frameLinkYn: string;
}

interface MediaDataState {
  file: Array<any>;
  animation: string;
  zoom: string;
  fitOption: string;
}

interface LayerContentsState {
  playlistId: string;
  frameId: string;
  layerId: string;
  contentsId: string;
  contentsType: string;
  contentsData: CalendarAppDataState | WeatherAppDataState | TextAppDataState | MediaDataState;
  contentsFileList: Array<any>;
  editingMode: boolean;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

interface OnSubmitPayload {
  contentsId?: string;
  contentsType: string;
  contentsData: CalendarAppDataState | WeatherAppDataState | TextAppDataState | MediaDataState;
}

const initialState: LayerContentsState = {
  playlistId: '',
  frameId: '',
  layerId: '',
  contentsId: '',
  contentsType: '',
  contentsData: {},
  contentsFileList: [],
  editingMode: false,
  layerContentsList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'layerContents',
  initialState,
  reducers: {
    initLayerContentsState(state: LayerContentsState) {
      state.playlistId = '';
      state.frameId = '';
      state.layerId = '';
      state.contentsId = '';
      state.contentsType = '';
      state.contentsData = {};
      state.contentsFileList = [];
      state.editingMode = false;
      state.actionResult = '';
    },
    getLayerContentsList(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'GET_CONTENTS_LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getLayerContentsListSuccess(state: LayerState, { payload: { layerContentsList } }: PayloadAction<LayerState>) {
      state.layerContentsList = layerContentsList;

      state.actionResult = 'GET_CONTENTS_LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    getLayerContentsListFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'GET_CONTENTS_LIST_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    getLayerContents(state: LayerContentsState) {
      state.actionResult = 'GET_CONTENTS_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getLayerContentsSuccess(
      state: LayerContentsState,
      {
        payload: {
          playlistId,
          layerId,
          frameId = '',
          contentsId = '',
          contentsType = '',
          contentsData = {},
          contentsFileList = [],
        },
      }: PayloadAction<LayerContentsState>,
    ) {
      state.playlistId = playlistId;
      state.frameId = frameId;
      state.layerId = layerId;
      state.contentsId = contentsId;
      state.contentsType = contentsType;
      state.contentsData = contentsData;
      state.contentsFileList = contentsFileList;

      state.editingMode = false;
      state.actionResult = 'GET_CONTENTS_OK';
      state.isLoading = false;
      state.error = null;
    },
    getLayerContentsFailure(state: LayerContentsState, { payload: error }: PayloadAction<string>) {
      state.actionResult = 'GET_CONTENTS_ERR';
      state.error = error;
      state.isLoading = false;
    },
    saveLayerContents(state: LayerContentsState) {
      state.actionResult = 'CONTENTS_SAVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    saveLayerContentsSuccess(
      state: LayerContentsState,
      { payload: { contentsId, contentsType, contentsData, contentsFileList } }: PayloadAction<OnSubmitPayload>,
    ) {
      state.contentsId = contentsId;
      state.contentsType = contentsType;
      state.contentsData = contentsData;
      state.contentsFileList = contentsFileList;
      state.editingMode = false;
      state.actionResult = 'CONTENTS_SAVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    saveLayerContentsFailure(state: LayerContentsState, { payload: error }: PayloadAction<string>) {
      state.editingMode = false;
      state.actionResult = 'CONTENTS_SAVE_ERR';
      state.isLoading = false;
      state.error = error;
    },
    updateLayerContents(state: LayerContentsState) {
      state.actionResult = 'CONTENTS_UPDATE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    updateLayerContentsSuccess(
      state: LayerContentsState,
      { payload: { contentsType, contentsData, contentsFileList } }: PayloadAction<OnSubmitPayload>,
    ) {
      state.contentsType = contentsType;
      state.contentsData = contentsData;
      state.contentsFileList = contentsFileList;
      state.editingMode = false;
      state.actionResult = 'CONTENTS_UPDATE_OK';
      state.isLoading = false;
      state.error = null;
    },
    updateLayerContentsFailure(state: LayerContentsState, { payload: error }: PayloadAction<string>) {
      state.editingMode = false;
      state.actionResult = 'CONTENTS_UPDATE_ERR';
      state.isLoading = false;
      state.error = error;
    },
    removeLayerContents(state: LayerContentsState) {
      state.actionResult = 'CONTENTS_REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeLayerContentsSuccess(state: LayerContentsState) {
      state.editingMode = false;
      state.contentsId = initialState.contentsId;
      state.contentsType = initialState.contentsType;
      state.contentsData = initialState.contentsData;
      state.contentsFileList = initialState.contentsFileList;
      state.actionResult = 'CONTENTS_REMOVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    removeLayerContentsFailure(state: LayerContentsState, { payload: error }: PayloadAction<string>) {
      state.editingMode = false;
      state.actionResult = 'CONTENTS_REMOVE_ERR';
      state.isLoading = false;
      state.error = error;
    },
    editingModeLayerContents(state: LayerContentsState) {
      state.editingMode = true;
    },
    resetEditingModeLayerContents(state: LayerContentsState) {
      state.editingMode = false;
    },
  },
});

const selectLayerContentsList = createDraftSafeSelector(
  (state: LayerState) => state.layerContentsList,
  layerContentsList => layerContentsList,
);

const selectLayerContents = createDraftSafeSelector(
  (state: LayerContentsState) => state.playlistId,
  (state: LayerContentsState) => state.frameId,
  (state: LayerContentsState) => state.layerId,
  (state: LayerContentsState) => state.contentsId,
  (state: LayerContentsState) => state.contentsType,
  (state: LayerContentsState) => state.contentsData,
  (state: LayerContentsState) => state.contentsFileList,
  (playlistId, frameId, layerId, contentsId, contentsType, contentsData, contentsFileList) => ({
    playlistId,
    frameId,
    layerId,
    contentsId,
    contentsType,
    contentsData,
    contentsFileList,
  }),
);

const selectLayerOrOverlayContents = createDraftSafeSelector(
  (state: any) => state,
  state => (state.activeItem.layerType === 'OVERLAY' ? state.overlayContents : state.layerContents),
);

const selectLayerOrOverlayContentsStatus = createDraftSafeSelector(
  (state: any) => state,
  state => {
    if (state.activeItem.layerType === 'OVERLAY') {
      const { editingMode, actionResult, isLoading, error } = state.overlayContents;
      return { editingMode, actionResult, isLoading, error };
    } else {
      const { editingMode, actionResult, isLoading, error } = state.layerContents;
      return { editingMode, actionResult, isLoading, error };
    }
  },
);

const selectStatus = createDraftSafeSelector(
  (state: LayerContentsState) => state.editingMode,
  (state: LayerContentsState) => state.actionResult,
  (state: LayerContentsState) => state.isLoading,
  (state: LayerContentsState) => state.error,
  (editingMode, actionResult, isLoading, error) => ({ editingMode, actionResult, isLoading, error }),
);

export const layerContentsSelector = {
  layerContentsList: state => selectLayerContentsList(state[LAYERCONTENTS]),
  layerContents: state => selectLayerContents(state[LAYERCONTENTS]),
  layerOrOverlayContents: state => selectLayerOrOverlayContents(state),
  layerOrOverlayContentsStatus: state => selectLayerOrOverlayContentsStatus(state),
  status: state => selectStatus(state[LAYERCONTENTS]),
};

export const LAYERCONTENTS = slice.name;
export const layerContentsReducer = slice.reducer;
export const layerContentsAction = slice.actions;
