import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { authAction, authSelector } from '../../module/authSlice';
import { accountSelector, accountAction } from '../../module/accountSlice';

import { FaSpinner as LoadingIcon } from 'react-icons/fa';
import { FiChevronDown as DownArrowIcon } from 'react-icons/fi';
import { MdNotificationsActive as NoticeIcon } from 'react-icons/md';
import {
  BsPersonLinesFill as ProfileIcon,
  BsBook as GuideIcon,
  BsInfoCircle as ReleaseIcon,
  BsQuestionCircle as QnaIcon,
} from 'react-icons/bs';
import { FiPower as LogoutIcon } from 'react-icons/fi';

import ClickOutside from '../../components/ClickOutside';
import useCheckEditingMode from '../../components/useCheckEditingMode';

const ProfileBtn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector(authSelector.userInfo);
  const authStatus = useSelector(authSelector.status);
  const accountInfo = useSelector(accountSelector.accountInfo);
  const status = useSelector(accountSelector.status);

  const { editingCheck, LayerContentsWarnModal, isEditingChecking } = useCheckEditingMode();

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    dispatch(accountAction.detail({ accountId: userInfo.accountId }));
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (authStatus.actionResult === 'TOKEN_OK') {
      dispatch(authAction.actionResultClear());
    } else if (authStatus.actionResult === 'TOKEN_ERR') {
      dispatch(authAction.actionResultClear());
      navigate('/account/login');
    }
  }, [dispatch, navigate, authStatus]);

  // useEffect(() => {
  //   const timerId = setInterval(() => {
  //     // 30분 마다 토근 검사 실행
  //     dispatch(authAction.token());
  //   }, 1000 * 60 * 30);

  //   return () => {
  //     clearInterval(timerId);
  //   };
  // }, [dispatch]);

  if (status.isLoading) {
    return <LoadingIcon />;
  }

  return (
    <>
      <ClickOutside onClickOutside={() => !isEditingChecking && setIsShow(false)}>
        <Container>
          <SelectBtn onClick={() => setIsShow(!isShow)}>
            <SelectLogo>
              {accountInfo.imagePath ? (
                <img src={`${process.env.REACT_APP_CORE_CDN_URL}${accountInfo.imagePath}`} alt="" />
              ) : (
                <span>{accountInfo.accountEmail?.substring(0, 2).toUpperCase()}</span>
              )}
            </SelectLogo>
            <SelectTitle>{accountInfo.accountNm}</SelectTitle>
            <DownArrowIcon />
          </SelectBtn>
          <OptionWrapper style={isShow ? { display: 'block' } : { display: 'none' }}>
            <OptionList>
              <OptionItem
                key={'profile'}
                onClick={() =>
                  editingCheck(() => {
                    setIsShow(false);
                    navigate('/profile');
                  })
                }
              >
                <ProfileIcon />
                {t('profileBoxEdit')}
              </OptionItem>
              <OptionItem
                key={'notice'}
                onClick={() =>
                  editingCheck(() => {
                    setIsShow(false);
                    navigate('/notice/list');
                  })
                }
              >
                <NoticeIcon />
                {t('profileBoxNotice')}
              </OptionItem>
              <OptionItem
                key={'guide'}
                onClick={() =>
                  editingCheck(() => {
                    setIsShow(false);
                    navigate('/guide/list');
                  })
                }
              >
                <GuideIcon />
                {t('profileBoxGuide')}
              </OptionItem>
              <OptionItem
                key={'release'}
                onClick={() =>
                  editingCheck(() => {
                    setIsShow(false);
                    navigate('/release/list');
                  })
                }
              >
                <ReleaseIcon />
                <span>{t('profileBoxUpdate')}</span>
              </OptionItem>
              <OptionItem
                key={'qna'}
                onClick={() =>
                  editingCheck(() => {
                    setIsShow(false);
                    navigate('/qna/list');
                  })
                }
              >
                <QnaIcon />
                {t('profileBoxQna')}
              </OptionItem>
              <OptionItem
                key={'logout'}
                onClick={() =>
                  editingCheck(() => {
                    setIsShow(false);
                    navigate('/account/logout');
                  })
                }
              >
                <LogoutIcon />
                {t('profileBoxLogout')}
              </OptionItem>
            </OptionList>
          </OptionWrapper>
        </Container>
      </ClickOutside>
      <LayerContentsWarnModal />
    </>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const SelectBtn = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  outline: 0;

  & > svg {
    margin-left: 0.375rem;
  }
`;

const SelectLogo = styled.div`
  position: relative;
  width: 1.875rem;
  height: 1.875rem;
  border: 0.0625rem solid #999999;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.375rem;

  & > img {
    position: absolute;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 0.8rem;
    color: #333333;
  }
`;

const SelectTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #666666;
`;

const OptionWrapper = styled.div`
  width: 174px;
  position: absolute;
  display: flex;
  right: 10px;
  top: 40px;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
`;

const OptionList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const OptionItem = styled.li`
  padding: 0 20px;
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  cursor: pointer;
  gap: 10px;

  &:not(:first-child) {
    border-top: 1px solid rgba(60, 60, 67, 0.36);
  }

  & > svg {
    width: 20px;
  }

  & > span {
    flex: 1;
  }

  &:hover {
    background: #dddddd;
  }
`;

export default React.memo(ProfileBtn);
