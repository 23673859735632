import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';

interface LayoutState {
  menuShow: boolean;
  isLoading: boolean;
  error: string | null;
}

const layoutInitialState: LayoutState = {
  menuShow: true,
  isLoading: false,
  error: null,
};

const reducers = {
  toggleMenuShow: (state: LayoutState) => {
    state.menuShow = !state.menuShow;
  },
};

const slice = createSlice({
  name: 'layout',
  initialState: layoutInitialState,
  reducers: reducers,
});

const selectAll = createDraftSafeSelector(
  (state: LayoutState) => state.menuShow,
  (state: LayoutState) => state.isLoading,
  (state: LayoutState) => state.error,
  (menuShow, isLoading, error) => ({
    menuShow,
    isLoading,
    error,
  }),
);

export const layoutSelector = {
  all: (state) => selectAll(state[slice.name]),
};

export const LAYOUT = slice.name;
export const layoutReducer = slice.reducer;
export const layoutAction = slice.actions;
