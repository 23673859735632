import React from "react";
import styled from "styled-components";

const Scroll = ({
  paddingTop = 0,
  paddingRight = 0,
  paddingBottom = 0,
  paddingLeft = 0,
  children,
}) => {
  return (
    <ScrollBox
      padding={{ paddingTop, paddingRight, paddingBottom, paddingLeft }}
    >
      <div>
        <div>{children}</div>
      </div>
    </ScrollBox>
  );
};

const ScrollBox = styled.div`
  width: inherit;
  height: inherit;
  overflow: scroll;
  padding-top: ${({ padding }) => padding.paddingTop + "px"};
  padding-left: ${({ padding }) => padding.paddingLeft + "px"};
  display: flex;
  flex: 1;
  &::after {
    flex: none;
    content: "";
    width: ${({ padding }) => padding.paddingRight + "px"};
  }
  & > div {
    width: auto;
    height: inherit;
    &::after {
      content: "";
      display: block;
      height: ${({ padding }) => padding.paddingBottom + "px"};
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default React.memo(Scroll);
