import * as apiLib from './api';

export const login = async ({ loginType, loginId, loginPw }) => {
  try {
    const data = {
      loginType: loginType,
      loginId: loginId,
      loginPw: loginPw,
    };

    const result = await apiLib.fetchInteractionApi('/auth/create-token', data);
    if (result.resultFlag) {
      return { ...result.loginInfo, accessToken: result.accessToken };
    } else {
      let errCode = 0;
      switch (result.resultMsg) {
        case 'Error: account is not exist':
          errCode = 1000;
          break;
        case 'Error: password incorrect.':
          errCode = 1001;
          break;
        case 'Error: email is not verify.':
          errCode = 1002;
          break;
        case 'Error: google oauth incorrect.':
          errCode = 1003;
          break;
        default:
          break;
      }
      throw Object.assign(new Error('login error'), { status: 401, errCode });
    }
  } catch (err) {
    throw err;
  }
};

export const token = async ({ accessToken }) => {
  try {
    const data = {
      accessToken: accessToken,
    };

    const result = await apiLib.fetchInteractionApi(
      '/auth/confirm-token',
      data,
    );
    if (result.resultFlag) {
      return { ...result.loginInfo, accessToken: accessToken };
    } else {
      throw Object.assign(new Error('token error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const signUp = async ({
  origin,
  loginType,
  loginId,
  loginPw,
  loginNm,
}) => {
  try {
    const data = {
      origin: origin,
      loginType: loginType,
      loginId: loginId,
      loginPw: loginPw,
      loginNm: loginNm,
    };

    const result = await apiLib.fetchInteractionApi('/auth/sign-up', data);
    if (result.resultFlag) {
      return true;
    } else {
      let errCode = 0;
      switch (result.resultMsg) {
        case 'Error: email is exist.':
          errCode = 1000;
          break;
        case 'Error: google token error.':
          errCode = 1001;
          break;
        default:
          break;
      }
      throw Object.assign(new Error('sign up error'), { status: 401, errCode });
    }
  } catch (err) {
    throw err;
  }
};

export const verify = async ({ verifyToken }) => {
  try {
    const data = {
      verifyToken: verifyToken,
    };

    const result = await apiLib.fetchInteractionApi('/auth/verify', data);
    if (result.resultFlag) {
      return true;
    } else {
      let errCode = 0;
      switch (result.resultMsg) {
        case 'JsonWebTokenError: invalid signature':
          errCode = 1000;
          break;
        case 'Error: token error.':
          errCode = 1001;
          break;
        case 'Error: account error.':
          errCode = 1002;
          break;
        default:
          break;
      }
      throw Object.assign(new Error('verify error'), { status: 401, errCode });
    }
  } catch (err) {
    throw err;
  }
};

export const reset = async ({ origin, loginId }) => {
  try {
    const data = {
      origin: origin,
      loginId: loginId,
    };

    const result = await apiLib.fetchInteractionApi('/auth/reset', data);
    if (result.resultFlag) {
      return result.loginId;
    } else {
      let errCode = 0;
      switch (result.resultMsg) {
        case 'email is not exist.':
          errCode = 1000;
          break;
        case 'email is empty.':
          errCode = 1001;
          break;
        default:
          break;
      }
      throw Object.assign(new Error('reset error'), { status: 401, errCode });
    }
  } catch (err) {
    throw err;
  }
};

export const changePw = async ({ changeToken, loginPw }) => {
  try {
    const data = {
      changeToken: changeToken,
      loginPw: loginPw,
    };

    const result = await apiLib.fetchInteractionApi(
      '/auth/change-password',
      data,
    );
    if (result.resultFlag) {
      return true;
    } else {
      let errCode = 0;
      switch (result.resultMsg) {
        case 'JsonWebTokenError: invalid signature':
          errCode = 1000;
          break;
        case 'token error.':
          errCode = 1001;
          break;
        case 'account error.':
          errCode = 1002;
          break;
        default:
          break;
      }
      throw Object.assign(new Error('change password error'), {
        status: 401,
        errCode,
      });
    }
  } catch (err) {
    throw err;
  }
};
