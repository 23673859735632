import * as apiLib from './api';

export const getOverlayList = async playlistId => {
  try {
    const data = {
      playlistId,
    };

    const result = await apiLib.fetchInteractionApi('/overlay/list', data);
    if (result.resultFlag) {
      return result.overlayList;
    } else {
      throw Object.assign(new Error('getOverlayList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const addOverlay = async ({ overlay }) => {
  try {
    const data = {
      addList: [overlay],
    };

    const result = await apiLib.fetchInteractionApi('/overlay/add-list', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addOverlay error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const updateOverlay = async ({ overlayId, updateOverlay }) => {
  try {
    const data = {
      updateList: [
        {
          overlayId,
          updateInfo: updateOverlay,
        },
      ],
    };

    const result = await apiLib.fetchInteractionApi('/overlay/update-list', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('updateOverlay error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const removeOverlay = async ({ overlayId }) => {
  try {
    const data = {
      removeList: [{ overlayId }],
    };

    const result = await apiLib.fetchInteractionApi('/overlay/remove-list', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('overlayRemove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
