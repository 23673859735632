import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Tab = ({ tabIndex, tabList, tabChange, fontSize = '1.125rem' }) => {
  const navigate = useNavigate();

  /*
  const handleChangeUrl = useCallback(
    url => {
      navigate(url);
    },
    [navigate],
  );
  */

  return (
    <Container>
      <TabButtonArea>
        {tabList?.map(
          (tab, index) =>
            tab && (
              <TabButton fontSize={fontSize} className="tabButton" key={`tabIndex-${index}`} isSelected={tabIndex === index} onClick={e => tabChange(index)}>
                {tab.title}
              </TabButton>
            ),
        )}
      </TabButtonArea>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
`;

const TabButtonArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 50px;

  & > :not(:last-child) {
    margin-right: 20px;
  }
`;

const TabButton = styled.div`
  width: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: ${props => props.fontSize};
  color: ${props => (props.isSelected ? '#6A8BFF' : '#000000')};
  border-bottom: ${props => (props.isSelected ? '4px solid #6A8BFF' : 'none')};
  padding-bottom: ${props => (props.isSelected ? '10px' : '14px')};

  cursor: pointer;

  &:hover {
    color: #6a8bff;
  }
`;

export default React.memo(Tab);
