export const EDITOR_HEADER_HEIGHT = 50;
export const EDITOR_LEFT_WIDTH = 240;
export const EDITOR_RIGHT_WIDTH = 350;
export const EDITOR_ROW_PADDING = 30; // 검은영역 벌어짐 정도
export const EDITOR_COL_PADDING = 30; // 검은영역 벌어짐 정도
export const EDITOR_GUIDELINE_OFFSET = 20;
export const EDITOR_GRID_SIZE = 20;
export const EDITOR_SPLITBAR_SIZE = 3;
export const ALPHABET_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
export const getTitle = number => {
  const title = ALPHABET_LIST[number - 26 * Math.floor(number / 26)] + (Math.floor(number / 26) !== 0 ? Math.floor(number / 26) : '');
  return title;
};

export const INIT_CANVAS_INFO = {
  zoom: 100,
  originZoom: 100,
  gridMode: true,
  snapMode: true,
  lockMode: true,
  overlayShowMode: true,
  dragMode: false,
  cursorType: 'SELECT',
};

export const INIT_THUMB_INFO = {
  url: '',
  thumbId: '',
};
export const INIT_LAYER_WIDTH = 200;
export const INIT_LAYER_HEIGHT = 200;
export const CANVAS_MIN_ZOOMLEVEL = 2;
export const CANVAS_MAX_ZOOMLEVEL = 25600;
