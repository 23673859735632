import React from 'react';
import styled from 'styled-components';

const InputText = ({ onChange, value, placeholder, height, width, isError }) => {
  return <Input type="text" value={value} placeholder={placeholder} onChange={onChange} width={width} isError={isError} />;
};

const Input = styled.input`
  padding: 0.5rem;
  width: ${props => props.width};
  height: ${props => props.height};
  background: white !important ;
  outline: none;
  border: ${props => (props.isError ? '0.5px solid rgb(255 112 112)' : '0.5px solid rgb(228 228 228)')};
  border-radius: 3px;
  color: black !important;

  &::placeholder {
    color: #999999;
    font-size: 0.8rem;
    font-weight: 500;
  }
`;

export default React.memo(InputText);
