import { interactionBaseApi } from './interactionBaseApi';

export const contentsApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    contentsFileDetail: build.query({
      query: data => ({ url: '/contents/place-file-info', data }),
      transformResponse: response => {
        return response.placeFileInfo;
      },
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, useContentsFileDetailQuery } = contentsApi;
