import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface ChannelInfoState {
  placeId: string;
  channelId: String;
  channelNm: string;
  deviceCnt: string;
  useYn: string;
  tot_fileLen: string;
  tot_fileSize: string;
  regDt: string;
  regId: string;
  modId: string;
  modDt: string;
}

interface ChannelState {
  channelList: ChannelInfoState[];
  channelInfo: ChannelInfoState;
  isLoading: Boolean;
  error: string | null;
  actionResult: String;
}

// 초기 상태
const ChannelInitialState: ChannelState = {
  channelList: [],
  channelInfo: {},
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'channel',
  initialState: ChannelInitialState,
  reducers: {
    initChannelState(state: ChannelState) {
      state.actionResult = '';
    },
    getChannelList(state: ChannelState) {
      state.actionResult = 'LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getChannelListSuccess(state: ChannelState, { payload: channelList }: PayloadAction<ChannelState>) {
      state.channelList = channelList;
      state.actionResult = 'LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    getChannelListFailure(state: ChannelState, { payload: error }: PayloadAction<string>) {
      state.channelList = [];
      state.actionResult = 'LIST_ERR';
      state.isLoading = false;
      state.error = error;
    },
    getChannelInfo(state: ChannelState) {
      state.actionResult = 'DETAIL_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getChannelInfoSuccess(state: ChannelState, { payload: channelInfo }: PayloadAction<ChannelState>) {
      state.channelInfo = channelInfo;
      state.actionResult = 'DETAIL_OK';
      state.isLoading = false;
      state.error = null;
    },
    getChannelInfoFailure(state: ChannelState, { payload: error }: PayloadAction<string>) {
      state.channelList = [];
      state.actionResult = 'DETAIL_ERR';
      state.isLoading = false;
      state.error = error;
    },
    addChannelInfo(state: ChannelState) {
      state.actionResult = 'ADD_REQ';
      state.isLoading = true;
      state.error = null;
    },
    addChannelInfoSuccess(state: ChannelState, { payload: channelInfo }: PayloadAction<ChannelState>) {
      state.channelInfo = channelInfo;
      state.actionResult = 'ADD_OK';
      state.isLoading = false;
      state.error = null;
    },
    addChannelInfoFailure(state: ChannelState, { payload: error }: PayloadAction<string>) {
      state.channelList = [];
      state.actionResult = 'ADD_ERR';
      state.isLoading = false;
      state.error = error;
    },
    removeChannelInfo(state: ChannelState) {
      state.actionResult = 'REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeChannelInfoSuccess(state: ChannelState, { payload: channelInfo }: PayloadAction<ChannelState>) {
      state.actionResult = 'REMOVE_OK';
      state.isLoading = false;
      state.error = null;
      state.channelList = channelInfo;
    },
    removeChannelInfoFailure(state: ChannelState, { payload: error }: PayloadAction<string>) {
      state.channelList = [];
      state.actionResult = 'REMOVE_ERR';
      state.isLoading = false;
      state.error = error;
    },
  },
});
const selectChannelList = createDraftSafeSelector(
  (state: ChannelState) => state.channelList,
  frameList => frameList,
);
const selectChannelInfo = createDraftSafeSelector(
  (state: ChannelState) => state.channelInfo,
  channelInfo => channelInfo,
);
const selectStatus = createDraftSafeSelector(
  (state: ChannelState) => state.actionResult,
  (state: ChannelState) => state.isLoading,
  (state: ChannelState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const channelSelector = {
  channelList: state => selectChannelList(state[CHANNEL]),
  channelInfo: state => selectChannelInfo(state[CHANNEL]),
  status: state => selectStatus(state[CHANNEL]),
};

export const CHANNEL = slice.name;
export const channelReducer = slice.reducer;
export const channelAction = slice.actions;
