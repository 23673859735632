import dayjs from 'dayjs';
import { googleLogout } from '@react-oauth/google';
import { interactionBaseApi } from './interactionBaseApi';

export const authApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    createToken: build.mutation({
      query: data => ({ url: '/auth/create-token', data }),
      transformResponse: (response, meta, arg) => {
        if (response.resultFlag) {
          if (arg.idSave === 'Y') {
            localStorage.setItem('loginId', response.loginInfo.loginId);
            localStorage.setItem('idSave', 'Y');
          } else {
            localStorage.setItem('loginId', '');
            localStorage.setItem('idSave', 'N');
          }

          localStorage.setItem('accessToken', response.accessToken);

          const userInfo = { ...response.loginInfo, accessToken: response.accessToken };
          userInfo.lastLoginDt = dayjs(userInfo.lastLoginDt).format('YYYY-MM-DD HH:mm:ss');

          return userInfo;
        } else {
          throw Object.assign(new Error('login error'), { status: 401 });
        }
      },
    }),
    confirmToken: build.query({
      async queryFn(_, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        try {
          const accessToken = localStorage.getItem('accessToken');
          if (!accessToken) {
            throw Object.assign(new Error('token error'), { status: 401 });
          }

          const response = await fetchInteractionApi({ url: '/auth/confirm-token', data: { accessToken } });
          if (response.data.resultFlag) {
            const userInfo = { ...response.data.loginInfo, accessToken };
            userInfo.lastLoginDt = dayjs(userInfo.lastLoginDt).format('YYYY-MM-DD HH:mm:ss');
            return { data: userInfo };
          } else {
            throw Object.assign(new Error('token exfired'), { status: 401 });
          }
        } catch (error) {
          return { error };
        }
      },
    }),
    refreshToken: build.mutation({
      async queryFn(_, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        try {
          const accessToken = localStorage.getItem('accessToken');
          if (!accessToken) {
            throw Object.assign(new Error('token error'), { status: 401 });
          }

          const response = await fetchInteractionApi({ url: '/auth/confirm-token', data: { accessToken } });
          if (response.data.resultFlag) {
            const userInfo = { ...response.data.loginInfo, accessToken };
            userInfo.lastLoginDt = dayjs(userInfo.lastLoginDt).format('YYYY-MM-DD HH:mm:ss');
            return { data: userInfo };
          } else {
            throw Object.assign(new Error('token exfired'), { status: 401 });
          }
        } catch (error) {
          return { error };
        }
      },
    }),
    removeToken: build.mutation({
      async queryFn(arg, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        try {
          if (arg.loginType === 'google') {
            googleLogout();
          }

          localStorage.removeItem('accessToken');

          return { data: true };
        } catch (error) {
          return { error };
        }
      },
    }),
    signUp: build.mutation({
      query: data => ({ url: '/auth/sign-up', data }),
      transformResponse: (response, meta, arg) => {
        if (response.resultFlag) {
          return { data: true };
        } else {
          throw Object.assign(new Error(response.resultMsg), { status: 401 });
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateTokenMutation,
  useConfirmTokenQuery,
  useRefreshTokenMutation,
  useRemoveTokenMutation,
  useSignUpMutation,
} = authApi;
