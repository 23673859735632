import { fork, call, put, takeLatest, all } from 'redux-saga/effects';

import * as scheduleApiLib from '../lib/scheduleApi';
import { scheduleAction } from './scheduleSlice';

function* saveSchedule({ payload: scheduleData }) {
  try {
    yield call(scheduleApiLib.saveSchedule, scheduleData);
    yield put(scheduleAction.saveScheduleSuccess());
  } catch (error) {
    yield put(scheduleAction.saveScheduleFailure());
  }
}

function* editSchedule({ payload: scheduleData }) {
  try {
    yield call(scheduleApiLib.editSchedule, scheduleData);
    yield put(scheduleAction.editScheduleSuccess());
  } catch (error) {
    yield put(scheduleAction.editScheduleFailure());
  }
}

export function* watchSaveSchedule() {
  yield takeLatest(scheduleAction.saveSchedule, saveSchedule);
}
export function* watchEditSchedule() {
  yield takeLatest(scheduleAction.editSchedule, editSchedule);
}

function* rootSaga() {
  yield all([
    fork(watchSaveSchedule),
    fork(watchEditSchedule),
  ]);
}

export default rootSaga;