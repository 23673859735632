import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import { FiMenu as MenuIcon } from 'react-icons/fi';

import { configSelector, configAction } from 'module/configSlice';

import PlaceBox from './PlaceBox';
import ProfileBtn from './ProfileBtn';

const Header = props => {
  const dispatch = useDispatch();
  const langCd = useSelector(configSelector.langCd);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const handleChangeLangCd = useCallback(
    langCd => {
      dispatch(configAction.changeLangCd(langCd));
    },
    [dispatch],
  );

  return (
    <Container>
      <LeftSide>
        {!isMobile && (
          <MenuBtn
            onClick={() => {
              props.onMenuToggle();
            }}
          >
            <MenuIcon />
          </MenuBtn>
        )}
        <PlaceBox />
      </LeftSide>
      <RightSide>
        <LanguageSelect value={langCd} onChange={e => handleChangeLangCd(e.target.value)}>
          <option value="ko">한국어</option>
          <option value="en">English</option>
          <option value="jp">日本語</option>
        </LanguageSelect>
        <ProfileBtn />
      </RightSide>
    </Container>
  );
};

const Container = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
  box-shadow: 0 0.0625rem 0.625rem rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const LeftSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const MenuBtn = styled.div`
  padding: 0 0.625rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LanguageSelect = styled.select`
  padding: 2px;
  font-weight: 300;
  font-size: 16px;
  color: #333333;
  border: none;
  outline: none;
`;

export default React.memo(Header);
