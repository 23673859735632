import { all, fork, call, put, takeEvery, select } from 'redux-saga/effects';
// import { LAYER } from './layerSlice';
import { layerHistoryAction } from './layerHistorySlice';
import { ACTIVEITEM } from './activeItemSlice';

import * as layerApiLib from '../lib/layerApi';

function* initLayerHistorySaga({ payload }) {
  try {
    // 1. DB 에서 조회
    const activeFrameId = yield select(state => state[ACTIVEITEM].activeFrameId);
    const initialLayerState = yield call(layerApiLib.getLayerList, { indexId: activeFrameId });

    // 2. redux store 에서 조회 - 이전 스토어 정보가 불러와짐..
    // const initialLayerState = yield select(state => state[LAYER].layerList);

    const baseLayer = initialLayerState.find(layer => layer.baseYn === 'Y');
    const payload = {
      activeLayerId: baseLayer.layerId,
      layerState: [initialLayerState],
    };
    yield put(layerHistoryAction.initLayerHistorySuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(layerHistoryAction.initLayerHistoryFailure('Init Layer History Error'));
  }
}

export function* watchInitLayerHistory() {
  yield takeEvery(layerHistoryAction.initLayerHistory, initLayerHistorySaga);
}

function* rootSaga() {
  yield all([
    fork(watchInitLayerHistory),
  ]);
}

export default rootSaga;
