import * as apiLib from './api';

import { createThumbnailFile, getDuration } from '../utils/file';

export const getStorageFolderList = async ({ folderId }) => {
  try {
    const data = {
      folderId: folderId,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-folder-list', data);
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFolderCheckAndMake = async ({ placeId, folderPid, folderId, folderNm }) => {
  try {
    const data = {
      placeId: placeId,
      folderPid: folderPid,
      folderId: folderId,
      folderNm: folderNm,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-folder-check-and-make', data);

    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFolderAdd = async ({ placeId, folderPid, folderNm }) => {
  try {
    const data = {
      placeId: placeId,
      folderPid: folderPid,
      folderNm: folderNm,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-folder-add', data);
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFolderEdit = async ({ placeId, folderPid, folderId, folderNm }) => {
  try {
    const data = {
      placeId: placeId,
      folderPid: folderPid,
      folderId: folderId,
      folderNm: folderNm,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-folder-edit', data);
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFolderMove = async ({ folderId, folderPid }) => {
  try {
    const data = {
      folderId: folderId,
      folderPid: folderPid,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-folder-move', data);
    if (result) {
      return result;
    } else {
      throw Object.assign(new Error('folder move error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFileMove = async ({ fileId, folderId }) => {
  try {
    const data = {
      fileId: fileId,
      folderId: folderId,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-file-move', data);
    if (result) {
      return result;
    } else {
      throw Object.assign(new Error('file move error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFolderRemove = async ({ folderId }) => {
  try {
    const data = {
      folderId: folderId,
    };
    const result = await apiLib.fetchInteractionApi('/contents/storage-folder-remove', data);
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFileList = async ({ folderId }) => {
  try {
    const data = {
      folderId: folderId,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-file-list', data);
    if (result.resultFlag) {
      return result.fileList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFileDownload = async ({ fileId }) => {
  try {
    const data = {
      fileId: fileId,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-file-presign-download', data);
    if (result.resultFlag) {
      const url = result.url;
      const fileInfo = result.fileInfo;

      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      let element = document.createElement('a');
      element.setAttribute('href', blobUrl);
      element.setAttribute('download', fileInfo.fileNm);
      element.style.display = '';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } else {
      throw Object.assign(new Error('file error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFileAdd = async ({ placeId, folderId, fileDetail, fileNm, fileSize, fileType, fileDuration, fileThumbId, fileThumb }) => {
  try {
    const data = {
      placeId: placeId,
      folderId: folderId,
      fileDetail: fileDetail,
      fileNm: fileNm,
      fileSize: fileSize,
      fileType: fileType,
      fileDuration: fileDuration,
      fileThumbId: fileThumbId,
      fileThumb: fileThumb,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-file-presign-upload', data);
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageThumbAdd = async ({ folderId, thumbNm, thumbType }) => {
  try {
    const data = {
      folderId: folderId,
      thumbNm: thumbNm,
      thumbType: thumbType,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-thumb-presign-upload', data);
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFileRemove = async ({ fileId }) => {
  try {
    const data = {
      fileId: fileId,
    };

    const result = await apiLib.fetchInteractionApi('/contents/storage-file-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getStorageFileRemove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getStorageFileExistenceCheck = async ({ fileId }) => {
  try {
    const data = {
      fileId: fileId,
    };
    const result = await apiLib.fetchInteractionApi('/contents/layer-file-check', data);
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPlaceFileInfo = async placeId => {
  try {
    const data = {
      placeId: placeId,
    };

    const result = await apiLib.fetchInteractionApi('/contents/place-file-info', data);
    if (result.resultFlag) {
      return result.placeFileInfo;
    } else {
      throw Object.assign(new Error('getPlaceFileInfo api error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

////////////////////////////////////////

export const upload = async ({ fileList, placeId, folderId, handleUploadProgress }) => {
  try {
    const resultFileList = [];
    const uploadThumbFileList = [];
    const uploadFileList = [];

    for (let index = 0; index < fileList.length; index++) {
      const file = fileList[index];
      const fileDuration = await getDuration(file.fileData);
      const thumbFile = await createThumbnailFile(file.fileData);

      let fileThumbId = '';
      let fileThumb = '';
      let fileThumbSize = '';
      let fileThumbHash = '';
      if (thumbFile) {
        const thumbPresignPayload = {
          placeId,
          folderId,
          fileThumbId: file.fileId + '-thumb',
          fileThumbNm: thumbFile.name,
          fileThumbType: thumbFile.type,
          fileThumbSize: thumbFile.size,
        };
        const { url, thumbFileInfo } = await apiLib.fetchInteractionApi('/storage/thumb-presign', thumbPresignPayload);
        // resultFileList.push(thumbFileInfo);

        uploadThumbFileList.push({ url, file: thumbFile });

        // thumbFileHash = await getFileHash(thumbFile);
        fileThumbId = thumbFileInfo.fileThumbId;
        fileThumb = thumbFileInfo.fileThumb;
        fileThumbSize = thumbFileInfo.fileThumbSize;
      }

      const fileHash = '';
      const presignPayload = {
        placeId,
        folderId,
        fileId: file.fileId,
        fileNm: file.fileNm,
        fileSize: file.fileSize,
        fileType: file.fileType,
        fileOrder: file.fileOrder,
        fileHash,
        fileDuration,
        fileThumbId,
        fileThumb,
        fileThumbSize,
        fileThumbHash,
      };

      const { url, fileInfo } = await apiLib.fetchInteractionApi('/storage/presign', presignPayload);
      // uploadFileList.push({ url, file: file.fileData, onUploadProgress: handleUploadProgress, id: fileInfo.fileId });

      uploadFileList.push({ url, file: file.fileData, onUploadProgress: handleUploadProgress });

      resultFileList.push(fileInfo);
    }

    if (fileList.length > 0) {
      await Promise.all([
        ...uploadThumbFileList.map(upload => apiLib.fetchUploadApi(upload.url, upload.file)),
        ...uploadFileList.map(upload => apiLib.fetchUploadApi(upload.url, upload.file, upload.onUploadProgress, upload.file.path)),
      ]);
    }

    return resultFileList;
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ filePathList }) => {
  try {
    console.log('filePathList', filePathList);
    const data = {
      filePathList,
    };
    const result = await apiLib.fetchInteractionApi('/storage/fileList-remove', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw new Error('remove error');
    }
  } catch (err) {
    throw err;
  }
};
