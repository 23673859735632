import { editorAction } from '../module/editorSlice';
import { interactionBaseApi } from './interactionBaseApi';

export const overlayApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    overlayList: build.query({
      query: data => ({ url: '/overlay/list', data }),
      transformResponse: response => {
        const overlayList = response.overlayList;

        const sortOverlayList = overlayList.sort((lhs, rhs) => lhs.overlayOrder - rhs.overlayOrder);

        return sortOverlayList;
      },
      providesTags: (result, error, payload) => [...result.map(({ overlayId }) => ({ type: 'OVERLAY', id: overlayId })), { type: 'OVERLAY', id: 'OVERLAY-LIST' }],
    }),
    overlayAddList: build.mutation({
      query: data => ({ url: '/overlay/add-list', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'OVERLAY', id: 'OVERLAY-LIST' }],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        let patchResult = undefined;
        try {
          const { data } = await queryFulfilled;

          if (data.resultFlag) {
            const addList = data.addList.filter(add => add.overlayId);
            if (addList.length > 0) {
              const addInfo = addList[0];

              patchResult = dispatch(
                overlayApi.util.updateQueryData('overlayList', { playlistId: addInfo.playlistId }, draft => {
                  draft.push(...addList);
                }),
              );
            }
          }
        } catch {
          patchResult.undo();
        }
      },
    }),
    overlayUpdateList: build.mutation({
      query: data => ({ url: '/overlay/update-list', data }),
      invalidatesTags: [{ type: 'OVERLAY', id: 'OVERLAY-LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const playlistId = getState().editor.playlistId;

        const patchResultList = [];
        for (const update of updateList) {
          const { overlayId, updateInfo } = update;

          patchResultList.push(
            dispatch(
              overlayApi.util.updateQueryData('overlayList', { playlistId }, draft => {
                const index = draft.findIndex(overlay => overlay.overlayId === overlayId);
                draft[index] = { ...draft[index], ...updateInfo };
              }),
            ),
          );
        }
        try {
          await queryFulfilled;
        } catch {
          patchResultList.forEach(patchResult => patchResult.undo());
        }
      },
    }),
    overlayRemoveList: build.mutation({
      async queryFn({ removeList }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const playlistId = getState().editor.playlistId;

          let orderUpdateList = [];
          patchResult = dispatch(
            overlayApi.util.updateQueryData('overlayList', { playlistId }, draft => {
              dispatch(editorAction.filterSelectedLayerList({ removeList: removeList.map(remove => ({ id: remove.overlayId })) }));
              for (const remove of removeList) {
                const index = draft.findIndex(overlay => overlay.overlayId === remove.overlayId);
                draft.splice(index, 1);
              }

              orderUpdateList = draft.reduce((target, newOverlay, index) => {
                if (newOverlay.overlayOrder !== index) {
                  draft[index].overlayOrder = index;
                  target.push({
                    overlayId: newOverlay.overlayId,
                    overlayOrder: index,
                  });
                }
                return target;
              }, []);

              draft = draft.sort((lhs, rhs) => lhs.overlayOrder - rhs.overlayOrder);
            }),
          );

          if (orderUpdateList.length > 0) {
            await fetchInteractionApi({ url: '/overlay/overlay-order', data: { updateList: orderUpdateList } });
          }

          const result = await fetchInteractionApi({ url: '/overlay/remove-list', data: { removeList } });
          return result;
        } catch (error) {
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
      invalidatesTags: [{ type: 'OVERLAY', id: 'OVERLAY-LIST' }],
    }),
    overlayReOrder: build.mutation({
      query: data => ({ url: '/overlay/overlay-order', data }),
      invalidatesTags: [{ type: 'OVERLAY', id: 'OVERLAY-LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const playlistId = getState().editor.playlistId;

        const patchResult = dispatch(
          overlayApi.util.updateQueryData('overlayList', { playlistId }, draft => {
            for (const update of updateList) {
              const overlayId = update.overlayId;
              const overlayOrder = update.overlayOrder;
              const index = draft.findIndex(overlay => overlay.overlayId === overlayId);
              draft[index].overlayOrder = overlayOrder;
            }
            draft = draft.sort((lhs, rhs) => lhs.overlayOrder - rhs.overlayOrder);
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});
export const { endpoints, useOverlayListQuery, useOverlayAddListMutation, useOverlayUpdateListMutation, useOverlayRemoveListMutation, useOverlayReOrderMutation } = overlayApi;
