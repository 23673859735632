import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface FolderInfoState {
  folderId: string;
  folderPid: string;
  folderNm: string;
  placeId: string;
  folderPathNm: string;
  childFolderList: array<any>;
  childPlaylistArray: array<any>;
}

interface PlaylistInfoState {
  playlistId: string;
  placeId: string;
  folderId: string;
  defaultPlaylistYn: string;
  playlistNm: string;
  horizonResolution: string;
  verticalResolution: string;
  changeAnimation: string;
  frameRandomYn: string;
}

interface DeviceInfo {
  playlistId: string;
  playlistNm: string;
  deviceId: string;
  deviceNm: string;
}

interface PlaylistInitState {
  folderList: FolderInfoState[];
  playlistArray: PlaylistInfoState[];
  deviceList: DeviceInfo[];
  searchFilter: string;
  isLoading: boolean;
  error: string | null;
}

const initPlaylistInfo = {
  playlistId: '',
  folderId: '',
  defaultPlaylistYn: 'N',
  playlistNm: '',
  horizonResolution: '1920',
  verticalResolution: '1080',
  changeAnimation: 'none',
  frameRandomYn: 'N',
  placeId: '',
  primaryFrameId: '',
}

const playlistInitialState: PlaylistInitState = {
  folderList: [],
  playlistArray: [],
  deviceList: [],
  searchFilter: '',
  actionResult: '',
  isLoading: true,
  error: null,
};

const slice = createSlice({
  name: 'playlist',
  initialState: playlistInitialState,
  reducers: {
    initPlaylistState(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.folderList = [];
      state.playlistArray = [];
      state.deviceList = [];
      state.searchFilter = '';
      state.actionResult = '';
    },
    getPlaylistArray(state: PlaylistInitState) {
      if (state.actionResult !== 'FOLDER_LIST_REQ') { // getfolderList 동시 실행 이거나 재생목록
        state.actionResult = 'PLAYLIST_LIST_REQ';
      }
      state.isLoading = true;
      state.error = null;
    },
    getPlaylistArraySuccess(state: PlaylistInitState, { payload: playlistArray }: PayloadAction<PlaylistInitState>) {
      state.playlistArray = playlistArray;
      if (state.actionResult === 'PLAYLIST_LIST_REQ') {
        state.actionResult = 'PLAYLIST_LIST_OK';
        state.isLoading = false;
      }
      state.error = null;
    },
    getPlaylistArrayFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'PLAYLIST_LIST_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    addPlaylist(state: PlaylistInitState) {
      state.actionResult = 'PLAYLIST_ADD_REQ';
      state.isLoading = true;
      state.error = null;
    },
    addPlaylistSuccess(state: PlaylistInitState, { payload: { playlistArray, folderList } }: PayloadAction<PlaylistInitState>) {
      state.playlistArray = playlistArray;
      state.folderList = folderList;
      state.actionResult = 'PLAYLIST_ADD_OK';
      state.isLoading = false;
      state.error = null;
    },
    addPlaylistFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'PLAYLIST_ADD_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    removePlaylist(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'PLAYLIST_REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removePlaylistSuccess(state: PlaylistInitState, { payload: { playlistArray, folderList } }: PayloadAction<string>) {
      state.playlistArray = playlistArray;
      state.folderList = folderList;
      state.actionResult = 'PLAYLIST_REMOVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    removePlaylistFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'PLAYLIST_REMOVE_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    updatePlaylist(state: PlaylistInitState) {
      state.actionResult = 'PLAYLIST_UPDATE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    updatePlaylistSuccess(state: PlaylistInitState, { payload: { playlistArray, folderList } }: PayloadAction<PlaylistInfoState>) {
      state.playlistArray = playlistArray;
      state.folderList = folderList;
      state.actionResult = 'PLAYLIST_UPDATE_OK';
      state.isLoading = false;
      state.error = null;
    },
    updatePlaylistFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'PLAYLIST_UPDATE_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    getFolderList(state: PlaylistInitState) {
      state.actionResult = 'FOLDER_LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getFolderListSuccess(state: PlaylistInitState, { payload: folderList }: PayloadAction<PlaylistInitState>) {
      state.folderList = folderList;
      state.actionResult = 'FOLDER_LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    getFolderListFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'FOLDER_LIST_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    addFolder(state: PlaylistInitState) {
      state.actionResult = 'FOLDER_ADD_REQ';
      state.isLoading = true;
      state.error = null;
    },
    addFolderSuccess(state: PlaylistInitState, { payload: { playlistArray, folderList } }: PayloadAction<PlaylistInfoState>) {
      state.playlistArray = playlistArray;
      state.folderList = folderList;
      state.actionResult = 'FOLDER_ADD_OK';
      state.isLoading = false;
      state.error = null;
    },
    addFolderFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'FOLDER_ADD_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    updateFolder(state: PlaylistInitState) {
      state.actionResult = 'FOLDER_UPDATE_REQ';
      state.isLoading = false;
      state.error = null;
    },
    updateFolderSuccess(state: PlaylistInitState, { payload: { playlistArray, folderList } }: PayloadAction<PlaylistInfoState>) {
      state.playlistArray = playlistArray;
      state.folderList = folderList;
      state.actionResult = 'FOLDER_UPDATE_OK';
      state.isLoading = false;
      state.error = null;
    },
    updateFolderFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'FOLDER_UPDATE_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    removeFolder(state: PlaylistInitState) {
      state.actionResult = 'FOLDER_REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeFolderSuccess(state: PlaylistInitState, { payload: { playlistArray, folderList } }: PayloadAction<PlaylistInfoState>) {
      state.playlistArray = playlistArray;
      state.folderList = folderList;
      state.actionResult = 'FOLDER_REMOVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    removeFolderFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'FOLDER_REMOVE_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    changePlaylist(state: PlaylistInitState, { payload: { playlistId, type, value } }: PayloadAction<boolean>) {
      const index = state.playlistArray.findIndex(playlist => playlist.playlistId === playlistId);
      state.playlistArray[index][type] = value;
    },
    changeSearchFilter(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.searchFilter = payload;
    },
    actionResultClear(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = '';
    },
  },
});

const selectPlaylist = createDraftSafeSelector(
  (state: any) => state,
  state =>
    state.playlist.playlistArray.find(
      playlist => playlist.playlistId === state.activeItem.activePlaylistId,
    ) || initPlaylistInfo,
);

const selectFolderList = createDraftSafeSelector(
  (state: PlaylistInitState) => state.folderList,
  folderList => folderList,
);

const selectPlaylistArray = createDraftSafeSelector(
  (state: PlaylistInitState) => state.playlistArray,
  playlistArray => playlistArray,
);

const selectDeviceList = createDraftSafeSelector(
  (state: PlaylistState) => state.deviceList,
  (deviceList) => deviceList,
);

const selectSearchFilter = createDraftSafeSelector(
  (state: PlaylistState) => state.searchFilter,
  (searchFilter) => searchFilter,
);

const selectStatus = createDraftSafeSelector(
  (state: PlaylistState) => state.actionResult,
  (state: PlaylistState) => state.isLoading,
  (state: PlaylistState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error })
);

export const playlistSelector = {
  playlist: state => selectPlaylist(state),
  playlistArray: state => selectPlaylistArray(state[PLAYLIST]),
  folderList: state => selectFolderList(state[PLAYLIST]),
  searchFilter: state => selectSearchFilter(state[PLAYLIST]),
  deviceList: state => selectDeviceList(state[PLAYLIST]),
  status: state => selectStatus(state[PLAYLIST]),
};

export const PLAYLIST = slice.name;
export const playlistReducer = slice.reducer;
export const playlistAction = slice.actions;
