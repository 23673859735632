import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { FiArrowLeft as PrevIcon, FiArrowRight as NextIcon, FiHome as HomeIcon } from 'react-icons/fi';

const Navigation = ({ naviData }) => {
  const getNavigationButton = useCallback((type, index) => {
    switch (type) {
      case 'home':
        return (
          <li
            key={index}
            onClick={e => {
              e.stopPropagation();
              // navFunction.jumpToFrame();
            }}
          >
            <HomeIcon className={'navigation-icon'} />
            <span className={'navigation-text'}>처음</span>
          </li>
        );
      case 'prev':
        return (
          <li
            key={index}
            onClick={e => {
              e.stopPropagation();
              // navFunction.moveToPrevFrame();
            }}
            // className={navFunction.isFirstFrameOfHistory() ? 'inactive' : null}
          >
            <PrevIcon className={'navigation-icon'} />
            <span className={'navigation-text'}>뒤로</span>
          </li>
        );
      case 'next':
        return (
          <li
            key={index}
            onClick={e => {
              e.stopPropagation();
              // navFunction.moveToNextFrame();
            }}
            // className={navFunction.isLastFrameOfHistory() ? 'inactive' : null}
          >
            <NextIcon className={'navigation-icon'} />
            <span className={'navigation-text'}>앞으로</span>
          </li>
        );
      default:
        return;
    }
  }, []);

  return <NavigationBox buttonRadius={naviData.buttonRadius}>{naviData.naviList.map((navi, index) => getNavigationButton(navi.id, index))}</NavigationBox>;
};

const NavigationBox = styled.ul`
  margin: auto;
  width: auto;
  height: auto;
  li {
    width: 100px;
    height: 110px;
    box-sizing: border-box;
    background-color: rgba(51, 51, 51, 0.7);
    border-bottom: rgba(153, 153, 153, 0.7) 1px solid;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:first-child {
      ${({ buttonRadius }) =>
        buttonRadius !== 'left' &&
        css`
          border-top-right-radius: 15px;
        `}
      ${({ buttonRadius }) =>
        buttonRadius !== 'right' &&
        css`
          border-top-left-radius: 15px;
        `}
    }
    &:last-child {
      border-bottom: 0px;
      ${({ buttonRadius }) =>
        buttonRadius !== 'left' &&
        css`
          border-bottom-right-radius: 15px;
        `}
      ${({ buttonRadius }) =>
        buttonRadius !== 'right' &&
        css`
          border-bottom-left-radius: 15px;
        `}
    }
    .navigation-icon {
      width: 45px;
      height: 45px;
    }
    .navigation-text {
      font-size: 18px;
      line-height: 30px;
      margin-top: 5px;
    }
  }
  .inactive {
    color: rgba(99, 99, 99, 0.7);
  }
`;

export default React.memo(Navigation);
