import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { accountSelector } from '../../../../module/accountSlice';
import { endpoints as endpointsAppApi } from '../../../../rtk/appApi';

import useFileUpload from '../../../../newComponents/useFileUpload';
import Button from '../../../../newComponents/Button';
import CreateConfirmModal from './modal/CreateConfirmModal';
import RemoveConfirmModal from './modal/RemoveConfirmModal';
import UpdateConfirmModal from './modal/UpdateConfirmModal';
import { useDispatch } from 'react-redux';
import { editorAction, editorSelector } from '../../../../module/editorSlice';

const AppButtonArea = ({
  formData,
  addFileList = [],
  removeFileList = [],
  cancelApp,
  checkValidDate,
  selectedLayerType,
  noConform = false,
}) => {
  const [isCreateLayerContentsConfirmModal, setIsCreateLayerContentsConfirmModal] = useState(false);
  const [isRemoveLayerContentsConfirmModal, setIsRemoveLayerContentsConfirmModal] = useState(false);
  const [isUpdateLayerContentsConfirmModal, setIsUpdateLayerContentsConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const { UploadProgressModal, handleUploadProgress, handleUploadStart, handleUploadEnd } = useFileUpload();
  const { accountId } = useSelector(accountSelector.accountInfo);
  const currentFrameId = useSelector(editorSelector.frameId);
  const { data: appList } = endpointsAppApi.appList.useQueryState('');
  const closeModal = useCallback(() => {
    if (isCreateLayerContentsConfirmModal) setIsCreateLayerContentsConfirmModal(false);
    if (isRemoveLayerContentsConfirmModal) setIsRemoveLayerContentsConfirmModal(false);
    if (isUpdateLayerContentsConfirmModal) setIsUpdateLayerContentsConfirmModal(false);
  }, [isCreateLayerContentsConfirmModal, isRemoveLayerContentsConfirmModal, isUpdateLayerContentsConfirmModal]);

  // 앱 만들기 수정시 로컬스토리지에 히스토리 저장
  const saveHistoryToLocalStorage = useCallback(
    contentsType => {
      const history = JSON.parse(localStorage.getItem('layerContents.history' + accountId)) || {};
      let appHistoryList = history.appHistoryList || [];

      // 중복 방지
      if (appHistoryList.length > 0) {
        const findIndex = appHistoryList.findIndex(app => app.appType === contentsType);
        if (findIndex !== -1) {
          appHistoryList.splice(findIndex, 1);
        }
      }

      const findApp = appList.find(app => app.appType === contentsType);
      appHistoryList.unshift(findApp);

      if (appHistoryList.length > 4) {
        // 히스토리 최근 4개까지만 기록
        appHistoryList.pop();
      }

      appHistoryList = Array.from(new Set(appHistoryList));
      localStorage.setItem('layerContents.history' + accountId, JSON.stringify({ ...history, appHistoryList }));
    },
    [appList, accountId],
  );

  const openModal = useCallback(
    type => {
      const validFlag = checkValidDate ? checkValidDate() : false;
      const contentsId = formData.contentsId;
      if (type === 'CREATE' && !validFlag) {
        setIsCreateLayerContentsConfirmModal(true);
      } else if (type === 'UPDATE' && !validFlag) {
        setIsUpdateLayerContentsConfirmModal(true);
      } else if (type === 'REMOVE' && contentsId !== '') {
        setIsRemoveLayerContentsConfirmModal(true);
      }
    },
    [checkValidDate, formData.contentsId],
  );
  return (
    <>
      <ButtonArea>
        {formData.contentsId === '' ? (
          <>
            <Button color={'gray700'} outline="true" onClick={() => cancelApp()}>
              취소
            </Button>
            {!noConform && (
              <Button outline="true" onClick={() => openModal('CREATE')}>
                만들기
              </Button>
            )}
          </>
        ) : (
          <>
            <Button color={'gray700'} outline="true" onClick={() => openModal('REMOVE')}>
              삭제
            </Button>
            {!noConform && (
              <Button outline="true" onClick={() => openModal('UPDATE')}>
                수정
              </Button>
            )}
          </>
        )}
      </ButtonArea>
      <CreateConfirmModal
        formData={formData}
        addFileList={addFileList}
        isOpen={isCreateLayerContentsConfirmModal}
        closeModal={closeModal}
        handleUploadProgress={handleUploadProgress}
        handleUploadStart={handleUploadStart}
        handleUploadEnd={handleUploadEnd}
        saveHistoryToLocalStorage={saveHistoryToLocalStorage}
        selectedLayerType={selectedLayerType}
      />
      <RemoveConfirmModal
        contentsId={formData.contentsId}
        isOpen={isRemoveLayerContentsConfirmModal}
        closeModal={closeModal}
        selectedLayerType={selectedLayerType}
      />
      <UpdateConfirmModal
        formData={formData}
        addFileList={addFileList}
        removeFileList={removeFileList}
        isOpen={isUpdateLayerContentsConfirmModal}
        closeModal={closeModal}
        handleUploadProgress={handleUploadProgress}
        handleUploadStart={handleUploadStart}
        handleUploadEnd={handleUploadEnd}
        saveHistoryToLocalStorage={saveHistoryToLocalStorage}
        selectedLayerType={selectedLayerType}
      />

      {UploadProgressModal}
    </>
  );
};

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3.4rem;
  min-height: 3rem;
  border-top: 1px solid #efeff4;
  padding-right: 25px;
`;

export default React.memo(AppButtonArea);
