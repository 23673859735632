import { interactionBaseApi } from './interactionBaseApi';

export const channelApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    channelList: build.query({
      query: data => ({ url: '/channel/list', data }),
      transformResponse: response => {
        const channelList = response.channelList;
        return channelList;
      },
      providesTags: [{ type: 'CHANNEL', id: 'LIST' }],
    }),
    channelInfo: build.query({
      query: data => ({ url: '/channel/info', data }),
      transformResponse: response => {
        const channelInfo = response.channelInfo;
        return channelInfo;
      },
      providesTags: (result, error, payload) => [{ type: 'CHANNELINFO', id: 'INFO' }],
    }),
    channelAdd: build.mutation({
      query: data => ({ url: '/channel/add', data }),
    }),
    channelRemove: build.mutation({
      query: data => ({ url: '/channel/remove', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'CHANNEL', id: payload.channelId }],
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, useChannelAddMutation, useChannelInfoQuery, useChannelListQuery, useChannelRemoveMutation } =
  channelApi;
