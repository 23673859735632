import React, { useEffect, Suspense } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

const loading = () => <div className="text-center"></div>;

const Auth = props => {
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname.indexOf('/account/login') === 0) {
      document.title = 'ELIGA - 로그인';
    } else if (pathname.indexOf('/account/logout') === 0) {
      document.title = 'ELIGA - 로그아웃';
    } else if (pathname.indexOf('/account/signup') === 0) {
      document.title = 'ELIGA - 회원가입';
    } else if (pathname.indexOf('/account/verify') === 0) {
      document.title = 'ELIGA - 이메일 인증';
    } else if (pathname.indexOf('/account/forget') === 0) {
      document.title = 'ELIGA - 비밀번호 찾기';
    } else if (pathname.indexOf('/account/change-password') === 0) {
      document.title = 'ELIGA - 비밀번호 변경';
    } else {
      document.title = 'ELIGA';
    }
  }, [location]);

  return (
    <Suspense fallback={loading()}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}>
        <Outlet />
      </GoogleOAuthProvider>
    </Suspense>
  );
};

export default Auth;
