import React from 'react';
import styled, { css } from 'styled-components';

const AppTitle = ({ text }) => {
  return <AppTitleWrap>{text}</AppTitleWrap>;
};

const AppTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // color: #333333;
  font-size: 0.77rem;
  font-weight: 500;
  // padding-bottom: 8px;
  padding: 1rem 1.2rem;
  color: rgb(136 136 136);
`;

export default React.memo(AppTitle);
