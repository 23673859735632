import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { CONTENTS, contentsAction } from './contentsSlice';
import { ACCOUNT } from './accountSlice';

import * as apiLib from '../lib/api';
import * as contentsApiLib from '../lib/contentsApi';

function* storageFolderPathInit({ payload }) {
  try {
    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
    yield put(contentsAction.storageFileList(folderPath));
    // yield put(contentsAction.storageMoveFolderList(folderPath));
    // yield put(contentsAction.storageMoveFileList(folderPath));
  } catch (err) {}
}

function* storageMoveFolderPathInit({ payload }) {
  try {
    const folderPath = yield select(state => state[CONTENTS].storageMoveFolderPath);
    // yield put(contentsAction.storageFolderList(folderPath));
    // yield put(contentsAction.storageFileList(folderPath));
    yield put(contentsAction.storageMoveFolderList(folderPath));
    yield put(contentsAction.storageMoveFileList(folderPath));
  } catch (err) {}
}

function* storageFolderPathBack({ payload }) {
  try {
    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
    yield put(contentsAction.storageFileList(folderPath));
  } catch (err) {}
}

function* storageFolderPathUpper({ payload }) {
  try {
    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
    yield put(contentsAction.storageFileList(folderPath));
  } catch (err) {}
}


function* storageFolderPathLower({ payload }) {
  try {
    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
    yield put(contentsAction.storageFileList(folderPath));
  } catch (err) {}
}

function* storageFolderList({ payload }) {
  try {
    const folderList = yield call(contentsApiLib.getStorageFolderList, payload);
    yield put(
      contentsAction.storageFolderListSuccess({
        storageFolderList: folderList,
      }),
    );
  } catch (err) {
    yield put(contentsAction.storageFolderListFailure('Folder List Error'));
  }
}

function* storageMoveFolderPathUpper({ payload }) {
  try {
    const folderPath = yield select(state => state[CONTENTS].storageMoveFolderPath);
    yield put(contentsAction.storageMoveFolderList(folderPath));
    yield put(contentsAction.storageMoveFileList(folderPath));
  } catch (err) {}
}

function* storageMoveFolderPathLower({ payload }) {
  try {
    const folderPath = yield select(state => state[CONTENTS].storageMoveFolderPath);
    yield put(contentsAction.storageMoveFolderList(folderPath));
    yield put(contentsAction.storageMoveFileList(folderPath));
  } catch (err) {}
}

function* storageMoveFolderList({ payload }) {
  try {
    const folderList = yield call(contentsApiLib.getStorageFolderList, payload);
    yield put(
      contentsAction.storageMoveFolderListSuccess({
        storageMoveFolderList: folderList,
      }),
    );
  } catch (err) {
    yield put(contentsAction.storageMoveFolderListFailure('Move Folder List Error'));
  }
}

function* storageFolderCheckAndMake({ payload }) {
  try {
    yield call(contentsApiLib.getStorageFolderCheckAndMake, payload);
    yield put(contentsAction.storageFolderCheckAndMakeSuccess());

    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
  } catch (error) {
    yield put(contentsAction.storageFolderCheckAndMakeFailure(error));
  }
}

function* storageFolderAdd({ payload }) {
  try {
    yield call(contentsApiLib.getStorageFolderAdd, payload);
    yield put(contentsAction.storageFolderAddSuccess());

    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
  } catch (error) {
    yield put(contentsAction.storageFolderAddFailure());
  }
}

function* storageFolderEdit({ payload }) {
  try {
    yield call(contentsApiLib.getStorageFolderEdit, payload);
    yield put(contentsAction.storageFolderEditSuccess());
    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
  } catch (error) {
    yield put(contentsAction.storageFolderEditFailure());
  }
}

function* storageFolderMove({ payload: { folderId, folderPid, fromStorage } }) {
  try {
    const data = {
      folderId:folderId,
      folderPid: folderPid,
    };

    yield call(contentsApiLib.getStorageFolderMove, data);
    yield put(contentsAction.storageFolderMoveSuccess());
    
    if (fromStorage) {
      const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
      yield put(contentsAction.storageFolderList(folderPath));
      yield put(contentsAction.storageFileList(folderPath));
      yield put(contentsAction.initMoveFolderState());
    }
  } catch (error) {
    yield put(contentsAction.storageFolderMoveFailure());
  }
}

function* storageFolderListMove({ payload: { folderIdList, folderPid, fromStorage } }) {
  for (let id of folderIdList) {
    try{
      const data = {
        folderId: id,
        folderPid: folderPid,
      };
  
      yield call(contentsApiLib.getStorageFolderMove, data);
    } catch(error) {
      yield put(contentsAction.storageFolderListMoveFailure());
    }
  }

  yield put(contentsAction.storageFolderListMoveSuccess());
  
  if (fromStorage) {
    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
    yield put(contentsAction.storageFileList(folderPath));
    yield put(contentsAction.initMoveFolderState());
  }
}

function* storageFileMove({ payload: { fileId, folderId, fromStorage } }) {
  try {
    const data = {
      fileId:fileId,
      folderId: folderId,
    };

    yield call(contentsApiLib.getStorageFileMove, data);
    yield put(contentsAction.storageFileMoveSuccess());
    
    if (fromStorage) {
      const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
      yield put(contentsAction.storageFolderList(folderPath));
      yield put(contentsAction.storageFileList(folderPath));
      yield put(contentsAction.initMoveFolderState());
    }
  } catch (error) {
    yield put(contentsAction.storageFileMoveFailure());
  }
}

function* storageFileListMove({ payload: { fileIdList, folderId, fromStorage } }) {
  for (let id of fileIdList) {
    try{
      const data = {
        fileId: id,
        folderId: folderId,
      };
  
      yield call(contentsApiLib.getStorageFileMove, data);
    } catch(error) {
      yield put(contentsAction.storageFileListMoveFailure());
    }
  }

  yield put(contentsAction.storageFileListMoveSuccess());
  
  if (fromStorage) {
    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
    yield put(contentsAction.storageFileList(folderPath));
    yield put(contentsAction.initMoveFolderState());
  }
}

function* storageFolderRemove({ payload }) {
  try {
    yield call(contentsApiLib.getStorageFolderRemove, payload);
    yield put(contentsAction.storageFolderRemoveSuccess());

    // 기존: 폴더 삭제 시 부모 폴더로 이동
    // yield put(contentsAction.storageFolderPathBack());

    // 현재: 폴더 삭제 시 현재 폴더에 남아있음
    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFolderList(folderPath));
  } catch (error) {
    yield put(contentsAction.storageFolderRemoveFailure());
  }
}

function* storageFolderListRemove({ payload: { folderIdList } }) {
  for (let id of folderIdList) {
    try {
      const data = {
        folderId: id,
      };
      yield call(contentsApiLib.getStorageFolderRemove, data);
    } catch (error) {
      yield put(contentsAction.storageFolderListRemoveFailure());
      return;
    }
  }

  yield put(contentsAction.storageFolderListRemoveSuccess());
  const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
  yield put(contentsAction.storageFolderList(folderPath));
}

function* storageFileList({ payload }) {
  try {
    const fileList = yield call(contentsApiLib.getStorageFileList, payload);
    yield put(
      contentsAction.storageFileListSuccess({
        storageFileList: fileList,
      }),
    );
  } catch (err) {
    yield put(contentsAction.storageFileListFailure('File List Error'));
  }
}

function* storageMoveFileList({ payload }) {
  try {
    const fileList = yield call(contentsApiLib.getStorageFileList, payload);
    yield put(
      contentsAction.storageMoveFileListSuccess({
        storageMoveFileList: fileList,
      }),
    );
  } catch (err) {
    yield put(contentsAction.storageMoveFileListFailure('Move File List Error'));
  }
}

function* getStorageFileDownload({ payload }) {
  try {
    yield call(contentsApiLib.getStorageFileDownload, payload);
    yield put(contentsAction.storageFileDownloadSuccess());
  } catch (err) {
    yield put(contentsAction.storageFileDownloadFailure('getStorageFileDownload Error'));
  }
}

function* storageFileAdd({
  payload: { placeId, folderId, fileDetail, files, durationList, thumbList, onUploadProgress },
}) {
  yield put(contentsAction.storageFileUploadMode(1));

  for (let i = 0; i < files.length; i++) {
    try {
      let data = {
        placeId: placeId,
        folderId: folderId,
        fileDetail: fileDetail,
        fileNm: files[i].name,
        fileSize: files[i].size,
        fileType: files[i].type,
        fileDuration: durationList[i],
      };

      if (thumbList[i] !== '') {
        const thumbNm = thumbList[i].name;
        const thumbType = thumbList[i].type;
        const thumbData = {
          folderId: folderId,
          thumbNm: thumbNm,
          thumbType: thumbType,
        }
        const thumbResult = yield call(contentsApiLib.getStorageThumbAdd, thumbData);
        const thumbPresignUrl = thumbResult.url;
        yield call(apiLib.fetchUploadApi, thumbPresignUrl, thumbList[i]);
        data.fileThumbId = thumbResult.thumbInfo.thumbId;
        data.fileThumb = thumbResult.thumbInfo.thumbPath;
      }

      const result = yield call(contentsApiLib.getStorageFileAdd, data);
      const presignUrl = result.url;
      
      const uploadData = new FormData();
      uploadData.append('file', files[i], files[i].name);
      yield call(apiLib.fetchUploadApi, presignUrl, files[i], onUploadProgress, i);

    } catch (error) {
      yield put(contentsAction.storageFileUploadMode(5));
      yield put(contentsAction.storageFileAddFailure());
      return;
    }
  }
  yield put(contentsAction.storageFileUploadMode(4));
  yield put(contentsAction.storageFileAddSuccess());
  
  const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
  yield put(contentsAction.storageFileList(folderPath));

  yield put(contentsAction.getPlaceFileInfo());
}

function* storageFileRemove({ payload }) {
  try {
    yield call(contentsApiLib.getStorageFileRemove, payload);
    yield put(contentsAction.storageFileRemoveSuccess());

    const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
    yield put(contentsAction.storageFileList(folderPath));

    yield put(contentsAction.getPlaceFileInfo());
  } catch (error) {
    yield put(contentsAction.storageFileRemoveFailure());
  }
}

function* storageFileListRemove({ payload: { fileIdList } }) {
  for (let id of fileIdList) {
    try {
      const data = {
        fileId: id,
      };
      yield call(contentsApiLib.getStorageFileRemove, data);
    } catch (error) {
      yield put(contentsAction.storageFileListRemoveFailure());
      return;
    }
  }

  yield put(contentsAction.storageFileListRemoveSuccess());
  const folderPath = yield select(state => state[CONTENTS].storageFolderPath);
  yield put(contentsAction.storageFileList(folderPath));
}

function* getPlaceFileInfo({ payload: placeId }) {
  try {
    const accountInfo = yield select(state => state[ACCOUNT].accountInfo);

    if (accountInfo.viewPlaceId) {
      const placeFileInfo = yield call(contentsApiLib.getPlaceFileInfo, accountInfo.viewPlaceId);
      yield put(contentsAction.getPlaceFileInfoSuccess(placeFileInfo));
    }
  } catch (err) {
    yield put(contentsAction.getPlaceFileInfoFailure('getPlaceFileInfo Error'));
  }
}

export function* watchFolderPathInit() {
  yield takeLatest(contentsAction.storageFolderPathInit, storageFolderPathInit);
}

export function* watchMoveFolderPathInit() {
  yield takeLatest(contentsAction.storageMoveFolderPathInit, storageMoveFolderPathInit);
}

export function* watchFolderPathBack() {
  yield takeLatest(contentsAction.storageFolderPathBack, storageFolderPathBack);
}

export function* watchFolderPathUpper() {
  yield takeLatest(
    contentsAction.storageFolderPathUpper,
    storageFolderPathUpper,
  );
}

export function* watchFolderPathLower() {
  yield takeLatest(
    contentsAction.storageFolderPathLower,
    storageFolderPathLower,
  );
}

export function* watchFolderList() {
  yield takeLatest(contentsAction.storageFolderList, storageFolderList);
}
  
export function* watchMoveFolderPathUpper() {
  yield takeLatest(
    contentsAction.storageMoveFolderPathUpper,
    storageMoveFolderPathUpper,
  );
}

export function* watchMoveFolderPathLower() {
  yield takeLatest(
    contentsAction.storageMoveFolderPathLower,
    storageMoveFolderPathLower,
  );
}

export function* watchMoveFolderList() {
  yield takeLatest(contentsAction.storageMoveFolderList, storageMoveFolderList);
}

export function* watchFolderCheckAndMake() {
  yield takeLatest(contentsAction.storageFolderCheckAndMake, storageFolderCheckAndMake);
}

export function* watchFolderAdd() {
  yield takeLatest(contentsAction.storageFolderAdd, storageFolderAdd);
}

export function* watchFolderEdit() {
  yield takeLatest(contentsAction.storageFolderEdit, storageFolderEdit);
}

export function* watchFolderMove() {
  yield takeLatest(contentsAction.storageFolderMove, storageFolderMove);
}

export function* watchFolderListMove() {
  yield takeLatest(contentsAction.storageFolderListMove, storageFolderListMove);
}

export function* watchFileMove() {
  yield takeLatest(contentsAction.storageFileMove, storageFileMove);
}

export function* watchFileListMove() {
  yield takeLatest(contentsAction.storageFileListMove, storageFileListMove);
}

export function* watchFolderRemove() {
  yield takeLatest(contentsAction.storageFolderRemove, storageFolderRemove);
}

export function* watchFolderListRemove() {
  yield takeLatest(contentsAction.storageFolderListRemove, storageFolderListRemove);
}

export function* watchFileList() {
  yield takeLatest(contentsAction.storageFileList, storageFileList);
}

export function* watchMoveFileList() {
  yield takeLatest(contentsAction.storageMoveFileList, storageMoveFileList);
}

export function* watchFileDownload() {
  yield takeLatest(contentsAction.storageFileDownloadRequest, getStorageFileDownload);
}

export function* watchFileAdd() {
  yield takeLatest(contentsAction.storageFileAdd, storageFileAdd);
}

export function* watchFileRemove() {
  yield takeLatest(contentsAction.storageFileRemove, storageFileRemove);
}

export function* watchFileListRemove() {
  yield takeLatest(contentsAction.storageFileListRemove, storageFileListRemove);
}

export function* watchPlaceFileInfo() {
  yield takeLatest(contentsAction.getPlaceFileInfo, getPlaceFileInfo);
}

function* rootSaga() {
  yield all([
    fork(watchFolderPathInit),
    fork(watchMoveFolderPathInit),
    fork(watchFolderPathBack),
    fork(watchFolderPathUpper),
    fork(watchFolderPathLower),
    fork(watchFolderList),
    fork(watchMoveFolderList),
    fork(watchMoveFolderPathUpper),
    fork(watchMoveFolderPathLower),
    fork(watchFolderCheckAndMake),
    fork(watchFolderAdd),
    fork(watchFolderEdit),
    fork(watchFolderMove),
    fork(watchFolderListMove),
    fork(watchFileMove),
    fork(watchFileListMove),
    fork(watchFolderRemove),
    fork(watchFolderListRemove),
    fork(watchFileList),
    fork(watchMoveFileList),
    fork(watchFileDownload),
    fork(watchFileAdd),
    fork(watchFileRemove),
    fork(watchFileListRemove),
    fork(watchPlaceFileInfo),
  ]);
}

export default rootSaga;
