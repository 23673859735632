import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface DevicePayload {
  placeId: string;
  groupId: string;
  deviceId: string;
  pairingId: string;
  playlistId: string;
  deviceList: DevicePayload[];
}

interface ScrshotPayload {
  deviceId: string;
  scrshotDt: string;
  page: number;
}

interface FilterInfo {
  groupId: string;
  deviceNm: string;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
}

interface DeviceInfo {
  placeId: string;
  deviceId: string;
  deviceNm: string;
}

interface PairingInfo {
  placeId: string;
  licenseId: string;
  deviceId: string;
}

interface ScrshotInfo {
  pageIndex: number;
  page: number;
  pages: number;
  listCount: number;
  totalCount: number;
  deviceId: string;
  regDt: string;
  scrshotFile: string;
}

interface DeviceState {
  filterInfo: FilterInfo;
  pageInfo: PageInfo;
  deviceInfo: DeviceInfo;
  lastDeviceKey?: {
    placeId: string,
    deviceId: string,
  };
  deviceList: Array<DeviceInfo>;
  scrshotList: Array<ScrshotInfo>;
  pairingInfo: PairingInfo;
  remoteUrl: string;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const deviceInitialState: DeviceState = {
  filterInfo: {},
  pageInfo: { rowsPerPage: 10, curPage: 1 },
  deviceInfo: {},
  deviceList: [],
  scrshotList: [],
  pairingInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  initDeviceList: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.filterInfo = {};
    state.pageInfo = { rowsPerPage: 10, curPage: 1 };
    state.deviceList = [];
    state.actionResult = '';
  },
  deviceFilter(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.filterInfo.groupId = payload.groupId;
    state.filterInfo.deviceNm = payload.deviceNm;
  },
  devicePage: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.pageInfo.rowsPerPage = payload.rowsPerPage;
    state.pageInfo.curPage = payload.curPage;
  },
  deviceList: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceListSuccess: (state: DeviceState, { payload }: PayloadAction<DeviceState>) => {
    state.deviceList = payload.deviceList;
    state.lastDeviceKey = payload.lastKey;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceListFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceInfo: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.actionResult = 'INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceInfoSuccess: (state: DeviceState, { payload }: PayloadAction<DeviceState>) => {
    state.deviceInfo = payload.deviceInfo;
    state.actionResult = 'INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceInfoFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'INFO_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceInfoClear: (state: DeviceState) => {
    state.deviceInfo = {};
  },
  deviceAdd: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceAddSuccess: (state: DeviceState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceAddFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceEdit: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceEditSuccess: (state: DeviceState, { payload }: PayloadAction<DeviceState>) => {
    state.deviceInfo = {
      ...state.deviceInfo,
      ...payload.deviceInfo,
    };
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceEditFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceRemove: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceRemoveSuccess: (state: DeviceState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceRemoveFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceCommand(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'COMMAND_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceCommandSuccess(state: DeviceState) {
    state.actionResult = 'COMMAND_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceCommandFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'COMMAND_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  deviceCommandSocket(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'COMMAND_SOCKET_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceCommandSocketSuccess(state: DeviceState) {
    state.actionResult = 'COMMAND_SOCKET_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceCommandSocketFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'COMMAND_SOCKET_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  devicePowerUpdate(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'POWER_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  devicePowerUpdateSuccess(state: DeviceState) {
    state.actionResult = 'POWER_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  devicePowerUpdateFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'POWER_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  pairing: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.isLoading = true;
    state.error = null;
  },
  pairingSuccess: (state: DeviceState, { payload }: PayloadAction<DeviceState>) => {
    state.pairingInfo = payload.pairingInfo;
    state.isLoading = false;
    state.error = null;
  },
  pairingFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  pairingUnset: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.isLoading = true;
    state.error = null;
  },
  pairingUnsetSuccess(state: DeviceState) {
    state.actionResult = 'PAIRING_UNSET_OK';
    state.isLoading = false;
    state.error = null;
  },
  pairingUnsetFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'PAIRING_UNSET_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  stop(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'STOP_REQ';
    state.isLoading = true;
    state.error = null;
  },
  stopSuccess(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'STOP_OK';
    state.isLoading = false;
    state.error = null;
  },
  stopFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'STOP_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  play(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'PLAY_REQ';
    state.isLoading = true;
    state.error = null;
  },
  playSuccess(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'PLAY_OK';
    state.isLoading = false;
    state.error = null;
  },
  playFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'PLAY_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  playUnset(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.isLoading = true;
    state.error = null;
  },
  scrshotList: (state: DeviceState, { payload }: PayloadAction<ScrshotPayload>) => {
    state.actionResult = 'SCRSHOT_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  scrshotListSuccess: (state: DeviceState, { payload }: PayloadAction<DeviceState>) => {
    state.scrshotList = payload.scrshotList;
    state.actionResult = 'SCRSHOT_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  scrshotListFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'SCRSHOT_LIST_ERR';
    state.error = action.payload;
  },
  scrshotListClear: (state: DeviceState) => {
    state.scrshotList = [];
  },
  groupMove(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'GROUP_MOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  groupMoveSuccess(state: DeviceState) {
    state.actionResult = 'GROUP_MOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  groupMoveFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'GROUP_MOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  remoteUrl(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'REMOTE_URL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  remoteUrlSuccess(state: DeviceState, { payload }: PayloadAction<DeviceState>) {
    state.deviceInfo.remoteUrl = payload.remoteUrl;
    state.actionResult = 'REMOTE_URL_OK';
    state.isLoading = false;
    state.error = null;
  },
  remoteUrlFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'REMOTE_URL_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  actionResultClear: (state: DeviceState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'device',
  initialState: deviceInitialState,
  reducers: reducers,
});

const selectFilterInfo = createDraftSafeSelector(
  (state: BoardState) => state.filterInfo,
  filterInfo => filterInfo,
);

const selectPageInfo = createDraftSafeSelector(
  (state: BoardState) => state.pageInfo,
  pageInfo => {
    return {
      rowsPerPage: pageInfo.rowsPerPage,
      curPage: pageInfo.curPage,
    };
  },
);

const selectDeviceInfo = createDraftSafeSelector(
  (state: DeviceState) => state.deviceInfo,
  deviceInfo => deviceInfo,
);

const selectDeviceList = createDraftSafeSelector(
  (state: DeviceState) => state.filterInfo,
  (state: DeviceState) => state.deviceList,
  (filterInfo, deviceList) => {
    if (
      (!filterInfo.groupId || filterInfo.groupId.trim() === '') &&
      (!filterInfo.deviceNm || filterInfo.deviceNm.trim() === '')
    ) {
      return deviceList;
    }

    return deviceList.filter(device => {
      let groupFilter = true;
      let deviceFilter = true;

      if (filterInfo.groupId && filterInfo.groupId !== '') {
        groupFilter = device.groupId === filterInfo.groupId;
      }

      if (filterInfo.deviceNm && filterInfo.deviceNm !== '') {
        deviceFilter = device.deviceNm.indexOf(filterInfo.deviceNm) >= 0;
      }

      return groupFilter && deviceFilter;
    });
  },
);

const selectPageDeviceList = createDraftSafeSelector(
  (state: DeviceState) => state.filterInfo,
  (state: DeviceState) => state.pageInfo,
  (state: DeviceState) => state.deviceList,
  (filterInfo, pageInfo, deviceList) => {
    if (
      (filterInfo.groupId && filterInfo.groupId.trim() !== '') ||
      (filterInfo.deviceNm && filterInfo.deviceNm.trim() !== '')
    ) {
      const filterDeviceList = deviceList.filter(device => {
        let groupFilter = true;
        let deviceFilter = true;

        if (filterInfo.groupId && filterInfo.groupId !== '') {
          groupFilter = device.groupId === filterInfo.groupId;
        }

        if (filterInfo.deviceNm && filterInfo.deviceNm !== '') {
          deviceFilter = device.deviceNm.indexOf(filterInfo.deviceNm) >= 0;
        }

        return groupFilter && deviceFilter;
      });

      const totalCount = filterDeviceList.length;
      const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
      const endIndex = totalCount - startIndex;
      const totalPages =
        filterDeviceList.length % pageInfo.rowsPerPage > 0
          ? Math.floor(filterDeviceList.length / pageInfo.rowsPerPage) + 1
          : Math.floor(filterDeviceList.length / pageInfo.rowsPerPage);

      const sliceList = filterDeviceList.slice(
        pageInfo.rowsPerPage * (pageInfo.curPage - 1),
        pageInfo.rowsPerPage * pageInfo.curPage,
      );

      return {
        totalCount,
        startIndex,
        endIndex,
        totalPages,
        deviceList: sliceList,
      };
    }

    const totalCount = deviceList.length;
    const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
    const endIndex = totalCount - startIndex;
    const totalPages =
      deviceList.length % pageInfo.rowsPerPage > 0
        ? Math.floor(deviceList.length / pageInfo.rowsPerPage) + 1
        : Math.floor(deviceList.length / pageInfo.rowsPerPage);

    const sliceList = deviceList.slice(
      pageInfo.rowsPerPage * (pageInfo.curPage - 1),
      pageInfo.rowsPerPage * pageInfo.curPage,
    );

    return {
      totalCount,
      startIndex,
      endIndex,
      totalPages,
      deviceList: sliceList,
    };
  },
);

const selectPairingDeviceList = createDraftSafeSelector(
  (state: DeviceState) => state.deviceList,
  deviceList => deviceList.filter(device => device.pairingYn === 'Y'),
);

const selectPairingInfo = createDraftSafeSelector(
  (state: DeviceState) => state.pairingInfo,
  pairingInfo => pairingInfo,
);

const selectScrshotList = createDraftSafeSelector(
  (state: DeviceState) => state.scrshotList,
  scrshotList => scrshotList,
);

const selectStatus = createDraftSafeSelector(
  (state: DeviceState) => state.actionResult,
  (state: DeviceState) => state.isLoading,
  (state: DeviceState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

const selectLastDeviceKey = createDraftSafeSelector(
  (state: DeviceState) => state.lastDeviceKey,
  lastDeviceKey => lastDeviceKey,
);

const selectIsLastPage = createDraftSafeSelector(
  (state: DeviceState) => state.deviceList,
  (state: DeviceState) => state.lastDeviceKey,
  (deviceList, lastDeviceKey) => deviceList?.length > 0 && !lastDeviceKey,
);

export const deviceSelector = {
  filterInfo: state => selectFilterInfo(state[DEVICE]),
  pageInfo: state => selectPageInfo(state[DEVICE]),
  deviceInfo: state => selectDeviceInfo(state[DEVICE]),
  deviceList: state => selectDeviceList(state[DEVICE]),
  lastDeviceKey: state => selectLastDeviceKey(state[DEVICE]),
  pageDeviceList: state => selectPageDeviceList(state[DEVICE]),
  pairingDeviceList: state => selectPairingDeviceList(state[DEVICE]),
  scrshotList: state => selectScrshotList(state[DEVICE]),
  pairingInfo: state => selectPairingInfo(state[DEVICE]),
  status: state => selectStatus(state[DEVICE]),
  isLastPage: state => selectIsLastPage(state[DEVICE]),
};

export const DEVICE = slice.name;
export const deviceReducer = slice.reducer;
export const deviceAction = slice.actions;
