import React from 'react';
import styled from 'styled-components';

const TocuhModalLayer = ({ layerId, backgroundColor, x, y, width, height, layerNm, rotate }) => {
  return (
    <>
      <Rect
        className="layer"
        id={layerId}
        style={{
          backgroundColor: `rgba(${Number(backgroundColor.r)}, ${Number(backgroundColor.g)}, ${Number(backgroundColor.b)}, ${Number(backgroundColor.a)})`,
          transform: `translate(${x}px, ${y}px)`,
          width: width + 'px',
          height: height + 'px',
        }}
      >
        <LayerBorder />
        <LayerNm rotate={rotate}>
          <span>{layerNm}</span>
        </LayerNm>
      </Rect>
    </>
  );
};

const Rect = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
  overflow: hidden;

  & img {
    -webkit-user-drag: none;
  }
`;

const LayerNm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #fff;

  & > span {
    transform: ${({ rotate }) => `rotate(${-rotate}deg)`};
  }
`;

const LayerBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid ${({ baseYn }) => (baseYn === 'Y' ? '#41A1EA' : '#666')};
`;

export default React.memo(TocuhModalLayer);
