import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../assets/images/common/icon-check.svg';

const Checkbox = ({ width, checked, onChange, disabled, id }) => {
  return (
    <Container width={width} checked={checked} disabled={disabled}>
      <input id={id} type="checkbox" checked={checked} onChange={e => onChange(e)} />
      <CheckIcon />
    </Container>
  )
};

const Container = styled.label`
  position: relative;
  min-width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  background: ${({ checked, disabled }) =>
    checked ? (disabled ? '#999999' : '#41a1ea') : '#FFFFFF'};
  border: 1px solid
    ${({ checked, disabled }) =>
      checked ? (disabled ? '#999999' : '#41a1ea') : '#CCCCCC'};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  overflow: hidden;
  cursor: pointer;

  & > input {
    position: absolute;
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  & > svg {
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  transition: background-color .15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'all'}
`;

Checkbox.defaultProps = {
  width: 16,
  checked: false,
  disabled: false,
  onChange: () => {},
};

export default React.memo(Checkbox);
