import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import * as appLibApi from '../lib/appApi';
import { appAction } from './appSlice';

function* getAppList() {
  try {
    const appList = yield call(appLibApi.getAppList);
    yield put(appAction.getAppListSuccess(appList));
  } catch (error) {
    yield put(appAction.getAppListFailure('getAppList error'));
  }
}

export function* watAppList() {
  yield takeLatest(appAction.getAppList, getAppList);
}

function* rootSaga() {
  yield all([
    fork(watAppList),
  ]);
}

export default rootSaga;
