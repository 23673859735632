import { all, fork, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import * as channelContentsApiLib from '../lib/channelContentsApi';
import { CHANNELCONTENTS, channelContentsAction } from './channelContentsSlice';

function* list({ payload: placeId }) {
  try {
    const channelList = yield call(channelContentsApiLib.list, placeId);
    const sort = channelList.sort((a, b) => {
      return a.contentsOrder - b.contentsOrder;
    });
    yield put(channelContentsAction.getChannelContentsListSuccess(sort));
  } catch (error) {
    if (error.message === 'no channelContents') {
    }
    yield put(channelContentsAction.getChannelContentsListFailure('getChannelList error'));
  }
}

function* listAll({ payload: placeId }) {
  try {
    console.log('listAll');
    const channelList = yield call(channelContentsApiLib.listAll, placeId);
    console.log('channelIst', channelList);
    yield put(channelContentsAction.getChannelContentsTotalListSuccess(channelList));
  } catch (error) {
    if (error.message === 'no channelContents') {
    }
    yield put(channelContentsAction.getChannelContentsTotalListFailure('getChannelList error'));
  }
}

function* add(param) {
  let { contentsList, channelId, removeIdList } = param.payload;
  const data = {
    contentsList,
    channelId,
    removeIdList,
  };
  try {
    yield call(channelContentsApiLib.add, data);
    yield put(channelContentsAction.addChannelContentsInfoSuccess());
  } catch (error) {
    yield put(channelContentsAction.addChannelContentsInfoFailure(error));
  }
}

function* remove({ payload: { channelId } }) {
  try {
    yield call(channelContentsApiLib.remove, channelId);
    const channelList = yield select(state => state[CHANNELCONTENTS].channelList);
    yield put(channelContentsAction.removeChannelContentsInfoSuccess(channelList));
  } catch (error) {
    yield put(channelContentsAction.removeChannelContentsFailure(error));
  }
}

export function* watchChannelContentsList() {
  yield takeLatest(channelContentsAction.getChannelContentsList, list);
}
export function* watchChannelContentsTotalList() {
  yield takeLatest(channelContentsAction.getChannelContentsTotalList, listAll);
}

export function* watchAddChannelContents() {
  yield takeEvery(channelContentsAction.addChannelContentsInfo, add);
}

export function* watchRemoveChannelContents() {
  yield takeEvery(channelContentsAction.removeChannelInfo, remove);
}

function* rootSaga() {
  yield all([fork(watchChannelContentsList), fork(watchAddChannelContents), fork(watchRemoveChannelContents), fork(watchChannelContentsTotalList)]);
}

export default rootSaga;
