import { interactionBaseApi } from './interactionBaseApi';
import * as apiLib from '../../src/lib/api';

export const frameApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    frameList: build.query({
      query: data => ({ url: '/frame/list', data }),
      transformResponse: response => {
        const frameList = response.frameList;
        return frameList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ frameId }) => ({ type: 'FRAME', id: frameId })),
        { type: 'FRAME', id: 'LIST' },
      ],
    }),
    addFrameList: build.mutation({
      query: data => ({ url: '/frame/frame-add', data }),
      invalidatesTags: [{ type: 'FRAME', id: 'LIST' }],
    }),
    uploadThumbnail: build.mutation({
      async queryFn(
        { addFileList, frameId },
        { dispatch, queryFulfilled, getState },
        _extraOptions,
        fetchInteractionApi,
      ) {
        const placeId = getState().editor.placeId;
        const playlistId = getState().editor.playlistId;
        try {
          let fileList = addFileList;
          const uploadFileList = [];
          const file = fileList[0];
          const thumbPath = `contents/${placeId}-frame/${file.fileNm}`;
          await fetchInteractionApi({ url: '/frame/frame-update', data: { frameId, frameInfo: { thumbPath } } }).then(
            res => {
              apiLib.fetchUploadApi(res.data.url, file.fileData, null);
            },
          );
          dispatch(
            frameApi.util.updateQueryData('frameList', { playlistId }, draft => {
              const frameIndex = draft.findIndex(frame => frame.frameId === frameId);
              draft[frameIndex] = { ...draft[frameIndex], ...{ thumbPath: thumbPath } };
            }),
          );
          return uploadFileList;
        } catch (error) {
          throw error;
        }
      },
    }),
    framePrimaryChange: build.mutation({
      query: data => ({ url: '/frame/frame-primary-change', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'FRAME', id: payload.frameId }],
    }),
    frameUpdate: build.mutation({
      query: data => ({ url: '/frame/frame-update', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'FRAME', id: payload.frameId }],
      async onQueryStarted({ frameId, frameInfo }, { dispatch, queryFulfilled, getState }) {
        const playlistId = getState().editor.playlistId;
        const patchResult = dispatch(
          frameApi.util.updateQueryData('frameList', { playlistId }, draft => {
            const index = draft.findIndex(frame => frame.frameId === frameId);
            draft[index] = { ...draft[index], ...frameInfo };
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    frameReOrder: build.mutation({
      query: data => ({ url: '/frame/order', data }),
      invalidatesTags: [{ type: 'FRAME', id: 'LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const playlistId = getState().editor.playlistId;
        const patchResult = dispatch(
          frameApi.util.updateQueryData('frameList', { playlistId }, draft => {
            for (const update of updateList) {
              const frameId = update.frameId;
              const frameOrder = update.frameOrder;
              const index = draft.findIndex(frame => frame.frameId === frameId);
              draft[index].frameOrder = frameOrder;
            }
            draft = draft.sort((lhs, rhs) => lhs.frameOrder - rhs.frameOrder);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    frameLinkList: build.query({
      query: data => ({ url: '/frame/frame-link-group', data }),
      transformResponse: response => {
        return response.linkResultObj;
      },
    }),
    cloneFrameList: build.mutation({
      async queryFn(data, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const playlistId = getState().editor.playlistId;
          await fetchInteractionApi({ url: '/frame/clone-group', data });
          const resultData = await fetchInteractionApi({ url: '/frame/list', data: { playlistId } });
          return resultData;
        } catch (err) {
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
      invalidatesTags: [{ type: 'FRAME', id: 'LIST' }],
    }),
    removeFrameListGroup: build.mutation({
      async queryFn({ frameId }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const playlistId = getState().editor.playlistId;

          let orderUpdateList = [];
          patchResult = dispatch(
            frameApi.util.updateQueryData('frameList', { playlistId }, draft => {
              for (const obj of frameId) {
                const frameId = obj.frameId;
                const index = draft.findIndex(frame => frame.frameId === frameId);
                draft.splice(index, 1);
              }
              orderUpdateList = draft.reduce((target, newFrame, index) => {
                if (newFrame.frameOrder !== index) {
                  draft[index].frameOrder = index;
                  target.push({
                    frameId: newFrame.frameId,
                    frameOrder: index,
                  });
                }
                return target;
              }, []);
              draft = draft.sort((lhs, rhs) => lhs.frameOrder - rhs.frameOrder);
            }),
          );
          for (const frame of orderUpdateList) {
            await fetchInteractionApi({
              url: '/frame/frame-update',
              data: { frameId: frame.frameId, frameInfo: { frameOrder: frame.frameOrder } },
            });
          }

          await fetchInteractionApi({ url: '/frame/frame-remove-group', data: { frameId } });
          const resultData = await fetchInteractionApi({ url: '/frame/list', data: { playlistId } });

          return resultData;
        } catch (err) {
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
      invalidatesTags: [{ type: 'FRAME', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useFrameListQuery,
  useAddFrameListMutation,
  useFramePrimaryChangeMutation,
  useFrameUpdateMutation,
  useFrameLinkListQuery,
  useCloneFrameListMutation,
  useRemoveFrameListGroupMutation,
  useFrameReOrderMutation,
  useUploadThumbnailMutation,
} = frameApi;
