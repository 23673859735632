import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface HomePayload {
  navIndex: number;
}

interface ContactPayload {
  contactName: string;
  contactLastname: string;
  contactEmail: string;
  contactTitle: string;
  contactMsg: string;
  contactLocation: string;
  contactProduct: String;
}

interface HomeState {
  navIndex: number;
  newsList: array<any>;
  faqList: array<any>;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const homeInitialState: HomeState = {
  navIndex: 0,
  newsList: [],
  faqList: [],
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  contact: (state: HomeState, { payload }: PayloadAction<ContactPayload>) => {
    state.isLoading = true;
    state.actionResult = 'CONTACT_REQ';
    state.error = '';
  },
  contactSuccess: (state: HomeState) => {
    state.isLoading = false;
    state.actionResult = 'CONTACT_OK';
    state.error = '';
  },
  contactFailure: (state: HomeState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'CONTACT_ERR';
    state.error = action.payload;
  },
  newsList: (state: HomeState) => {
    state.isLoading = true;
    state.actionResult = 'NEWSLIST_REQ';
    state.error = '';
  },
  newsListSuccess: (state: HomeState, { payload }: PayloadAction<Array<any>>) => {
    state.newsList = payload.newsList;
    state.isLoading = false;
    state.actionResult = 'NEWSLIST_OK';
    state.error = '';
  },
  newsListFailure: (state: HomeState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'NEWSLIST_ERR';
    state.error = action.payload;
  },
  faqList: (state: HomeState) => {
    state.isLoading = true;
    state.actionResult = 'FAQLIST_REQ';
    state.error = '';
  },
  faqListSuccess: (state: HomeState, { payload }: PayloadAction<Array<any>>) => {
    state.faqList = payload.faqList;
    state.isLoading = false;
    state.actionResult = 'FAQLIST_OK';
    state.error = '';
  },
  faqListFailure: (state: HomeState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'FAQLIST_ERR';
    state.error = action.payload;
  },
  navIndex: (state: HomeState, { payload }: PayloadAction<HomePayload>) => {
    state.navIndex = payload.navIndex;
  },
  actionResultClear: (state: HomeState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'home',
  initialState: homeInitialState,
  reducers: reducers,
});

const selectHomeInfo = createDraftSafeSelector(
  (state: HomeState) => state.navIndex,
  navIndex => ({
    navIndex,
  }),
);

const selectNewsList = createDraftSafeSelector(
  (state: HomeState) => state.newsList,
  newsList => newsList,
);

const selectFaqList = createDraftSafeSelector(
  (state: HomeState) => state.faqList,
  faqList => faqList,
);

const selectStatus = createDraftSafeSelector(
  (state: HomeState) => state.actionResult,
  (state: HomeState) => state.isLoading,
  (state: HomeState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const homeSelector = {
  homeInfo: state => selectHomeInfo(state[HOME]),
  status: state => selectStatus(state[HOME]),
  newsList: state => selectNewsList(state[HOME]),
  faqList: state => selectFaqList(state[HOME]),
};

export const HOME = slice.name;
export const homeReducer = slice.reducer;
export const homeAction = slice.actions;
