import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface FolderPayload {
  placeId: String;
  folderPid: String;
  folderId: String;
  folderNm: String;
}

interface FilePayload {
  placeId: String;
  folderId: String;
  fileDetail: String;
  files: Array<any>;
  onUploadProgress: () => void;
}

interface FileListPayload {
  fileList: Array<FilePayload>;
}

interface ContentsState {
  storageFolderPath: FolderPayload;
  storageFolderPathList: Array<FolderPayload>;
  storageFolderList: Array<FolderPayload>;
  storageMoveFolderPath: FolderPayload;
  storageMoveFolderPathList: Array<FolderPayload>;
  storageMoveFolderList: Array<FolderPayload>;
  storageFileList: Array<FilePayload>;
  storageMoveFileList: Array<FilePayload>;
  storageFileUploadMode: number;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const contentsInitialState: ContentsState = {
  storageFolderPath: {},
  storageFolderPathList: [],
  storageFolderList: [],
  storageFileList: [],
  storageMoveFolderPath: {},
  storageMoveFolderPathList: [],
  storageMoveFolderList: [],
  storageMoveFileList: [],
  // storageFolderLastUpdate: 0,
  // storageFileLastUpdate: [],
  storageFileUploadMode: 0,
  storagePlaceFileInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const initStorageState = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.storageFolderPath = {};
  state.storageFolderPathList = [];
  state.storageFolderList = [];
  state.storageFileList = [];
  // state.actionResult = '';
};

const initMoveFolderState = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.storageMoveFolderPath = {};
  state.storageMoveFolderPathList = [];
  state.storageMoveFolderList = [];
  state.storageMoveFileList = [];
  // state.actionResult = '';
};

const storageFolderPathInit = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.storageFolderPath = payload;
  state.storageFolderPathList = [payload];
};

const storageMoveFolderPathInit = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.storageMoveFolderPath = payload.folderPath;
  state.storageMoveFolderPathList = state.storageFolderPathList;
};

const storageFolderPathBack = (state: ContentsState) => {
  const idx = state.storageFolderPathList.length - 1;
  if (idx > 0) {
    state.storageFolderPathList = state.storageFolderPathList.slice(0, idx);
    state.storageFolderPath = state.storageFolderPathList[idx - 1];
  } else {
    state.storageFolderPath = state.storageFolderPathList[0];
  }
};

const storageFolderPathUpper = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  const idx = state.storageFolderPathList.findIndex(folderPath => {
    return folderPath.folderId === payload.folderId;
  });
  if (idx >= 0) {
    state.storageFolderPathList = state.storageFolderPathList.slice(0, idx + 1);
    state.storageFolderPath = state.storageFolderPathList[idx];
  } else {
    state.storageFolderPath = state.storageFolderPathList[0];
  }
};

const storageFolderPathLower = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.storageFolderPathList.push(payload);
  state.storageFolderPath = payload;
};

const storageMoveFolderPathUpper = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  const idx = state.storageMoveFolderPathList.findIndex(folderPath => {
    return folderPath.folderId === payload.folderId;
  });
  if (idx >= 0) {
    state.storageMoveFolderPathList = state.storageMoveFolderPathList.slice(0, idx + 1);
    state.storageMoveFolderPath = state.storageMoveFolderPathList[idx];
  } else {
    state.storageMoveFolderPath = state.storageMoveFolderPathList[0];
  }
};

const storageMoveFolderPathLower = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.storageMoveFolderPathList.push(payload);
  state.storageMoveFolderPath = payload;
};

const storageFolderList = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_LIST_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFolderListSuccess = (state: ContentsState, { payload }: PayloadAction<ContentsState>) => {
  state.actionResult = 'FOLDER_LIST_OK';
  state.storageFolderList = payload.storageFolderList;
  state.isLoading = false;
  state.error = null;
};

const storageFolderListFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_LIST_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageMoveFolderList = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = true;
  state.error = null;
};

const storageMoveFolderListSuccess = (state: ContentsState, { payload }: PayloadAction<ContentsState>) => {
  state.storageMoveFolderList = payload.storageMoveFolderList;
  state.isLoading = false;
  state.error = null;
};

const storageMoveFolderListFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = payload;
};

const storageFolderAdd = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.isLoading = true;
  state.error = null;
};

const storageFolderAddSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_ADD_OK';
  state.isLoading = false;
  state.error = null;
};

const storageFolderAddFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_ADD_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageFolderRename = (
  state: ContentsState,
  { payload: { folderId, folderNm } }: PayloadAction<FolderPayload>,
) => {
  const findIndex = state.storageFolderList.findIndex(folder => folder.folderId === folderId);

  if (folderId.substring(folderId.lastIndexOf('-')) !== '-app') {
    state.storageFolderList[findIndex].folderNm = folderNm;
  } else {
    return;
  }
};

const storageFolderEdit = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.actionResult = 'FOLDER_NAMEEDIT_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFolderEditSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_NAMEEDIT_OK';
  state.isLoading = false;
  state.error = null;
};

const storageFolderEditFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_NAMEEDIT_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageFolderMove = (state: ContentsState, { payload }: PayloadAction<FilePayload>) => {
  state.isLoading = true;
  state.error = null;
};

const storageFolderMoveSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = null;
};

const storageFolderMoveFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = payload;
};

const storageFolderListMove = (state: ContentsState, { payload }: PayloadAction<FilePayload>) => {
  state.actionResult = 'FOLDER_MOVE_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFolderListMoveSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_MOVE_OK';
  state.isLoading = false;
  state.error = null;
};

const storageFolderListMoveFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_MOVE_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageFileMove = (state: ContentsState, { payload }: PayloadAction<FilePayload>) => {
  state.isLoading = true;
  state.error = null;
};

const storageFileMoveSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = null;
};

const storageFileMoveFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = payload;
};

const storageFileListMove = (state: ContentsState, { payload }: PayloadAction<FilePayload>) => {
  state.actionResult = 'FOLDER_MOVE_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFileListMoveSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_MOVE_OK';
  state.isLoading = false;
  state.error = null;
};

const storageFileListMoveFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FOLDER_MOVE_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageFolderRemove = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.isLoading = true;
  state.error = null;
};

const storageFolderRemoveSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = null;
};

const storageFolderRemoveFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = payload;
};

const storageFolderListRemove = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.actionResult = 'REMOVE_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFolderListRemoveSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'REMOVE_OK';
  state.isLoading = false;
  state.error = null;
};

const storageFolderListRemoveFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'REMOVE_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageFileList = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FILE_LIST_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFileListSuccess = (state: ContentsState, { payload }: PayloadAction<ContentsState>) => {
  state.storageFileList = payload.storageFileList;
  state.actionResult = 'FILE_LIST_OK';
  state.isLoading = false;
  state.error = null;
};

const storageFileListFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'FILE_LIST_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageMoveFileList = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = true;
  state.error = null;
};

const storageMoveFileListSuccess = (state: ContentsState, { payload }: PayloadAction<ContentsState>) => {
  state.storageMoveFileList = payload.storageMoveFileList;
  state.isLoading = false;
  state.error = null;
};

const storageMoveFileListFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = payload;
};

const storageFileUploadMode = (state: ContentsState, { payload }: PayloadAction<Number>) => {
  state.storageFileUploadMode = payload;
  state.isLoading = true;
  state.error = null;
};

const storageFileAdd = (state: ContentsState, { payload }: PayloadAction<FilePayload>) => {
  state.actionResult = 'UPLOAD_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFileAddRequest = (state: ContentsState, { payload }: PayloadAction<FilePayload>) => {
  state.actionResult = 'UPLOAD_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFileAddSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'UPLOAD_OK';
  state.isLoading = false;
  state.error = null;
};

const storageFileAddFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'UPLOAD_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageFileDownloadRequest = (state: ContentsState, { payload }: PayloadAction<FilePayload>) => {
  state.actionResult = 'DOWNLOAD_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFileDownloadSuccess = (state: ContentsState) => {
  state.actionResult = 'DOWNLOAD_OK';
  state.isLoading = false;
  state.error = null;
};

const storageFileDownloadFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'DOWNLOAD_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageFileRemove = (state: ContentsState, { payload }: PayloadAction<FilePayload>) => {
  state.isLoading = true;
  state.error = null;
};

const storageFileRemoveSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = null;
};

const storageFileRemoveFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = payload;
};

const storageFileListRemove = (state: ContentsState, { payload }: PayloadAction<FileListPayload>) => {
  state.actionResult = 'REMOVE_REQ';
  state.isLoading = true;
  state.error = null;
};

const storageFileListRemoveSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'REMOVE_OK';
  state.isLoading = false;
  state.error = null;
};

const storageFileListRemoveFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.actionResult = 'REMOVE_ERR';
  state.isLoading = false;
  state.error = payload;
};

const storageFolderCheckAndMake = (state: ContentsState, { payload }: PayloadAction<FolderPayload>) => {
  state.isLoading = true;
  state.error = null;
};

const storageFolderCheckAndMakeSuccess = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = null;
};

const storageFolderCheckAndMakeFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = payload;
};

const getPlaceFileInfo = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = true;
  state.error = null;
};

const getPlaceFileInfoSuccess = (state: ContentsState, { payload }: PayloadAction<ContentsState>) => {
  state.storagePlaceFileInfo = payload;
  state.isLoading = false;
  state.error = null;
};

const getPlaceFileInfoFailure = (state: ContentsState, { payload }: PayloadAction<String>) => {
  state.isLoading = false;
  state.error = payload;
};

const actionResultClear = (state: ContentsState) => {
  state.actionResult = '';
};

const slice = createSlice({
  name: 'contents',
  initialState: contentsInitialState,
  reducers: {
    initStorageState,
    initMoveFolderState,
    storageFolderPathInit,
    storageMoveFolderPathInit,
    storageFolderPathBack,
    storageFolderPathUpper,
    storageFolderPathLower,
    storageMoveFolderPathUpper,
    storageMoveFolderPathLower,
    storageFolderList,
    storageFolderListSuccess,
    storageFolderListFailure,
    storageMoveFolderList,
    storageMoveFolderListSuccess,
    storageMoveFolderListFailure,
    storageFolderAdd,
    storageFolderAddSuccess,
    storageFolderAddFailure,
    storageFolderRename,
    storageFolderEdit,
    storageFolderEditSuccess,
    storageFolderEditFailure,
    storageFolderMove,
    storageFolderMoveSuccess,
    storageFolderMoveFailure,
    storageFolderListMove,
    storageFolderListMoveSuccess,
    storageFolderListMoveFailure,
    storageFileMove,
    storageFileMoveSuccess,
    storageFileMoveFailure,
    storageFileListMove,
    storageFileListMoveSuccess,
    storageFileListMoveFailure,
    storageFolderRemove,
    storageFolderRemoveSuccess,
    storageFolderRemoveFailure,
    storageFolderListRemove,
    storageFolderListRemoveSuccess,
    storageFolderListRemoveFailure,
    storageFileList,
    storageFileListSuccess,
    storageFileListFailure,
    storageMoveFileList,
    storageMoveFileListSuccess,
    storageMoveFileListFailure,
    storageFileUploadMode,
    storageFileAdd,
    storageFileAddRequest,
    storageFileAddSuccess,
    storageFileAddFailure,
    storageFileDownloadRequest,
    storageFileDownloadSuccess,
    storageFileDownloadFailure,
    storageFileRemove,
    storageFileRemoveSuccess,
    storageFileRemoveFailure,
    storageFileListRemove,
    storageFileListRemoveSuccess,
    storageFileListRemoveFailure,
    storageFolderCheckAndMake,
    storageFolderCheckAndMakeSuccess,
    storageFolderCheckAndMakeFailure,
    getPlaceFileInfo,
    getPlaceFileInfoSuccess,
    getPlaceFileInfoFailure,
    actionResultClear,
  },
});

const selectSelf = (state: ContentsState) => state;

const selectStorageFolderPath = createDraftSafeSelector(selectSelf, state => state.storageFolderPath);

const selectStorageFolderPathList = createDraftSafeSelector(selectSelf, state => state.storageFolderPathList);

const selectMoveStorageFolderPath = createDraftSafeSelector(selectSelf, state => state.storageMoveFolderPath);

const selectStorageFolderList = createDraftSafeSelector(selectSelf, state => state.storageFolderList);

const selectStorageMoveFolderPathList = createDraftSafeSelector(selectSelf, state => state.storageMoveFolderPathList);

const selectstorageMoveFolderList = createDraftSafeSelector(selectSelf, state => state.storageMoveFolderList);

const selectStorageFileList = createDraftSafeSelector(selectSelf, state => state.storageFileList);

const selectStorageMoveFileList = createDraftSafeSelector(selectSelf, state => state.storageMoveFileList);

const selectStoragePlaceFileInfo = createDraftSafeSelector(selectSelf, state => state.storagePlaceFileInfo);

const selectStatus = createDraftSafeSelector(
  (state: ContentsState) => state.actionResult,
  (state: ContentsState) => state.isLoading,
  (state: ContentsState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const contentsSelector = {
  storageFolderPath: state => selectStorageFolderPath(state[CONTENTS]),
  storageMoveFolderPath: state => selectMoveStorageFolderPath(state[CONTENTS]),
  storageFolderPathList: state => selectStorageFolderPathList(state[CONTENTS]),
  storageFolderList: state => selectStorageFolderList(state[CONTENTS]),
  storageMoveFolderPathList: state => selectStorageMoveFolderPathList(state[CONTENTS]),
  storageMoveFolderList: state => selectstorageMoveFolderList(state[CONTENTS]),
  storageFileList: state => selectStorageFileList(state[CONTENTS]),
  storageMoveFileList: state => selectStorageMoveFileList(state[CONTENTS]),
  storagePlaceFileInfo: state => selectStoragePlaceFileInfo(state[CONTENTS]),
  status: state => selectStatus(state[CONTENTS]),
};

export const CONTENTS = slice.name;
export const contentsReducer = slice.reducer;
export const contentsAction = slice.actions;
