import * as apiLib from './api';

export const getLayerList = async ({ indexId }) => {
  try {
    const data = {
      frameId: indexId,
    };

    const result = await apiLib.fetchInteractionApi('/layer/list', data);

    if (result.resultFlag) {
      return result.layerList;
    } else {
      throw Object.assign(new Error('getLayerList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getAddLayer = async ({ playlistId, frameId, layerId, layerOrder, width, height, x, y, title, color, baseYn, muteYn, frameLinkId, lockYn, backgroundColor }) => {
  try {
    const data = {
      addList: [
        {
          playlistId,
          frameId,
          layerId,
          layerOrder,
          width,
          height,
          x,
          y,
          title,
          color,
          baseYn,
          muteYn,
          frameLinkId,
          lockYn,
          backgroundColor,
        },
      ],
    };

    const result = await apiLib.fetchInteractionApi('/layer/add-list', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getLayerAdd error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPasteLayer = async ({ pasteLayer, pasteLayerContents }) => {
  try {
    const data = {
      pasteLayer,
      pasteLayerContents,
    };

    const result = await apiLib.fetchInteractionApi('/layer/layer-paste', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getPasteLayer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getAddLayerBatch = async ({ newLayerListData }) => {
  try {
    const data = {
      layerList: newLayerListData,
    };

    const result = await apiLib.fetchInteractionApi('/layer/layer-add-batch', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getLayerAddBatch error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getUpdateLayer = async ({ layerId, layerInfo }) => {
  try {
    const data = {
      updateList: [
        {
          layerId,
          updateInfo: layerInfo,
        },
      ],
    };

    const result = await apiLib.fetchInteractionApi('/layer/update-list', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getUpdateLayer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getRemoveLayer = async ({ activeLayerId }) => {
  try {
    const data = { removeList: [{ layerId: activeLayerId }] };

    const result = await apiLib.fetchInteractionApi('/layer/remove-list', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getRemoveLayer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getRemoveLayerBatch = async ({ layerList }) => {
  try {
    const data = {
      layerList,
    };

    const result = await apiLib.fetchInteractionApi('/layer/layer-remove-batch', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getRemoveLayerBatch error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getSortLayer = async ({ layerId, layerOrder }) => {
  try {
    const data = {
      layerId,
      layerOrder,
    };

    const result = await apiLib.fetchInteractionApi('/layer/layer-sort', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getSortLayer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
