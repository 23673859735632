import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { editorAction, editorSelector } from '../../module/editorSlice';

import { endpoints as endpointsLayerApi, useLayerRemoveListMutation, useLayerReOrderMutation, useLayerUpdateListMutation } from '../../rtk/layerApi';
import { endpoints as endpointsLayerContentsApi } from '../../rtk/layerContentsApi';

import { getLayerContentsIcon } from '../../components/AppIcon';

import { FiMove as MoveIcon } from 'react-icons/fi';
import { ReactComponent as RemoveIcon } from '../../assets/images/common/icon-trash.svg';

import useCheckEditingMode from './app/components/modal/useCheckEditingMode';
import useRemoveConfirmModal from './app/components/modal/useRemoveConfirmModal';
import { handleNotifyLockMode } from './LayerTool';

const LayerCardList = ({ historyRef }) => {
  const dispatch = useDispatch();

  const { editingCheck, LayerContentsWarnModal } = useCheckEditingMode();
  const { removeCheck, LayerContentsWarnModal: removeConfirmModal } = useRemoveConfirmModal();

  const frameId = useSelector(editorSelector.frameId);
  const selectedLayerList = useSelector(editorSelector.selectedLayerList);
  const { lockMode } = useSelector(editorSelector.canvasInfo);

  const { currentData: layerList, isError: isLayerListError } = endpointsLayerApi.layerList.useQueryState({ frameId });
  const { currentData: layerContentsList } = endpointsLayerContentsApi.layerContentsList.useQueryState({ frameId });

  const [reOrderLayer] = useLayerReOrderMutation();
  const [layerRemoveListMutation] = useLayerRemoveListMutation();
  const [layerUpdateListMutation] = useLayerUpdateListMutation();

  // TODO ctrl 레이어 다중 선택
  const handleClickLayer = useCallback(
    (e, layer) => {
      const prevSelectedLayerList = selectedLayerList;
      const nextSelectedLayerList = [{ id: layer.layerId, type: 'LAYER', ...layer }];

      const compareArray = function (arr1, arr2) {
        var i = arr1.length;
        if (i !== arr2.length) return false;

        for (const obj of arr1) {
          const id = obj.id;

          if (!arr2.find(obj2 => obj2.id === id)) {
            return false;
          }
        }
        return true;
      };

      const isSameArray = compareArray(prevSelectedLayerList, nextSelectedLayerList);

      if (!isSameArray) {
        if (!selectedLayerList?.some(selectedLayer => selectedLayer.id === layer.layerId)) {
          editingCheck(() => {
            historyRef.current.addHistory({ type: 'SELECT-LAYER', props: { prevSelectedLayerList, nextSelectedLayerList } });
            dispatch(editorAction.setState({ key: 'selectedLayerList', value: nextSelectedLayerList }));
          });
        } else {
          historyRef.current.addHistory({ type: 'SELECT-LAYER', props: { prevSelectedLayerList, nextSelectedLayerList } });
          dispatch(editorAction.setState({ key: 'selectedLayerList', value: nextSelectedLayerList }));
        }
      }
    },
    [dispatch, editingCheck, historyRef, selectedLayerList],
  );

  const layerRemove = useCallback(
    layer => {
      layerRemoveListMutation({ removeList: [{ layerId: layer.layerId }] }).then(() => {
        let nextSelectedLayerList = selectedLayerList;

        const index = selectedLayerList.findIndex(selectedLayer => selectedLayer.id === layer.layerId);
        if (index > -1) {
          nextSelectedLayerList = [];
        }
        historyRef.current.addHistory({ type: 'REMOVE-LAYER', props: { infos: [layer], prevSelectedLayerList: selectedLayerList, nextSelectedLayerList } });
      });
    },
    [historyRef, layerRemoveListMutation, selectedLayerList],
  );

  const handleRemoveLayerList = useCallback(
    layer => {
      if (lockMode) {
        handleNotifyLockMode();
      } else {
        if (layer.baseYn === 'Y') {
          toast.error('기준 레이어는 삭제 불가합니다.');
          return;
        }

        if (layerContentsList?.some(layerContents => layerContents.layerId === layer.layerId)) {
          removeCheck(() => {
            layerRemove(layer);
          });
        } else {
          layerRemove(layer);
        }
      }
    },
    [lockMode, removeCheck, layerRemove, layerContentsList],
  );

  const handleReorderLayer = useCallback(
    result => {
      if (lockMode) {
        handleNotifyLockMode();
        return;
      }

      if (!result.destination) return;

      let layerLen = layerList.length - 1;

      let startIndex = Math.abs(result.source.index - layerLen);
      let endIndex = Math.abs(result.destination.index - layerLen);

      let newLayerList = [...layerList];
      const [reorderLayerList] = newLayerList.splice(startIndex, 1);
      newLayerList.splice(endIndex, 0, reorderLayerList);

      const updateList = newLayerList.reduce((target, newLayer, index) => {
        if (newLayer.layerOrder !== index) {
          target.push({
            layerId: newLayer.layerId,
            layerOrder: index,
          });
        }
        return target;
      }, []);

      if (updateList.length > 0) {
        reOrderLayer({ updateList });
      }
    },
    [reOrderLayer, layerList, lockMode],
  );

  return (
    <Wrap>
      {layerList && !isLayerListError ? (
        <>
          {/* {lockMode && (
            <ToolArea>
              <LockModeWarn>* 잠금모드일때는 레이어 수정을 할 수 없습니다</LockModeWarn>
            </ToolArea>
          )} */}
          <DragDropContext onDragEnd={handleReorderLayer}>
            <Droppable droppableId="layerList">
              {provided => (
                <ListContainer {...provided.droppableProps} ref={provided.innerRef}>
                  {layerList
                    .slice(0)
                    .reverse()
                    .map((layer, index) => (
                      <Draggable key={layer.layerId} draggableId={layer.layerId} index={index}>
                        {provided => (
                          <Card ref={provided.innerRef} onClick={e => handleClickLayer(e, layer)} {...provided.dragHandleProps} {...provided.draggableProps}>
                            <Layer
                              index={index}
                              layerInfo={layer}
                              contentsType={layerContentsList?.find(contents => contents.layerId === layer.layerId)?.contentsType || undefined}
                              handleRemoveLayerList={handleRemoveLayerList}
                              layerUpdateListMutation={layerUpdateListMutation}
                              isSelect={selectedLayerList?.some(selectedLayer => selectedLayer.id === layer.layerId)}
                              lockMode={lockMode}
                            />
                          </Card>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </ListContainer>
              )}
            </Droppable>
          </DragDropContext>
          {LayerContentsWarnModal()}
          {removeConfirmModal()}
        </>
      ) : (
        <></>
      )}
    </Wrap>
  );
};

const Layer = React.memo(({ index, layerInfo, contentsType, handleRemoveLayerList, layerUpdateListMutation, isSelect, lockMode }) => {
  const nameRef = useRef(null);

  const [value, setValue] = useState(layerInfo.layerNm || '');

  useEffect(() => {
    setValue(value => layerInfo.layerNm || '');
  }, [layerInfo.layerNm]);

  useEffect(() => {
    if (!isSelect) {
      nameRef.current.blur();
    }
  }, [isSelect]);

  const checkUpdate = useCallback(
    value => {
      if (value !== layerInfo.layerNm && !(layerInfo.layerNm === null && value === '')) {
        layerUpdateListMutation({ updateList: [{ layerId: layerInfo.layerId, updateInfo: { layerNm: value } }] });
      }
    },
    [layerInfo.layerId, layerInfo.layerNm, layerUpdateListMutation],
  );

  return (
    <>
      <Order>{index + 1}</Order>
      <Box isSelect={isSelect}>
        {layerInfo.baseYn === 'Y' && <BaseLayer isSelect={isSelect}>기준</BaseLayer>}
        <Icon>{getLayerContentsIcon(contentsType)}</Icon>
        <LayerNm
          ref={nameRef}
          value={value}
          disabled={lockMode || !isSelect}
          placeholder="이름을 입력하세요"
          onKeyUp={e => e.keyCode === 13 && e.target.blur()}
          onChange={e => setValue(e.target.value)}
          onFocus={e => e.target.select()}
          onBlur={e => checkUpdate(e.target.value)}
        />
        <RemoveIcon
          onClick={e => {
            e.stopPropagation();
            handleRemoveLayerList(layerInfo);
          }}
        />
        <MoveIcon className="move" color="#555" cursor="grap" />
      </Box>
    </>
  );
});

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  padding: 10px 15px 20px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  flex: 1;
`;

const BaseLayer = styled.div`
  width: 30px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  background-color: white;
  border-radius: 0px 0px 74px 0px / 8px 148px 78px 0px;
  box-shadow: 1px 0px 3px #85858578;

  color: ${({ isSelect }) => (isSelect ? '#41A1EA' : '#333')};
`;

const Order = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15px;
  height: 100%;
  font-size: 12px;
  color: #333;
  text-align: center;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  min-height: 50px;
  gap: 10px;
  cursor: default !important;
  .move {
    cursor: grab !important;
  }
`;

const Box = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  height: 100%;
  border: 1px solid ${({ isSelect }) => (isSelect ? '#41A1EA' : '#dddddd')};
  border-radius: 8px;
  padding: 10px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const LayerNm = styled.input`
  flex: 1;
  border: none;
  height: 100%;
  border-radius: 6px;

  &:focus {
    outline-color: #dddddd;
  }

  &::placeholder {
    font-size: 11px;
    color: #999;
  }
`;

export default React.memo(LayerCardList);
