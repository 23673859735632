import { interactionBaseApi } from './interactionBaseApi';
import { accountAction } from '../module/accountSlice';

export const { endpoints, useAccountDetailQuery } = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    accountDetail: build.query({
      query: data => ({ url: '/account/detail', data }),
      async onQueryStarted({ layerId, frameId }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: { accountInfo, memberInfo } } = await queryFulfilled;
          dispatch(accountAction.detailSuccess({ accountInfo, memberInfo }));
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});
