import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { authAction } from './authSlice';

import * as authApiLib from '../lib/authApi';
import * as googleApiLib from '../lib/googleApi';

import dayjs from 'dayjs';

function* handleLogin({ payload }) {
  try {
    if (payload.loginType === 'google') {
      yield call(googleApiLib.logout);
      const googleUser = yield call(googleApiLib.login);
      const profile = googleUser.getBasicProfile();
      const token = googleUser.getAuthResponse().id_token;
      payload.loginId = profile.getEmail();
      payload.loginPw = token;
      localStorage.setItem('loginId', '');
      localStorage.setItem('idSave', 'Y');
    } else {
      if (payload.idSave === 'Y') {
        localStorage.setItem('loginId', payload.loginId);
        localStorage.setItem('idSave', 'Y');
      } else {
        localStorage.setItem('loginId', '');
        localStorage.setItem('idSave', 'N');
      }
    }

    const result = yield call(authApiLib.login, payload);

    const userInfo = result;
    userInfo.lastLoginDt = dayjs(userInfo.lastLoginDt).format(
      'YYYY-MM-DD HH:mm:ss',
    );

    localStorage.setItem('accessToken', result.accessToken);

    yield put(authAction.loginSuccess({ userInfo }));
  } catch (err) {
    localStorage.removeItem('accessToken');

    switch (err.errCode) {
      case 1000:
      case 1001:
        yield put(
          authAction.loginFailure('아이디 또는 비밀번호를 확인해 주세요.'),
        );
        break;
      case 1002:
        yield put(
          authAction.loginFailure('이메일 인증이 진행되지 않았습니다.'),
        );
        break;
      case 1003:
        yield put(authAction.loginFailure('Google 인증에 실패했습니다.'));
        break;
      default:
        if (err.error === 'popup_closed_by_user') {
          yield put(authAction.loginFailure('Google 인증을 취소했습니다.'));
        } else {
          yield put(authAction.loginFailure('Login Error'));
        }
        break;
    }
  }
}

function* handleToken() {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      throw Object.assign(new Error('token error'), { status: 401 });
    }

    const data = {
      accessToken: accessToken,
    };

    const result = yield call(authApiLib.token, data);

    const userInfo = result;
    userInfo.lastLoginDt = dayjs(userInfo.lastLoginDt).format(
      'YYYY-MM-DD HH:mm:ss',
    );

    yield put(authAction.tokenSuccess({ userInfo }));
  } catch (err) {
    localStorage.removeItem('accessToken');
    yield put(authAction.tokenFailure('Token Error'));
  }
}

function* handleLogout({ payload }) {
  try {
    if (payload.loginType === 'google') {
      yield call(googleApiLib.logout);
    }

    localStorage.removeItem('accessToken');

    yield put(authAction.logoutSuccess());
  } catch (err) {
    yield put(authAction.logoutFailure('Logout Error'));
  }
}

function* handleSignUp({ payload }) {
  try {
    if (payload.loginType === 'google') {
      yield call(googleApiLib.logout);
      const googleUser = yield call(googleApiLib.login);
      const profile = googleUser.getBasicProfile();
      const token = googleUser.getAuthResponse().id_token;
      payload.loginNm = profile.getName();
      payload.loginId = profile.getEmail();
      payload.loginPw = token;
    }

    yield call(authApiLib.signUp, payload);

    yield put(authAction.signUpSuccess());
  } catch (err) {
    switch (err.errCode) {
      case 1000:
        yield put(authAction.signUpFailure('이미 가입한 이메일 입니다.'));
        break;
      case 1001:
        yield put(authAction.signUpFailure('Google 인증에 실패했습니다.'));
        break;
      default:
        if (err.error === 'popup_closed_by_user') {
          yield put(authAction.signUpFailure('Google Error'));
        } else {
          yield put(authAction.signUpFailure('Sign Up Error'));
        }
        break;
    }
  }
}

function* handleVerify({ payload }) {
  try {
    yield call(authApiLib.verify, payload);

    yield put(authAction.verifySuccess());
  } catch (err) {
    switch (err.errCode) {
      case 1000:
      case 1001:
        yield put(authAction.verifyFailure('사용할 수 없는 토큰 입니다.'));
        break;
      case 1002:
        yield put(authAction.verifyFailure('가입되지 않은 이메일 입니다.'));
        break;
      default:
        yield put(authAction.verifyFailure('Verify Error'));
        break;
    }
  }
}

function* handleReset({ payload }) {
  try {
    const loginId = yield call(authApiLib.reset, payload);

    yield put(authAction.resetSuccess({ userInfo: { loginId: loginId } }));
  } catch (err) {
    switch (err.errCode) {
      case 1000:
      case 1001:
        yield put(authAction.changePwFailure('가입되지 않은 이메일 입니다.'));
        break;
      default:
        yield put(authAction.resetFailure('Reset Error'));
        break;
    }
  }
}

function* handleChangePw({ payload }) {
  try {
    yield call(authApiLib.changePw, payload);

    yield put(authAction.changePwSuccess());
  } catch (err) {
    switch (err.errCode) {
      case 1000:
      case 1001:
        yield put(authAction.changePwFailure('사용할 수 없는 토큰 입니다.'));
        break;
      case 1002:
        yield put(authAction.changePwFailure('가입되지 않은 이메일 입니다.'));
        break;
      default:
        yield put(authAction.changePwFailure('Change Password Error'));
        break;
    }
  }
}

export function* watchLogin() {
  yield takeLatest(authAction.login, handleLogin);
}

export function* watchToken() {
  yield takeLatest(authAction.token, handleToken);
}

export function* watchLogout() {
  yield takeLatest(authAction.logout, handleLogout);
}

export function* watchSignUp() {
  yield takeLatest(authAction.signUp, handleSignUp);
}

export function* watchVerify() {
  yield takeLatest(authAction.verify, handleVerify);
}

export function* watchReset() {
  yield takeLatest(authAction.reset, handleReset);
}

export function* watchChangePw() {
  yield takeLatest(authAction.changePw, handleChangePw);
}

function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchToken),
    fork(watchLogout),
    fork(watchSignUp),
    fork(watchVerify),
    fork(watchReset),
    fork(watchChangePw),
  ]);
}

export default rootSaga;
