import { interactionBaseApi } from './interactionBaseApi';

import * as contentsApiLib from '../lib/contentsApi';

import cloneDeep from 'lodash/cloneDeep';

export const layerContentsApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    layerContentsList: build.query({
      query: data => ({ url: '/layer-contents/list', data }),
      transformResponse: response => {
        const layerContentsList = response.layerContentsList;

        return layerContentsList;
      },
      providesTags: (result, error, payload) => [...result.map(({ contentsId }) => ({ type: 'LAYERCONTENTS', id: contentsId })), { type: 'LAYERCONTENTS', id: 'LIST' }],
      keepUnusedDataFor: 0,
    }),
    layerContentsAdd: build.mutation({
      async queryFn({ formData, addFileList, placeId, folderId, folderNm, handleUploadProgress }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const frameId = getState().editor.frameId;
          const tempForm = cloneDeep(formData);

          patchResult = dispatch(
            layerContentsApi.util.updateQueryData('layerContentsList', { frameId }, draft => {
              draft.push(tempForm);
            }),
          );

          const contentFileAddParams = { formData, addFileList, placeId, folderId, folderNm, handleUploadProgress };
          const resultContentFileAdd = await contentsFileAdd(fetchInteractionApi, contentFileAddParams);

          const result = await fetchInteractionApi({ url: '/layer-contents/layer-contents-save', data: resultContentFileAdd });

          return result;
        } catch (err) {
          if (patchResult) {
            console.log('에러', err);
            patchResult.undo();
          }
        }
      },

      invalidatesTags: [{ type: 'LAYERCONTENTS', id: 'LIST' }],
    }),
    layerContentsCopyList: build.mutation({
      query: data => ({ url: '/layer-contents/layer-contents-copy', data }),
      invalidatesTags: [{ type: 'LAYERCONTENTS', id: 'LIST' }],
    }),
    layerContentsRemove: build.mutation({
      async queryFn({ contentsId }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const frameId = getState().editor.frameId;

          patchResult = dispatch(
            layerContentsApi.util.updateQueryData('layerContentsList', { frameId }, draft => {
              const index = draft.findIndex(contents => contents.contentsId === contentsId);
              draft.splice(index, 1);
            }),
          );

          const result = await fetchInteractionApi({ url: '/layer-contents/layer-contents-remove', data: { contentsId: contentsId } });
          return result;
        } catch (err) {
          if (patchResult) {
            patchResult.undo();
          }
        }
      },

      invalidatesTags: [{ type: 'LAYERCONTENTS', id: 'LIST' }],
    }),
    layerContentsUpdate: build.mutation({
      async queryFn({ formData, addFileList, removeFileList, placeId, folderId, folderNm, handleUploadProgress }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const frameId = getState().editor.frameId;
          const tempForm = cloneDeep(formData);

          patchResult = dispatch(
            layerContentsApi.util.updateQueryData('layerContentsList', { frameId }, draft => {
              draft.push(tempForm);
            }),
          );

          const contentFileUpdateParams = { formData, addFileList, removeFileList, placeId, folderId, folderNm, handleUploadProgress };
          const resultContentFileUpdate = await contentsFileUpdate(fetchInteractionApi, contentFileUpdateParams);

          const result = await fetchInteractionApi({ url: '/layer-contents/layer-contents-save', data: resultContentFileUpdate });

          return result;
        } catch (err) {
          console.log('에러', err);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },

      invalidatesTags: [{ type: 'LAYERCONTENTS', id: 'LIST' }],
    }),
    layerContentsPaste: build.mutation({
      query: data => ({ url: '/layer-contents/layer-contents-save', data }),
      invalidatesTags: [{ type: 'LAYERCONTENTS', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

// export const contentsFileAdd = async ({ formData, addFileList, placeId, folderId, folderNm, handleUploadProgress }) => {
export const contentsFileAdd = async (fetchInteractionApi, contentFileAddParams) => {
  let resultFileList = [];

  const formData = contentFileAddParams.formData;
  const addFileList = contentFileAddParams.addFileList;
  const placeId = contentFileAddParams.placeId;
  const folderId = contentFileAddParams.folderId;
  const folderNm = contentFileAddParams.folderNm;
  const handleUploadProgress = contentFileAddParams.handleUploadProgress;

  if (formData.contentsType === 'media' || formData.contentsType === 'audio') {
    for (const folderId in addFileList) {
      const folderInfo = addFileList[folderId];
      // const folderData = {
      //   placeId: folderInfo.placeId,
      //   folderPid: folderInfo.folderPid,
      //   folderId: folderInfo.folderId,
      //   folderNm: folderInfo.folderNm,
      // };
      // await getStorageFolderCheckAndMake(fetchInteractionApi, folderData);

      const tempResultFileList = await contentsApiLib.upload({
        fileList: folderInfo.fileList,
        placeId: folderInfo.placeId,
        folderId: folderInfo.folderId,
        handleUploadProgress,
      });

      resultFileList = resultFileList.concat(tempResultFileList);
    }
  } else {
    const folderData = {
      placeId: placeId,
      folderPid: placeId,
      folderId: folderId,
      folderNm: folderNm,
    };
    await getStorageFolderCheckAndMake(fetchInteractionApi, folderData);
    resultFileList = await contentsApiLib.upload({ fileList: addFileList, placeId, folderId, handleUploadProgress });
  }

  if (formData.contentsType === 'logo' || formData.contentsType === 'oneFloor') {
    let changeLogoList = [];
    if (formData.contentsType === 'logo') {
      changeLogoList = [...formData.contentsData.logoList];
    } else {
      changeLogoList = [...formData.contentsData.companyList];
    }

    for (const file of resultFileList) {
      const findIndex = changeLogoList.findIndex(logoInfo => logoInfo?.logoFile?.fileId === file.fileId);
      if (findIndex > -1) {
        changeLogoList[findIndex].logoFile = file;
      }
    }

    if (formData.contentsType === 'logo') {
      formData.contentsData.logoList = changeLogoList;
    } else {
      formData.contentsData.companyList = changeLogoList;
    }
  } else if (formData.contentsType === 'wholeFloor') {
    let changeFloorList = [...formData.contentsData.floorList];

    for (let index = 0; index < changeFloorList.length; index++) {
      let floor = { ...changeFloorList[index] };

      for (const file of resultFileList) {
        const findIndex = floor.companyList.findIndex(compnay => compnay?.logoFile?.fileId === file.fileId);
        if (findIndex > -1) {
          floor.companyList[findIndex].logoFile = file;
        }
      }

      changeFloorList[index] = floor;
    }

    formData.contentsData.floorList = changeFloorList;
  } else if (formData.contentsType === 'media' || formData.contentsType === 'audio') {
    let changeMediaList = [...formData.contentsData.mediaList];

    for (const file of resultFileList) {
      const findIndex = changeMediaList.findIndex(fileInfo => fileInfo?.fileId === file.fileId);
      if (findIndex > -1) {
        const tempMediaInfo = { ...changeMediaList[findIndex] };

        let changeMedaiInfo = {
          animation: tempMediaInfo.animation,
          changeTime: tempMediaInfo.changeTime,
          fileData: file,
          fileDrageId: tempMediaInfo.fileDrageId,
          fileId: tempMediaInfo.fileId,
          fileType: tempMediaInfo.fileType,
          fitOption: tempMediaInfo.fitOption,
          fromPublic: tempMediaInfo.fromPublic,
        };

        if (formData.contentsType === 'audio') {
          changeMedaiInfo.sound = tempMediaInfo.sound;
        } else {
          changeMedaiInfo.animation = tempMediaInfo.animation;
          changeMedaiInfo.fitOption = tempMediaInfo.fitOption;
        }

        changeMediaList[findIndex] = changeMedaiInfo;
      }
    }

    formData.contentsData.mediaList = changeMediaList;

    resultFileList = [];
    for (const mediaInfo of changeMediaList) {
      resultFileList.push(mediaInfo.fileData);
    }
  }

  formData.contentsFileList = resultFileList;

  return formData;
};

// export const contentsFileUpdate = async ({ formData, addFileList, removeFileList, placeId, folderId, folderNm, handleUploadProgress }) => {
export const contentsFileUpdate = async (fetchInteractionApi, contentFileUpdateParams) => {
  const formData = contentFileUpdateParams.formData;
  const addFileList = contentFileUpdateParams.addFileList;
  const removeFileList = contentFileUpdateParams.removeFileList;
  const placeId = contentFileUpdateParams.placeId;
  const folderId = contentFileUpdateParams.folderId;
  const folderNm = contentFileUpdateParams.folderNm;
  const handleUploadProgress = contentFileUpdateParams.handleUploadProgress;

  let resultFileList = [];
  let origitnFileList = [];

  if (formData.contentsType === 'media' || formData.contentsType === 'audio') {
    for (const folderId in addFileList) {
      const folderInfo = addFileList[folderId];
      // const folderData = {
      //   placeId: folderInfo.placeId,
      //   folderPid: folderInfo.folderPid,
      //   folderId: folderInfo.folderId,
      //   folderNm: folderInfo.folderNm,
      // };
      // await contentsApiLib.getStorageFolderCheckAndMake(folderData);
      const tempResultFileList = await contentsApiLib.upload({
        fileList: folderInfo.fileList,
        placeId: folderInfo.placeId,
        folderId: folderInfo.folderId,
        handleUploadProgress,
      });

      resultFileList = resultFileList.concat(tempResultFileList);
    }
  } else if (formData.contentsType === 'channel') {

    
  } else {
    origitnFileList = [...formData?.contentsFileList];
    const folderData = {
      placeId: placeId,
      folderPid: placeId,
      folderId: folderId,
      folderNm: folderNm,
    };

    await contentsApiLib.getStorageFolderCheckAndMake(folderData);

    if (removeFileList.length > 0) {
      for (const removeFile of removeFileList) {
        origitnFileList = origitnFileList.filter(fileInfo => fileInfo.fileId !== removeFile.fileId);
      }
    }
    resultFileList = await contentsApiLib.upload({ fileList: addFileList, placeId, folderId, handleUploadProgress });
  }

  origitnFileList = origitnFileList.concat(resultFileList);

  if (formData.contentsType === 'logo' || formData.contentsType === 'oneFloor') {
    let changeLogoList = [];
    if (formData.contentsType === 'logo') {
      changeLogoList = [...formData.contentsData.logoList];
    } else {
      changeLogoList = [...formData.contentsData.companyList];
    }

    for (const file of origitnFileList) {
      const findIndex = changeLogoList.findIndex(logoInfo => logoInfo?.logoFile?.fileId === file.fileId);
      if (findIndex > -1) {
        changeLogoList[findIndex].logoFile = file;
      }
    }

    if (formData.contentsType === 'logo') {
      formData.contentsData.logoList = changeLogoList;
    } else {
      formData.contentsData.companyList = changeLogoList;
    }
  } else if (formData.contentsType === 'wholeFloor') {
    let changeFloorList = [...formData.contentsData.floorList];
    for (let index = 0; index < changeFloorList.length; index++) {
      let floor = { ...changeFloorList[index] };
      for (const file of origitnFileList) {
        const findIndex = floor.companyList.findIndex(compnay => compnay?.logoFile?.fileId === file.fileId);
        if (findIndex > -1) {
          floor.companyList[findIndex].logoFile = file;
        }
      }

      changeFloorList[index] = floor;
    }

    formData.contentsData.floorList = changeFloorList;
  } else if (formData.contentsType === 'media' || formData.contentsType === 'audio') {
    let changeMediaList = [...formData.contentsData.mediaList];

    for (const file of origitnFileList) {
      const findIndex = changeMediaList.findIndex(fileInfo => fileInfo?.fileId === file.fileId);
      if (findIndex > -1) {
        const tempMediaInfo = { ...changeMediaList[findIndex] };
        let changeMedaiInfo = {
          changeTime: tempMediaInfo.changeTime,
          fileData: file,
          fileDrageId: tempMediaInfo.fileDrageId,
          fileId: tempMediaInfo.fileId,
          fileType: tempMediaInfo.fileType,
          fromPublic: tempMediaInfo.fromPublic,
        };

        if (formData.contentsType === 'audio') {
          changeMedaiInfo.sound = tempMediaInfo.sound;
        } else {
          changeMedaiInfo.animation = tempMediaInfo.animation;
          changeMedaiInfo.fitOption = tempMediaInfo.fitOption;
        }

        changeMediaList[findIndex] = changeMedaiInfo;
      }
    }

    formData.contentsData.mediaList = changeMediaList;

    origitnFileList = [];

    for (const mediaInfo of changeMediaList) {
      origitnFileList.push(mediaInfo.fileData);
    }
  }

  formData.contentsFileList = origitnFileList;

  return formData;
};

export const getStorageFolderCheckAndMake = async (fetchInteractionApi, folderData) => {
  try {
    const data = {
      placeId: folderData.placeId,
      folderPid: folderData.folderPid,
      folderId: folderData.folderId,
      folderNm: folderData.folderNm,
    };

    const result = await fetchInteractionApi({ url: '/contents/storage-folder-check-and-make', data: data });

    if (result.data.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const {
  endpoints,
  useLayerContentsListQuery,
  useLayerContentsAddMutation,
  useLayerContentsCopyListMutation,
  useLayerContentsRemoveMutation,
  useLayerContentsUpdateMutation,
  useLayerContentsPasteMutation,
} = layerContentsApi;
