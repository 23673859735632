import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Button from './Button';

import { ReactComponent as CloseIcon } from '../assets/images/common/icon-close.svg';

const Modal = ({
  title,
  children,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  onBackdropClick,
  onClose,
  confirmColor,
  cancelColor,
  visible,
  hideXButton,
  hideButton,
  modalRef,
  isLine,
  size,
  height,
  className,
  isFooterFix,
}) => {
  const [firstRendering, setFirstRendering] = useState(true);
  const [modalClassName, setModalClassName] = useState('');

  useEffect(() => {
    let timeout = null;
    if (visible) {
      setFirstRendering(firstRendering => firstRendering && false);
      setModalClassName('slide-up');

      timeout = setTimeout(() => {
        setModalClassName('');
        clearTimeout(timeout);
      }, 250);
    } else {
      setModalClassName('slide-down');

      timeout = setTimeout(() => {
        setModalClassName('');
        clearTimeout(timeout);
      }, 250);
    }
  }, [visible]);

  return (
    <DarkBackground
      firstRendering={firstRendering}
      disappear={!visible}
      onClick={e => {
        if (visible && onBackdropClick) {
          e.preventDefault();
          onBackdropClick(e);
        } else {
          return undefined;
        }
      }}
    >
      <ModalContainer
        className={`${className} ${modalClassName}`}
        ref={modalRef}
        isLine={isLine}
        height={height}
        size={size}
        onClick={e => e.stopPropagation()}
        isFooterFix={isFooterFix}
      >
        {(title || !hideXButton) && (
          <div className="header">
            <span className="title">{title}</span>
            {!hideXButton && (
              <button className="close-btn" onClick={e => onClose(e)}>
                <CloseIcon />
              </button>
            )}
          </div>
        )}
        <div className="body">{children}</div>
        {!hideButton && (
          <div className="footer">
            <ButtonGroup>
              {onCancel && (
                <Button className="cancel-button" color={cancelColor} onClick={onCancel} outline>
                  {cancelText}
                </Button>
              )}
              {onConfirm && (
                <Button className="confirm-button" color={confirmColor} onClick={onConfirm} outline>
                  {confirmText}
                </Button>
              )}
            </ButtonGroup>
          </div>
        )}
      </ModalContainer>
    </DarkBackground>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; z-index: 9999}
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; z-index: -1; pointer-events: none;}
`;

const slideUp = keyframes`
  from { transform: translateY(12.5rem); }
  to { transform: translateY(0); }
`;

const slideDown = keyframes`
  from { transform: translateY(0); }
  to { transform: translateY(12.5rem); }
`;

const modalSizes = {
  xxlarge: {
    width: '80rem',
  },
  xlarge: {
    width: '60rem',
  },
  large: {
    width: '31.25rem',
  },
  medium: {
    width: '28rem',
  },
  small: {
    width: '18.75rem',
  },
};

const DarkBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);

  ${({ firstRendering }) =>
    firstRendering
      ? css`
          opacity: 0;
          z-index: -1;
          pointer-events: none;
        `
      : css`
          animation-duration: 0.25s;
          animation-timing-function: ease-out;
          animation-name: ${fadeIn};
          animation-fill-mode: forwards;

          ${props =>
            props.disappear &&
            css`
              animation-name: ${fadeOut};
            `}
        `}
`;

const ModalContainer = styled.div`
  width: ${({ size }) => (size ? modalSizes[size].width : '100%')};
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 0.625rem;
  color: #333333;
  height: ${({ height }) => height && height};
  overflow: overlay;

  .header {
    top: 0;
    z-index: 1;
    display: flex;
    background: white;
    align-items: center;
    width: 100%;
    padding: 1.25rem;
    border-bottom: ${({ isLine }) => isLine && '1px solid #dddddd'};
  }

  .title {
    margin: 0;
    font-size: 1.125rem;
  }

  .close-btn {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: none;
  }

  .body {
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 0.9375rem;
    color: #000000;
    padding: 1.25rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border-radius: 2.5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #aaaaaa;
    }
  }

  .footer {
    display: flex;
    justify-content: right;
    align-items: center;

    ${({ isFooterFix }) =>
      isFooterFix
        ? css`
            position: sticky;
            bottom: 0;
            background-color: inherit;
            padding: 0.5rem 1.25rem;
          `
        : css`
            padding: 1.25rem;
          `}
  }

  &.slide-up {
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    animation-name: ${slideUp};
    animation-fill-mode: forwards;
  }

  &.slide-down {
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    animation-name: ${slideDown};
    animation-fill-mode: forwards;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

Modal.defaultProps = {
  confirmText: '확인',
  cancelText: '취소',
  confirmColor: 'blue',
  cancelColor: 'gray700',
  isFooterFix: false,
};

export default Modal;
