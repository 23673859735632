import * as apiLib from './api';

export const list = async ({ placeId, startKey = null }) => {
  try {
    const data = {
      placeId: placeId,
      startKey: startKey,
      limit: 50,
    };

    const result = await apiLib.fetchInteractionApi('/device/list', data);
    if (result.resultFlag) {
      return { deviceList: result.deviceList, lastKey: result.lastKey };
    } else {
      throw Object.assign(new Error('device error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ deviceId }) => {
  try {
    const data = {
      deviceId: deviceId,
    };

    const result = await apiLib.fetchInteractionApi('/device/detail', data);
    if (result.resultFlag) {
      return result.deviceInfo;
    } else {
      throw Object.assign(new Error('device error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ placeId }) => {
  try {
    const data = {
      placeId: placeId,
    };

    const result = await apiLib.fetchInteractionApi('/device/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = {
      deviceId: payload.deviceId,
      deviceNm: payload.deviceNm,
      deviceType: payload.deviceType,
      deviceDesc: payload.deviceDesc,
      deviceTag: payload.deviceTag,
      productType: payload.productType,
      displayType: payload.displayType,
      startupTime: payload.startupTime,
      shutdownTime: payload.shutdownTime,
      rebootTime: payload.rebootTime,
      updateTime: payload.updateTime,
      scrshotPeriod: payload.scrshotPeriod,
      agentPeriod: payload.agentPeriod,
      soundVolume: payload.soundVolume,
      soundMute: payload.soundMute,
      weekends: payload.weekends,
      caseByWeekDays: payload.caseByWeekDays,
      holidays: payload.holidays,
      groupCnt: payload.groupCnt,
      groupSeq: payload.groupSeq,
      remoteId: payload.remoteId,
      remotePw: payload.remotePw,
      remotePort: payload.remotePort,
      remoteServerIp: payload.remoteServerIp,
      remoteServerPort: payload.remoteServerPort,
      onceCommandNm: payload.onceCommandNm,
    };

    const result = await apiLib.fetchInteractionApi('/device/edit', data);
    if (result.resultFlag) {
      return result.deviceInfo;
    } else {
      throw Object.assign(new Error('add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ deviceId }) => {
  try {
    const data = {
      deviceId: deviceId,
    };

    const result = await apiLib.fetchInteractionApi('/device/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const command = async ({ deviceList, commandName }) => {
  try {
    const data = {
      commandName: commandName,
      deviceList: deviceList,
    };

    const result = await apiLib.fetchInteractionApi('/device/command', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('command error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const commandSocket = async ({ deviceId, connectionId, commandType, commandData }) => {
  try {
    const data = {
      deviceId: deviceId,
      connectionId: connectionId,
      commandType: commandType,
      commandData: commandData,
    };

    const result = await apiLib.fetchInteractionApi('/device/command-socket', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('command error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const powerUpdate = async ({
  deviceList,
  startupTime,
  shutdownTime,
  rebootTime,
  updateTime,
  weekends,
  caseByWeekDays,
  holidays,
}) => {
  try {
    const data = {
      startupTime,
      shutdownTime,
      rebootTime,
      updateTime,
      weekends,
      caseByWeekDays,
      holidays,
      deviceList,
    };

    const result = await apiLib.fetchInteractionApi('/device/power-update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('power update error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const pairing = async ({ deviceId }) => {
  try {
    const data = {
      deviceId: deviceId,
    };

    const result = await apiLib.fetchInteractionApi('/device/pairing', data);
    if (result.resultFlag) {
      return result.pairingInfo;
    } else {
      throw Object.assign(new Error('pairing error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const pairingUnset = async ({ deviceId }) => {
  try {
    const data = {
      deviceId: deviceId,
    };

    const result = await apiLib.fetchInteractionApi('/device/pairing-unset', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('pairing unset error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const stop = async ({ deviceList }) => {
  try {
    const data = {
      deviceList: deviceList,
    };

    const result = await apiLib.fetchInteractionApi('/device/stop', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('stop error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const play = async ({ playlistId, playlistNm, deviceList }) => {
  try {
    const data = {
      playlistId: playlistId,
      playlistNm: playlistNm,
      deviceList: deviceList,
    };

    const result = await apiLib.fetchInteractionApi('/device/play', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('play error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const playUnset = async ({ playlistId, playlistNm, deviceList }) => {
  try {
    const data = {
      deviceList: deviceList,
    };

    const result = await apiLib.fetchInteractionApi('/device/play-unset', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('play unset error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const scrshotList = async ({ deviceId, scrshotDt }) => {
  try {
    const data = {
      deviceId: deviceId,
      scrshotDt: scrshotDt,
    };

    const result = await apiLib.fetchInteractionApi('/device/scrshot-list', data);
    if (result.resultFlag) {
      return result.scrshotList;
    } else {
      throw Object.assign(new Error('scrshot list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const groupMove = async ({ groupId, deviceList }) => {
  try {
    const data = {
      groupId: groupId,
      deviceList: deviceList,
    };

    const result = await apiLib.fetchInteractionApi('/device/group-move', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('group move error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remoteUrl = async ({ deviceId }) => {
  try {
    const data = {
      deviceId: deviceId,
    };

    const result = await apiLib.fetchInteractionApi('/device/remote-url', data);
    if (result.resultFlag) {
      return result.remoteUrl;
    } else {
      throw Object.assign(new Error('device remote url error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
