import React, { useEffect, Suspense } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Header = React.lazy(() => import('../home/Header'));
const Footer = React.lazy(() => import('../home/Footer'));

const loading = () => <div className="text-center"></div>;

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    const search = location.search;

    if (search.indexOf('?v=features') === 0) {
      document.title = 'ELIGA - 주요기능';
    } else if (search.indexOf('?v=case') === 0) {
      document.title = 'ELIGA - 구축사례';
    } else if (search.indexOf('?v=contactUs') === 0) {
      document.title = 'ELIGA - 고객지원';
    } else if (pathname.indexOf('/terms/privacy') === 0) {
      document.title = 'ELIGA - 개인정보 처리방침';
    } else {
      document.title = 'ELIGA';
    }
  }, [location]);

  return (
    <Container>
      <Suspense fallback={loading()}>
        <Header />
      </Suspense>
      <Suspense fallback={loading()}>
        <Outlet />
      </Suspense>
      <Suspense fallback={loading()}>
        <Footer />
      </Suspense>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export default Home;
