import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { placeAction } from './placeSlice';

import * as placeApiLib from '../lib/placeApi';

function* placeInfo({ payload }) {
  try {
    const placeInfo = yield call(placeApiLib.detail, payload);

    yield put(placeAction.placeInfoSuccess({ placeInfo }));
  } catch (err) {
    yield put(placeAction.placeInfoFailure('Place Info Error'));
  }
}

function* placeList({ payload }) {
  try {
    const placeList = yield call(placeApiLib.list, payload);

    yield put(placeAction.placeListSuccess({ placeList }));
  } catch (err) {
    yield put(placeAction.placeListFailure('Place List Error'));
  }
}

function* placeAdd({ payload }) {
  try {
    yield call(placeApiLib.add, payload);

    yield put(placeAction.placeAddSuccess());
  } catch (err) {
    yield put(placeAction.placeAddFailure('Place Add Error'));
  }
}

function* placeEdit({ payload }) {
  try {
    yield call(placeApiLib.edit, payload);

    yield put(placeAction.placeEditSuccess(payload));
  } catch (err) {
    yield put(placeAction.placeEditFailure('Place Edit Error'));
  }
}

function* placeRemove({ payload }) {
  try {
    yield call(placeApiLib.remove, payload);

    yield put(placeAction.placeRemoveSuccess());
  } catch (err) {
    yield put(placeAction.placeRemoveFailure('Place Remove Error'));
  }
}

function* placeImageUpload({ payload }) {
  try {
    const placeInfo = yield call(placeApiLib.upload, payload);

    yield put(placeAction.placeImageUploadSuccess({ placeInfo }));
  } catch (err) {
    yield put(placeAction.placeImageUploadFailure('Place Image Upload Error'));
  }
}

function* pricingPayment({ payload }) {
  try {
    yield call(placeApiLib.upgrade, payload);

    const placeInfo = yield call(placeApiLib.detail, payload);
    yield put(placeAction.placeInfoSuccess({ placeInfo }));

    // yield put(placeAction.pricingPaymentSuccess({ pricingInfo }));
  } catch (err) {
    yield put(placeAction.pricingPaymentFailure('Pricing Payment Error'));
  }
}

function* memberList({ payload }) {
  try {
    const { memberList, accountList } = yield call(
      placeApiLib.memberList,
      payload,
    );

    yield put(placeAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(placeAction.memberListFailure('Member List Error'));
  }
}

function* memberDetail({ payload }) {
  try {
    const memberInfo = yield call(placeApiLib.memberDetail, payload);

    yield put(placeAction.memberDetailSuccess({ memberInfo }));
  } catch (err) {
    yield put(placeAction.memberDetailFailure('Member Detail Error'));
  }
}

function* memberAdd({ payload }) {
  try {
    yield call(placeApiLib.memberAdd, payload);
    yield put(placeAction.memberAddSuccess());

    const { memberList, accountList } = yield call(
      placeApiLib.memberList,
      payload,
    );
    yield put(placeAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(placeAction.memberAddFailure('Member Add Error'));
  }
}

function* memberEdit({ payload }) {
  try {
    yield call(placeApiLib.memberEdit, payload);
    yield put(placeAction.memberEditSuccess());

    const { memberList, accountList } = yield call(
      placeApiLib.memberList,
      payload,
    );
    yield put(placeAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(placeAction.memberEditFailure('Member Edit Error'));
  }
}

function* memberRemove({ payload }) {
  try {
    yield call(placeApiLib.memberRemove, payload);
    yield put(placeAction.memberRemoveSuccess());

    const { memberList, accountList } = yield call(
      placeApiLib.memberList,
      payload,
    );
    yield put(placeAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(placeAction.memberRemoveFailure('Member Remove Error'));
  }
}

function* playerInfo() {
  try {
    const playerInfo = yield call(placeApiLib.player);

    yield put(placeAction.playerInfoSuccess({ playerInfo }));
  } catch (err) {
    yield put(placeAction.playerInfoFailure('Player Info Error'));
  }
}

export function* watchPlaceInfo() {
  yield takeLatest(placeAction.placeInfo, placeInfo);
}

export function* watchPlaceList() {
  yield takeLatest(placeAction.placeList, placeList);
}

export function* watchPlaceAdd() {
  yield takeLatest(placeAction.placeAdd, placeAdd);
}

export function* watchPlaceEdit() {
  yield takeLatest(placeAction.placeEdit, placeEdit);
}

export function* watchPlaceRemove() {
  yield takeLatest(placeAction.placeRemove, placeRemove);
}

export function* watchPlaceImageUpload() {
  yield takeLatest(placeAction.placeImageUpload, placeImageUpload);
}

export function* watchPricingPayment() {
  yield takeLatest(placeAction.pricingPayment, pricingPayment);
}

export function* watchMemberList() {
  yield takeLatest(placeAction.memberList, memberList);
}

export function* watchMemberDetail() {
  yield takeLatest(placeAction.memberDetail, memberDetail);
}

export function* watchMemberAdd() {
  yield takeLatest(placeAction.memberAdd, memberAdd);
}

export function* watchMemberEdit() {
  yield takeLatest(placeAction.memberEdit, memberEdit);
}

export function* watchMemberRemove() {
  yield takeLatest(placeAction.memberRemove, memberRemove);
}

export function* watchPlayerInfo() {
  yield takeLatest(placeAction.playerInfo, playerInfo);
}

function* rootSaga() {
  yield all([
    fork(watchPlaceInfo),
    fork(watchPlaceList),
    fork(watchPlaceAdd),
    fork(watchPlaceEdit),
    fork(watchPlaceRemove),
    fork(watchPlaceImageUpload),
    fork(watchPricingPayment),
    fork(watchMemberList),
    fork(watchMemberDetail),
    fork(watchMemberAdd),
    fork(watchMemberEdit),
    fork(watchMemberRemove),
    fork(watchPlayerInfo),
  ]);
}

export default rootSaga;
