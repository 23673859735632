export function hexToRgb(hexCode) {
  const hex = hexCode.trim().replace('#', '');

  /* rgb로 각각 분리해서 배열에 담기. */
  const rgbArr = 3 === hex.length ? hex.match(/[a-f\d]/gi) : hex.match(/[a-f\d]{2}/gi);

  rgbArr.forEach(function (str, x, arr) {
    /* rgb 각각의 헥사값이 한자리일 경우, 두자리로 변경하기. */
    if (str.length === 1) str = str + str;

    /* 10진수로 변환하기. */
    arr[x] = parseInt(str, 16);
  });

  return { r: rgbArr[0], g: rgbArr[1], b: rgbArr[2] };
}

export function rgbToHex(rgba) {
  const rgbaArr = [rgba.r, rgba.g, rgba.b, rgba.a];
  const hex = `${((1 << 24) + (parseInt(rgbaArr[0]) << 16) + (parseInt(rgbaArr[1]) << 8) + parseInt(rgbaArr[2]))
    .toString(16)
    .slice(1)}`.toUpperCase();

  return hex;
}
