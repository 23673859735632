import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface OpenPayload {
  photoId: string;
}

interface Photo {
  photoId: string;
  photoFile: string;
}

interface OpenState {
  photoInfo: Photo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const openInitialState: OpenState = {
  photoInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  photoDetail: (state: OpenState, { payload }: PayloadAction<OpenPayload>) => {
    state.isLoading = true;
    state.actionResult = 'PHOTO_DETAIL_REQ';
    state.error = '';
  },
  photoDetailSuccess: (
    state: OpenState,
    { payload }: PayloadAction<OpenState>,
  ) => {
    state.photoInfo = payload.photo;
    state.isLoading = false;
    state.actionResult = 'PHOTO_DETAILT_OK';
    state.error = '';
  },
  photoDetailFailure: (state: OpenState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'PHOTO_DETAIL_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: OpenState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'open',
  initialState: openInitialState,
  reducers: reducers,
});

const selectPhotoInfo = createDraftSafeSelector(
  (state: OpenState) => state.photoInfo,
  photoInfo => photoInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: OpenState) => state.actionResult,
  (state: OpenState) => state.isLoading,
  (state: OpenState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const openSelector = {
  photoInfo: state => selectPhotoInfo(state[OPEN]),
  status: state => selectStatus(state[OPEN]),
};

export const OPEN = slice.name;
export const openReducer = slice.reducer;
export const openAction = slice.actions;
