import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface LayerState {
  layerId: string;
  layerOrder: number;
  width: number;
  height: number;
  x: number;
  y: number;
  title: number;
  color: string;
  baseYn: string;
  muteYn: string;
}

interface LayerHistoryPayload {
  historyStep?: number;
  activeLayerId?: string;
  layerState?: Array<LayerState>;
}

interface LayerHistoryState {
  historyStep: number;
  historyActiveLayerId: Array<string>;
  historyList: Array<LayerState>;
  isLoading: boolean;
  error: string | null;
}

const initialState: LayerHistoryState = {
  historyStep: -1,
  historyActiveLayerId: [],
  historyList: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'layerHistory',
  initialState,
  reducers: {
    initLayerHistoryState(state: LayerHistoryState, { payload }: PayloadAction<String>) {
      state.historyStep = -1;
      state.historyActiveLayerId = [];
      state.historyList = [];
    },
    addLayerHistory(state: LayerHistoryState, { payload }: PayloadAction<LayerHistoryPayload>) {
      state.historyList.splice(state.historyStep + 1, state.historyList.length, payload.layerState);
      state.historyActiveLayerId.splice(state.historyStep + 1, state.historyActiveLayerId.length, payload.activeLayerId);
      state.historyStep += 1;
    },
    setHistoryStep(state: LayerHistoryState, { payload }: PayloadAction<LayerHistoryPayload>) {
      state.historyStep = payload.historyStep;
    },
    removeAllLayerHistory(state: LayerHistoryState, { payload }: PayloadAction<String>) {
      state.historyStep = initialState.historyStep;
      state.historyActiveLayerId = initialState.historyActiveLayerId;
      state.historyList = initialState.historyList;
    },
    initLayerHistory(state: LayerHistoryState, { payload }: PayloadAction<LayerHistoryPayload>) {
      state.isLoading = true;
      state.error = null;
    },
    initLayerHistorySuccess(state: LayerHistoryState, { payload }: PayloadAction<LayerHistoryPayload>) {
      state.historyStep = initialState.historyStep + 1;
      state.historyActiveLayerId.splice(0, state.historyActiveLayerId.length, payload.activeLayerId);
      state.historyList = payload.layerState;

      state.isLoading = false;
      state.error = null;
    },
    initLayerHistoryFailure(state: LayerHistoryState, { payload }: PayloadAction<String>) {
      state.isLoading = false;
      state.error = payload;
    },
  }
});

const selectHistoryStep = createDraftSafeSelector(
  (state: LayerHistoryState) => state.historyStep,
  (historyStep) => historyStep,
);

const selectHistoryActiveLayerId = createDraftSafeSelector(
  (state: LayerHistoryState) => state.historyActiveLayerId,
  (historyActiveLayerId) => historyActiveLayerId,
);

const selectHistoryList = createDraftSafeSelector(
  (state: LayerHistoryState) => state.historyList,
  (historyList) => historyList,
);

export const layerHistorySelector = {
  historyStep: state => selectHistoryStep(state[LAYERHISTORY]),
  historyActiveLayerId: state => selectHistoryActiveLayerId(state[LAYERHISTORY]),
  historyList: state => selectHistoryList(state[LAYERHISTORY]),
};

export const LAYERHISTORY = slice.name;
export const layerHistoryReducer = slice.reducer;
export const layerHistoryAction = slice.actions;
