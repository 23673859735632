const dataURLtoFile = (dataurl, fileName) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

const createThumb = (fileEl, file) => {
  let width = fileEl.width;
  let height = fileEl.height;

  let canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');

  var maxSize = 350;
  if (width > height) {
    if (width > maxSize) {
      height *= maxSize / width;
      width = maxSize;
    }
  } else {
    if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }
  }

  canvas.width = width;
  canvas.height = height;

  context.drawImage(fileEl, 0, 0, width, height);
  let dataURL = canvas.toDataURL();
  if (!dataURL || dataURL === 'data:,') {
    return null;
  }
  const thumbFile = dataURLtoFile(dataURL, file.name);
  return thumbFile;
};

export const getVideoThumb = file =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement('video');
      video.preload = 'metadata';

      video.src = URL.createObjectURL(file);

      video.addEventListener('loadeddata', async () => {
        video.currentTime = 2;
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        // 속성이 적용되기 위해 200ms 딜레이 한다.
        await new Promise(delay => setTimeout(delay, 200));
        resolve(createThumb(video, file));
      });

      video.onerror = () => {
        reject('Invalid video. Please select a video file.');
      };
    } catch (e) {
      reject(e);
    }
  });

export const getImageThumb = file =>
  new Promise((resolve, reject) => {
    try {
      let image = document.createElement('img');
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        resolve(createThumb(image, file));
      };
    } catch (e) {
      reject(e);
    }
  });

export const getVideoDuration = file =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        resolve(parseInt(video.duration));
      };

      video.onerror = function () {
        reject('Invalid video. Please select a video file.');
      };

      video.src = URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });
