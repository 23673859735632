import { editorAction } from '../module/editorSlice';
import { interactionBaseApi } from './interactionBaseApi';

export const layerApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    layerList: build.query({
      query: data => ({ url: '/layer/list', data }),
      transformResponse: response => {
        const layerList = response.layerList;

        const sortLayerList = layerList.sort((lhs, rhs) => lhs.layerOrder - rhs.layerOrder);

        return sortLayerList;
      },
      providesTags: (result, error, payload) => [...result.map(({ layerId }) => ({ type: 'LAYER', id: layerId })), { type: 'LAYER', id: 'LIST' }],
      keepUnusedDataFor: 0,
    }),
    layerAddList: build.mutation({
      query: data => ({ url: '/layer/add-list', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'LAYER', id: 'LIST' }],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        let patchResult = undefined;
        try {
          const { data } = await queryFulfilled;

          if (data.resultFlag) {
            const addList = data.addList.filter(add => add.layerId);
            if (addList.length > 0) {
              patchResult = dispatch(
                layerApi.util.updateQueryData('layerList', { frameId: addList[0].frameId }, draft => {
                  draft.push(...addList);
                }),
              );
            }
          }
        } catch {
          patchResult.undo();
        }
      },
    }),
    layerUpdateList: build.mutation({
      query: data => ({ url: '/layer/update-list', data }),
      invalidatesTags: [{ type: 'LAYER', id: 'LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const frameId = getState().editor.frameId || '';

        const patchResultList = [];
        for (const update of updateList) {
          const { layerId, updateInfo } = update;

          patchResultList.push(
            dispatch(
              layerApi.util.updateQueryData('layerList', { frameId }, draft => {
                const index = draft.findIndex(layer => layer.layerId === layerId);
                draft[index] = { ...draft[index], ...updateInfo };
              }),
            ),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          patchResultList.forEach(patchResult => patchResult.undo());
        }
      },
    }),
    layerRemoveList: build.mutation({
      async queryFn({ removeList }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const frameId = getState().editor.frameId || '';

          let orderUpdateList = [];
          patchResult = dispatch(
            layerApi.util.updateQueryData('layerList', { frameId }, draft => {
              dispatch(editorAction.filterSelectedLayerList({ removeList: removeList.map(remove => ({ id: remove.layerId })) }));
              for (const remove of removeList) {
                const index = draft.findIndex(layer => layer.layerId === remove.layerId);
                draft.splice(index, 1);
              }

              orderUpdateList = draft.reduce((target, newLayer, index) => {
                if (newLayer.layerOrder !== index) {
                  draft[index].layerOrder = index;
                  target.push({
                    layerId: newLayer.layerId,
                    layerOrder: index,
                  });
                }
                return target;
              }, []);

              draft = draft.sort((lhs, rhs) => lhs.layerOrder - rhs.layerOrder);
            }),
          );

          if (orderUpdateList.length > 0) {
            await fetchInteractionApi({ url: '/layer/layer-order', data: { updateList: orderUpdateList } });
          }

          const result = await fetchInteractionApi({ url: '/layer/remove-list', data: { removeList } });
          return result;
        } catch (error) {
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
      invalidatesTags: [{ type: 'LAYER', id: 'LIST' }],
    }),
    layerReOrder: build.mutation({
      query: data => ({ url: '/layer/layer-order', data }),
      invalidatesTags: [{ type: 'LAYER', id: 'LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const frameId = getState().editor.frameId;

        const patchResult = dispatch(
          layerApi.util.updateQueryData('layerList', { frameId }, draft => {
            for (const update of updateList) {
              const layerId = update.layerId;
              const layerOrder = update.layerOrder;
              const index = draft.findIndex(layer => layer.layerId === layerId);
              draft[index].layerOrder = layerOrder;
            }
            draft = draft.sort((lhs, rhs) => lhs.layerOrder - rhs.layerOrder);
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    baseLayerUpdate: build.mutation({
      query: data => ({ url: '/layer/base-layer-change', data }),
      invalidatesTags: [{ type: 'LAYER', id: 'LIST' }],
      async onQueryStarted({ layerId, frameId }, { dispatch, queryFulfilled, getState }) {
        dispatch(editorAction.updateSelectedLayerInfo({ baseYn: 'Y' }));

        const patchResult = dispatch(
          layerApi.util.updateQueryData('layerList', { frameId }, draft => {
            const oldBaseLayerIndex = draft.findIndex(layer => layer.baseYn === 'Y');
            const newBaseLayerIndex = draft.findIndex(layer => layer.layerId === layerId);
            draft[newBaseLayerIndex].baseYn = 'Y';
            draft[oldBaseLayerIndex].baseYn = 'N';
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useLayerListQuery,
  useLayerAddListMutation,
  useLayerUpdateListMutation,
  useLayerRemoveListMutation,
  useBaseLayerUpdateMutation,
  useLayerReOrderMutation,
} = layerApi;
