import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import CheckBox from 'newComponents/CheckBox';
import Loading from 'newComponents/Loading';
import Modal from 'newComponents/Modal';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components';
import { formatBytes } from 'utils/file';
import { changeFromSecondToTime } from 'utils/time';
import { accountSelector } from '../../module/accountSlice';
import Button from '../../newComponents/Button';
import {
  Content,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
  TableTitle,
  Title,
} from './components/CommonComponent';
import { RiSearchLine as SearchIcon } from 'react-icons/ri';
import { useChannelListQuery, useChannelRemoveMutation } from 'rtk/channelApi';

const ChannelList = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { viewPlaceId: placeId } = useSelector(accountSelector.accountInfo);
  const [checkList, setCheckList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [removeModal, setRemoveModal] = useState(false);

  //rtk
  const { currentData: channelList, isLoading } = useChannelListQuery({ placeId });
  const [channelRemove, { isLoading: isChannelRemoveLoading }] = useChannelRemoveMutation();

  useEffect(() => {
    if (!placeId) {
      return;
    }
  }, [placeId]);

  const handleDetailPage = useCallback(
    channelId => {
      navigate('/channel/detail', { state: { channelId } });
    },
    [navigate],
  );

  //채널 삭제
  const handleRemoveChannel = useCallback(() => {
    if (checkList.length > 0) {
      channelRemove({ removeList: checkList, placeId }).then(res => {
        setCheckList([]);
        setRemoveModal(removeModal => false);
        toast.success('삭제되었습니다.');
      });
    }
  }, [checkList, channelRemove, placeId]);

  const handleCheckChannel = useCallback(
    channelId => {
      if (channelId === 'all') {
        if (checkList.length === channelList.length) {
          setCheckList([]);
        } else {
          setCheckList(channelList.map(item => item.channelId));
        }
      } else {
        const isExist = checkList.some(item => item === channelId);
        if (isExist) {
          setCheckList(checkList.filter(item => item !== channelId));
        } else {
          setCheckList(checkList.concat(channelId));
        }
      }
    },
    [channelList, checkList],
  );

  const handleChangeSearch = useCallback(searchValue => {
    setSearchText(searchValue.trim());
  }, []);

  return (
    <Container isMobile={isMobile}>
      <Content>
        <Header>
          <InputDiv isMobile={isMobile}>
            <div className="inputWrapper">
              <span className="icon">
                <SearchIcon size={20} color="#999999" />
              </span>
              <SearchInput
                placeholder={'채널명을 입력해 주세요.'}
                width={'100%'}
                height={'100%'}
                onChange={e => handleChangeSearch(e.target.value)}
              />
            </div>
          </InputDiv>
          <ButtonDiv>
            <Button onClick={() => navigate('/channel/detail')}>등록</Button>
            <RemobeStyledButton
              color={'red'}
              onClick={() => setRemoveModal(removeModal => true)}
              disabled={checkList.length <= 0}
            >
              삭제
            </RemobeStyledButton>
          </ButtonDiv>
        </Header>
        <Table>
          <TableHead>
            <TableTitle>
              <TableColumn className="number">
                <CheckBox
                  checked={checkList.length === channelList?.length}
                  onChange={() => handleCheckChannel('all')}
                />
              </TableColumn>
              <TableColumn className="number">No</TableColumn>
              <TableColumn className="title" isMobile={isMobile}>
                채널명
              </TableColumn>
              <TableColumn className="title" isMobile={isMobile}>
                채널 설명
              </TableColumn>
              <TableColumn className="status">총 재생시간</TableColumn>
              <TableColumn className="status">총 파일 용량</TableColumn>
              <TableColumn className="reg-date">등록일시</TableColumn>
            </TableTitle>
          </TableHead>
          <TableBody className="designed-scroll">
            {isChannelRemoveLoading || isLoading ? (
              <Loading />
            ) : (
              <>
                {channelList?.length <= 0 ? (
                  <NoData>조회된 데이터가 없습니다.</NoData>
                ) : (
                  channelList
                    ?.filter(item => item.channelNm?.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                    .map((channel, index) => {
                      let { hour, minute, second } = changeFromSecondToTime(channel?.totChannelDuration || 0);
                      return (
                        <TableRow key={'notice-list-' + index} onClick={() => handleDetailPage(channel?.channelId)}>
                          <TableColumn
                            className="number"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <CheckBox
                              checked={checkList.some(item => item === channel?.channelId)}
                              onChange={e => {
                                handleCheckChannel(channel?.channelId);
                              }}
                            />
                          </TableColumn>
                          <TableColumn className="number">{index + 1}</TableColumn>
                          <TableColumn className="title" isMobile={isMobile}>
                            {channel.channelNm}
                          </TableColumn>
                          <TableColumn className="title" isMobile={isMobile}>
                            {channel.channelDesc}
                          </TableColumn>
                          <TableColumn className="status"> {`${hour}:${minute}:${second}`}</TableColumn>
                          <TableColumn className="status"> {formatBytes(channel?.totChannelFileSize || 0)}</TableColumn>
                          <TableColumn className="reg-date"> {dayjs(channel?.regDt).format('YYYY-MM-DD')}</TableColumn>
                        </TableRow>
                      );
                    })
                )}
              </>
            )}
          </TableBody>
        </Table>
      </Content>

      <Modal
        size={'small'}
        visible={removeModal}
        title="삭제"
        isLine={true}
        confirmText={'삭제'}
        onCancel={() => setRemoveModal(false)}
        onClose={() => setRemoveModal(false)}
        onConfirm={handleRemoveChannel}
        cancelText={'취소'}
        children={<>선택한 채널을 삭제하시겠습니까?</>}
      />

      <ToastContainer
        style={{ width: 'auto', minWidth: '312px', zIndex: '10000' }}
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </Container>
  );
};

const RemobeStyledButton = styled(Button)`
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: rgb(149 70 70 / 48%);
    `}
`;

const SearchInput = styled.input`
  height: 2.5rem;
  padding: 0 0.6875rem;
  min-width: 13rem;
  width: 100%;
  background: #ffffff;
  border: 0.0625rem solid #999999;
  border-radius: 0.375rem;
  outline: 0;
  font-weight: 500;
  font-size: 1rem;
  color: #999999;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  flex: 2;

  .inputWrapper {
    width: ${({ isMobile }) => (isMobile ? '50%' : '30%')};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;
  }
`;

const ButtonDiv = styled.div`
  gap: 1px;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.isMobile ? '0.625rem' : '1.875rem 2.1875rem')};
  background-color: ${props => props.theme.bodyBg};

  .pageTitle {
    color: gray;
  }
`;

const NoData = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export default ChannelList;
