import React, { useEffect, useCallback, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { FaRegTimesCircle as RemoveIcon } from 'react-icons/fa';

import useMultiContents from '../../../../newComponents/useMultiContents';

import SelectInput from '../../../../newComponents/SelectInput';

const OneCompany = ({ index, company, companyList, handleChangeCompanyOption, frameOptionList, formValidate, handleChangeObject }) => {
  const { fileList, addFileList, removeFileList, handleSetOriginFileList, renderMultiContentsComponent } = useMultiContents();

  useEffect(() => {
    if (company) {
      handleSetOriginFileList(company.logoFile ? [company.logoFile] : []);
      // initFlag.current = true;
    }
  }, [company, handleSetOriginFileList, index]);

  useEffect(() => {
    if (addFileList.length > 0 || removeFileList.length > 0) {
      let logoFileId = company?.logoFile?.fileId || '';
      let fileListId = fileList?.[0]?.fileId || '';

      if (logoFileId !== fileListId) {
        let changeCompanyList = [...companyList];
        if (fileListId === '') {
          const { logoFile, ...changeCompanyInfo } = company;
          changeCompanyList[index] = changeCompanyInfo;
        } else {
          changeCompanyList[index] = { ...company, logoFile: fileList[0] };
        }

        handleChangeObject('contentsData', 'companyList', changeCompanyList);
        // initFlag.current = false;
      }
    }
  }, [addFileList.length, company, companyList, fileList, handleChangeObject, index, removeFileList.length]);

  return (
    <Container>
      <Box>
        <LeftArea>
          <LogoImg>
            <>{renderMultiContentsComponent('image/jpeg, image/png, image/gif', false, false)}</>
          </LogoImg>
        </LeftArea>
        <RightArea>
          <Input
            className="companyName"
            placeholder="* 기업명을 입력하세요."
            value={company.companyName || ''}
            onChange={e => handleChangeCompanyOption(index, 'companyName', e.target.value)}
            isValidate={formValidate.companyList[index] && formValidate.companyList[index].companyName}
          />
          <InputError>{formValidate.companyList[index] && formValidate.companyList[index].companyNameError}</InputError>
          <Input
            className="companyYear"
            placeholder="* 설립연도를 입력하세요."
            value={company.companyYear || ''}
            onChange={e => handleChangeCompanyOption(index, 'companyYear', e.target.value)}
            isValidate={formValidate.companyList[index] && formValidate.companyList[index].companyYear}
          />
          <InputError>{formValidate.companyList[index] && formValidate.companyList[index].companyYearError}</InputError>
          <Input
            className="bossName"
            placeholder="*  대표자명을 입력하세요."
            value={company.bossName || ''}
            onChange={e => handleChangeCompanyOption(index, 'bossName', e.target.value)}
            isValidate={formValidate.companyList[index] && formValidate.companyList[index].bossName}
          />
          <InputError>{formValidate.companyList[index] && formValidate.companyList[index].bossNameError}</InputError>
          <Input placeholder="대표전화를 입력하세요." value={company.telephone || ''} onChange={e => handleChangeCompanyOption(index, 'telephone', e.target.value)} />
          <InputError>{formValidate.companyList[index] && formValidate.companyList[index].telephoneError}</InputError>
          <Input placeholder="사업분야를 입력하세요" value={company.businessArea || ''} onChange={e => handleChangeCompanyOption(index, 'businessArea', e.target.value)} />
          <InputError>{formValidate.companyList[index] && formValidate.companyList[index].businessAreaError}</InputError>

          <FrameSelectWrapper>
            <FrameSelectTitle>프레임링크</FrameSelectTitle>
            <SelectInput
              selectedValue={company.frameLinkId ? company.frameLinkId : null}
              optionList={frameOptionList}
              onSelectChange={value => handleChangeCompanyOption(index, 'frameLinkId', value)}
              defaultTitle={null}
            />
          </FrameSelectWrapper>
        </RightArea>
      </Box>
    </Container>
  );
};

const getColor = props => {
  if (props.isDragAccept) {
    return '#2196f3';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#00e676';
  }
  return '#999999';
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Box = styled.div`
  display: flex;
`;

const LeftArea = styled.div`
  display: flex;
  width: 130px;
  min-width: 130px;
`;
const RightArea = styled.div`
  // display: flex;
  // flex-direction: column;
  flex: 1;
  align-items: center;
  padding-left: 20px;
`;

const LogoImg = styled.div`
  width: 100%;
  height: 154px;
  background: #c4c4c4;
  border: 2px dashed #999999;
  border-radius: 6px;
  border-color: ${props => getColor(props)};
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    object-fit: contain;
  }
`;

const Input = styled.input`
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 6px 17px 6px 12px;
  border: 1px solid ${({ isError }) => (isError ? '#f05b5b' : '#dedede')};
  border-radius: 8px;
  font-size: 12px;
  color: #333333;
  border: 0.0625rem solid ${props => (props.isValidate === undefined || props.isValidate === true ? '#DDDDDD' : '#F05B5B')};

  &:focus {
    outline: none;
  }
`;

const InputError = styled.label`
  height: 1.25rem;
  padding: 0.125rem 0.25rem;
  color: #f05b5b;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
`;

const FrameSelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FrameSelectTitle = styled.div`
  margin-right: 10px;
  font-size: 11px;
`;

export default React.memo(OneCompany);
