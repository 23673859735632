import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';

import ReactHtmlParser from 'react-html-parser';

const TickerMono = ({ tickerData, layerHeight }) => {
  const [tickerText, setTickerText] = useState(tickerData.captionText.replace(/\n/g, '<br/>'));
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [textWidth, setTextWidth] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [key, setKey] = useState(1);

  const containerRef = useRef(0);
  const textRef = useRef(0);

  useEffect(() => {
    setContainerWidth(containerRef.current.offsetWidth);
    setContainerHeight(containerRef.current.offsetHeight);
    setTextWidth(textRef.current.offsetWidth);
    setTextHeight(textRef.current.offsetHeight);
  }, [tickerData, containerRef, textRef]);

  const [style, setStyle] = useSpring(() => {
    return { transform: `translate3d(0, 0, 0)` };
  });

  useEffect(() => {
    if (containerWidth && containerHeight && textWidth && textHeight) {
      switch (tickerData.animation) {
        case 'rightToLeft':
          setStyle({
            from: { transform: `translate3d(${containerWidth / 2 + textWidth / 2}px, 0, 0)` },
            to: { transform: `translate3d(-${containerWidth / 2 + textWidth / 2}px, 0, 0)` },
            config: {
              duration: `${tickerData.transitionTime}` * 1000,
            },
            reset: true,
            onRest: () => {
              setKey(key + 1);
            },
          });
          break;
        case 'topToBottom':
          setStyle({
            from: { transform: `translate3d(0, -${textHeight}px, 0)` },
            to: { transform: `translate3d(0, ${containerHeight}px, 0)` },
            config: {
              duration: `${tickerData.transitionTime}` * 1000,
            },
            reset: true,
            onRest: () => {
              setKey(key + 1);
            },
          });
          break;
        case 'bottomToTop':
          setStyle({
            from: { transform: `translate3d(0, ${containerHeight}px, 0)` },
            to: { transform: `translate3d(0, -${textHeight}px, 0)` },
            config: {
              duration: `${tickerData.transitionTime}` * 1000,
            },
            reset: true,
            onRest: () => {
              setKey(key + 1);
            },
          });
          break;
        default:
          break;
      }
    }
  }, [tickerData, containerWidth, containerHeight, textWidth, textHeight, key, setKey, setStyle]);

  return (
    <TickerBox ref={containerRef} key={key} height={layerHeight} tickerTheme={tickerData.animation}>
      <Background />
      <TextBox ref={textRef} style={style} $textInfo={tickerData}>
        {/* {tickerData.text || ReactHtmlParser(tickerText)} */}
        {ReactHtmlParser(tickerText)}
      </TextBox>
    </TickerBox>
  );
};

const TickerBox = styled.div`
  width: 100%;
  height: ${({ height }) => height || 100}px;
  /* background-color: #000; */ // 레이어 배경색 적용을 위해 주석처리
  color: #fff;
  font-size: 40px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: ${props => (props.tickerTheme === 'rightToLeft' ? 'center' : '')};
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  flex: none;
`;

const TextBox = styled(animated.span)`
  width: auto;
  height: auto;
  line-height: 100px;
  flex: none;
  display: flex;
  position: absolute;

  ${({ $textInfo }) => {
    return css`
      color: ${$textInfo?.color
        ? `rgba(${$textInfo.color?.r},${$textInfo.color?.g},${$textInfo.color?.b},${$textInfo.color?.a})`
        : 'inherit'};
      font-size: ${$textInfo?.fontSize ? $textInfo?.fontSize + 'px' : 'inherit'};
      font-weight: ${$textInfo?.boldYn === 'Y' ? 'bold' : 'normal'};
      font-style: ${$textInfo?.italicYn === 'Y' ? 'italic' : 'normal'};
      text-decoration-line: ${() => {
        let result = '';
        if ($textInfo?.underlineYn === 'Y') {
          result += 'underline ';
        }
        if ($textInfo?.lineThroughYn === 'Y') {
          result += 'line-through ';
        }
        return result ? result : 'none';
      }};
      text-shadow: ${$textInfo?.textShadowYn === 'Y' ? '2px 2px 4px #777' : 'none'};
    `;
  }}
`;

export default React.memo(TickerMono);
