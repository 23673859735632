import { all, fork, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import * as channelApiLib from '../lib/channelApi';
import { channelAction } from './channelSlice';

function* list({ payload: placeId }) {
  try {
    const channelList = yield call(channelApiLib.list, placeId);
    let sortList = channelList.sort((a, b) => (a.regDt === b.regDt ? 0 : a.regDt > b.regDt ? -1 : 1));

    yield put(channelAction.getChannelListSuccess(sortList));
  } catch (error) {
    if (error.message === 'no channelList.') {
    }
    yield put(channelAction.getChannelListFailure('getChannelList error'));
  }
}

function* detail({ payload: channelId }) {
  try {
    const channelInfo = yield call(channelApiLib.info, channelId);

    yield put(channelAction.getChannelInfoSuccess(channelInfo));
  } catch (error) {
    yield put(channelAction.getChannelInfoFailure('getChannelList error'));
  }
}

function* add({ payload: { channelInfo, placeId } }) {
  const data = {
    channelInfo,
    placeId,
  };
  try {
    yield call(channelApiLib.add, data);
    yield put(channelAction.addChannelInfoSuccess());
  } catch (error) {
    yield put(channelAction.addChannelInfoFailure(error));
  }
}

function* remove({ payload: { removeList, placeId } }) {
  const data = {
    removeList,
    placeId,
  };
  try {
    const channelList = yield call(channelApiLib.remove, data);
    yield put(channelAction.removeChannelInfoSuccess(channelList));
  } catch (error) {
    yield put(channelAction.removeChannelInfoFailure(error));
  }
}

export function* watchChannelList() {
  yield takeLatest(channelAction.getChannelList, list);
}

export function* watchAddChannel() {
  yield takeEvery(channelAction.addChannelInfo, add);
}

export function* watchRemoveChannel() {
  yield takeEvery(channelAction.removeChannelInfo, remove);
}

export function* watchChannelDetail() {
  yield takeLatest(channelAction.getChannelInfo, detail);
}

function* rootSaga() {
  yield all([fork(watchChannelList), fork(watchAddChannel), fork(watchRemoveChannel), fork(watchChannelDetail)]);
}

export default rootSaga;
