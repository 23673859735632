import React from 'react';
import { Reset } from 'styled-reset';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { injectStyle } from 'react-toastify/dist/inject-style';
import Router from './router';

import 'assets/styles/fonts.css';
import 'react-datepicker/dist/react-datepicker.css';

const theme = {
  white: '#fff !default',
  black: '#000 !default',
  grayBackground: '#DFDFDF',
  gray50: '#F6F6F6',
  gray75: '#F5F5F5',
  gray100: '#F3F3F3',
  gray200: '#EEEEEE',
  gray250: '#EBEBEB',
  gray300: '#DDDDDD',
  gray400: '#C8C8C8',
  gray500: '#CCCCCC',
  gray600: '#999999',
  gray700: '#666666',
  gray800: '#333333',
  gray900: '#111111',
  blue: '#41a1ea',
  indigo: '#727cf5',
  purple: '#6b5eae',
  pink: '#5e5759',
  red: '#f05b5b',
  orange: '#fd7e14',
  yellow: '#ffbc00',
  green: '#0acf97',
  teal: '#02a8b5',
  cyan: '#39afd1',
  bodyBg: '#f4f5f6',
};

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }

  body {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', 'Noto Sans KR', 'NanumSquare', sans-serif !important;
    box-sizing: border-box;

    #root {
      width: 100%;
      height: 100%;
    }
  }

  /* button, input, textarea {
    font: inherit;
  } */

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: none;
    &:focus {
      outline: none;
    }
  }

  * {
    box-sizing: inherit;
  }

  *:not(input, textarea) {
    /* 드래그 방지 css */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  /* 포커스 시 placeholder 숨기기 in <input>, <textarea> */
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder { /* WebKit browsers */
    color: transparent;
  } 
  input:focus:-moz-placeholder,
  textarea:focus:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:transparent;
  }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:transparent;
  }
  input:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder { /* Internet Explorer 10+ */
    color:transparent;
  }

  .designed-scroll::-webkit-scrollbar {
    width: 6px;
  }
  .designed-scroll::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  .designed-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }

  .viewer {
    overflow: hidden;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select:none;
  }
  .viewer::-webkit-scrollbar {
    display: none;
  }

 // ReactHtmlParser 시 reset css 때문에 적용 안되는 태그 및 클래스 재설정
 .htmlparser {
    h1 {
      font-size: 2em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.5em;
      font-weight: bold;
    }
    h3 {
      font-size: 1.17em;
      font-weight: bold;
    }
    h4 {
      font-size: 1.33em;
      font-weight: bold;
    }
    h5 {
      font-size: .83em;
      font-weight: bold;
    }
    h6 {
      font-size: .67em;
      font-weight: bold;
    }
    strong { 
      font-weight : bold; 
    }
    em { 
      font-style : italic; 
    }
    u { 
      text-decoration : underline; 
    }
    s { 
      text-decoration : line-through; 
    }
    blockquote {
      border-left : 4px solid #aaaaaa;
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left : 16px;
    }
    ol {
      display: block;
      list-style-type: decimal;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      padding-left: 40px;
     }
    ul {
      display: block;
      list-style-type: disc;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      padding-left: 40px;
    }
    // 들여쓰기
    .ql-indent-1 {
      padding-left : 3em;
    }
    .ql-indent-2 {
      padding-left : 6em;
    }
    .ql-indent-3 {
      padding-left : 9em;
    }
    .ql-indent-4 {
      padding-left : 12em;
    }
    .ql-indent-5 {
      padding-left : 15em;
    }
    .ql-indent-6 {
      padding-left : 18em;
    }
    .ql-indent-7 {
      padding-left : 21em;
    }
    .ql-indent-8 {
      padding-left : 24em;
    }

    //가운데 정렬
    .ql-align-center {
      text-align : center;
    }
    //오른쪽 정렬
    .ql-align-right {
      text-align : right;
    }
    //일반 정렬
    .ql-align-justify {
      text-align : justify;
    }
  }
`;

const App = props => {
  injectStyle();

  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  );
};

export default App;
