import { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { editorAction, editorSelector } from '../../module/editorSlice';

import { CANVAS_MAX_ZOOMLEVEL, CANVAS_MIN_ZOOMLEVEL } from './constants/editor';

const useLayerKeyEvent = ({ viewportRef, infiniteViewerRef, historyRef, clipboardRef }) => {
  const dispatch = useDispatch();

  const canvasInfo = useSelector(editorSelector.canvasInfo);

  // 랜더링으로 인한 이벤트 중복 방지 ref
  const _canvasInfo = useRef(canvasInfo);

  useEffect(() => {
    _canvasInfo.current = canvasInfo;
  }, [canvasInfo]);

  // 마우스 휠이벤트
  const shortcutZoomWheel = useCallback(
    e => {
      if (e.ctrlKey) {
        e.preventDefault();
        if (e.deltaY < 0) {
          const zoomIn = _canvasInfo.current.zoom + _canvasInfo.current.zoom * 0.05;
          if (zoomIn >= CANVAS_MAX_ZOOMLEVEL) {
            dispatch(editorAction.updateCanvasInfo({ zoom: CANVAS_MAX_ZOOMLEVEL }));
          } else {
            dispatch(editorAction.updateCanvasInfo({ zoom: zoomIn }));
          }
        } else {
          const zoomOut = _canvasInfo.current.zoom - _canvasInfo.current.zoom * 0.05;
          if (zoomOut <= CANVAS_MIN_ZOOMLEVEL) {
            dispatch(editorAction.updateCanvasInfo({ zoom: CANVAS_MIN_ZOOMLEVEL }));
          } else {
            dispatch(editorAction.updateCanvasInfo({ zoom: zoomOut }));
          }
        }
      }
    },
    [dispatch],
  );

  useEffect(() => {
    viewportRef.current.addEventListener('wheel', shortcutZoomWheel, { passive: false });
  }, [viewportRef, shortcutZoomWheel]);
};

export default useLayerKeyEvent;
