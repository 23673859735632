import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

import { changeFromSecondToTime } from '../../../../../utils/time';

import Modal from '../../../../../newComponents/Modal';
import { editorAction, editorSelector } from '../../../../../module/editorSlice';

import { useLayerContentsAddMutation } from '../../../../../rtk/layerContentsApi';
import { useOverlayContentsAddMutation } from '../../../../../rtk/overlayContentsApi';
import { endpoints as endpointsFrameApi, useFrameUpdateMutation } from '../../../../../rtk/frameApi';

const CreateConfirmModal = ({
  formData,
  addFileList,
  isOpen,
  closeModal,
  handleUploadProgress,
  handleUploadStart,
  handleUploadEnd,
  saveHistoryToLocalStorage,
  selectedLayerType,
}) => {
  const dispatch = useDispatch();

  const placeId = useSelector(editorSelector.placeId);
  const selectedLayerList = useSelector(editorSelector.selectedLayerList);

  const [frameUpdate] = useFrameUpdateMutation();
  const [addLayerContents, { isLoading: isLayerContentsAddLoading }] = useLayerContentsAddMutation({
    fixedCacheKey: 'layer-app-create',
  });
  const [addOverlayContents, { isLoading: isOverLayContentsAddLoading }] = useOverlayContentsAddMutation({
    fixedCacheKey: 'overlay-app-create',
  });

  const createApp = useCallback(() => {
    const newContentsId = dayjs().unix() + v4().substr(0, 8);
    const createFormData = { ...formData, contentsId: newContentsId };

    let totalLength = 0;
    let totalSize = 0;
    if (formData.contentsType === 'media' || formData.contentsType === 'audio') {
      if (
        selectedLayerList.length === 1 &&
        selectedLayerList[0].type === 'LAYER' &&
        selectedLayerList[0].baseYn === 'Y' &&
        formData.contentsType === 'media'
      ) {
        let changeTime = 0;
        for (const mediaInfo of formData.contentsData.mediaList) {
          changeTime += Number(mediaInfo.changeTime);
        }
        frameUpdate({ frameId: formData.frameId, frameInfo: { frameDuration: changeFromSecondToTime(changeTime) } });
      }

      for (const fileInfo in addFileList) {
        totalSize += addFileList[fileInfo].fileList.reduce((sum, file) => sum + file.fileSize, 0);
        totalLength += addFileList[fileInfo].fileList.length;
      }
    } else {
      totalSize = addFileList.reduce((sum, file) => sum + file.fileSize, 0);
      totalLength = addFileList.length;
    }

    handleUploadStart(totalSize, totalLength);
    saveHistoryToLocalStorage(formData.contentsType);

    if (selectedLayerType === 'OVERLAY') {
      addOverlayContents({
        formData: createFormData,
        addFileList: addFileList,
        placeId: placeId,
        folderId: `${placeId}-app`,
        folderNm: 'app-file',
        handleUploadProgress,
      }).then(() => {
        handleUploadEnd();
      });
    } else {
      addLayerContents({
        formData: createFormData,
        addFileList: addFileList,
        placeId: placeId,
        folderId: `${placeId}-app`,
        folderNm: 'app-file',
        handleUploadProgress,
      }).then(() => {
        handleUploadEnd();
      });
    }

    closeModal();
    dispatch(editorAction.editingModeLayerContents(false));
  }, [
    formData,
    handleUploadStart,
    saveHistoryToLocalStorage,
    selectedLayerType,
    closeModal,
    dispatch,
    selectedLayerList,
    frameUpdate,
    addFileList,
    addOverlayContents,
    placeId,
    handleUploadProgress,
    handleUploadEnd,
    addLayerContents,
  ]);

  return (
    <Modal
      title={<Title>App 생성</Title>}
      isLine
      onConfirm={createApp}
      onCancel={closeModal}
      onClose={closeModal}
      onBackdropClick={closeModal}
      visible={isOpen}
      size="large"
    >
      <Notice>Layer App을 생성하시겠습니까?</Notice>
    </Modal>
  );
};

const Title = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  color: #f05b5b;
  font-size: 14px;
  font-weight: 500;
`;

const Notice = styled.div`
  font-size: 14px;
  color: #333333;
`;

export default CreateConfirmModal;
