import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import FadeLoader from 'react-spinners/FadeLoader';

import { useRefreshTokenMutation } from 'rtk/authApi';

const Token = () => {
  const navigate = useNavigate();

  const [refreshToken] = useRefreshTokenMutation();

  useEffect(() => {
    refreshToken()
      .unwrap()
      .then(result => {
        // navigate('/dashboard');
      })
      .catch(err => {
        navigate('/account/login');
      });
  }, [navigate, refreshToken]);

  return (
    <Container>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FadeLoader color={'#41a1ea'} loading={true} size={100} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.grayBackground};
`;

export default React.memo(Token);
