import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';

const Error = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Container>
      <span className="comment-service-not">
        <span>서비스</span>에 접속할 수 없습니다.
      </span>
      <span className="comment-sorry">
        페이지를 로드하는 도중에 오류가 발생하였습니다.
        <br />
        서비스 이용에 불편을 드려 죄송합니다.
      </span>
      <div className="button-area">
        <StyledButton color="black" outline onClick={() => handleBack()}>
          뒤로가기
        </StyledButton>
        <StyledButton color="black" onClick={() => handleReload()}>
          새로고침
        </StyledButton>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 40px;

  .comment-service-not {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
  }

  .comment-sorry {
    font-weight: normal;
    margin-top: 20px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }

  .error-msg {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }

  .button-area {
    margin-top: 30px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 16px;
  line-height: 20px;

  & + & {
    margin-left: 20px;
  }
`;

export default Error;
