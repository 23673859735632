import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface OverlayInfoState {
  overlayId: string,
  overlayType: string,
  overlayData: object,
  overlayFileList: array,
  toggleYn: string,
  playlistId: string,
  width: string,
  height: string,
  x: string,
  y: string,
}

interface OverlayState {
  overlayList: OverlayInfoState[];
  actionResult: string;
  isLoading: string;
  error: string | null;
}

const InitLogo: OverlayInfoState = {
  overlayId: '',
  overlayType: 'logo',
  overlayData: {
    logoFile: {}
  },
  overlayFileList: [],
  toggleYn: 'N',
  playlistId: '',
};

const InitWeather: OverlayInfoState = {
  overlayId: '',
  overlayType: 'weather',
  overlayData: {
    location: '서울특별시',
  },
  overlayFileList: [],
  toggleYn: 'N',
  playlistId: '',
};

const InitTicker: OverlayInfoState = {
  overlayId: '',
  overlayType: 'ticker',
  overlayData: {
    text: '',
    speed: '0',
  },
  overlayFileList: [],
  toggleYn: 'N',
  playlistId: '',
};

const InitNews: OverlayInfoState = {
  overlayId: '',
  overlayType: 'news',
  overlayData: {
    transitionTime: '3',
    animation:  'bottomToTop',
  },
  overlayFileList: [],
  toggleYn: 'N',
  playlistId: '',
};

const InitDatetime: OverlayInfoState = {
  overlayId: '',
  overlayType: 'datetime',
  overlayData: {
    time: 'hmap',
    date: 'mdw'
  },
  overlayFileList: [],
  toggleYn: 'N',
  playlistId: '',
};

// 초기 상태
const OverlayInitialState: OverlayState = {
  overlayList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'overlay',
  initialState: OverlayInitialState,
  reducers: {
    initOverlayState(state: OverlayState) {
      state.overlayList = [];
    },
    getOverlayList(state: OverlayState) {
      state.isLoading = true;
      state.error = null;
    },
    getOverlayListSuccess(state: OverlayState, { payload: overlayList }: PayloadAction<OverlayState>) {
      state.overlayList = overlayList;
      state.isLoading = false;
      state.error = null;
    },
    getOverlayListFailure(state: OverlayState, { payload: error }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = error;
    },
    addOverlay(state: OverlayState, { payload: { overlay } }: PayloadAction<OverlayInfoState>) {
      state.overlayList.push(overlay);
      state.isLoading = true;
      state.error = null;
    },
    addOverlaySuccess(state: OverlayState) {
      state.isLoading = false;
      state.error = null;
    },
    addOverlayFailure(state: OverlayState, { payload: error }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = error;
    },
    updateOverlay(state: OverlayState, { payload: { overlayId, updateOverlay } }: PayloadAction<OverlayInfoState>) {
      const index = state.overlayList.findIndex(overlay => overlay.overlayId === overlayId);
      state.overlayList[index] = { ...state.overlayList[index], ...updateOverlay };
      state.isLoading = true;
      state.error = null;
    },
    updateOverlaySuccess(state: OverlayState) {
      state.isLoading = false;
      state.error = null;
    },
    updateOverlayFailure(state: OverlayState, { payload: error }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = error;
    },
    uploadLogoFile(state: OverlayState) {
      state.isLoading = false;
      state.error = null;
    },
    removeOverlay(state: LayerState, { payload: { overlayId }}: PayloadAction<LayerPayload>) {
      const index = state.overlayList.findIndex(overlay => overlay.overlayId === overlayId);
      state.overlayList.splice(index, 1);

      state.actionResult = 'REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeOverlaySuccess(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'REMOVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    removeOverlayFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'REMOVE_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    uploadLogoFileSuccess(state: OverlayState, { payload: { overlayId, logoFile, overlayFileList } }: PayloadAction<OverlayInfoState>) {
      const index = state.overlayList.findIndex(findOverlay => findOverlay.overlayId === overlayId);
      state.overlayList[index].overlayData.logoFile = logoFile;
      state.overlayList[index].overlayFileList = overlayFileList;
      state.isLoading = false;
      state.error = null;
    },
    uploadLogoFileFailure(state: OverlayState, { payload: error }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = error;
    },
    changeOverlayData(state: OverlayState, { payload: { overlayId, key, value } }: PayloadAction<OverlayInfoState>) {
      const index = state.overlayList.findIndex(overlay => overlay.overlayId === overlayId);
      state.overlayList[index].overlayData[key] = value;
      state.isLoading = false;
      state.error = null;
    },
  },
});

const selectOverlayList = createDraftSafeSelector(
  (state: OverlayState) => state.overlayList,
  (overlayList) => overlayList,
);

const selectOverlayLogo = createDraftSafeSelector(
  (state: OverlayState) => state.overlayList,
  (overlayList) => overlayList.find(overlay => overlay.overlayType === 'logo') || InitLogo,
)
const selectOverlayWeather = createDraftSafeSelector(
  (state: OverlayState) => state.overlayList,
  (overlayList) => overlayList.find(overlay => overlay.overlayType === 'weather') || InitWeather,
)
const selectOverlayNews = createDraftSafeSelector(
  (state: OverlayState) => state.overlayList,
  (overlayList) => overlayList.find(overlay => overlay.overlayType === 'news') || InitNews,
)
const selectOverlayTicker = createDraftSafeSelector(
  (state: OverlayState) => state.overlayList,
  (overlayList) => overlayList.find(overlay => overlay.overlayType === 'ticker') || InitTicker,
)
const selectOverlayDatetime = createDraftSafeSelector(
  (state: OverlayState) => state.overlayList,
  (overlayList) => overlayList.find(overlay => overlay.overlayType === 'datetime') || InitDatetime,
)

const selectStatus = createDraftSafeSelector(
  (state: OverlayState) => state.isLoading,
  (state: OverlayState) => state.error,
  (isLoading, error) => ({ isLoading, error }),
);

export const overlaySelector = {
  overlayList: state => selectOverlayList(state[OVERLAY]),
  logo: state => selectOverlayLogo(state[OVERLAY]),
  weather: state => selectOverlayWeather(state[OVERLAY]),
  news: state => selectOverlayNews(state[OVERLAY]),
  ticker: state => selectOverlayTicker(state[OVERLAY]),
  datetime: state => selectOverlayDatetime(state[OVERLAY]),
  status: state => selectStatus(state[OVERLAY]),
};

export const OVERLAY = slice.name;
export const overlayReducer = slice.reducer;
export const overlayAction = slice.actions;
