import * as apiLib from './api';

export const getPublicFolderList = async ({ folderId }) => {
  try {
    const data = {
      folderId: folderId,
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-folder-list',
      data,
    );
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicFolderCheckAndMake = async ({ placeId, folderPid, folderId, folderNm }) => {
  try {
    const data = {
      placeId: placeId,
      folderPid: folderPid,
      folderId: folderId,
      folderNm: folderNm,
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-folder-check-and-make',
      data,
    );

    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicFolderAdd = async ({ placeId, folderPid, folderNm }) => {
  try {
    const data = {
      placeId: placeId,
      folderPid: folderPid,
      folderNm: folderNm,
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-folder-add',
      data,
    );
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicFolderEdit = async ({
  placeId,
  folderPid,
  folderId,
  folderNm,
}) => {
  try {
    const data = {
      placeId: placeId,
      folderPid: folderPid,
      folderId: folderId,
      folderNm: folderNm,
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-folder-edit',
      data,
    );
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicFolderRemove = async ({ folderId }) => {
  try {
    const data = {
      folderId: folderId,
    };
    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-folder-remove',
      data,
    );
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicFileList = async ({ folderId }) => {
  try {
    const data = {
      folderId: folderId,
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-file-list',
      data,
    );
    if (result.resultFlag) {
      return result.fileList;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicFileDownload = async ({
  fileId,
}) => {
  try {
    const data = {
      fileId: fileId,
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-file-presign-download',
      data,
    );
    if (result.resultFlag) {
      const url = result.url;
      const fileInfo = result.fileInfo;

      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      let element = document.createElement('a');
      element.setAttribute('href', blobUrl);
      element.setAttribute('download', fileInfo.fileNm);
      element.style.display = '';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } else {
      throw Object.assign(new Error('file error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicFileAdd = async ({
  placeId,
  folderId,
  fileDetail,
  fileNm,
  fileSize,
  fileType,
  fileDuration,
  fileThumbId,
  fileThumb,
}) => {
  try {
    const data = {
      placeId: placeId,
      folderId: folderId,
      fileDetail: fileDetail,
      fileNm: fileNm,
      fileSize: fileSize,
      fileType: fileType,
      fileDuration: fileDuration,
      fileThumbId: fileThumbId,
      fileThumb: fileThumb,
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-file-presign-upload',
      data,
    );
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicThumbAdd = async ({
  folderId,
  thumbNm,
  thumbType,
}) => {
  try {
    const data = {
      folderId: folderId,
      thumbNm: thumbNm,
      thumbType: thumbType,
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-thumb-presign-upload',
      data,
    );
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicFileRemove = async ({ fileId }) => {
  try {
    const data = {
      fileId: fileId,
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/storage-file-remove',
      data,
    );
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getPublicFileRemove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPublicFileExistenceCheck = async ({ fileId }) => {
  try {
    const data = {
      fileId: fileId,
    };
    const result = await apiLib.fetchInteractionApi(
      '/publicContents/layer-public-file-check',
      data,
    );
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getFolderListAll = async () => {
  try {
    const data = {};
    const result = await apiLib.fetchInteractionApi(
      '/publicContents/get-folder-list-all',
      data,
    );
    if (result.resultFlag) {
      return result.folderList;
    } else {
      throw Object.assign(new Error('get-folder-list-all error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const changeUseYn = async ({ folderId }) => {
  try {
    const data = {
      folderId
    };

    const result = await apiLib.fetchInteractionApi(
      '/publicContents/change-useYn',
      data,
    );
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('get-folder-list-all error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}