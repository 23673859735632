import { all, fork, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';

import * as frameApiLib from '../lib/frameApi';
import * as playlistApiLib from '../lib/playlistApi';

import { FRAME, frameAction } from './frameSlice';
import { layerAction } from './layerSlice';
import { layerContentsAction } from './layerContentsSlice';
import { layerHistoryAction } from './layerHistorySlice';
import { ACTIVEITEM, activeItemAction, activeItemSelector } from './activeItemSlice';
import { overlayContentsAction } from './overlayContentsSlice';

function* getFrameList({ payload: playlistId}) {
  try {
    const frameList = yield call(frameApiLib.getFrameList, playlistId);
    if (frameList.length) {
      yield put(frameAction.clickFrame({ frameId: frameList[0].frameId, frameOrder: frameList[0].frameOrder }));
    } else {
      yield put(activeItemAction.setActiveFrameId(''));
      yield put(activeItemAction.setInitFrameIdList());
    }
    yield put(frameAction.getFrameListSuccess(frameList));
  } catch (error) {
    if (error.message === 'no playlist.') { // 재생목록 없으면 재생목록 추가 페이지로 replace
      //yield put(replace('/interaction/management'));
    }
    yield put(frameAction.getFrameListFailure('getFrameList error'));
  }
}

function* addFrame({ payload: { activePlaylistId, initFrame, initLayer } }) {
  try {
    yield call(frameApiLib.addFrame, { frame: initFrame });

    yield put(frameAction.addFrameSuccess());
    yield put(frameAction.clickFrame({ frameId: initFrame.frameId, frameOrder: initFrame.frameOrder, addAfter: true, layer: initLayer }));
  } catch (error) {
    yield put(frameAction.addFrameFailure(error));
  }
}

function* removeFrame({ payload: { frameId } }) {
  try {
    yield call(frameApiLib.removeFrame, frameId);

    const frameList = yield select(state => state[FRAME].frameList);

    yield put(frameAction.removeFrameSuccess(frameList));
    yield put(layerAction.removeLayerSuccess());
  } catch (error) {
    yield put(frameAction.removeFrameFailure(error));
  }
}

function* removeMultiFrame({ payload: { frameIdList } }) {
  try {
    for (const frameIdObj of frameIdList) {
      yield call(frameApiLib.removeFrame, frameIdObj.frameId);
    }

    const frameList = yield select(state => state[FRAME].frameList);

    yield put(frameAction.removeFrameSuccess(frameList));
    yield put(layerAction.removeLayerSuccess());
  } catch (error) {
    console.log(error);
    yield put(frameAction.removeFrameFailure(error));
  }
}

function* cloneFrame({ payload: { cloneFrame, activeFrameId, cloneType = 'ALL' }}) {
  try {
    yield call(frameApiLib.cloneFrame, { cloneFrame, activeFrameId, cloneType });

    yield put(frameAction.clickFrame({ frameId: cloneFrame.frameId, frameOrder: cloneFrame.frameOrder }));
    yield put(frameAction.cloneFrameSuccess([cloneFrame]));
    yield put(layerHistoryAction.removeAllLayerHistory());
  } catch (error) {
    console.log(error);
    yield put(frameAction.cloneFrameFailure('cloneFrame error'));
  }
}

function* cloneMultiFrame({ payload: { cloneFrameList, activeFrameIdList, cloneType = 'ALL' }}) {
  try {
    for (let i = 0; i < cloneFrameList.length; i++) {
      yield call(frameApiLib.cloneFrame, { cloneFrame: cloneFrameList[i], activeFrameId: activeFrameIdList[i].frameId, cloneType });
    }

    yield put(frameAction.clickFrame({ frameId: cloneFrameList.slice(-1)[0].frameId, frameOrder: cloneFrameList.slice(-1)[0].frameOrder }));
    yield put(frameAction.cloneFrameSuccess(cloneFrameList));
    yield put(layerHistoryAction.removeAllLayerHistory());
  } catch (error) {
    console.log(error);
    yield put(frameAction.cloneFrameFailure('cloneMultiFrame error'));
  }
}

function* updateFrame({ payload: { frameId, frameInfo } }) {
  try {
    yield call(frameApiLib.updateFrame, { frameId, frameInfo });

    yield put(frameAction.updateFrameSuccess({ frameId, frameInfo }));
  } catch (error) {
    yield put(frameAction.updateFrameFailure('updateFrame error'));
  }
}

function* changePrimaryFrame({ payload: { frameId, primaryYn } }) {
  try {
    const playlistId = yield select(state => state[ACTIVEITEM].activePlaylistId);

    yield call(frameApiLib.changePrimaryFrame, { playlistId, frameId, primaryYn });
    yield call(playlistApiLib.changePrimaryFrame, { playlistId, frameId, primaryYn });
    yield put(frameAction.changePrimaryFrameSuccess());
  } catch (error) {
    yield put(frameAction.changePrimaryFrameFailure('changePrimaryFrame error'));
  }
}

function* clickFrame({ payload: { frameId, frameOrder, addAfter = false, layer, multiClick = false } }) {
  try {
    const playlistId = yield select(activeItemSelector.playlistId);

    const linkedFrameOrderList = yield call(frameApiLib.getLinkedFrame, frameId);

    yield put(activeItemAction.setActiveFrameId(frameId));

    if (!multiClick) {
      yield put(activeItemAction.setInitFrameIdList());
      yield put(activeItemAction.setActiveFrameIdList({ frameId, frameOrder }));
    }

    if (addAfter) {
      yield put(layerHistoryAction.removeAllLayerHistory());
      yield put(layerAction.addLayer({ activeFrameId: frameId, layer }));
    } else {
      yield put(layerAction.initLayerState());
      yield put(layerAction.getLayerList(frameId));
      yield put(layerContentsAction.getLayerContentsList(frameId));
      yield put(overlayContentsAction.getOverlayContentsList(playlistId));
      yield put(layerHistoryAction.initLayerHistory());
    }

    yield put(frameAction.clickFrameSuccess(linkedFrameOrderList));
  } catch (error) {
    yield put(frameAction.clickFrameFailure('clickFrame error'));
  }
}

export function* watchGetFrameList() {
  yield takeLatest(frameAction.getFrameList, getFrameList);
}

export function* watchAddFrame() {
  yield takeEvery(frameAction.addFrame, addFrame);
}

export function* watchRemoveFrame() {
  yield takeEvery(frameAction.removeFrame, removeFrame);
}

export function* watchRemoveMultiFrame() {
  yield takeEvery(frameAction.removeMultiFrame, removeMultiFrame);
}

export function* watchCloneFrame() {
  yield takeEvery(frameAction.cloneFrame, cloneFrame);
}

export function* watchCloneMultiFrame() {
  yield takeEvery(frameAction.cloneMultiFrame, cloneMultiFrame);
}

export function* watchUpdateFrame() {
  yield takeEvery(frameAction.updateFrame, updateFrame);
}

export function* watchChangePrimaryFrame() {
  yield takeEvery(frameAction.changePrimaryFrame, changePrimaryFrame);
}

export function* watchClickFrame() {
  yield takeEvery(frameAction.clickFrame, clickFrame);
}


function* rootSaga() {
  yield all([
    fork(watchGetFrameList),
    fork(watchAddFrame),
    fork(watchRemoveFrame),
    fork(watchRemoveMultiFrame),
    fork(watchCloneFrame),
    fork(watchCloneMultiFrame),
    fork(watchUpdateFrame),
    fork(watchChangePrimaryFrame),
    fork(watchClickFrame),
  ]);
}

export default rootSaga;
