import React, { useEffect, useCallback, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { FaRegTimesCircle as RemoveIcon } from 'react-icons/fa';

import useMultiContents from '../../../../newComponents/useMultiContents';

import SelectInput from '../../../../newComponents/SelectInput';

const Company = ({ fIndex, index, company, companyList, frameOptionList, formValidate, handleChangeCompanyOption, handleChangeCompanyObject }) => {
  const { fileList, addFileList, removeFileList, handleSetOriginFileList, renderMultiContentsComponent } = useMultiContents();

  useEffect(() => {
    if (company) {
      handleSetOriginFileList(company.logoFile ? [company.logoFile] : []);
    }
  }, [company, handleSetOriginFileList, index]);

  useEffect(() => {
    if (addFileList.length > 0 || removeFileList.length > 0) {
      let logoFileId = company?.logoFile?.fileId || '';
      let fileListId = fileList?.[0]?.fileId || '';

      if (logoFileId !== fileListId) {
        if (fileListId === '') {
          const { logoFile, ...changeCompanyInfo } = company;
          handleChangeCompanyObject(index, changeCompanyInfo);
        } else {
          handleChangeCompanyObject(index, { ...company, logoFile: fileList[0] });
        }
      }
    }
  }, [addFileList.length, company, fileList, handleChangeCompanyObject, index, removeFileList.length]);

  return (
    <Container className={`company-${index}`}>
      <Box>
        <LeftArea>
          <LogoImg>
            <>{renderMultiContentsComponent('image/jpeg, image/png, image/gif', false, false, true)}</>
          </LogoImg>
        </LeftArea>
        <RightArea>
          <Input
            placeholder="* 기업명을 입력하세요."
            value={company.companyName || ''}
            onChange={e => handleChangeCompanyOption(index, 'companyName', e.target.value)}
            isValidate={formValidate?.floorList[fIndex]?.companyList[index] && formValidate?.floorList[fIndex]?.companyList[index].companyName}
          />
          <InputError>{formValidate?.floorList[fIndex]?.companyList[index] && formValidate?.floorList[fIndex]?.companyList[index].companyNameError}</InputError>
        </RightArea>
      </Box>
    </Container>
  );
};

const getColor = props => {
  if (props.isDragAccept) {
    return '#2196f3';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#00e676';
  }
  return '#999999';
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const Box = styled.div`
  display: flex;
`;

const LeftArea = styled.div`
  display: flex;
  width: 40px;
  min-width: 20px;
`;

const LogoImg = styled.div`
  width: 100%;
  height: 40px;
  background: #c4c4c4;
  border: 2px dashed #999999;
  border-radius: 6px;
  border-color: ${props => getColor(props)};
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    object-fit: contain;
  }
`;

const RightArea = styled.div`
  // display: flex;
  // flex-direction: column;
  flex: 1;
  align-items: center;
  padding-left: 20px;
`;

const Input = styled.input`
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 6px 17px 6px 12px;
  border: 1px solid ${({ isError }) => (isError ? '#f05b5b' : '#dedede')};
  border-radius: 8px;
  font-size: 13px;
  color: #333333;
  border: 0.0625rem solid ${props => (props.isValidate === undefined || props.isValidate === true ? '#DDDDDD' : '#F05B5B')};

  &:focus {
    outline: none;
  }
`;
const InputError = styled.label`
  height: 1.25rem;
  padding: 0.125rem 0.25rem;
  color: #f05b5b;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
`;

export default React.memo(Company);
