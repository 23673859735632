import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../newComponents/Loading';
import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';
import Floor from './components/Floor';

import { editorAction, editorSelector } from '../../../module/editorSlice';

import { INIT_WHOLEFLOOR_INFO } from '../constants/contentApp';
import { endpoints as enpointsFrameApi } from '../../../rtk/frameApi';

const WholeFloorApp = ({ selectLayerContentsInfo = INIT_WHOLEFLOOR_INFO, handleCancelLayerContents, selectedLayerType, appLoading }) => {
  const playlistId = useSelector(editorSelector.playlistId);
  const { data: frameList } = enpointsFrameApi.frameList.useQueryState({ playlistId });

  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_WHOLEFLOOR_INFO);
  const [formValidate, setFormValidate] = useState({ wholeFloorTitle: true, wholeFloorTitleError: '', floorList: [{ companyList: [] }] });
  const [frameOptionList, setFrameOptionList] = useState([]);

  const [isTitleOpen, setIsTitleOpen] = useState(true);
  const [isFloorOpen, setIsFloorOpen] = useState(true);
  const [isTextOpen, setIsTextOpen] = useState(true);

  const [addFileList, setAddFileList] = useState([]);
  const [removeFileList, setRemoveFileList] = useState([]);

  useEffect(() => {
    if (frameList) {
      let frameOptionList = [{ title: '없음', value: null }];
      frameOptionList = frameOptionList.concat(frameList.map(frame => ({ title: frame.frameOrder + 1, value: frame.frameId })));
      setFrameOptionList(frameOptionList);
    }
  }, [frameList]);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const appContentWrapRef = useRef(null);
  const titleRef = useRef(null);
  const floorListRef = useRef([]);

  const handleChangeFloorOption = useCallback(
    (index, key, value) => {
      let changeFormData = [...formData.contentsData.floorList];
      changeFormData[index][key] = value;

      handleChangeObject('contentsData', 'floorList', changeFormData);
    },
    [formData.contentsData.floorList, handleChangeObject],
  );

  const handleChangeCounter = useCallback(
    type => {
      let changeFormData = [...formData.contentsData.floorList];
      if (type === 'plus') {
        changeFormData.push({
          floorId: changeFormData.length + 1,
          floorTitle: `${changeFormData.length + 1}F`,
          floorSubTitle: '',
          floorYn: 'Y',
          companyList: [
            {
              companyId: 1,
              companyName: '',
              frameLinkId: null,
            },
          ],
        });
        handleChangeObject('contentsData', 'floorList', changeFormData);
      } else if (type === 'minus' && formData.contentsData.floorList.length > 1) {
        changeFormData.pop();
        handleChangeObject('contentsData', 'floorList', changeFormData);
      }
    },
    [formData.contentsData.floorList, handleChangeObject],
  );

  const checkValidDate = useCallback(() => {
    let validate = {
      wholeFloorTitle: true,
      wholeFloorTitleError: '',
      floorList: [],
    };

    let validFlag = false;

    if (formData.contentsData.wholeFloorTitle.trim() === '') {
      validFlag = true;
      validate.wholeFloorTitle = false;
      validate.wholeFloorTitleError = 'Infomation을 입력하세요.';
      setIsTitleOpen(true);
    }
    formData.contentsData.floorList.forEach((floor, index) => {
      let floorValidate = {
        floorSubTitle: true,
        floorSubTitleError: '',
        companyList: [],
      };
      if (floor.floorSubTitle.trim() === '') {
        validFlag = true;
        floorValidate.floorSubTitle = false;
        floorValidate.floorSubTitleError = 'Sub Title을 입력하세요.';
        setIsTextOpen(true);
      }

      floor.companyList.forEach((company, index) => {
        let companyValidate = {
          companyName: true,
          companyNameError: '',
        };
        if (company.companyName.trim() === '') {
          validFlag = true;
          companyValidate.companyName = false;
          companyValidate.companyNameError = '기업명을 입력하세요.';
          setIsTextOpen(true);
        }
        floorValidate.companyList.push(companyValidate);
      });
      validate.floorList.push(floorValidate);
    });

    setFormValidate(validate);

    // todo file upload
    let addFileList = [];
    let removeFileList = [...formData.contentsFileList];

    for (const floor of formData.contentsData.floorList) {
      for (const afterLogoFile of floor.companyList) {
        if (afterLogoFile.logoFile) {
          const index = removeFileList.findIndex(beforeFile => beforeFile.fileId === afterLogoFile.logoFile.fileId);
          if (index > -1) {
            removeFileList.splice(index, 1);
          } else {
            addFileList.push(afterLogoFile.logoFile);
          }
        }
      }
    }

    setAddFileList(addFileList);
    setRemoveFileList(removeFileList);

    if (validFlag) {
      if (!validate.wholeFloorTitle) {
        setTimeout(() => {
          appContentWrapRef.current.scrollTo({ top: titleRef.current.scrollIntoView({ block: 'center' }) });
        }, 100);
      } else {
        let scrollFlag = false;
        const floorList = validate.floorList;
        for (let index = 0; index < floorList.length; index++) {
          if (!floorList[index].floorSubTitle) {
            setTimeout(() => {
              appContentWrapRef.current.scrollTo({ top: floorListRef.current[index].getElementsByClassName('floorSubTitle')[0].scrollIntoView({ block: 'center' }) });
            }, 100);
            break;
          }

          const companyList = floorList[index].companyList;

          for (let idx = 0; idx < companyList.length; idx++) {
            if (!companyList[idx].companyName) {
              scrollFlag = true;

              setTimeout(() => {
                appContentWrapRef.current.scrollTo({ top: floorListRef.current[index].getElementsByClassName(`company-${idx}`)[0].scrollIntoView({ block: 'center' }) });
              }, 100);
              break;
            }
          }

          if (scrollFlag) break;
        }
      }
    }

    return validFlag;
  }, [formData]);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };

      if (!layerContentsInfo.contentsData.wholeFloorTitle) {
        layerContentsInfo.contentsData.wholeFloorTitle = '';
      }
      if (!layerContentsInfo.contentsData.floorList) {
        layerContentsInfo.contentsData.floorList = [
          {
            floorId: 1,
            floorTitle: '1F',
            floorSubTitle: '',
            floorYn: 'Y',
            companyList: [
              {
                companyId: 1,
                companyName: '',
                frameLinkId: null,
              },
            ],
          },
        ];
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_WHOLEFLOOR_INFO);
    }
  }, [selectLayerContentsInfo]);

  return (
    <Container>
      <AppTitle text="층 안내(전층)APP" />
      <AppContentWrap ref={appContentWrapRef}>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox title="제목" isOpen={isTitleOpen} changeOpen={setIsTitleOpen} line>
              <Input
                ref={titleRef}
                placeholder="*0층 기업 정보"
                value={formData.contentsData.wholeFloorTitle || ''}
                onChange={e => handleChangeObject('contentsData', 'wholeFloorTitle', e.target.value)}
                isValidate={formValidate.wholeFloorTitle}
              />
              <InputError>{formValidate.wholeFloorTitleError}</InputError>
            </ContentBox>
            <ContentBox title="층수" isOpen={isFloorOpen} changeOpen={setIsFloorOpen} line>
              <CounterWrapper>
                <WhiteButton onClick={() => handleChangeCounter('minus')}>-</WhiteButton>
                <CustomInput value={formData.contentsData.floorList.length || 0} readOnly></CustomInput>
                <WhiteButton onClick={() => handleChangeCounter('plus')}>+</WhiteButton>
              </CounterWrapper>
            </ContentBox>
            <ContentBox title="내용" isOpen={isTextOpen} changeOpen={setIsTextOpen}>
              {formData.contentsData.floorList &&
                formData.contentsData.floorList.map((floor, index) => (
                  <FloorWrapper key={`floorWrapper-app-${index}`} ref={ref => (floorListRef.current[index] = ref)}>
                    <Floor
                      className={`floor-${index}`}
                      key={`floor-app-${index}`}
                      index={index}
                      floor={floor}
                      floorList={formData.contentsData.floorList}
                      handleChangeFloorOption={handleChangeFloorOption}
                      frameOptionList={frameOptionList}
                      formValidate={formValidate}
                      handleChangeObject={handleChangeObject}
                    />
                  </FloorWrapper>
                ))}
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        checkValidDate={checkValidDate}
        addFileList={addFileList}
        removeFileList={removeFileList}
        cancelApp={handleCancelLayerContents}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const InputError = styled.label`
  height: 1.25rem;
  padding: 0.125rem 0.25rem;
  color: #f05b5b;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
`;

const Input = styled.input`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 6px 17px 6px 12px;
  border: 1px solid ${({ isError }) => (isError ? '#f05b5b' : '#dedede')};
  border-radius: 8px;
  font-size: 14px;
  color: #333333;
  border: 0.0625rem solid ${props => (props.isValidate === undefined || props.isValidate === true ? '#DDDDDD' : '#F05B5B')};

  &:focus {
    outline: none;
  }
`;

const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-left: 10px; */
`;

const WhiteButton = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid #dddddd;
  background: #ffffff;
  border-radius: 6px;
  color: #999999;
  font-size: 1.5rem;

  // ${({ disabled }) =>
    disabled &&
    css`
      background: rgba(221, 221, 221, 0);
    `};
`;

const CustomInput = styled(Input)`
  text-align: center;
  margin: 0 8px;
`;

const FloorWrapper = styled.div``;

export default React.memo(WholeFloorApp);
