import { interactionBaseApi } from './interactionBaseApi';

import { contentsFileAdd, contentsFileUpdate } from './layerContentsApi';

import cloneDeep from 'lodash/cloneDeep';

export const overlayContentsApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    overlayContentsList: build.query({
      query: data => ({ url: '/overlay-contents/list', data }),
      transformResponse: response => {
        const overlayContentsList = response.overlayContentsList;

        return overlayContentsList;
      },
      providesTags: (result, error, payload) => [...result.map(({ contentsId }) => ({ type: 'OVERLAYCONTENTS', id: contentsId })), { type: 'OVERLAYCONTENTS', id: 'LIST' }],
      keepUnusedDataFor: 0,
    }),
    overlayContentsAdd: build.mutation({
      async queryFn({ formData, addFileList, placeId, folderId, folderNm, handleUploadProgress }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;

        try {
          const playlistId = getState().editor.playlistId;
          const tempForm = cloneDeep(formData);

          patchResult = dispatch(
            overlayContentsApi.util.updateQueryData('overlayContentsList', { playlistId }, draft => {
              draft.push(tempForm);
            }),
          );

          const overlayFileAddParams = { formData, addFileList, placeId, folderId, folderNm, handleUploadProgress };

          const resultContentFileAdd = await contentsFileAdd(fetchInteractionApi, overlayFileAddParams);

          const result = await fetchInteractionApi({ url: '/overlay-contents/overlay-contents-save', data: resultContentFileAdd });
          return result;
        } catch (err) {
          console.log('에러', err);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },

      invalidatesTags: [{ type: 'OVERLAYCONTENTS', id: 'LIST' }],
    }),
    overlayContentsCopyList: build.mutation({
      query: data => ({ url: '/overlay-contents/overlay-contents-copy', data }),
      invalidatesTags: [{ type: 'OVERLAYCONTENTS', id: 'LIST' }],
    }),
    overlayContentsRemove: build.mutation({
      async queryFn({ contentsId }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const playlistId = getState().editor.playlistId;

          patchResult = dispatch(
            overlayContentsApi.util.updateQueryData('overlayContentsList', { playlistId }, draft => {
              const index = draft.findIndex(contents => contents.contentsId === contentsId);
              draft.splice(index, 1);
            }),
          );

          const result = await fetchInteractionApi({ url: '/overlay-contents/overlay-contents-remove', data: { contentsId: contentsId } });
          return result;
        } catch (err) {
          if (patchResult) {
            patchResult.undo();
          }
        }
      },

      invalidatesTags: (result, error, payload) => [{ type: 'OVERLAYCONTENTS', id: 'LIST' }],
    }),
    overlayContentsUpdate: build.mutation({
      async queryFn({ formData, addFileList, removeFileList, placeId, folderId, folderNm, handleUploadProgress }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const playlistId = getState().editor.playlistId;
          const tempForm = cloneDeep(formData);

          patchResult = dispatch(
            overlayContentsApi.util.updateQueryData('overlayContentsList', { playlistId }, draft => {
              draft.push(tempForm);
            }),
          );

          const overlayFileUpdateParams = { formData, addFileList, removeFileList, placeId, folderId, folderNm, handleUploadProgress };
          const resultContentFileUpdate = await contentsFileUpdate(fetchInteractionApi, overlayFileUpdateParams);

          const result = await fetchInteractionApi({ url: '/overlay-contents/overlay-contents-update', data: resultContentFileUpdate });

          return result;
        } catch (err) {
          if (patchResult) {
            patchResult.undo();
          }
        }
      },

      invalidatesTags: (result, error, payload) => [{ type: 'OVERLAYCONTENTS', id: 'LIST' }],
    }),
    overlayContentsPaste: build.mutation({
      query: data => ({ url: '/overlay-contents/layer-contents-save', data }),
      invalidatesTags: [{ type: 'OVERLAYCONTENTS', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useOverlayContentsListQuery,
  useOverlayContentsAddMutation,
  useOverlayContentsCopyListMutation,
  useOverlayContentsRemoveMutation,
  useOverlayContentsUpdateMutation,
  useOverlayContentsPasteMutation,
} = overlayContentsApi;
