import { all, fork, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { PUBLICCONTENTS, publicContentsAction } from './publicContentsSlice';

import * as apiLib from '../lib/api';
import * as publicContentsApiLib from '../lib/publicContentsApi';

function* publicFolderPathInit({ payload }) {
  try {
    const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
    yield put(publicContentsAction.publicFolderList(folderPath));
    yield put(publicContentsAction.publicFileList(folderPath));
  } catch (err) {}
}

function* publicFolderPathBack({ payload }) {
  try {
    const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
    yield put(publicContentsAction.publicFolderList(folderPath));
    yield put(publicContentsAction.publicFileList(folderPath));
  } catch (err) {}
}

function* publicFolderPathUpper({ payload }) {
  try {
    const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
    yield put(publicContentsAction.publicFolderList(folderPath));
    yield put(publicContentsAction.publicFileList(folderPath));
  } catch (err) {}
}


function* publicFolderPathLower({ payload }) {
  try {
    const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
    yield put(publicContentsAction.publicFolderList(folderPath));
    yield put(publicContentsAction.publicFileList(folderPath));
  } catch (err) {}
}

function* publicFolderList({ payload }) {
  try {
    const folderList = yield call(publicContentsApiLib.getPublicFolderList, payload);
    yield put(
      publicContentsAction.publicFolderListSuccess({
        publicFolderList: folderList,
      }),
      );
  } catch (err) {
    yield put(publicContentsAction.publicFolderListFailure('Folder List Error'));
  }
}

function* publicFolderCheckAndMake({ payload }) {
  try {
    yield call(publicContentsApiLib.getPublicFolderCheckAndMake, payload);
    yield put(publicContentsAction.publicFolderCheckAndMakeSuccess());

    const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
    yield put(publicContentsAction.publicFolderList(folderPath));
  } catch (error) {
    yield put(publicContentsAction.publicFolderCheckAndMakeFailure(error));
  }
}

function* publicFolderAdd({ payload }) {
  try {
    yield call(publicContentsApiLib.getPublicFolderAdd, payload);
    yield put(publicContentsAction.publicFolderAddSuccess());

    const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
    yield put(publicContentsAction.publicFolderList(folderPath));
  } catch (error) {
    yield put(publicContentsAction.publicFolderAddFailure());
  }
}

function* publicFolderEdit({ payload }) {
  try {
    yield call(publicContentsApiLib.getPublicFolderEdit, payload);
    yield put(publicContentsAction.publicFolderEditSuccess());
    const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
    yield put(publicContentsAction.publicFolderList(folderPath));
  } catch (error) {
    yield put(publicContentsAction.publicFolderEditFailure());
  }
}

function* publicFolderRemove({ payload }) {
  try {
    yield call(publicContentsApiLib.getPublicFolderRemove, payload);
    yield put(publicContentsAction.publicFolderRemoveSuccess());

    // 기존: 폴더 삭제 시 부모 폴더로 이동
    // yield put(publicContentsAction.publicFolderPathBack());

    // 현재: 폴더 삭제 시 현재 폴더에 남아있음
    const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
    yield put(publicContentsAction.publicFolderList(folderPath));
  } catch (error) {
    yield put(publicContentsAction.publicFolderRemoveFailure());
  }
}

function* publicFileList({ payload }) {
  try {
    const fileList = yield call(publicContentsApiLib.getPublicFileList, payload);
    yield put(
      publicContentsAction.publicFileListSuccess({
        publicFileList: fileList,
      }),
    );
  } catch (err) {
    yield put(publicContentsAction.publicFileListFailure('File List Error'));
  }
}

function* getPublicFileDownload({ payload }) {
  try {
    yield call(publicContentsApiLib.getPublicFileDownload, payload);
    yield put(publicContentsAction.publicFileDownloadSuccess());
  } catch (err) {
    yield put(publicContentsAction.publicFileDownloadFailure('getPublicFileDownload Error'));
  }
}

function* publicFileAdd({
  payload: { folderId, fileDetail, files, durationList, thumbList, onUploadProgress },
}) {
  yield put(publicContentsAction.publicFileUploadMode(1));

  for (let i = 0; i < files.length; i++) {
    try {
      let data = {
        folderId: folderId,
        fileDetail: fileDetail,
        fileNm: files[i].name,
        fileSize: files[i].size,
        fileType: files[i].type,
        fileDuration: durationList[i],
      };

      if (thumbList[i] !== '') {
        const thumbNm = thumbList[i].name;
        const thumbType = thumbList[i].type;
        const thumbData = {
          folderId: folderId,
          thumbNm: thumbNm,
          thumbType: thumbType,
        }
        const thumbResult = yield call(publicContentsApiLib.getPublicThumbAdd, thumbData);
        const thumbPresignUrl = thumbResult.url;
        yield call(apiLib.fetchUploadApi, thumbPresignUrl, thumbList[i]);
        data.fileThumbId = thumbResult.thumbInfo.thumbId;
        data.fileThumb = thumbResult.thumbInfo.thumbPath;
      }

      const result = yield call(publicContentsApiLib.getPublicFileAdd, data);
      const presignUrl = result.url;
      
      const uploadData = new FormData();
      uploadData.append('file', files[i], files[i].name);
      yield call(apiLib.fetchUploadApi, presignUrl, files[i], onUploadProgress, i);

    } catch (error) {
      yield put(publicContentsAction.publicFileUploadMode(5));
      yield put(publicContentsAction.publicFileAddFailure());
      return;
    }
  }
  yield put(publicContentsAction.publicFileUploadMode(4));
  yield put(publicContentsAction.publicFileAddSuccess());
  
  const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
  yield put(publicContentsAction.publicFileList(folderPath));
}

function* publicFileRemove({ payload }) {
  try {
    yield call(publicContentsApiLib.getPublicFileRemove, payload);
    yield put(publicContentsAction.publicFileRemoveSuccess());

    // if (!payload.fromDetail) {
      const folderPath = yield select(state => state[PUBLICCONTENTS].publicFolderPath);
      yield put(publicContentsAction.publicFileList(folderPath));
    // }
  } catch (error) {
    yield put(publicContentsAction.publicFileRemoveFailure());
  }
}

function* publicFileListRemove({ payload: { fileList } }) {
  for (let fileItem of fileList) {
    try {
      const data = {
        fileId: fileItem.fileId,
      };
      yield call(publicContentsApiLib.getPublicFileRemove, data);
    } catch (error) {
      yield put(publicContentsAction.publicFileListRemoveFail());
      return;
    }
  }

  yield put(publicContentsAction.publicFileListRemoveSuccess());
}

function* getFolderListAll({ payload }) {
  try {
    const folderList = yield call(publicContentsApiLib.getFolderListAll);

    yield put(publicContentsAction.getFolderListAllSuccess({ folderList }));
  } catch (error) {
    yield put(publicContentsAction.getFolderListAllFailure('getFolderList Error'));
  }
}

function* changeUseYn({ payload: { folderId } }) {
  try {
    yield call(publicContentsApiLib.changeUseYn, { folderId });
    const folderList = yield call(publicContentsApiLib.getFolderListAll);

    yield put(publicContentsAction.getFolderListAllSuccess({ folderList }));
  } catch (error) {
    yield put(publicContentsAction.getFolderListAllFailure('getFolderList Error'));
  }
}

export function* watchFolderPathInit() {
  yield takeLatest(publicContentsAction.publicFolderPathInit, publicFolderPathInit);
}

export function* watchFolderPathBack() {
  yield takeLatest(publicContentsAction.publicFolderPathBack, publicFolderPathBack);
}

export function* watchFolderPathUpper() {
  yield takeLatest(
    publicContentsAction.publicFolderPathUpper,
    publicFolderPathUpper,
  );
}

export function* watchFolderPathLower() {
  yield takeLatest(
    publicContentsAction.publicFolderPathLower,
    publicFolderPathLower,
  );
}

export function* watchFolderList() {
  yield takeLatest(publicContentsAction.publicFolderList, publicFolderList);
}

export function* watchFolderCheckAndMake() {
  yield takeLatest(publicContentsAction.publicFolderCheckAndMake, publicFolderCheckAndMake);
}

export function* watchFolderAdd() {
  yield takeLatest(publicContentsAction.publicFolderAdd, publicFolderAdd);
}

export function* watchFolderEdit() {
  yield takeLatest(publicContentsAction.publicFolderEdit, publicFolderEdit);
}

export function* watchFolderRemove() {
  yield takeLatest(publicContentsAction.publicFolderRemove, publicFolderRemove);
}

export function* watchFileList() {
  yield takeLatest(publicContentsAction.publicFileList, publicFileList);
}

export function* watchFileDownload() {
  yield takeLatest(publicContentsAction.publicFileDownloadRequest, getPublicFileDownload);
}

export function* watchFileAdd() {
  yield takeLatest(publicContentsAction.publicFileAdd, publicFileAdd);
}

export function* watchFileRemove() {
  yield takeLatest(publicContentsAction.publicFileRemove, publicFileRemove);
}

export function* watchFileListRemove() {
  yield takeLatest(publicContentsAction.publicFileListRemove, publicFileListRemove);
}

export function* watchGetFolderListAll() {
  yield takeLatest(publicContentsAction.getFolderListAll, getFolderListAll);
}

export function* watchChangeUseYn() {
  yield takeEvery(publicContentsAction.changeUseYn, changeUseYn);
}

function* rootSaga() {
  yield all([
    fork(watchFolderPathInit),
    fork(watchFolderPathBack),
    fork(watchFolderPathUpper),
    fork(watchFolderPathLower),
    fork(watchFolderList),
    fork(watchFolderCheckAndMake),
    fork(watchFolderAdd),
    fork(watchFolderEdit),
    fork(watchFolderRemove),
    fork(watchFileList),
    fork(watchFileDownload),
    fork(watchFileAdd),
    fork(watchFileRemove),
    fork(watchFileListRemove),
    fork(watchGetFolderListAll),
    fork(watchChangeUseYn),
  ]);
}

export default rootSaga;
