import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';
import * as templateLibApi from '../lib/templateApi';
import { activeItemSelector } from './activeItemSlice';
import { playlistSelector } from './playlistSlice';
import { frameAction } from './frameSlice';
import { templateAction } from './templateSlice';

function* getTemplateList() {
  try {
    const templateList = yield call(templateLibApi.getTemplateList);
    yield put(templateAction.getTemplateListSuccess(templateList));
  } catch (error) {
    yield put(templateAction.getTemplateListFailure('getTemplateList error'));
  }
}

function* addTemplateFrame({ payload: { frameList, frame, templateId } }) {
  try {
    yield call(templateLibApi.addTemplateFrame, { frame, templateId });

    yield put(templateAction.addTemplateFrameSuccess());

    yield put(frameAction.getFrameListSuccess(frameList.concat(frame)));

    yield put(frameAction.clickFrame({ frameId: frame.frameId, frameOrder: frame.frameOrder }));
  } catch (error) {
    yield put(templateAction.addTemplateFrameFailure('addTemplateFrame error'));
  }
}

function* addTemplate({ payload: { templateNm, templateType } }) {
  try {
    const frameId = yield select(activeItemSelector.frameId);
    const { horizonResolution, verticalResolution } = yield select(playlistSelector.playlist);
    yield call(templateLibApi.addTemplate, { frameId, templateNm, horizonResolution, verticalResolution });

    yield put(templateAction.addTemplateSuccess());
  } catch (error) {
    yield put(templateAction.addTemplateFailure('addTemplate error'));
  }
}

export function* watchGetTemplateList() {
  yield takeLatest(templateAction.getTemplateList, getTemplateList);
}

export function* watchAddTemplateFrame() {
  yield takeLatest(templateAction.addTemplateFrame, addTemplateFrame);
}

export function* watchAddTemplate() {
  yield takeLatest(templateAction.addTemplate, addTemplate);
}

function* rootSaga() {
  yield all([
    fork(watchGetTemplateList),
    fork(watchAddTemplateFrame),
    fork(watchAddTemplate),
  ]);
}

export default rootSaga;
