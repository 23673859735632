import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const developmentUrl = 'https://faas.eligaws.com/interaction';
const productionUrl = 'https://faas.eligaspace.com/interaction';
const localUrl = 'http://localhost:8000/interaction';

const axiosBaseQuery = async ({ url, method = 'POST', data }) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    const baseUrl = window.location.href.includes('eligaws.com')
      ? developmentUrl
      : window.location.href.includes('eligaspace.com')
      ? productionUrl
      : window.location.href.includes('eliga.co.kr')
      ? productionUrl
      : localUrl;

    const response = await axios({
      method: 'post',
      url: baseUrl + url,
      headers: {
        'X-Api-Key': 'bCAoIUYQ8N1CZ4rMDPdvJ54HqeZwfgeF96NCYJhQ',
        'content-type': 'application/json',
      },
      data: { ...data, accessToken },
    }).catch(err => {
      if (err.response.status === 401) {
        window.location.href = '/account/logout';
      }
      throw err;
    });

    return { data: response.data };
  } catch (err) {
    throw err;
  }
};

export const interactionBaseApi = createApi({
  reducerPath: 'interactionBaseApi',
  baseQuery: axiosBaseQuery,
  endpoints: () => ({}),
});

export const fetchUploadApi = async (url, file, onUploadProgress, index) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status < 400) {
        resolve(true);
      } else {
        const error = new Error(xhr.response);
        reject(error);
      }
    };
    xhr.upload.onprogress = evt => {
      if (evt.lengthComputable) {
        onUploadProgress && onUploadProgress(file, evt.loaded, evt.total, index);
      }
    };
    xhr.onerror = error => {
      reject(error);
    };
    xhr.onreadystatechange = evt => {
      if (xhr.readyState === 1) {
      } else if (xhr.readyState === 4) {
      }
    };

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
};
