import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface PlacePayload {
  placeId: string;
  placeNm: string;
  accountId: string;
  pricing: string;
  licenseSeat: string;
}

interface MemberPayload {
  placeId: string;
  accountId: string;
  accountEmail: string;
  permissionType: string;
}

interface ImagePayload {
  placeId: string;
  file: Array<any>;
}

interface PricingPayload {
  placeId: string;
  customerKey: string;
  authKey: string;
}

interface PlaceInfo {
  placeId: string;
  placeNm: string;
}

interface MemberInfo {
  placeId: string;
  permissionType: string;
  permissionNm: string;
  regDt: string;
}

interface AccountInfo {
  placeId: string;
  accountId: string;
  accountNm: string;
  accountEmail: string;
  imagePath: string;
  imageSize: string;
  lastLoginDt: string;
  coordinate: any;
  zoom: number;
}

interface PlayerInfo {
  path: string;
  version: string;
}

interface PlaceState {
  placeInfo: PlaceInfo;
  placeList: Array<PlaceInfo>;
  memberInfo: MemberInfo;
  memberList: Array<MemberInfo>;
  accountList: Array<AccountInfo>;
  playerInfo: PlayerInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const placeInitialState: PlaceState = {
  placeInfo: {},
  placeList: [],
  pricingInfo: {},
  memberInfo: {},
  memberList: [],
  accountList: [],
  playerInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  placeInfo: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  placeInfoSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.placeInfo = payload.placeInfo;
    state.actionResult = 'INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  placeInfoFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'INFO_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  placeList: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  placeListSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.placeList = payload.placeList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  placeListFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  placeAdd: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  placeAddSuccess: (state: PlaceState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  placeAddFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  placeEdit: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  placeEditSuccess: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.placeInfo.placeNm = payload.placeNm;
    for (let place of state.placeList) {
      if (place.placeId === payload.placeId) {
        place.placeNm = payload.placeNm;
      }
    }
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  placeEditFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  placeRemove: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  placeRemoveSuccess: (state: PlaceState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  placeRemoveFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  placeImageUpload: (state: PlaceState, { payload }: PayloadAction<ImagePayload>) => {
    state.actionResult = 'UPLOAD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  placeImageUploadSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.placeInfo = payload.placeInfo;
    state.actionResult = 'UPLOAD_OK';
    state.isLoading = false;
    state.error = null;
  },
  placeImageUploadFailure: (state: PlaceState, { payload }: PayloadAction<String>) => {
    state.actionResult = 'UPLOAD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  pricingPayment: (state: PlaceState, { payload }: PayloadAction<PricingPayload>) => {
    state.isLoading = true;
    state.error = null;
  },
  pricingPaymentSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.pricingInfo = payload.pricingInfo;
    state.isLoading = false;
    state.error = null;
  },
  pricingPaymentFailure: (state: PlaceState, { payload }: PayloadAction<String>) => {
    state.isLoading = false;
    state.error = payload;
  },
  memberList: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'MEMBER_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberListSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.memberList = payload.memberList;
    state.accountList = payload.accountList;
    state.actionResult = 'MEMBER_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberListFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberDetail: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'MEMBER_DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberDetailSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.memberInfo = payload.memberInfo;
    state.actionResult = 'MEMBER_DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberDetailFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberAdd: (state: PlaceState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'MEMBER_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberAddSuccess: (state: PlaceState) => {
    state.actionResult = 'MEMBER_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberAddFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberEdit: (state: PlaceState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'MEMBER_EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberEditSuccess: (state: PlaceState) => {
    state.actionResult = 'MEMBER_EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberEditFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberRemove: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'MEMBER_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberRemoveSuccess: (state: PlaceState) => {
    state.actionResult = 'MEMBER_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberRemoveFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  playerInfo: (state: PlaceState) => {
    state.actionResult = 'PLAYER_INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  playerInfoSuccess: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.playerInfo = payload.playerInfo;
    state.actionResult = 'PLAYER_INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  playerInfoFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'PLAYER_INFO_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'place',
  initialState: placeInitialState,
  reducers: reducers,
});

const selectSelf = (state: PlaceState) => state;

const selectPlaceInfo = createDraftSafeSelector(selectSelf, state => state.placeInfo);

const selectPlaceList = createDraftSafeSelector(selectSelf, state => state.placeList);

const selectPricingInfo = createDraftSafeSelector(selectSelf, state => state.pricingInfo);

const selectMemberInfo = createDraftSafeSelector(
  (state: PlaceState) => state.memberInfo,
  (state: PlaceState) => state.accountList,
  (memberInfo, accountList) => {
    if (accountList && accountList.length > 0) {
      let accountFilter = accountList.filter(account => account.accountId === memberInfo.accountId);
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        return { ...memberInfo, ...account };
      } else {
        return memberInfo;
      }
    } else {
      return memberInfo;
    }
  },
);

const selectMemberList = createDraftSafeSelector(
  (state: PlaceState) => state.memberList,
  (state: PlaceState) => state.accountList,
  (memberList, accountList) => {
    let newMemberList = [];
    for (let member of memberList) {
      let accountFilter = accountList.filter(account => account.accountId === member.accountId);
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        newMemberList.push({ ...member, ...account });
      }
    }
    return newMemberList;
  },
);

const selectLastLoginMemberList = createDraftSafeSelector(
  (state: PlaceState) => state.memberList,
  (state: PlaceState) => state.accountList,
  (memberList, accountList) => {
    let newMemberList = [];
    for (let member of memberList) {
      let accountFilter = accountList.filter(account => account.accountId === member.accountId);
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        newMemberList.push({ ...member, ...account });
      }
    }

    newMemberList.sort(function (lhs, rhs) {
      const lhsDate = dayjs(lhs.lastLoginDt);
      const rhsDate = dayjs(rhs.lastLoginDt);

      return rhsDate.diff(lhsDate);
    });

    newMemberList.splice(3);

    return newMemberList;
  },
);

const selectPlayerInfo = createDraftSafeSelector(selectSelf, state => state.playerInfo);

const selectStatus = createDraftSafeSelector(
  (state: PlaceState) => state.actionResult,
  (state: PlaceState) => state.isLoading,
  (state: PlaceState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const placeSelector = {
  placeInfo: state => selectPlaceInfo(state[PLACE]),
  placeList: state => selectPlaceList(state[PLACE]),
  pricingInfo: state => selectPricingInfo(state[PLACE]),
  memberInfo: state => selectMemberInfo(state[PLACE]),
  memberList: state => selectMemberList(state[PLACE]),
  lastLoginMemberList: state => selectLastLoginMemberList(state[PLACE]),
  playerInfo: state => selectPlayerInfo(state[PLACE]),
  status: state => selectStatus(state[PLACE]),
};

export const PLACE = slice.name;
export const placeReducer = slice.reducer;
export const placeAction = slice.actions;
