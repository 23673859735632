import React from 'react';
import styled from 'styled-components';

const ContextMenuWrapper = props => {
  const { isContextVisible } = props;
  return <ContextMenuWrapperWrapper isContextVisible={isContextVisible}>{props.children}</ContextMenuWrapperWrapper>;
};

const ContextMenuWrapperWrapper = styled.div`
  .react-contextmenu {
    background-color: rgb(44 44 44);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    //border-radius: 0.375rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    color: rgb(65 161 234);
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 174px;
    outline: none;
    opacity: 0;
    padding: 4px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
  }

  .react-contextmenu.react-contextmenu--visible {
    display: ${props => (props.isContextVisible ? 'block' : 'none')};
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }

  .react-contextmenu-item:not(.react-contextmenu-item--divider) {
    background: 0 0;
    border: 0;

    color: rgb(195 195 195);
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
    outline: none;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
  }

  .react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-bottom: 4px;
    padding: 4px 0 0;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  /* .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  } */

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item::after {
    content: '▶';
    /* content: url('../../assets/images/icon-right-arrow.svg'); */
    display: inline-block;
    position: absolute;
    right: 15px;
  }

  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }
`;

export default ContextMenuWrapper;
