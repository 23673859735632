import { createSlice, createDraftSafeSelector, PayloadAction, } from '@reduxjs/toolkit';

interface TemplateInfoState {
  templateId: string;
  templateNm: string;
  templateType: string;
  layerList: Array<any>;
  previewFilePath: string;
}

interface TemplateState {
  templateList: TemplateInfoState[];
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const templateInitialState: TemplateState = {
  templateList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'template',
  initialState: templateInitialState,
  reducers: {
    initTemplateState(state: TemplateState) {
      state.templateList = [];
      state.actionResult = '';
      state.isLoading = false;
      state.error = null;
    },
    getTemplateList(state: TemplateState) {
      state.actionResult = 'TEMPLATE_LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getTemplateListSuccess(state: TemplateState, { payload: templateList }: PayloadAction<TemplateInfoState[]>) {
      state.templateList = templateList;
      state.actionResult = 'TEMPLATE_LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    getTemplateListFailure(state: TemplateState, { payload: error }: PayloadAction<string>) {
      state.actionResult = 'TEMPLATE_LIST_ERR';
      state.isLoading = false;
      state.error = error;
    },
    addTemplateFrame(state: TemplateState) {
      state.actionResult = 'ADD_TEMPLATE_FRAME_REQ';
      state.isLoading = true;
      state.error = null;
    },
    addTemplateFrameSuccess(state: TemplateState) {
      state.actionResult = 'ADD_TEMPLATE_FRAME_OK';
      state.isLoading = false;
      state.error = null;
    },
    addTemplateFrameFailure(state: TemplateState, { payload: error }: PayloadAction<string>) {
      state.actionResult = 'ADD_TEMPLATE_FRAME_ERR';
      state.isLoading = false;
      state.error = error;
    },
    addTemplate(state: TemplateState) {
      state.isLoading = true;
      state.error = null;
    },
    addTemplateSuccess(state: TemplateState) {
      state.isLoading = false;
      state.error = null;
    },
    addTemplateFailure(state: TemplateState, { payload: error }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = error;
    },
  },
});

const selectTemplate = createDraftSafeSelector(
  (state: any) => state,
  state => {
    const templateList = state.template.templateList;
    const { pricing } = state.place.placeInfo;

    const pricingLevel = { BASIC: 1, STANDARD: 2, ENTERPRISE: 3 };
    const pricingFilterTemplateList = templateList.filter(app => pricingLevel[pricing] >= pricingLevel[app.pricing]);

    const URL = window.location.href;
    if (URL.indexOf('localhost') > 0 || URL.indexOf('eligaws') > 0) {
      return pricingFilterTemplateList;
    } else {
      return pricingFilterTemplateList.filter(template => template.devYn === 'N');
    }
  }
)

const selectStatus = createDraftSafeSelector(
  (state: TemplateState) => state.isLoading,
  (state: TemplateState) => state.error,
  (state: TemplateState) => state.actionResult,
  (isLoading, error, actionResult) => ({ isLoading, error, actionResult }),
);

export const templateSelector = {
  templateList: (state) => selectTemplate(state),
  status: (state) => selectStatus(state[TEMPLATE]),
};

export const TEMPLATE = slice.name;
export const templateReducer = slice.reducer;
export const templateAction = slice.actions;