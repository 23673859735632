import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { VscTriangleUp as TriangleIcon } from 'react-icons/vsc';
import { ReactComponent as DownIcon } from '../../assets/images/common/icon-chevron-down.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/common/icon-check.svg';

import { editorAction, editorSelector } from '../../module/editorSlice';

import ClickOutside from '../../components/ClickOutside';

import { CANVAS_MAX_ZOOMLEVEL, CANVAS_MIN_ZOOMLEVEL } from './constants/editor';

const ZoomMenu = ({ infiniteViewerRef }) => {
  const { zoom } = useSelector(editorSelector.canvasInfo);

  const [isOpen, setIsOpen] = useState(false);

  const scale = useMemo(() => {
    return Math.floor(zoom) + '%';
  }, [zoom]);

  return (
    <Wrap>
      <ClickOutside
        style={{ width: '100%', height: '100%', position: 'relative' }}
        onClickOutside={() => setIsOpen(false)}
      >
        <Zoom onClick={() => setIsOpen(isOpen => !isOpen)}>
          {scale} <DownIcon width={13} />
        </Zoom>
        {isOpen && <Menu scale={scale} infiniteViewerRef={infiniteViewerRef} />}
      </ClickOutside>
    </Wrap>
  );
};

const Menu = ({ scale, infiniteViewerRef }) => {
  const dispatch = useDispatch();

  const { originZoom, zoom, gridMode, snapMode, lockMode, overlayShowMode } = useSelector(editorSelector.canvasInfo);
  const [zoomValue, setZoomValue] = useState(scale);

  useEffect(() => {
    setZoomValue(scale);
  }, [scale]);

  const handleToggleMode = key => {
    if (key === 'gridMode') {
      dispatch(editorAction.updateCanvasInfo({ [key]: !gridMode }));
    } else if (key === 'snapMode') {
      dispatch(editorAction.updateCanvasInfo({ [key]: !snapMode }));
    } else if (key === 'overlayShowMode') {
      dispatch(editorAction.updateCanvasInfo({ [key]: !overlayShowMode }));
    } else if (key === 'lockMode') {
      dispatch(editorAction.updateCanvasInfo({ [key]: !lockMode }));
    }
  };

  const handleBlurZoomInput = value => {
    if (value <= CANVAS_MIN_ZOOMLEVEL) {
      dispatch(editorAction.updateCanvasInfo({ zoom: CANVAS_MIN_ZOOMLEVEL }));
    } else {
      dispatch(editorAction.updateCanvasInfo({ zoom: value <= CANVAS_MAX_ZOOMLEVEL ? value : CANVAS_MAX_ZOOMLEVEL }));
    }
  };

  const handleFit = e => {
    dispatch(editorAction.updateCanvasInfo({ zoom: originZoom }));
    infiniteViewerRef.current.scrollCenter();
  };

  const handleZoomIn = e => {
    dispatch(editorAction.zoomInCanvas());
  };

  const handleZoomOut = e => {
    dispatch(editorAction.zoomOutCanvas());
  };

  const handleZoom = value => {
    dispatch(editorAction.updateCanvasInfo({ zoom: value }));
  };

  return (
    <ContainerWrap>
      <Container>
        <Triangle>
          <TriangleIcon size={17} color="#2a2a2a" />
        </Triangle>
        <Box>
          <ZoomInput
            onFocus={e => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
              e.target.select();
            }}
            onInput={e => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}
            onBlur={e => handleBlurZoomInput(e.target.value)}
            onKeyUp={e => e.keyCode === 13 && e.target.blur()}
            onChange={e => setZoomValue(e.target.value)}
            value={zoomValue}
          />
        </Box>
        <Box>
          <Row onClick={e => handleZoomIn(Math.round(zoom * 2))}>
            <span>Zoom in</span>
            <span>ctrl + =</span>
          </Row>
          <Row onClick={e => handleZoomOut(Math.round(zoom / 2))}>
            <span>Zoom out</span>
            <span>ctrl + -</span>
          </Row>
          <Row onClick={e => handleFit()}>
            <span>Zoom to fit</span>
            <span>shift + 1</span>
          </Row>
          <Row onClick={e => handleZoom(50)}>
            <span>Zoom to 50%</span>
          </Row>
          <Row onClick={e => handleZoom(100)}>
            <span>Zoom to 100%</span>
            <span>ctrl + 0</span>
          </Row>
          <Row onClick={e => handleZoom(200)}>
            <span>Zoom to 200%</span>
          </Row>
        </Box>
        <Box>
          <Row onClick={() => handleToggleMode('gridMode')}>
            {gridMode && <CheckIcon />}
            <span>Layout grids</span>
            <span>ctrl + 1</span>
          </Row>
          <Row onClick={() => handleToggleMode('snapMode')}>
            {snapMode && <CheckIcon />}
            <span>Snap to pixel</span>
            <span>ctrl + 2</span>
          </Row>
          <Row onClick={() => handleToggleMode('overlayShowMode')}>
            {overlayShowMode && <CheckIcon />}
            <span>Show overlay</span>
            <span>ctrl + 3</span>
          </Row>
          <Row onClick={() => handleToggleMode('lockMode')}>
            {lockMode && <CheckIcon />}
            <span>Lock editor</span>
            <span>shift + `</span>
          </Row>
        </Box>
      </Container>
    </ContainerWrap>
  );
};

const Wrap = styled.div`
  position: relative;
  width: 64px;
  height: 100%;
`;

const Zoom = styled.div`
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;

  & > svg {
    margin-bottom: 1px;
  }
  & path {
    fill: rgba(255, 255, 255, 0.8);
  }

  &:hover {
    background-color: #222;
  }

  cursor: pointer;
`;

const ContainerWrap = styled.div`
  position: absolute;
  top: 57px;
  right: 5px;
  width: 245px;
  z-index: 30;
  background-color: #2a2a2a;
  border-radius: 2px;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.22);
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Triangle = styled.div`
  position: absolute;
  top: -10px;
  right: 2px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: inherit;
  padding: 10px 0;

  & + & {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 13px;
  font-family: 500;
  padding: 6px 13px 6px 22px;

  &:hover {
    background: #41a1ea;
  }

  & > svg {
    position: absolute;
    left: 4px;
    top: 8px;
  }
`;

const ZoomInput = styled.input`
  height: 30px;
  border-radius: 2px;
  border: 2px solid #41a1ea;
  background: inherit;
  color: rgba(255, 255, 255, 0.8);
  padding: 7px;
  font-size: 12px;
  margin: 0 22px;

  &:focus {
    outline: none;
  }
`;

export default React.memo(ZoomMenu);
