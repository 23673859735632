import React, { useMemo, useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import Modal from './Modal';

const useFileUpload = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadedList, setLoadedList] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);

  const handleProgressModalClear = useCallback(() => {
    setLoadedList([]);
    setTotalSize(0);
  }, []);

  const handleUploadStart = useCallback((totalSize, totalCnt) => {
    if (totalCnt > 0) {
      setTotalSize(totalSize);
      setTotalCnt(totalCnt);
      setIsOpen(true);
    }
  }, []);

  const handleUploadEnd = useCallback(() => {
    setIsOpen(false);
    setLoadedList([]);
    setTotalSize(0);
    setTotalCnt(0);
  }, []);

  const handleUploadProgress = useCallback((file, loaded, total, id) => {
    setLoadedList(loadedList => {
      const index = loadedList.findIndex(loadInfo => loadInfo.id === id);
      if (index > -1) {
        return loadedList.map((loadInfo, mapIndex) => (mapIndex === index ? { ...loadInfo, loaded } : loadInfo));
      } else {
        return loadedList.concat({ id, loaded });
      }
    });
  }, []);

  return {
    UploadProgressModal: <UploadProgressModal isOpen={isOpen} totalSize={totalSize} loadedList={loadedList} totalCnt={totalCnt} />,
    handleProgressModalClear,
    handleUploadStart,
    handleUploadEnd,
    handleUploadProgress,
  };
};

const UploadProgressModal = React.memo(({ isOpen, totalSize, loadedList, totalCnt }) => {
  const modalRef = useRef();

  const percent = useMemo(() => {
    const totalLoaded = loadedList.reduce((sum, loadedInfo) => sum + loadedInfo.loaded, 0);
    return Number((totalLoaded / totalSize) * 100).toFixed(0);
  }, [totalSize, loadedList]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.marginTop = 'auto';
      modalRef.current.style.marginLeft = 'auto';
      modalRef.current.parentElement.style.padding = 0;
    }
  }, []);

  return (
    <Modal hideButton hideXButton visible={isOpen} modalRef={modalRef} size="large">
      <Container>
        <Title>
          <span>{totalCnt}개 컨텐츠 업로드</span> <span>{percent}% 완료</span>
        </Title>
        <Progress>
          <Bar width={percent} />
        </Progress>
        <Warn>업로드 도중 종료시 정상적으로 저장이 안됩니다</Warn>
      </Container>
    </Modal>
  );
});

const Warn = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0;
  color: #f05b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px 5px 10px;
`;

const Progress = styled.div`
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 16px;
  height: 20px;
  overflow: hidden;
`;

const Bar = styled.div`
  width: ${({ width }) => width}%;
  height: 100%;
  border-radius: 16px;
  background: #6a8bff;
  transition: 1s ease;
  text-align: right;
`;

export default useFileUpload;
