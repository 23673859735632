import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import TextPreview from './preview/TextPreview';
import Navigation from './preview/Navigation';
import Media from './preview/Media';
import FloorInfo from './preview/FloorInfo';
import BuildingInfo from './preview/BuildingInfo';
import Logo from './preview/Logo';
import RSS from './preview/RSS';
import TickerMono from './preview/TickerMono';
import Weather from './preview/Weather';
import WebView from './preview/WebView';
import Watch from './preview/Watch';
import Audio from './preview/Audio';

import { accountSelector } from '../../module/accountSlice';
import Channel from './preview/Channel';

const Layer = ({ layerType, layerProps, layerContents, id }) => {
  const accountInfo = useSelector(accountSelector.accountInfo);

  const queryClient = new QueryClient();

  const isOverlay = useMemo(() => layerType === 'OVERLAY', [layerType]);
  return (
    <Rect
      className="layer"
      name={isOverlay ? layerProps.overlayNm : layerProps.layerNm}
      id={id}
      style={{
        backgroundColor: layerProps.backgroundColor
          ? `rgba(${Number(layerProps.backgroundColor.r)}, ${Number(layerProps.backgroundColor.g)}, ${Number(layerProps.backgroundColor.b)}, ${Number(
              layerProps.backgroundColor.a,
            )})`
          : 'rgba(255, 255, 255, 0)',
        color: layerProps.fontColor
          ? `rgba(${Number(layerProps.fontColor.r)}, ${Number(layerProps.fontColor.g)}, ${Number(layerProps.fontColor.b)}, ${Number(layerProps.fontColor.a)})`
          : 'initial',
        transform: `translate(${layerProps.x}px, ${layerProps.y}px)`,
        width: layerProps.width + 'px',
        height: layerProps.height + 'px',
      }}
    >
      {layerContents ? (
        <QueryClientProvider client={queryClient}>
          {
            {
              text: <TextPreview textInfo={layerContents.contentsData} />,
              navi: <Navigation naviData={layerContents.contentsData} />,
              media: <Media mediaList={layerContents.contentsData.mediaList} contentsFileList={layerContents.contentsFileList} muteYn={layerProps.muteYn} />,
              audio: <Audio audioData={layerContents.contentsData} contentsFileList={layerContents.contentsFileList} />,
              oneFloor: <FloorInfo floorData={layerContents.contentsData} contentsFileList={layerContents.contentsFileList} />,
              wholeFloor: <BuildingInfo buildingData={layerContents.contentsData} contentsFileList={layerContents.contentsFileList} />,
              logo: <Logo logoData={layerContents.contentsData.logoList && layerContents.contentsData.logoList[0]} fileList={layerContents.contentsFileList} isApp />,
              rss: <RSS rssData={layerContents.contentsData} layerHeight={layerProps.height} layerContents={layerContents} accountInfo={accountInfo} layerProps={layerProps} />,
              ticker: <TickerMono tickerData={layerContents.contentsData} layerHeight={layerProps.height} />,
              weather: <Weather weatherMetadata={layerContents.contentsData} layerContents={layerContents} accountInfo={accountInfo} layerProps={layerProps} />,
              webView: <WebView webViewData={layerContents.contentsData} />,
              datetime: <Watch watchFormat={layerContents.contentsData} container={layerProps} />,
              channel: <Channel channelId={layerContents?.contentsData.channelId} />,
            }[layerContents.contentsType]
          }
        </QueryClientProvider>
      ) : (
        <>
          <LayerBorder baseYn={layerProps.baseYn} isOverlay={isOverlay} />
          <LayerNm>{isOverlay ? layerProps.overlayNm : layerProps.layerNm}</LayerNm>
        </>
      )}
    </Rect>
  );
};

const Rect = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
  overflow: hidden;

  & img {
    -webkit-user-drag: none;
  }
`;

const LayerNm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
`;

const LayerBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: ${({ isOverlay, baseYn }) => (isOverlay ? `3px solid #fff` : `2px solid ${baseYn === 'Y' ? '#41A1EA' : '#666'}`)};
`;

export default React.memo(Layer);
