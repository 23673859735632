import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import Modal from '../../../newComponents/Modal';
import BackgroundLoading from '../../../newComponents/BackgroundLoading';

import { addArchivePackage, addStorePackage } from '../../../lib/packageApi';
import SelectInput from '../../../newComponents/SelectInput';
import { toast } from 'react-toastify';

const PackageAddModal = ({ isOpen, closeModal, playlistId, isArchivePackageMode, isStorePackageMode }) => {
  const [isLoading, setisLoading] = useState(false);
  const [packageType, setPackageType] = useState(isArchivePackageMode ? 'MUSEUM' : 'CAFE_KIOSK');
  const [playlistType, setPlaylistType] = useState(isArchivePackageMode ? '' : 'KIOSK');
  const packageTypeOptionList = useRef(
    isArchivePackageMode
      ? [
          { title: 'MESEUM', value: 'MESEUM' },
          { title: 'HISTORY-HALL', value: 'HISTORY-HALL' },
          { title: 'PHOTO-KIOSK', value: 'PHOTO-KIOSK' },
        ]
      : [
          { title: 'CAFE_KIOSK', value: 'CAFE_KIOSK' },
          { title: 'CAFE_MENUBOARD', value: 'CAFE_MENUBOARD' },
          { title: 'CAFE_PICKUPBOARD', value: 'CAFE_PICKUPBOARD' },
          { title: 'FOODCOURT_KIOSK', value: 'FOODCOURT_KIOSK' },
          { title: 'FOODCOURT_MENUBOARD', value: 'FOODCOURT_MENUBOARD' },
          { title: 'FOODCOURT_WAYFINDING', value: 'FOODCOURT_WAYFINDING' },
        ],
  );
  const playlistTypeOptionList = useRef([
    { title: 'KIOSK', value: 'KIOSK' },
    { title: 'TICKET_KIOSK', value: 'TICKET_KIOSK' },
    { title: 'MENUBOARD', value: 'MENUBOARD' },
    { title: 'PICKUPBOARD', value: 'PICKUPBOARD' },
    { title: 'WAYFINDING', value: 'WAYFINDING' },
    { title: 'SURVEY_KIOSK', value: 'SURVEY_KIOSK' },
  ]);

  // 아카이브 패키지 추가 함수
  const handleAddArchivePackage = async () => {
    setisLoading(true);
    const result = await addArchivePackage({ playlistId, packageType });
    setisLoading(false);
    if (result) {
      toast.success('패키지 생성 성공');
    } else {
      toast.error('패키지 생성 실패');
    }
    closeModal();
  };

  // 스토어 패키지 추가 함수
  const handleAddStorePackage = async () => {
    setisLoading(true);
    const result = await addStorePackage({ playlistId, packageType, playlistType });
    setisLoading(false);
    if (result) {
      toast.success('패키지 생성 성공');
    } else {
      toast.error('패키지 생성 실패');
    }
    closeModal();
  };

  return (
    <>
      <Modal
        title={<Title>패키지 추가</Title>}
        onConfirm={() => (isArchivePackageMode ? handleAddArchivePackage() : handleAddStorePackage())}
        confirmText="추가"
        onCancel={closeModal}
        onBackdropClick={closeModal}
        onClose={closeModal}
        visible={isOpen}
        height="40%"
        isLine
      >
        <Notice>
          패키지 타입 선택
          <SelectInput
            selectedValue={packageType}
            optionList={packageTypeOptionList.current}
            onSelectChange={value => setPackageType(value)}
          />
        </Notice>
        {!isArchivePackageMode && (
          <Notice>
            재생목록 타입 선택
            <SelectInput
              selectedValue={playlistType}
              optionList={playlistTypeOptionList.current}
              onSelectChange={value => setPlaylistType(value)}
            />
          </Notice>
        )}
      </Modal>
      {isLoading && <BackgroundLoading />}
    </>
  );
};

const Title = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  color: #f05b5b;
  font-size: 14px;
  font-weight: 500;
`;

const Notice = styled.div`
  font-size: 14px;
  color: #333333;
`;

export default React.memo(PackageAddModal);
