import * as apiLib from './api';

export const list = async placeId => {
  try {
    const result = await apiLib.fetchInteractionApi('/channel/list', placeId);
    if (result.resultFlag) {
      return result.channelList;
    } else {
      throw Object.assign(new Error('getChannelList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const info = async channelId => {
  try {
    const result = await apiLib.fetchInteractionApi('/channel/info', channelId);
    if (result.resultFlag) {
      return result.channelInfo;
    } else {
      throw Object.assign(new Error('getChannelList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchInteractionApi('/channel/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addChannel error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchInteractionApi('/channel/remove', data);

    if (result.resultFlag) {
      return result.channelList;
    } else {
      throw Object.assign(new Error('removeFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
