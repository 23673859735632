import { interactionBaseApi } from './interactionBaseApi';

export const publicFileApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    publicFileList: build.query({
      query: data => ({ url: '/publicContents/storage-file-list', data }),
      transformResponse: response => {
        const fileList = response.fileList;

        return fileList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ folderId }) => ({ type: 'PUBLICFILE', id: folderId })),
        { type: 'PUBLICFILE', id: 'LIST' },
      ],
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, usePublicFileListQuery } = publicFileApi;
