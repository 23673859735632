
/**
 * 문자열을 입력시 그대로 반환, 숫자를 입력시 px을 붙혀서 반환
 * @author Dongseek Son
 * @param {string|number} value
 * @param {{ratio: number}} options - value가 숫자이고, 옵션에 ratio 값이 있을 시 비율을 계산 후 값 반환
 */
export const pxUnitChecker = (value, options = {}) => {
  let number = Number(value);
  if (isNaN(number)) return value;
  else if (options.ratio) number = number * options.ratio;
  return number + 'px';
};

/**
 * 부모와 자식의 넓이, 높이 값을 받아 원하는 옵션에 따른 비율을 계산해서 반환
 * @author Dongseek Son
 * @param {number} contentWidth - 자식의 넓이
 * @param {number} contentHeight - 자식의 높이
 * @param {number} viewWidth - 부모의 넓이
 * @param {number} viewHeight - 부모의 높이
 * @param {"contain" | "cover" | "scale-down" | "none"} [fillOption] - default="none"
 */
export const calculateRatio = (contentWidth, contentHeight, viewWidth, viewHeight, fillOption = 'none') => {
  const viewRatio = viewWidth / viewHeight;
  const contentRatio = contentWidth / contentHeight;
  const widthRatio = viewWidth / contentWidth;
  const heightRatio = viewHeight / contentHeight;

  switch (fillOption) {
    case 'contain':
      return contentRatio > viewRatio ? widthRatio : heightRatio;
    case 'cover':
      return contentRatio < viewRatio ? widthRatio : heightRatio;
    case 'scale-down':
      let result = contentRatio < viewRatio ? widthRatio : heightRatio;
      return result < 1 ? result : 1;
    case 'none':
    default:
      return 1;
  }
};
