import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { editorAction, editorSelector } from '../../module/editorSlice';

import { useLayerRemoveListMutation } from '../../rtk/layerApi';
import { useOverlayRemoveListMutation } from '../../rtk/overlayApi';

const KeyEventManager = ({ historyRef, clipboardRef, infiniteViewerRef }) => {
  const dispatch = useDispatch();

  const frameId = useSelector(editorSelector.frameId);
  const canvasInfo = useSelector(editorSelector.canvasInfo);
  const selectedLayerList = useSelector(editorSelector.selectedLayerList);

  const [layerRemoveListMutation] = useLayerRemoveListMutation();
  const [overlayRemoveListMutation] = useOverlayRemoveListMutation();

  // 랜더링으로 인한 이벤트 중복 방지 ref
  const _canvasInfo = useRef(canvasInfo);
  const _frameId = useRef(frameId);
  const _selectedLayerList = useRef(selectedLayerList || []);

  useEffect(() => {
    _frameId.current = frameId;
    _canvasInfo.current = canvasInfo;
    _selectedLayerList.current = selectedLayerList || [];
  }, [frameId, canvasInfo, selectedLayerList]);

  // 레이어 그룹 삭제
  const handleRemoveLayerList = useCallback(() => {
    if (_selectedLayerList.current.length > 0) {
      const layerRemoveList = [];
      const overlayRemoveList = [];

      for (const selected of _selectedLayerList.current) {
        if (selected.type === 'LAYER') {
          if (selected.baseYn !== 'Y') {
            layerRemoveList.push(selected);
          }
        } else if (selected.type === 'OVERLAY') {
          overlayRemoveList.push(selected);
        }
      }

      if (layerRemoveList.length > 0) {
        layerRemoveListMutation({ removeList: layerRemoveList }).then(() => {
          if (overlayRemoveList.length <= 0) {
            historyRef.current.addHistory({
              type: 'REMOVE-LAYER',
              props: {
                infos: [...layerRemoveList, ...overlayRemoveList],
                prevSelectedLayerList: _selectedLayerList.current,
                nextSelectedLayerList: [],
              },
            });
          }
        });
      }

      if (overlayRemoveList.length > 0) {
        overlayRemoveListMutation({ removeList: overlayRemoveList }).then(() => {
          historyRef.current.addHistory({
            type: 'REMOVE-LAYER',
            props: {
              infos: [...layerRemoveList, ...overlayRemoveList],
              prevSelectedLayerList: _selectedLayerList.current,
              nextSelectedLayerList: [],
            },
          });
        });
      }
    }
  }, [layerRemoveListMutation, historyRef, overlayRemoveListMutation]);

  // 스페이스 키 - 드래그 모드 ON
  const shortcutEnableDrag = useCallback(
    e => {
      if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' && e.keyCode === 32) {
        const { dragMode } = _canvasInfo.current;
        if (!dragMode) {
          dispatch(editorAction.updateCanvasInfo({ dragMode: true }));
        }

        e.preventDefault();
        e.returnValue = false;
      }
    },
    [dispatch],
  );

  // 스페이스 키 - 드래그 모드 OFF
  const shortcutDisableDrag = useCallback(
    e => {
      if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' && e.keyCode === 32) {
        const { dragMode } = _canvasInfo.current;
        if (dragMode) {
          dispatch(editorAction.updateCanvasInfo({ dragMode: false }));
        }

        e.preventDefault();
        e.returnValue = false;
      }
    },
    [dispatch],
  );

  // keydown event
  const shortcutKeydownEvent = useCallback(
    e => {
      if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA') {
        const keyCode = e.keyCode;

        const ctrlKey = e.ctrlKey;
        const shiftKey = e.shiftKey;

        switch (keyCode) {
          case 46: // delete
            // delete키 누를시 선택 레이어 삭제
            handleRemoveLayerList();
            break;
          case 48: // 0
            if (ctrlKey) {
              // ctrl + 0 비율 100%로 맞추기
              dispatch(editorAction.updateCanvasInfo({ zoom: 100 }));
            }
            break;
          case 49: // 1
            if (ctrlKey) {
              // ctrl + 1 - 그리드 onoff
              dispatch(editorAction.updateCanvasInfo({ gridMode: !_canvasInfo.current.gridMode }));
            } else if (shiftKey) {
              // shift + 1 - 화면 맞추기
              dispatch(editorAction.updateCanvasInfo({ zoom: _canvasInfo.current.originZoom }));
              infiniteViewerRef.current?.scrollCenter();
            }
            break;
          case 50: // 2
            if (ctrlKey) {
              // ctrl + 2 - 스냅모드 onoff
              dispatch(editorAction.updateCanvasInfo({ snapMode: !_canvasInfo.current.snapMode }));
            }
            break;
          case 51: // 3
            if (ctrlKey) {
              // ctrl + 3 - 오버레이 onoff
              dispatch(editorAction.updateCanvasInfo({ overlayShowMode: !_canvasInfo.current.overlayShowMode }));
            }
            break;
          case 67: // c
            if (ctrlKey) {
              // ctrl + c 복사하기
              clipboardRef.current.copyLayer();
            }
            break;
          case 76: // L
            // 레이어 추가 모드
            dispatch(editorAction.updateCanvasInfo({ cursorType: 'LAYER-ADD' }));
            break;
          case 79: // O
            // 오버레이 추가 모드
            dispatch(editorAction.updateCanvasInfo({ cursorType: 'OVERLAY-ADD' }));
            break;
          case 86: // v
            if (ctrlKey) {
              // ctrl + v 붙여넣기
              clipboardRef.current.pasteLayer();
            } else {
              // 레이어 선택 모드
              dispatch(editorAction.updateCanvasInfo({ cursorType: 'SELECT' }));
            }
            break;
          case 89: // y
            if (ctrlKey) {
              // ctrl + y 재실행
              historyRef.current.redoHistory();
            }
            break;
          case 90: // z
            if (ctrlKey) {
              // ctrl + z 되돌리기
              historyRef.current.undoHistory();
            }
            break;

          case 187: // -
            if (ctrlKey) {
              // ctrl + - 줌인
              dispatch(editorAction.zoomInCanvas());
            }
            break;
          case 189: // =
            if (ctrlKey) {
              // ctrl + = 줌아웃
              dispatch(editorAction.zoomOutCanvas());
            }
            break;

          case 192: // ~
            if (shiftKey) {
              // shift + ` - 잠금 모드
              dispatch(editorAction.updateCanvasInfo({ lockMode: !_canvasInfo.current.lockMode }));
            }
            break;
          default:
            break;
        }
      }
    },
    [dispatch, handleRemoveLayerList, clipboardRef, infiniteViewerRef, historyRef],
  );

  useEffect(() => {
    // 브라우저 이벤트 방지
    const documentDisableKey = e => {
      const keyCode = e.keyCode;

      if (e.ctrlKey) {
        switch (keyCode) {
          case 187: // -
          case 189: // =
          case 90: // z
          case 89: // y
          case 48: // 0
          case 49: // 1
          case 50: // 2
          case 51: // 3
            e.preventDefault();
            e.returnValue = false;
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener('keydown', documentDisableKey);
    document.addEventListener('keydown', shortcutEnableDrag);
    document.addEventListener('keyup', shortcutDisableDrag);
    document.addEventListener('keydown', shortcutKeydownEvent);

    return () => {
      document.removeEventListener('keydown', documentDisableKey);
      document.removeEventListener('keydown', shortcutEnableDrag);
      document.removeEventListener('keyup', shortcutDisableDrag);
      document.removeEventListener('keydown', shortcutKeydownEvent);
    };
  }, [shortcutEnableDrag, shortcutDisableDrag, shortcutKeydownEvent]);

  return <></>;
};

export default React.memo(KeyEventManager);
