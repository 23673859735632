import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { TRANSITION } from './transitionProp';
import { useTimer } from '../../../utils/timerUtils';

import { useQuery } from 'react-query';
import { rss } from '../../../lib/playerApi';
import { previewToken } from '../../../lib/playlistApi';

const RSS = ({ rssData, layerHeight, layerContents, accountInfo }) => {
  const [titleList, setTitleList] = useState(['뉴스 데이터를 불러오는 중 입니다.']);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data, error } = useQuery(
    ['rss', rssData.rssId || 'mbn-all'],
    async () =>
      await rss({
        deviceToken: await previewToken(
          accountInfo.accountId,
          accountInfo.placeId,
          'preview',
          layerContents.playlistId,
        ),
        rssId: rssData.rssId || 'mbn-all',
      }),
    {
      refetchInterval: 60 * 60 * 1000,
    },
  );

  useEffect(() => {
    if (data?.rssFeedList?.length > 0) {
      setTitleList(data.rssFeedList.flatMap(e => e.title));
      setActiveIndex(0);
    }
  }, [data, setTitleList, setActiveIndex]);

  useTimer(
    () => {
      setActiveIndex((activeIndex + 1) % titleList?.length);
    },
    Number(rssData.transitionTime) * 1000,
    [activeIndex, titleList],
  );

  /* 프레임 트랜지션 설정 */
  /* 첫번째 prop => 넘겨줄 데이터 */
  /* 두번째 prop => 감시하고 있을 데이터 */
  const transitions = useTransition(titleList[activeIndex] || null, {
    ...TRANSITION?.[rssData?.animation || 'none'],
    key: activeIndex,
  });

  return (
    <>
      {error ? (
        <WarningBox>뉴스 데이터를 가져오지 못하였습니다.</WarningBox>
      ) : (
        <RSSBox height={layerHeight}>
          {transitions((props, item) => {
            return (
              <TitleBox key={props.key} style={props} $textInfo={rssData}>
                <span>{item}</span>
              </TitleBox>
            );
          })}
        </RSSBox>
      )}
    </>
  );
};

const WarningBox = styled.div`
  width: auto;
  height: auto;
  margin: 25px;
  overflow: hidden;
  font-size: 20px;
  color: #333333;
  display: flex;
  align-items: center;
`;

const RSSBox = styled.div`
  margin: auto;
  width: 100%;
  height: ${({ height }) => height || 100}px;
  font-size: 40px;
  position: relative;
  overflow: hidden;
`;

const TitleBox = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  display: flex;
  overflow: hidden;
  z-index: 999;
  span {
    display: block;
    width: calc(100% - 52px);
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: none;
    line-height: 1.5;
  }
  ${({ $textInfo }) => {
    return css`
      color: ${$textInfo?.color
        ? `rgba(${$textInfo.color?.r},${$textInfo.color?.g},${$textInfo.color?.b},${$textInfo.color?.a})`
        : 'inherit'};
      font-size: ${$textInfo?.fontSize ? $textInfo?.fontSize + 'px' : 'inherit'};
      font-weight: ${$textInfo?.boldYn === 'Y' ? 'bold' : 'normal'};
      font-style: ${$textInfo?.italicYn === 'Y' ? 'italic' : 'normal'};
      text-decoration-line: ${() => {
        let result = '';
        if ($textInfo?.underlineYn === 'Y') {
          result += 'underline ';
        }
        if ($textInfo?.lineThroughYn === 'Y') {
          result += 'line-through ';
        }
        return result ? result : 'none';
      }};
      text-shadow: ${$textInfo?.textShadowYn === 'Y' ? '2px 2px 4px #777' : 'none'};
    `;
  }}
`;

export default React.memo(RSS);
