import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../newComponents/Loading';
import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import OneCompany from './components/OneCompany';
import AppButtonArea from './components/AppButtonArea';

import { editorAction, editorSelector } from '../../../module/editorSlice';

import { INIT_ONEFLOOR_INFO } from '../constants/contentApp';
import { endpoints as enpointsFrameApi } from '../../../rtk/frameApi';

const OneFloorApp = ({ selectLayerContentsInfo = INIT_ONEFLOOR_INFO, handleCancelLayerContents, selectedLayerType, appLoading }) => {
  const placeId = useSelector(editorSelector.placeId);
  const playlistId = useSelector(editorSelector.playlistId);
  const { data: frameList } = enpointsFrameApi.frameList.useQueryState({ playlistId });

  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_ONEFLOOR_INFO);
  const [formValidate, setFormValidate] = useState({ oneFloorTitle: true, oneFloorTitleError: '', companyList: [] });
  const [frameOptionList, setFrameOptionList] = useState([]);

  const [isTitleOpen, setIsTitleOpen] = useState(true);
  const [isCountOpen, setIsCountOpen] = useState(true);
  const [isTextOpen, setIsTextOpen] = useState(true);

  const [addFileList, setAddFileList] = useState([]);
  const [removeFileList, setRemoveFileList] = useState([]);

  useEffect(() => {
    if (frameList) {
      let frameOptionList = [{ title: '없음', value: null }];
      frameOptionList = frameOptionList.concat(frameList.map(frame => ({ title: frame.frameOrder + 1, value: frame.frameId })));
      setFrameOptionList(frameOptionList);
    }
  }, [frameList]);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const appContentWrapRef = useRef(null);
  const titleRef = useRef(null);
  const companyListRef = useRef([]);

  const handleChangeCompanyOption = useCallback(
    (index, key, value) => {
      let changeFormData = [...formData.contentsData.companyList];
      changeFormData[index][key] = value;

      handleChangeObject('contentsData', 'companyList', changeFormData);
    },
    [formData.contentsData.companyList, handleChangeObject],
  );

  const handleChangeCounter = useCallback(
    type => {
      let changeFormData = [...formData.contentsData.companyList];
      if (type === 'plus') {
        changeFormData.push({ companyName: '', companyYear: '', bossName: '', telephone: '', businessArea: '', frameLinkId: null });
        handleChangeObject('contentsData', 'companyList', changeFormData);
      } else if (type === 'minus' && formData.contentsData.companyList.length > 1) {
        changeFormData.pop();
        handleChangeObject('contentsData', 'companyList', changeFormData);
      }
    },
    [formData.contentsData.companyList, handleChangeObject],
  );

  const checkValidDate = useCallback(() => {
    let validate = {
      companyList: [],
      oneFloorTitle: true,
      oneFloorTitleError: '',
    };
    let validFlag = false;

    if (formData.contentsData.oneFloorTitle.trim() === '') {
      validFlag = true;
      validate.oneFloorTitle = false;
      validate.oneFloorTitleError = '0층 기업정보를 입력하세요.';
      setIsTitleOpen(true);
    }

    formData.contentsData.companyList.forEach((company, index) => {
      let companyValidate = {};
      if (!company.companyName || company.companyName.trim() === '') {
        validFlag = true;
        companyValidate.companyName = false;
        companyValidate.companyNameError = '기업명을 입력하세요.';
        setIsTextOpen(true);
      } else {
        companyValidate.companyName = true;
        companyValidate.companyNameError = '';
      }

      if (!company.companyYear || company.companyYear.trim() === '') {
        validFlag = true;
        companyValidate.companyYear = false;
        companyValidate.companyYearError = '설립연도를 입력하세요.';
        setIsTextOpen(true);
      } else {
        companyValidate.companyYear = true;
        companyValidate.companyYearError = '';
      }

      if (!company.bossName || company.bossName.trim() === '') {
        validFlag = true;
        companyValidate.bossName = false;
        companyValidate.bossNameError = '대표자명을 입력하세요.';
        setIsTextOpen(true);
      } else {
        companyValidate.bossName = true;
        companyValidate.bossNameError = '';
      }

      validate.companyList.push(companyValidate);
    });

    setFormValidate(validate);

    let addFileList = [];
    let removeFileList = [...formData.contentsFileList];

    for (const afterLogoFile of formData.contentsData.companyList) {
      if (afterLogoFile.logoFile) {
        const index = removeFileList.findIndex(beforeFile => beforeFile.fileId === afterLogoFile.logoFile.fileId);
        if (index > -1) {
          removeFileList.splice(index, 1);
        } else {
          addFileList.push(afterLogoFile.logoFile);
        }
      }
    }

    setAddFileList(addFileList);
    setRemoveFileList(removeFileList);

    if (validFlag) {
      if (!validate.oneFloorTitle) {
        setTimeout(() => {
          appContentWrapRef.current.scrollTo({ top: titleRef.current.scrollIntoView({ block: 'center' }) });
        }, 100);
      } else {
        const companyList = validate.companyList;
        for (let index = 0; index < companyList.length; index++) {
          let scrollDiv;
          if (!companyList[index].companyName) {
            scrollDiv = companyListRef.current[index].getElementsByClassName('companyName');
          } else if (!companyList[index].companyYear) {
            scrollDiv = companyListRef.current[index].getElementsByClassName('companyYear');
          } else if (!companyList[index].bossName) {
            scrollDiv = companyListRef.current[index].getElementsByClassName('bossName');
          }
          if (scrollDiv !== undefined) {
            setTimeout(() => {
              appContentWrapRef.current.scrollTo({ top: scrollDiv[0].scrollIntoView({ block: 'center' }) });
            }, 100);
            break;
          }
        }
      }
    }

    return validFlag;
  }, [formData]);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };

      if (!layerContentsInfo.contentsData.oneFloorTitle) {
        layerContentsInfo.contentsData.oneFloorTitle = '';
      }
      if (!layerContentsInfo.contentsData.companyList) {
        layerContentsInfo.contentsData.companyList = [{ companyName: '', companyYear: '', bossName: '', telephone: '', businessArea: '', frameLinkId: null }];
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_ONEFLOOR_INFO);
    }
  }, [selectLayerContentsInfo]);

  return (
    <Container>
      <AppTitle text="층 안내(단층)APP" />
      <AppContentWrap ref={appContentWrapRef}>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox title="제목" isOpen={isTitleOpen} changeOpen={setIsTitleOpen} line>
              <Input
                ref={titleRef}
                placeholder="*0층 기업 정보"
                value={formData.contentsData.oneFloorTitle || ''}
                onChange={e => handleChangeObject('contentsData', 'oneFloorTitle', e.target.value)}
                isValidate={formValidate.oneFloorTitle}
              />
              <InputError>{formValidate.oneFloorTitleError}</InputError>
            </ContentBox>
            <ContentBox title="개수" isOpen={isCountOpen} changeOpen={setIsCountOpen} line>
              <CounterWrapper>
                <WhiteButton onClick={() => handleChangeCounter('minus')}>-</WhiteButton>
                <CustomInput value={formData.contentsData.companyList.length || 0} readOnly></CustomInput>
                <WhiteButton onClick={() => handleChangeCounter('plus')}>+</WhiteButton>
              </CounterWrapper>
            </ContentBox>
            <ContentBox title="내용" isOpen={isTextOpen} changeOpen={setIsTextOpen}>
              {formData.contentsData.companyList &&
                formData.contentsData.companyList.map((company, index) => (
                  <OneCompnayWrapper key={`companyWrapper-app-${index}`} ref={ref => (companyListRef.current[index] = ref)}>
                    <OneCompany
                      key={`company-app-${index}`}
                      index={index}
                      company={company}
                      companyList={formData.contentsData.companyList}
                      handleChangeCompanyOption={handleChangeCompanyOption}
                      frameOptionList={frameOptionList}
                      formValidate={formValidate}
                      handleChangeObject={handleChangeObject}
                    />
                  </OneCompnayWrapper>
                ))}
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        checkValidDate={checkValidDate}
        addFileList={addFileList}
        removeFileList={removeFileList}
        cancelApp={handleCancelLayerContents}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const InputError = styled.label`
  height: 1.25rem;
  padding: 0.125rem 0.25rem;
  color: #f05b5b;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
`;

const Input = styled.input`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 6px 17px 6px 12px;
  border: 1px solid ${({ isError }) => (isError ? '#f05b5b' : '#dedede')};
  border-radius: 8px;
  font-size: 14px;
  color: #333333;
  border: 0.0625rem solid ${props => (props.isValidate === undefined || props.isValidate === true ? '#DDDDDD' : '#F05B5B')};

  &:focus {
    outline: none;
  }
`;

const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-left: 10px; */
`;

const WhiteButton = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid #dddddd;
  background: #ffffff;
  border-radius: 6px;
  color: #999999;
  font-size: 1.5rem;

  // ${({ disabled }) =>
    disabled &&
    css`
      background: rgba(221, 221, 221, 0);
    `};
`;

const CustomInput = styled(Input)`
  text-align: center;
  margin: 0 8px;
`;

const OneCompnayWrapper = styled.div``;

export default React.memo(OneFloorApp);
