import React from 'react';
import styled, { css } from 'styled-components';

const TextPreview = ({ textInfo }) => {
  return (
    <TextBox textInfo={textInfo}>
      <pre>{textInfo.text}</pre>
    </TextBox>
  );
};

const TextBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ textInfo }) => {
    return css`
        color: ${textInfo?.color ? `rgba(${textInfo.color?.r},${textInfo.color?.g},${textInfo.color?.b},${textInfo.color?.a})` : '#000'};
        font-size: ${textInfo?.fontSize ? textInfo?.fontSize + 'px' : '10px'};
        line-height: ${textInfo?.lineHeight ? textInfo?.lineHeight : 'normal'};
        overflow: ${textInfo?.scrollYn === 'Y' ? 'scroll' : 'hidden'};
        font-weight: ${textInfo?.boldYn === 'Y' ? 'bold' : 'normal'};
        font-style: ${textInfo?.italicYn === 'Y' ? 'italic' : 'normal'};
        text-decoration-line: ${() => {
          let result = '';
          if (textInfo?.underlineYn === 'Y') {
            result += 'underline ';
          }
          if (textInfo?.lineThroughYn === 'Y') {
            result += 'line-through ';
          }
          return result ? result : 'none';
        }};
        text-shadow: ${
          textInfo?.textShadowYn === 'Y' ? '2px 2px 4px #777' : 'none'
        };
        justify-content: ${
          textInfo?.textAlign.vertical
            ? textInfo.textAlign?.vertical
            : 'center'
        };
        text-align: ${
          textInfo?.textAlign.horizontal
            ? textInfo.textAlign?.horizontal
            : 'center'
        };
      `;
  }};
  pre {
    width: 100%;
    height: auto;
    display: block;
    white-space: pre-wrap;
  };
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  };
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  };
`;

export default React.memo(TextPreview);
