import React, { useCallback, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { darken, lighten } from 'polished';
import { toast } from 'react-toastify';

import { editorSelector } from 'module/editorSlice';

import { useGroupListQuery } from 'rtk/groupApi';
import { useDeviceListQuery, useDevicePlayMutation, useDeviceStopMutation } from 'rtk/deviceApi';
import { usePlaylistListQuery } from 'rtk/playlistApi';

import Modal from 'newComponents/Modal';
import Loading from 'newComponents/Loading';
import SelectInput from 'newComponents/SelectInput';
import CheckBox from 'newComponents/CheckBox';
import DeviceScrshotPopup from 'components/DeviceScrshotPopup';

import { RiSearchLine as SearchIcon } from 'react-icons/ri';
import { IoLogoWindows as WindowsIcon, IoLogoAndroid as AndroidIcon } from 'react-icons/io5';

import { ReactComponent as ListBtnIcon } from 'assets/images/device/ic-list.svg';
import { ReactComponent as ParingBtnIcon } from 'assets/images/device/ic-paring-list.svg';
import { ReactComponent as StopBtnIcon } from 'assets/images/device/ic-stop.svg';
import { ReactComponent as PlayBtnIcon } from 'assets/images/device/ic-play.svg';
import { ReactComponent as PlayStateIcon } from 'assets/images/device/ic-play-state.svg';

const PlayModal = ({ isOpen, closeModal, openModal }) => {
  const placeId = useSelector(editorSelector.placeId);
  const playlistId = useSelector(editorSelector.playlistId);
  const playlistNm = useSelector(editorSelector.playlistNm);

  const filterGroupInputRef = useRef(null);
  const openDt = useRef(dayjs().toISOString());

  const [filterGroupId, setFilterGroupId] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const [tableType, setTableType] = useState('ALL');

  const { data: groupList = [], isLoading: isGroupListLoading } = useGroupListQuery(
    {
      groupPid: placeId,
      placeId: placeId,
      filterValue,
    },
    { refetchOnMountOrArgChange: true, skip: placeId ? false : true },
  );

  const { data: deviceList = [], isLoading: isDeviceListLoading } = useDeviceListQuery(
    {
      placeId: placeId,
      groupId: filterGroupId === '' ? placeId : filterGroupId,
      playlistId: tableType === 'PAIRING' ? playlistId : '',
      filterValue,
    },
    { refetchOnMountOrArgChange: true, skip: placeId ? false : true },
  );

  const { data: playlistList } = usePlaylistListQuery({ placeId }, { refetchOnMountOrArgChange: true, skip: !placeId });
  const [devicePlay] = useDevicePlayMutation();
  const [deviceStop] = useDeviceStopMutation();

  const [groupOptionList, setGroupOptionList] = useState([
    {
      value: '',
      title: '전체',
    },
  ]);

  const [hoverDevice, setHoverDevice] = useState(null);

  useEffect(() => {
    if (isGroupListLoading) {
      return;
    }

    let reduceList = [];

    reduceList.push({
      value: '',
      title: '전체',
      sortSeq: 0,
    });

    let newList = [...groupList];
    newList.sort(function (lhs: any, rhs: any) {
      if (lhs.sortSeq === rhs.sortSeq) {
        return 0;
      }
      return lhs.sortSeq > rhs.sortSeq ? 1 : -1;
    });

    newList.forEach(group => reduceList.push({ value: group.groupId, title: group.groupNm }));

    setGroupOptionList(reduceList);
  }, [isGroupListLoading, groupList]);

  const [checkList, setCheckList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  useEffect(() => {
    if (filterGroupInputRef.current && !isOpen) {
      setCheckList([]);
      setFilterGroupId('');
      setFilterValue('');
      setTableType('ALL');
      filterGroupInputRef.current.handleClearValue();
    }
  }, [isOpen]);

  const handleCheckAll = useCallback(() => {
    if (isCheckAll) {
      setCheckList([]);
    } else {
      setCheckList(deviceList.map(device => ({ deviceId: device.deviceId })));
    }
  }, [isCheckAll, deviceList]);

  const handleCheck = useCallback(
    device => {
      const isChecked = checkList.findIndex(check => check.deviceId === device.deviceId) >= 0;
      if (isChecked) {
        setCheckList(checkList.filter(check => check.deviceId !== device.deviceId));
      } else {
        setCheckList(checkList.concat([{ deviceId: device.deviceId }]));
      }
    },
    [checkList],
  );

  useEffect(() => {
    if (isDeviceListLoading) {
      return;
    }

    if (deviceList.length === 0) {
      setIsCheckAll(false);
      return;
    }

    setIsCheckAll(checkList.length === deviceList.length);
  }, [isDeviceListLoading, checkList, deviceList]);

  const handleSearch = useCallback(() => {
    setCheckList(() => []);
    const { value } = filterGroupInputRef.current;
    setFilterValue(value);
  }, []);

  const onEnterDeviceScrshot = useCallback((e, device) => {
    setHoverDevice({
      x: e.clientX,
      y: e.clientY,
      scrshotFile: device.scrshotFile,
      scrshotDt: device.scrshotDt,
    });
  }, []);

  const onLeaveDeviceScrshot = useCallback((e, device) => {
    setHoverDevice(null);
  }, []);

  const handleStop = useCallback(() => {
    if (checkList.length < 1) {
      toast.warn('디바이스를 체크해주세요.');
      return;
    }

    deviceStop({
      placeId,
      deviceList: checkList,
    }).then(({ data }) => {
      if (data.resultFlag) {
        toast.success('배포가 중단 되었습니다.');
      } else {
        toast.error('배포 중단에 실패하였습니다.');
      }
    });

    setCheckList(() => []);
  }, [deviceStop, checkList, placeId]);

  const handlePlay = useCallback(() => {
    if (checkList.length < 1) {
      toast.warn('디바이스를 체크해주세요.');
      return;
    }

    devicePlay({
      placeId,
      playlistId,
      playlistNm,
      deviceList: checkList,
    }).then(({ data }) => {
      if (data.resultFlag) {
        toast.success('배포 되었습니다.');
      } else {
        toast.error('배포에 실패하였습니다.');
      }
    });

    setCheckList(() => []);
  }, [devicePlay, checkList, playlistId, playlistNm, placeId]);

  const handleChangeTableType = useCallback(tableType => {
    setCheckList(() => []);
    const { handleClearValue } = filterGroupInputRef.current;
    setTableType(tableType);
    handleClearValue();
  }, []);

  if (isDeviceListLoading) {
    return <Loading />;
  }

  return (
    <StyledModal
      title={
        <Title>
          <span>플레이</span>
        </Title>
      }
      onClose={closeModal}
      onBackdropClick={closeModal}
      hideButton={true}
      visible={isOpen}
      height="90%"
      isLine
    >
      <Wrap>
        <ToolArea>
          <FilterBox>
            <SearchIcon />
            <SelectInput
              width="162px"
              selectedValue={filterGroupId}
              optionList={groupOptionList}
              onSelectChange={value => {
                setFilterGroupId(value);
                const { handleClearValue } = filterGroupInputRef.current;
                handleClearValue();
              }}
              displayOptionCnt={5}
            />
            <GroupInput ref={filterGroupInputRef} handleSearch={handleSearch} />
            <SearchButton onClick={() => handleSearch()}>검색</SearchButton>
            <DeviceListButton onClick={() => handleChangeTableType('ALL')}>
              <ListBtnIcon fill={tableType === 'ALL' ? '#41a1ea' : '#999999'} />
            </DeviceListButton>
            <DeviceListButton onClick={() => handleChangeTableType('PAIRING')}>
              <ParingBtnIcon fill={tableType === 'PAIRING' ? '#41a1ea' : '#999999'} />
            </DeviceListButton>
          </FilterBox>
          <PlayBox>
            <StopButton onClick={() => handleStop()}>
              <StopBtnIcon style={{ marginRight: '5px' }} />
              Stop
            </StopButton>
            <PlayButton onClick={() => handlePlay()}>
              <PlayBtnIcon style={{ marginRight: '5px' }} />
              Play
            </PlayButton>
          </PlayBox>
        </ToolArea>
        <TableDiv>
          <TableHead>
            <SmallCol
              onClick={e => {
                e.preventDefault();
                handleCheckAll();
              }}
            >
              <CheckBox checked={isCheckAll} />
            </SmallCol>
            <MiddleCol>그룹명</MiddleCol>
            <BigCol>디바이스이름</BigCol>
            <BigCol>설명</BigCol>
            <MiddleCol>디바이스타입</MiddleCol>
            <SmallCol>연결</SmallCol>
            <BigCol>플레이 중인 재생목록</BigCol>
            <MiddleCol>플레이 적용 시간</MiddleCol>
          </TableHead>
          <TableBody>
            {deviceList.length > 0 ? (
              deviceList.map((device, index) => (
                <TableRow
                  key={index}
                  selected={checkList.some(check => check.deviceId === device.deviceId)}
                  onClick={e => {
                    e.preventDefault();
                    handleCheck(device);
                  }}
                >
                  <SmallCol>
                    <CheckBox checked={checkList.some(check => check.deviceId === device.deviceId)} />
                  </SmallCol>
                  <MiddleCol>
                    {groupList?.find(group => group.groupId === device.groupId)?.groupNm || '미지정'}
                  </MiddleCol>
                  <BigCol>{device.deviceNm}</BigCol>
                  <BigCol>{device.deviceDesc}</BigCol>
                  <MiddleCol>
                    {
                      {
                        WINDOWS: <WindowsIcon color="#00AEF0" size={20} />,
                        ANDROID: <AndroidIcon color="#95CF00" size={20} />,
                      }[device.deviceType]
                    }
                    &nbsp;{device.deviceType}
                  </MiddleCol>
                  <SmallCol
                    onMouseEnter={e => onEnterDeviceScrshot(e, device)}
                    onMouseLeave={e => onLeaveDeviceScrshot(e, device)}
                  >
                    <DeviceConnectIcon
                      connected={
                        device.connectTime &&
                        dayjs(device.connectTime).isAfter(dayjs(openDt.current).subtract(5, 'minutes'))
                      }
                    />
                  </SmallCol>
                  <BigCol>
                    {device.playlistId === playlistId && (
                      <PlayStateIcon style={{ marginRight: '7px', top: '2px', position: 'relative' }} />
                    )}
                    {playlistList?.find(playlist => playlist.playlistId === device.playlistId)?.playlistNm || ''}
                  </BigCol>
                  <MiddleCol>{device.playlistDt && dayjs(device.playlistDt).format('YYYY.MM.DD HH:mm:ss')}</MiddleCol>
                </TableRow>
              ))
            ) : (
              <NoTable>디바이스 없음</NoTable>
            )}
          </TableBody>
        </TableDiv>

        {hoverDevice && (
          <DeviceScrshotPopup
            x={hoverDevice.x}
            y={hoverDevice.y}
            scrshotFile={hoverDevice.scrshotFile}
            scrshotDt={hoverDevice.scrshotDt}
          />
        )}
      </Wrap>
    </StyledModal>
  );
};

const GroupInput = forwardRef(({ handleSearch }, ref) => {
  const [value, setValue] = useState('');

  const handleClearValue = () => {
    setValue('');
  };

  useImperativeHandle(ref, () => ({
    value,
    handleClearValue,
  }));

  return (
    <GroupFilterInput
      placeholder="디바이스명을 입력해주세요"
      value={value}
      onChange={e => setValue(e.target.value)}
      onKeyUp={e => e.keyCode === 13 && handleSearch()}
    />
  );
});

const StyledModal = styled(Modal)`
  .body {
    padding: 10px;
  }
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #3333333;
  font-size: 14px;
  font-weight: 500;

  & > .warn {
    font-size: 12px;
    color: #f05b5b;
  }
`;

const Wrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const ToolArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 10px 10px;
`;

const FilterBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
`;

const GroupFilterInput = styled.input`
  flex: 1;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #dddddd;
  padding: 10px;
  color: #666666;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Noto Sans KR';
  &:focus {
    outline: none;
  }
`;

const PlayBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  margin-left: auto;
`;

const StopButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 100%;
  border: 1px solid #f05b5b;
  background: #ffffff;
  border-radius: 6px;
  color: #f05b5b;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    background: ${lighten(0.1, '#f05b5b')};
  }
  &:active {
    background: ${darken(0.1, '#f05b5b')};
  }
`;

const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 100%;
  border: 1px solid #2a91df;
  background: #ffffff;
  border-radius: 6px;
  color: #2a91df;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    background: ${lighten(0.1, '#2a91df')};
  }
  &:active {
    background: ${darken(0.1, '#2a91df')};
  }
`;

const SearchButton = styled.button`
  min-width: 50px;
  width: 50px;
  height: 100%;
  border: 1px solid #dddddd;
  background: #ffffff;
  border-radius: 6px;
  color: #666666;
  font-size: 16px;
`;

const DeviceListButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  width: 40px;
  height: 100%;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;

  svg {
    &:hover {
      fill: #41a1ea;
      line {
        color: #41a1ea;
      }
    }
  }
`;

const TableDiv = styled.div`
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #000000;
  margin-top: 24px;
  line-height: 21px;
  overflow-y: overlay;
`;

const TableHead = styled.div`
  display: flex;
  height: 54px;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: #f8f8f8;
  border-bottom: 1px solid #dedede;
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
`;

const TableRow = styled.div`
  display: flex;
  width: 100%;
  height: 54px;
  align-items: center;
  text-align: center;
  color: ${({ selected }) => selected && '#2a91df'};
  background-color: ${({ selected }) => selected && '#e3f0fa'};
  border-bottom: 1px solid #eeeeee;

  &:hover {
    background-color: #e3f0fa;
    color: #2a91df;
  }
`;

const SmallCol = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 5%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MiddleCol = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 10%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BigCol = styled.div`
  flex: 1;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NoTable = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #666666;
  padding: 14px 0;
`;

const DeviceConnectIcon = styled.div`
  width: 0.875rem;
  height: 0.875rem;
  background: ${props => (props.connected ? '#39b54a' : '#f05b5b')};
  border-radius: 50%;
`;

export default React.memo(PlayModal);
