import * as apiLib from './api';

export const saveSchedule = async scheduleData => {
  try {
    const data = {
      ...scheduleData,
    };
    const result = await apiLib.fetchInteractionApi('/schedule/schedule-save', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('saveSchedule error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const editSchedule = async scheduleData => {
  try {
    const data = {
      ...scheduleData,
    };
    const result = await apiLib.fetchInteractionApi('/schedule/schedule-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('editSchedule error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};