import * as apiLib from './api';

export const contact = async ({ contactName, contactLastname, contactEmail, contactTitle, contactMsg, contactLocation, contactProduct, contactMobile }) => {
  try {
    const data = {
      contactName,
      contactLastname,
      contactEmail,
      contactTitle,
      contactMsg,
      contactLocation,
      contactProduct,
      contactMobile,
    };

    const result = await apiLib.fetchInteractionApi('/home/contact', data);
    if (result.resultFlag) {
      return TextTrackCue;
    } else {
      throw Object.assign(new Error('contact error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getNewsList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchInteractionApi('/home/news-list', data);
    if (result.resultFlag) {
      return result.newsList;
    } else {
      throw Object.assign(new Error('news-list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getFaqList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchInteractionApi('/home/faq-list', data);
    if (result.resultFlag) {
      return result.faqList;
    } else {
      throw Object.assign(new Error('faq-list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
