import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

const Watch = ({ watchFormat, container }) => {
  const [idleTick, setIdleTick] = useState(0);
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [position, setPosition] = useState('line');
  const [direction, setDirection] = useState('row');
  const [hAlign, setHAlign] = useState('center');
  const [vAlign, setVAlign] = useState('center');
  const [dateFontSize, setDateFontSize] = useState('35px');
  const [timeFontSize, setTimeFontSize] = useState('35px');
  const [dateFontColor, setDateFontColor] = useState('#ffffff');
  const [timeFontColor, setTimeFontColor] = useState('#ffffff');
  const [isShowDate, setIsShowDate] = useState(true);
  const [isShowTime, setIsShowTime] = useState(true);

  useEffect(() => {
    const timeout = setInterval(() => {
      setIdleTick(dayjs().unix());
    }, 1000);

    return () => {
      if (timeout) clearInterval(timeout);
    };
  }, []);

  useEffect(() => {
    switch (watchFormat.time) {
      case 'hmsap':
        setTime(`${dayjs().format('hh:mm:ss A')}`);
        break;
      case 'hms':
        setTime(`${dayjs().format('HH:mm:ss')}`);
        break;
      case 'hmap':
        setTime(`${dayjs().format('hh:mm A')}`);
        break;
      case 'hm':
        setTime(`${dayjs().format('HH:mm')}`);
        break;
      default:
        setTime(`${dayjs().format('HH:mm:ss')}`);
        break;
    }

    const WEEK = ['일', '월', '화', '수', '목', '금', '토'];

    switch (watchFormat.date) {
      case 'ymdw':
        setDate(`${dayjs().format('YYYY년 MM월 DD일')} (${WEEK[dayjs().day()]})`);
        break;
      case 'ymd':
        setDate(`${dayjs().format('YYYY년 MM월 DD일')}`);
        break;
      case 'mdw':
        setDate(`${dayjs().format('MM월 DD일')} (${WEEK[dayjs().day()]})`);
        break;
      case 'md':
        setDate(`${dayjs().format('MM월 DD일')}`);
        break;
      default:
        setDate(`${dayjs().format('YYYY년 MM월 DD일')} (${WEEK[dayjs().day()]})`);
        break;
    }

    let color = '#000000';
    if (container.fontColor) {
      color = `rgba(${container.fontColor.r},${container.fontColor.g},${container.fontColor.b},100)`;
    }

    switch (watchFormat.theme) {
      case 'horizon':
        setPosition('line');
        setDirection('row');
        setHAlign('center');
        setVAlign('center');
        setDateFontSize('26px');
        setTimeFontSize('35px');
        setDateFontColor(color);
        setTimeFontColor(color);
        setIsShowDate(true);
        setIsShowTime(true);
        break;
      case 'dateOnly':
        setPosition('timeTop');
        setDirection('row');
        setHAlign('center');
        setVAlign('center');
        setDateFontSize('26px');
        setTimeFontSize('35px');
        setDateFontColor(color);
        setTimeFontColor(color);
        setIsShowDate(true);
        setIsShowTime(false);
        break;
      case 'timeOnly':
        setPosition('timeTop');
        setDirection('row');
        setHAlign('center');
        setVAlign('center');
        setDateFontSize('26px');
        setTimeFontSize('35px');
        setDateFontColor(color);
        setTimeFontColor(color);
        setIsShowDate(false);
        setIsShowTime(true);
        break;
      case 'dateTop':
        setPosition('dateTop');
        setDirection('column');
        setHAlign('center');
        setVAlign('flex-end');
        setDateFontSize('26px');
        setTimeFontSize('35px');
        setDateFontColor(color);
        setTimeFontColor(color);
        setIsShowDate(true);
        setIsShowTime(true);
        break;
      case 'timeTop':
        setPosition('timeTop');
        setDirection('column');
        setHAlign('center');
        setVAlign('flex-end');
        setDateFontSize('26px');
        setTimeFontSize('35px');
        setDateFontColor(color);
        setTimeFontColor(color);
        setIsShowDate(true);
        setIsShowTime(true);
        break;
      default:
        break;
    }
  }, [idleTick, watchFormat, container]);

  return (
    <WatchBox direction={direction} hAlign={hAlign} vAlign={vAlign}>
      {position === 'timeTop' ? (
        <>
          {isShowTime && (
            <TimeBox fontSize={timeFontSize} fontColor={timeFontColor}>
              <span>{time}</span>
            </TimeBox>
          )}
          {direction === 'row' && <span>&nbsp;</span>}
          {isShowDate && (
            <DateBox fontSize={dateFontSize} fontColor={dateFontColor}>
              <span>{date}</span>
            </DateBox>
          )}
        </>
      ) : (
        <>
          {isShowDate && (
            <DateBox fontSize={dateFontSize} fontColor={dateFontColor}>
              <span>{date}</span>
            </DateBox>
          )}
          {direction === 'row' && <span>&nbsp;</span>}
          {isShowTime && (
            <TimeBox fontSize={timeFontSize} fontColor={timeFontColor}>
              <span>{time}</span>
            </TimeBox>
          )}
        </>
      )}
    </WatchBox>
  );
};

const WatchBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 30px 0 0;
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: ${props => props.vAlign};
  justify-content: ${props => props.hAlign};
`;

const TimeBox = styled.div`
  font-size: ${props => props.fontSize};
  font-weight: 700;
  color: ${props => props.fontColor};
`;

const DateBox = styled.div`
  font-size: ${props => props.fontSize};
  font-weight: 700;
  color: ${props => props.fontColor};
`;

export default React.memo(Watch);
