import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { FiMenu as MenuIcon, FiX as CloseIcon } from 'react-icons/fi';

import logo from '../../assets/images/ELIGA_LOGO.svg';

import MenuListContainer from './MenuList';

const NavBar = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      {isMobile ? (
        <MobileContainer>
          <div onClick={e => navigate('/dashboard')}>
            <img src={logo} alt="" />
          </div>
          {isMenuOpen ? (
            <CloseIcon onClick={e => setIsMenuOpen(!isMenuOpen)} />
          ) : (
            <MenuIcon onClick={e => setIsMenuOpen(!isMenuOpen)} />
          )}
          {isMenuOpen && (
            <MobileMenu $isMobile={isMobile}>
              <MenuListContainer />
            </MobileMenu>
          )}
        </MobileContainer>
      ) : (
        <Container>
          <MenuLogo>
            <div onClick={e => navigate('/dashboard')}>
              <img src={logo} alt="" />
            </div>
          </MenuLogo>
          <MenuListContainer />
        </Container>
      )}
    </>
  );
};

const MobileContainer = styled.div`
  height: 64px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: #2d373e;
  z-index: 1001;

  img {
    width: 122px;
    height: 20px;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #ffffff;

    &:hover {
      color: #60bbff;
    }
  }
`;

const MobileMenu = styled.div`
  z-index: 1002;
  width: 100%;
  height: calc(100vh - 64px);
  position: absolute;
  left: 0;
  top: 64px;
  display: flex;
  flex-direction: column;
  background: #2d373e;
  overflow-y: ${props => props.$isMobile && 'auto'};
`;

const Container = styled.div`
  height: 100%;
  width: 200px;
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #2d373e;
  z-index: 1001;
`;

const MenuLogo = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 20px;
    cursor: pointer;
  }
`;

export default React.memo(NavBar);
