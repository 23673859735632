import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface ActiveItemState {
  activePlaylistId: string;
  activeFrameId: string;
  activeFrameIdList: Array<any>;
  activeLayerId: string;
  layerType: string;
  isLoading: boolean;
  error: string | null;
}

const ActiveItemInitialState: ActiveItemState = {
  activePlaylistId: '',
  activeFrameId: '',
  activeFrameIdList: [],
  activeLayerId: '',
  layerType: '',
  isLoading: false,
  error: null,
};

const initActiveItemState = (state: ActiveItemState, { payload }: PayloadAction<string>) => {
  state.activePlaylistId = '';
  state.activeFrameId = '';
  state.activeFrameIdList = [];
  state.activeLayerId = '';
  state.layerType = '';
};

const initActiveFrameIdList = (state: ActiveItemState, { payload }: PayloadAction<string>) => {
  state.activeFrameIdList = [];
};

const setActivePlaylistId = (
  state: ActiveItemState,
  { payload }: PayloadAction<string>,
) => {
  state.activePlaylistId = payload;
};

const setActiveFrameId = (
  state: ActiveItemState,
  { payload }: PayloadAction<string>,
) => {
  state.activeFrameId = payload;
};

const setActiveFrameIdList = (state: ActiveItemState, { payload: { frameId, frameOrder }}: PayloadAction<string>) => {
  let activeFrameIdObj = {
    frameId,
    frameOrder,
  };

  state.activeFrameIdList.push(activeFrameIdObj);
  state.activeFrameIdList.sort(function (lhs, rhs) {
    return lhs.frameOrder - rhs.frameOrder 
  });
};

const setUpdateFrameIdList = (state: ActiveItemState, { payload: { frameId, frameOrder }}: PayloadAction<string>) => {
  let targetObj = state.activeFrameIdList.find(activeFrameIdObj => activeFrameIdObj.frameId === frameId);
  if (targetObj) targetObj.frameOrder = frameOrder;

  state.activeFrameIdList.sort(function (lhs, rhs) {
    return lhs.frameOrder - rhs.frameOrder 
  });
};

const setRemoveFrameIdList = (state: ActiveItemState, { payload: frameId }: PayloadAction<string>) => {
  state.activeFrameIdList = state.activeFrameIdList.filter(activeFrameIdObj => activeFrameIdObj.frameId !== frameId);
};

const setInitFrameIdList = (state: ActiveItemState, { payload }: PayloadAction<string>) => {
  state.activeFrameIdList = [];
};

const setDeselectFrameIdList = (state: ActiveItemState, { payload: activeFrameId }: PayloadAction<string>) => {
  state.activeFrameIdList = state.activeFrameIdList.filter(activeFrameIdObj => activeFrameIdObj.frameId === activeFrameId);
};

const setActiveLayerId = (
  state: ActiveItemState,
  { payload }: PayloadAction<string>,
) => {
  state.activeLayerId = payload;
};

const setLayerType = (
  state: ActiveItemState,
  { payload }: PayloadAction<string>,
) => {
  state.layerType = payload;
};

const slice = createSlice({
  name: 'activeItem',
  initialState: ActiveItemInitialState,
  reducers: {
    initActiveItemState,
    initActiveFrameIdList,
    setActivePlaylistId,
    setActiveFrameId,
    setActiveFrameIdList,
    setUpdateFrameIdList,
    setRemoveFrameIdList,
    setInitFrameIdList,
    setDeselectFrameIdList,
    setActiveLayerId,
    setLayerType,
  },
});

const selectAll = createDraftSafeSelector(
  (state: ActiveItemState) => state.activePlaylistId,
  (state: ActiveItemState) => state.activeFrameId,
  (state: ActiveItemState) => state.activeFrameIdList,
  (state: ActiveItemState) => state.activeLayerId,
  (state: ActiveItemState) => state.isLoading,
  (state: ActiveItemState) => state.error,
  (activePlaylistId, activeFrameId, activeFrameIdList, activeLayerId, isLoading, error) => ({
    activePlaylistId,
    activeFrameId,
    activeFrameIdList,
    activeLayerId,
    isLoading,
    error,
  }),
);

const selectPlaylistId = createDraftSafeSelector(
  (state: ActiveItemState) => state.activePlaylistId,
  activePlaylistId => activePlaylistId,
);

const selectFrameId = createDraftSafeSelector(
  (state: ActiveItemState) => state.activeFrameId,
  activeFrameId => activeFrameId,
);

const selectActiveFrameIdList = createDraftSafeSelector(
  (state: ActiveItemState) => state.activeFrameIdList,
  activeFrameIdList => activeFrameIdList,
);

const selectLayerId = createDraftSafeSelector(
  (state: ActiveItemState) => state.activeLayerId,
  activeLayerId => activeLayerId,
);

const selectLayerType = createDraftSafeSelector(
  (state: ActiveItemState) => state.layerType,
  layerType => layerType,
);

export const activeItemSelector = {
  all: state => selectAll(state[ACTIVEITEM]),
  playlistId: state => selectPlaylistId(state[ACTIVEITEM]),
  frameId: state => selectFrameId(state[ACTIVEITEM]),
  activeFrameIdList: state => selectActiveFrameIdList(state[ACTIVEITEM]),
  layerId: state => selectLayerId(state[ACTIVEITEM]),
  layerType: state => selectLayerType(state[ACTIVEITEM]),
};

export const ACTIVEITEM = slice.name;
export const activeItemReducer = slice.reducer;
export const activeItemAction = slice.actions;
