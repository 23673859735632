import * as apiLib from './api';

export const getOverlayContentsList = async playlistId => {
  try {
    const data = {
      playlistId
    };

    const result = await apiLib.fetchInteractionApi(
      '/overlay-contents/list',
      data,
    );

    if (result.resultFlag) {
      return result.overlayContentsList;
    } else {
      throw Object.assign(new Error('getOverlayContentsList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getOverlayContents = async overlayId => {
  try {
    const data = {
      overlayId,
    };
    const result = await apiLib.fetchInteractionApi(
      '/overlay-contents/overlay-contents',
      data,
    );

    if (result.resultFlag) {
      return result.overlayContents;
    }
  } catch (err) {
    throw err;
  }
};

export const getSaveOverlayContents = async ({ playlistId, overlayId, newOverlayContents }) => {
  try {
    const data = {
      playlistId,
      overlayId,
      ...newOverlayContents,
    };

    const result = await apiLib.fetchInteractionApi('/overlay-contents/overlay-contents-save', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getSaveOverlayContents error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getUpdateOverlayContents = async updateOverlayContents => {
  try {
    const data = {
      ...updateOverlayContents,
    };
    const result = await apiLib.fetchInteractionApi('/overlay-contents/overlay-contents-update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getOverlayLayerContents error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getRemoveOverlayContents = async removeOverlayContents => {
  try {
    const data = {
      ...removeOverlayContents,
    };
    const result = await apiLib.fetchInteractionApi('/overlay-contents/overlay-contents-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getRemoveOverlayContents error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
