import React from 'react';
import styled from 'styled-components';

const Logo = ({ logoData, fileList, isApp }) => {
  const filePath = fileList.find(f => f.fileId === logoData.logoFile?.fileId)?.filePath;

  return filePath ? (
    <LogoBox fitOption={logoData.fitOption} isApp={isApp}>
      <img src={process.env.REACT_APP_INTERACTION_CDN_URL + filePath} alt={'회사 로고'} />
    </LogoBox>
  ) : null;
};

const LogoBox = styled.div`
  width: ${({ isApp }) => isApp ? '100%' : 'auto'};
  height: ${({ isApp }) => isApp ? '100%' : '100px'};
  margin: ${({ isApp }) => !isApp && '15px 30px'};
  overflow: hidden;

  img {
    width: ${({ isApp }) => isApp ? '100%' : 'auto'};
    height: 100%;
    object-fit: ${({ fitOption }) => fitOption || "scale-down"};
  }
`;

export default React.memo(Logo);
