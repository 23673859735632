import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FiAlertTriangle as AlertIcon } from 'react-icons/fi';
import { FiInfo as InfoIcon } from 'react-icons/fi';
import Button from './Button';
import ClickOutside from '../components/ClickOutside';
import { useState } from 'react';
import { useEffect } from 'react';

const typeIcon = {
  warn: <AlertIcon size={22} color="#f05b5b" />,
  info: <InfoIcon size={22} color="rgb(58 142 255)" />,
};
const modalSize = {
  small: {
    width: '21rem',
  },
};
const modalStyles = css`
  /* 크기 */
  position: fixed;
  left: ${({ left }) => left && `${left}px`};
  top: ${({ top }) => top && `${top}px`};
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(38, 50, 56, 0.12);
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  z-index: 1000;
  cursor: default;
  padding: 0.5rem 0.8rem;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(82 82 82) !important ;

  ${({ firstRendering }) =>
    firstRendering
      ? css`
          opacity: 0;
          z-index: -1;
          pointer-events: none;
        `
      : css`
          animation-duration: 0.25s;
          animation-timing-function: ease-out;
          animation-name: ${fadeIn};
          animation-fill-mode: forwards;

          ${props =>
            props.disappear &&
            css`
              animation-name: ${fadeOut};
            `}
        `}

  ${({ size }) => css`
    width: ${modalSize[size].width};
    font-size: ${modalSize[size].fontSize};
  `}
`;

const ConfirmModal = ({ isOpen, type, size, left, top, body, titleTxt, closeTxt, confirmTxt, handleClose, handleConfirm }) => {
  const [firstRendering, setFirstRendering] = useState(true);
  useEffect(() => {
    if (isOpen) {
      setFirstRendering(firstRendering => firstRendering && false);
    }
  }, [isOpen]);

  return (
    <ClickOutside onClickOutside={e => isOpen && handleClose()}>
      <Modal size={'small'} left={left} top={top} isOpen={isOpen} firstRendering={firstRendering}>
        <ModalTitle>
          {typeIcon[type]}
          <Title>{titleTxt}</Title>
        </ModalTitle>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          {closeTxt.length > 0 && (
            <Button size={'medium'} onClick={e => handleClose()}>
              {closeTxt}
            </Button>
          )}
          {confirmTxt.length > 0 && (
            <Button size={'medium'} onClick={e => handleConfirm()}>
              {confirmTxt}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </ClickOutside>
  );
};
const Modal = styled.div`
  ${modalStyles}
`;

const Title = styled.div`
  padding-left: 4px;
`;

const ModalTitle = styled.div`
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(161 161 161 / 26%);
  /* border: 1px solid purple; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const ModalBody = styled.div`
  /* border: 1px solid red; */
  overflow: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
  line-height: 1rem;
`;
const ModalFooter = styled.div`
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; z-index: 9999}
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; z-index: -1; pointer-events: none;}
`;

export default React.memo(ConfirmModal);
