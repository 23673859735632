import React, { useRef, useState } from 'react';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import DataFetch from './DataFetch';
import Playlist from './Playlist';
import LayerList from './LayerList';
import LayerSetting from './LayerSetting';
import LayerTool from './LayerTool';
import FrameList from './FrameList';
import LayerListSetting from './LayerListSetting';
import ZoomMenu from './ZoomMenu';
import Preview from './Preview';
import HistoryManager from './HistoryManager';
import ClipboardManager from './ClipboardManager';
import KeyEventManager from './KeyEventManager';

import { EDITOR_HEADER_HEIGHT, EDITOR_LEFT_WIDTH, EDITOR_RIGHT_WIDTH } from './constants/editor';

import { editorSelector } from '../../module/editorSlice';

import Loading from '../../newComponents/Loading';

import { endpoints as endpointsFrameApi } from '../../rtk/frameApi';

import { ReactComponent as EmptyFrameImage } from '../../assets/images/eliguy/empty-frame-eliguy.svg';

// import { addArchivePackage, addStorePackage } from '../../lib/packageApi';
import PackageAddModal from './modal/PackageAddModal';

const EditorLayout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [isArchivePackageMode] = useState(searchParams.get('editorMode') === 'archive-package');
  const [isStorePackageMode] = useState(searchParams.get('editorMode') === 'store-package');

  const [isOpenPackageAddModal, setIsOpenPackageAddModal] = useState(false);

  const playlistId = useSelector(editorSelector.playlistId);
  const frameId = useSelector(editorSelector.frameId);
  const isSideMenuShow = useSelector(editorSelector.isSideMenuShow);

  const { data: frameList } = endpointsFrameApi.frameList.useQueryState({ playlistId });
  const isFrameClickLoading = useSelector(editorSelector.isFrameClickLoading);

  const moveableRef = useRef(null);
  const infiniteViewerRef = useRef(null);
  const historyRef = useRef(null);
  const selectoRef = useRef(null);
  const clipboardRef = useRef(null);

  return (
    <Wrap>
      <DataFetch setIsLoading={setIsLoading} />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Header height={EDITOR_HEADER_HEIGHT}>
            {isArchivePackageMode && <button onClick={() => setIsOpenPackageAddModal(true)}>아카이브패키지추가</button>}
            {isStorePackageMode && <button onClick={() => setIsOpenPackageAddModal(true)}>스토어패키지추가</button>}
            {(isArchivePackageMode || isStorePackageMode) && (
              <PackageAddModal
                isOpen={isOpenPackageAddModal}
                closeModal={() => setIsOpenPackageAddModal(false)}
                playlistId={playlistId}
                isArchivePackageMode={isArchivePackageMode}
                isStorePackageMode={isStorePackageMode}
              />
            )}
            {frameList?.length > 0 && <LayerTool infiniteViewerRef={infiniteViewerRef} historyRef={historyRef} />}
            <Playlist />
            {frameList?.length > 0 && <Preview />}
            <ZoomMenu infiniteViewerRef={infiniteViewerRef} />
          </Header>
          <Container>
            <VerticalLayoutBox isSideMenuShow={isSideMenuShow} orientation="vertical">
              <VerticalBox flex={1}>
                {frameList && (
                  <>
                    <FrameBox>
                      <FrameList />
                    </FrameBox>
                    {frameList.length > 0 ? (
                      <>
                        <LayerWrap>
                          <LayerBox>
                            <LayerList
                              moveableRef={moveableRef}
                              infiniteViewerRef={infiniteViewerRef}
                              historyRef={historyRef}
                              selectoRef={selectoRef}
                              clipboardRef={clipboardRef}
                            />
                          </LayerBox>
                        </LayerWrap>
                      </>
                    ) : (
                      <EmptyFrameImage style={{ padding: '10em' }} width="100%" height="100%" />
                    )}
                  </>
                )}
              </VerticalBox>
              <VerticalSplitBar propagate={true} className="side" />
              <VerticalBox
                minSize={EDITOR_RIGHT_WIDTH}
                maxSize={500}
                className="side"
                style={{ minWidth: EDITOR_RIGHT_WIDTH }}
              >
                {frameId && frameList?.length > 0 && (
                  <HorizonLayoutBox orientation="horizontal">
                    <HorizonBox minSize={250} style={{ minHeight: 250 }}>
                      <LayerListSetting historyRef={historyRef} />
                    </HorizonBox>
                    <HorizonSplitBar propagate={true} />
                    <HorizonBox flex={1} style={{ minHeight: 40 }}>
                      <LayerSetting moveableRef={moveableRef} historyRef={historyRef} />
                    </HorizonBox>
                  </HorizonLayoutBox>
                )}
              </VerticalBox>
            </VerticalLayoutBox>
            <StyledLoading isFrameClickLoading={!frameList || isFrameClickLoading} />
          </Container>
          <HistoryManager ref={historyRef} selectoRef={selectoRef} />
          <ClipboardManager ref={clipboardRef} historyRef={historyRef} />
          <KeyEventManager historyRef={historyRef} clipboardRef={clipboardRef} infiniteViewerRef={infiniteViewerRef} />
        </>
      )}

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  * {
    font-family: 'Noto Sans KR' !important;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  min-height: ${({ height }) => height}px;
  max-height: ${({ height }) => height}px;
  background: #2c2c2c;
  border-bottom: 1px solid black;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  overflow: hidden;
`;

const VerticalLayoutBox = styled(ReflexContainer)`
  display: flex;
  width: 100%;
  height: 100%;
  background: #ffffff;
  .side {
    display: ${({ isSideMenuShow }) => !isSideMenuShow && 'none'};
  }
`;

const VerticalSplitBar = styled(ReflexSplitter)`
  background-color: #fff;
  width: 3px;
  height: auto;
  border: solid #aaa;
  border-width: 0 1px;
  cursor: col-resize;
`;

const VerticalBox = styled(ReflexElement)`
  position: relative;
  display: flex;
  height: 100%;
  background: #ffffff;
`;

const HorizonLayoutBox = styled(ReflexContainer)`
  display: flex;
  width: 100%;
  height: 100%;
  background: #ffffff;
  flex-direction: column;
  overflow: hidden;
`;

const HorizonSplitBar = styled(ReflexSplitter)`
  background-color: #fff;
  width: 100%;
  height: 3px;
  border: solid #aaa;

  border-width: 1px 0;
  cursor: row-resize;
`;

const HorizonBox = styled(ReflexElement)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  background: #ffffff;
`;

const FrameBox = styled.div`
  width: ${EDITOR_LEFT_WIDTH}px;
  min-width: ${EDITOR_LEFT_WIDTH}px;
  flex-basis: ${EDITOR_LEFT_WIDTH}px;
  height: 100%;
  z-index: 1;
  overflow: hidden;
`;

const LayerWrap = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
`;

const LayerBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const StyledLoading = styled(Loading)`
  position: absolute;
  display: ${({ isFrameClickLoading }) => (isFrameClickLoading ? 'flex' : 'none')};
  opacity: 0.6;
  background-color: #666;
`;

export default React.memo(EditorLayout);
