import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { editorSelector } from '../../module/editorSlice';

import SettingTab from './SettingTab';
import LayerCardList from './LayerCardList';
import OverlayCardList from './OverlayCardList';

const LayerListSetting = ({ historyRef }) => {
  const selectedLayerList = useSelector(editorSelector.selectedLayerList);

  const [tabIndex, setTabIndex] = useState(0);

  const tabList = useRef([
    { id: 'layerList', title: '레이어' },
    {
      id: 'overlayList',
      title: '오버레이',
    },
  ]);

  useEffect(() => {
    if (selectedLayerList?.length > 0) {
      if (selectedLayerList[0].type === 'LAYER') {
        setTabIndex(0);
      } else if (selectedLayerList[0].type === 'OVERLAY') {
        setTabIndex(1);
      }
    }
  }, [selectedLayerList]);

  return (
    <Component>
      <SettingTab tabIndex={tabIndex} tabList={tabList.current} tabChange={setTabIndex} />
      <Body>
        {
          {
            0: <LayerCardList historyRef={historyRef} />,
            1: <OverlayCardList historyRef={historyRef} />,
          }[tabIndex]
        }
      </Body>
    </Component>
  );
};

const Component = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export default React.memo(LayerListSetting);
