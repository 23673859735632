import { interactionBaseApi } from './interactionBaseApi';

export const placeApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    placeDetail: build.query({
      query: data => ({ url: '/place/detail', data }),
      transformResponse: response => {
        return response.placeInfo;
      },
    }),
    placeCoordinateUpdate: build.mutation({
      async queryFn({ placeId, lat, lng, zoom }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const result = await fetchInteractionApi({
            url: '/place/gps-edit',
            data: { placeId, placeGpsLat: lat, placeGpsLng: lng, zoom },
          });
          return result;
        } catch (err) {
          console.log('에러', err);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, usePlaceDetailQuery, usePlaceCoordinateUpdateMutation } = placeApi;
