import React from 'react';
import styled from 'styled-components';

const Toggle = ({ width, height, isChecked, onChange }) => {
  return (
    <Container width={width} height={height} isChecked={isChecked} circleSize={height - 6} right={width - (height - 6) - 3}>
      <ToggleInput type="checkbox" checked={isChecked} onChange={e => onChange(e)} />
      <ToggleIcon className="circle" />
    </Container>
  );
};

const Container = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  min-width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background: ${({ isChecked }) => (isChecked ? '#6A8BFF' : '#DBDBDB')};
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  & > input {
    visibility: hidden;
  }

  .circle {
    position: absolute;
    left: ${({ isChecked, right }) => (isChecked ? right : 3)}px;
    width: ${({ circleSize }) => circleSize}px;
    height: ${({ circleSize }) => circleSize}px;
    border-radius: 50%;
    transition: 0.4s;
    background: #ffffff;
  }
`;

const ToggleIcon = styled.span``;

const ToggleInput = styled.input`

  &:checked + ${ToggleIcon} {
    background

  }



`;

Toggle.defaultProps = {
  width: 40,
  height: 22,
  isChecked: false,
  onClick: () => {},
};

export default React.memo(Toggle);
