import axios from 'axios';
import { produce } from 'immer';

const developmentUrl = 'https://faas.eligaws.com/interaction';
const productionUrl = 'https://faas.eligaspace.com/interaction';
const localUrl = 'http://localhost:8000/interaction';

export const fetchInteractionApi = async (url, data, options = {}) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    data = produce(data, draft => {
      draft.accessToken = accessToken;
    });

    let apiUrl = '';
    if (window.location.href.includes('eligaws.com')) {
      apiUrl = developmentUrl;
    } else if (window.location.href.includes('eligaspace.com')) {
      apiUrl = productionUrl;
    } else if (window.location.href.includes('eliga.co.kr')) {
      apiUrl = productionUrl;
    } else {
      apiUrl = localUrl;
    }

    const response = await axios({
      method: 'post',
      url: apiUrl + url,
      headers: {
        'X-Api-Key': 'bCAoIUYQ8N1CZ4rMDPdvJ54HqeZwfgeF96NCYJhQ',
        'content-type': 'application/json',
        ...options.headers,
      },
      data: data,
    }).catch(err => {
      if (err.response.status === 401) {
        window.location.href = '/account/logout';
      }
      throw err;
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const fetchInteractionMultipartApi = async (url, data, options = {}) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    let apiUrl = '';
    if (window.location.href.includes('eligaws.com')) {
      apiUrl = developmentUrl;
    } else if (window.location.href.includes('eligaspace.com')) {
      apiUrl = productionUrl;
    } else if (window.location.href.includes('eliga.co.kr')) {
      apiUrl = productionUrl;
    } else {
      apiUrl = localUrl;
    }

    const response = await axios({
      method: 'post',
      url: apiUrl + url + `?accessToken=${accessToken}`,
      headers: {
        'X-Api-Key': 'bCAoIUYQ8N1CZ4rMDPdvJ54HqeZwfgeF96NCYJhQ',
        'content-type': 'multipart/form-data',
        ...options.headers,
      },
      data: data,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const fetchUploadApi = async (url, file, onUploadProgress, i) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status < 400) {
        resolve(true);
      } else {
        const error = new Error(xhr.response);
        reject(error);
      }
    };
    xhr.upload.onprogress = evt => {
      if (evt.lengthComputable) {
        onUploadProgress && onUploadProgress(file, evt.loaded, evt.total, i);
      }
    };
    xhr.onerror = error => {
      reject(error);
    };
    xhr.onreadystatechange = evt => {
      if (xhr.readyState === 1) {
      } else if (xhr.readyState === 4) {
      }
    };

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
};
