import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../components/Modal';
import SquareButton from '../components/SquareButton';
// number tpye을 string type으로 변환
const toStringTime = value => {
  if (value < 10) {
    value = '0' + value;
  } else {
    value = String(value);
  }
  return value;
};

//시간 select box 값
const selectBoxValue = {
  hour: [],
  minute: [],
};

for (let i = 0; i < 60; i++) {
  if (i < 24) {
    selectBoxValue.hour.push(toStringTime(i));
  }
  selectBoxValue.minute.push(toStringTime(i));
}

const DurationButton = ({ duration: { hour, minute, second }, onChangeDuration, isDisabled }) => {
  const [modal, setModal] = useState(false);
  const [time, setTime] = useState({
    hour: hour,
    minute: minute,
    second: second,
  });

  useEffect(() => {
    setTime(time => ({
      ...time,
      hour,
    }));
  }, [hour]);

  useEffect(() => {
    setTime(time => ({
      ...time,
      minute,
    }));
  }, [minute]);

  useEffect(() => {
    setTime(time => ({
      ...time,
      second,
    }));
  }, [second]);

  // 시간 선택 이벤트
  const onClickTimeVal = useCallback((type, value) => {
    setTime(time => ({
      ...time,
      [type]: value,
    }));
  }, []);

  // 재생시간 모달 닫을때 이벤트
  const onCloseDurationModal = useCallback(
    time => {
      onChangeDuration(time);
      setModal(modal => false);
    },
    [onChangeDuration],
  );

  return (
    <TimeSetWrapper>
      <StyledSquareBtn onClick={() => setModal(true)} disabled={isDisabled}>
        {time.hour} : {time.minute} : {time.second}
      </StyledSquareBtn>
      <ModalOverlay isOpen={modal} onClick={e => onCloseDurationModal(time)} />
      <Modal isOpen={modal} width={205} height={275} style={{ padding: '13px 16px 16px' }}>
        <TimeWrap>
          <TimeArea>
            <TimeTitleDiv>HH</TimeTitleDiv>
            <TimeSelectBox value={time.hour} selectBoxValueList={selectBoxValue.hour} onClickSetValue={value => onClickTimeVal('hour', value)} />
          </TimeArea>
          <TimeArea>
            <TimeTitleDiv>MM</TimeTitleDiv>
            <TimeSelectBox value={time.minute} selectBoxValueList={selectBoxValue.minute} onClickSetValue={value => onClickTimeVal('minute', value)} />
          </TimeArea>
          <TimeArea>
            <TimeTitleDiv>SS</TimeTitleDiv>
            <TimeSelectBox value={time.second} selectBoxValueList={selectBoxValue.minute} onClickSetValue={value => onClickTimeVal('second', value)} />
          </TimeArea>
        </TimeWrap>
      </Modal>
    </TimeSetWrapper>
  );
};

// 시간 selectBox
const TimeSelectBox = ({ value, selectBoxValueList, onClickSetValue }) => {
  return (
    <SelectBoxWrapper>
      <SelectBtn>
        <span>{value}</span>
      </SelectBtn>
      <SelectDiv>
        {selectBoxValueList.map((val, index) => {
          return (
            <SelectValBtn key={val} onClick={() => onClickSetValue(val)} className={value === val && 'active'}>
              <span>{val}</span>
            </SelectValBtn>
          );
        })}
      </SelectDiv>
    </SelectBoxWrapper>
  );
};

const TimeSetWrapper = styled.div`
  position: relative;
  width: 120px;
`;

const StyledSquareBtn = styled(SquareButton)`
  width: 120px;
  height: 30px;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.38px;
  background-color: ${props => (props.disabled ? 'rgb(247 247 247)' : '#ffffff')};
`;

const TimeWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TimeArea = styled.div`
  position: relative;
  width: 51px;
`;

const TimeTitleDiv = styled.div`
  width: 100%;
  text-align: center;
  color: #666666 !important;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
`;

const SelectBoxWrapper = styled.div`
  width: 100%;
  color: #666666;
  & * {
    font-size: 15px;
    line-height: 1.2;
  }
`;

const SelectBtn = styled.button`
  position: relative;
  width: 100%;
  height: 28px;
  border: 1px solid #dddddd;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #ffffff;
  & > img {
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translate(0, -50%);
  }
  & > span {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
  &:focus {
    outline: none;
  }
`;

const SelectDiv = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 196px;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 9999;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
`;

const SelectValBtn = styled.button`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 28px;
  border: none;
  background: #ffffff;
  &.active {
    color: #2a91df !important;
    background: #eaf6ff;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background: #f6f6f6;
  }
  & > span {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export default DurationButton;
