import { createSlice, createDraftSafeSelector, PayloadAction, } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface ChangeSchedulePayloadAction {
  type: string;
  data: string;
}

interface ScheduleState {
  playlistId: string;
  scheduleId: string;
  playStartDt: string;
  playEndDt: string;
  playAlwaysYn: string;
  playWeek: Array<any>;
  playStartTm: {
    hour: string;
    minute: string;
    second: string;
  };
  playEndTm: {
    hour: string;
    minute: string;
    second: string;
  };
  playAlldayYn: string;
  exceptDt: Array<any>;
  isLoading: boolean;
  error: string | null;
}

const scheduleInitialState: ScheduleState = {
  playlistId: '',
  scheduleId: '',
  playStartDt: dayjs().format('YYYY-MM-DD'),
  playEndDt: dayjs().format('YYYY-MM-DD'),
  playAlwaysYn: 'N',	
  playWeek: [],
  playStartTm: {
    hour: '00',
    minute: '00',
    second: '00'
  },
  playEndTm: {
    hour: '00',
    minute: '00',
    second: '00'
  }, 	
  playAlldayYn: 'N',
  exceptDt: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'schedule',
  initialState: scheduleInitialState,
  reducers: {
    changeScedule(
      state: ScheduleState,
      { payload: {type, data} }: PayloadAction<ChangeSchedulePayloadAction>,
    ) {
      state[type] = data;
    },
    clearSchedule(state: ScheduleState) {
      state.playStartDt = scheduleInitialState.playStartDt;
      state.playEndDt = scheduleInitialState.playEndDt;
      state.playAlwaysYn = scheduleInitialState.playAlwaysYn;
      state.playWeek = scheduleInitialState.playWeek;
      state.playStartTm = scheduleInitialState.playStartTm;
      state.playEndTm = scheduleInitialState.playEndTm;
      state.playAlldayYn = scheduleInitialState.playAlldayYn;
      state.exceptDt = scheduleInitialState.exceptDt;
    },
    addWeek(
      state: ScheduleState,
      { payload }: PayloadAction<string>,
    ) {
      state.playWeek.push(payload);
      state.playWeek.sort();
    },
    delWeek(
      state: ScheduleState,
      { payload }: PayloadAction<string>,
    ) {
      const idx = state.playWeek.findIndex(week => week === payload);
      state.playWeek.splice(idx, 1);
    },
    addExcept(
      state: ScheduleState,
      { payload }: PayloadAction<string>,
    ) {
      state.exceptDt.push(payload);
    },
    delExcept(
      state: ScheduleState,
      { payload }: PayloadAction<string>,
    ) {
      const idx = state.exceptDt.findIndex(date => date === payload);
      state.exceptDt.splice(idx, 1);
    },
    clearExcept(state: ScheduleState) {
      state.exceptDt = [];
    },
    saveSchedule(
      state: ScheduleState,
      { payload }: PayloadAction<String>,
    ) {
      state.isLoading = true;
      state.error = null;
    },
    saveScheduleSuccess(
      state: ScheduleState,
      { payload }: PayloadAction<String>,
    ) {
      state.isLoading = false;
      state.error = null;
    },
    saveScheduleFailure(
      state: ScheduleState,
      { payload }: PayloadAction<String>,
    ) {
      state.isLoading = false;
      state.error = payload;
    },
    editSchedule(
      state: ScheduleState,
      { payload }: PayloadAction<String>,
    ) {
      state.isLoading = true;
      state.error = null;
    },
    editScheduleSuccess(
      state: ScheduleState,
      { payload }: PayloadAction<String>,
    ) {
      state.isLoading = false;
      state.error = null;
    },
    editScheduleFailure(
      state: ScheduleState,
      { payload }: PayloadAction<String>,
    ) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

const selectSchedule = createDraftSafeSelector(
  (state: ScheduleState) => state.playlistId,
  (state: ScheduleState) => state.scheduleId,
  (state: ScheduleState) => state.playStartDt,
  (state: ScheduleState) => state.playEndDt,
  (state: ScheduleState) => state.playAlwaysYn,
  (state: ScheduleState) => state.playWeek,
  (state: ScheduleState) => state.playStartTm,
  (state: ScheduleState) => state.playEndTm,
  (state: ScheduleState) => state.playAlldayYn,
  (state: ScheduleState) => state.exceptDt,
  (playlistId, scheduleId, playStartDt, playEndDt, playAlwaysYn, playWeek, playStartTm, playEndTm, playAlldayYn, exceptDt) => ({
    playlistId, scheduleId, playStartDt, playEndDt, playAlwaysYn, playWeek, playStartTm, playEndTm, playAlldayYn,exceptDt
  }),
);

export const scheduleSelector = {
  scheduleInfo: (state) => selectSchedule(state[SCHEDULE]),
};

export const SCHEDULE = slice.name;
export const scheduleReducer = slice.reducer;
export const scheduleAction = slice.actions;