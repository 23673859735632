/**
 * 0 채워넣기
 * @author Dongseek Son
 * @param {string|number} value - 값
 * @param {number} digit - 자릿수
 */
export const zeroPadding = (num, digit) => {
    var zero = '';
    for(var i = 0; i < digit; i++) {
      zero += '0';
    }
    return (zero + num).slice(-digit);
};

/**
 * 반올림
 * @author Dongseek Son
 * @param {string|number} num - 값
 * @param {number} digit - 자릿수 default=0
 */
export const roundOff = (num, digit = 0) => {
    return Number.parseFloat(num).toFixed(digit);
};