import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { boardAction } from './boardSlice';

import * as boardApiLib from '../lib/boardApi';

function* handleNoticeList({ payload }) {
  try {
    const noticeList = yield call(boardApiLib.noticeList, payload);

    yield put(boardAction.noticeListSuccess({ noticeList }));
  } catch (err) {
    yield put(boardAction.noticeListFailure('Notice List Error'));
  }
}

function* handleNoticeDetail({ payload }) {
  try {
    const noticeInfo = yield call(boardApiLib.noticeDetail, payload);

    yield put(boardAction.noticeDetailSuccess({ noticeInfo }));
  } catch (err) {
    yield put(boardAction.noticeDetailFailure('Notice Detail Error'));
  }
}

function* handleReleaseList({ payload }) {
  try {
    const releaseList = yield call(boardApiLib.releaseList, payload);

    yield put(boardAction.releaseListSuccess({ releaseList }));
  } catch (err) {
    yield put(boardAction.releaseListFailure('Release List Error'));
  }
}

function* handleReleaseDetail({ payload }) {
  try {
    const releaseInfo = yield call(boardApiLib.releaseDetail, payload);

    yield put(boardAction.releaseDetailSuccess({ releaseInfo }));
  } catch (err) {
    yield put(boardAction.releaseDetailFailure('Release Detail Error'));
  }
}

function* handleQnaList({ payload }) {
  try {
    const qnaList = yield call(boardApiLib.qnaList, payload);

    yield put(boardAction.qnaListSuccess({ qnaList }));
  } catch (err) {
    yield put(boardAction.qnaListFailure('Qna List Error'));
  }
}

function* handleQnaDetail({ payload }) {
  try {
    const qnaInfo = yield call(boardApiLib.qnaDetail, payload);

    yield put(boardAction.qnaDetailSuccess({ qnaInfo }));
  } catch (err) {
    yield put(boardAction.qnaDetailFailure('Qna Detail Error'));
  }
}

function* handleQnaAdd({ payload }) {
  try {
    yield call(boardApiLib.qnaAdd, payload);

    yield put(boardAction.qnaAddSuccess());
  } catch (err) {
    yield put(boardAction.qnaAddFailure('Qna Add Error'));
  }
}

function* handleQnaEdit({ payload }) {
  try {
    yield call(boardApiLib.qnaEdit, payload);

    yield put(boardAction.qnaEditSuccess());
  } catch (err) {
    yield put(boardAction.qnaEditFailure('Qna Edit Error'));
  }
}

function* handleQnaCancel({ payload }) {
  try {
    yield call(boardApiLib.qnaCancel, payload);

    yield put(boardAction.qnaCancelSuccess());
  } catch (err) {
    yield put(boardAction.qnaCancelFailure('Qna Cancel Error'));
  }
}

function* handleQnaRemove({ payload }) {
  try {
    yield call(boardApiLib.qnaRemove, payload);

    yield put(boardAction.qnaRemoveSuccess());
  } catch (err) {
    yield put(boardAction.qnaRemoveFailure('Qna Remove Error'));
  }
}

function* handleGuideList({ payload }) {
  try {
    const { guideList, guideAllFileList }  = yield call(boardApiLib.guideList, payload);

    yield put(boardAction.guideListSuccess({ guideList, guideAllFileList }));
  } catch (err) {
    yield put(boardAction.guideListFailure('Guide List Error'));
  }
}

function* handleGuideDetail({ payload }) {
  try {
    const guideInfo = yield call(boardApiLib.guideDetail, payload);
    //test
    const guideFileList = yield call(boardApiLib.guideFileList, payload);

    yield put(boardAction.guideDetailSuccess({ guideInfo, guideFileList }));
    //test end
  } catch (err) {
    yield put(boardAction.guideDetailFailure('Guide Detail Error'));
  }
}

function* getGuideFileDownloadRequest({ payload }) {
  try {
    console.log(payload);
    yield call(boardApiLib.getGuideFileDownloadRequest, payload);

    yield put(boardAction.guideFileDownloadSuccess());
  } catch (err) {
    yield put(boardAction.guideFileDownloadFailure('Guide File Download Error'));
  }
}

function* getGuideFileListDownloadRequest({ payload }) {
  try {
    console.log(payload);
    yield call(boardApiLib.getGuideFileListDownloadRequest, payload);

    yield put(boardAction.guideFileListDownloadSuccess());
  } catch (err) {
    yield put(boardAction.guideFileListDownloadFailure('Guide File List Download Error'));
  }
}

export function* watchNoticeList() {
  yield takeLatest(boardAction.noticeList, handleNoticeList);
}

export function* watchNoticeDetail() {
  yield takeLatest(boardAction.noticeDetail, handleNoticeDetail);
}

export function* watchReleaseList() {
  yield takeLatest(boardAction.releaseList, handleReleaseList);
}

export function* watchReleaseDetail() {
  yield takeLatest(boardAction.releaseDetail, handleReleaseDetail);
}

export function* watchQnaList() {
  yield takeLatest(boardAction.qnaList, handleQnaList);
}

export function* watchQnaDetail() {
  yield takeLatest(boardAction.qnaDetail, handleQnaDetail);
}

export function* watchQnaAdd() {
  yield takeLatest(boardAction.qnaAdd, handleQnaAdd);
}

export function* watchQnaEdit() {
  yield takeLatest(boardAction.qnaEdit, handleQnaEdit);
}

export function* watchQnaCancel() {
  yield takeLatest(boardAction.qnaCancel, handleQnaCancel);
}

export function* watchQnaRemove() {
  yield takeLatest(boardAction.qnaRemove, handleQnaRemove);
}

export function* watchGuideList() {
  yield takeLatest(boardAction.guideList, handleGuideList);
}

export function* watchGuideDetail() {
  yield takeLatest(boardAction.guideDetail, handleGuideDetail);
}

//test
export function* watchGuideFileDownload() {
  yield takeLatest(boardAction.guideFileDownloadRequest, getGuideFileDownloadRequest);
}

export function* watchGuideFileListDownload() {
  yield takeLatest(boardAction.guideFileListDownloadRequest, getGuideFileListDownloadRequest);
}

//test end

function* rootSaga() {
  yield all([
    fork(watchNoticeList),
    fork(watchNoticeDetail),
    fork(watchReleaseList),
    fork(watchReleaseDetail),
    fork(watchQnaList),
    fork(watchQnaDetail),
    fork(watchQnaAdd),
    fork(watchQnaEdit),
    fork(watchQnaCancel),
    fork(watchQnaRemove),
    fork(watchGuideList),
    fork(watchGuideDetail),
    fork(watchGuideFileDownload),
    //test
    fork(watchGuideFileListDownload),
    //test end
  ]);
}

export default rootSaga;
