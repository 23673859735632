import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

const Modal = (props) => {

  return (
    <div>
      <ModalWrapper {...props}>
        {props.title && (
          <div className="modal-title">
            {props.title}
          </div>
        )}
        {props.children}
      </ModalWrapper>
    </div>
  );
};

const ModalWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 6px;
  width: ${props => props.width}px;
  height: ${props => (props.height === 'auto' ? 'auto' : props.height+'px')};
  top: ${props => props.buttonHeight || 0}px;
  margin: 0 auto;
  padding: 8px 0;
  color: #333333;
  z-index: 1000;

  /** 모달 위치 조정 */
  ${(props) =>
    props.displayLeft &&
    css`
      left: -${(props) => props.width}px; //상위 컴포넌트의 left를 기준으로 모달의 width만큼 왼쪽으로 이동
    `}

  ${(props) =>
    props.displayRight &&
    css`
      left: ${(props) =>
        props.buttonWidth}px; //상위 컴포넌트의 right를 기준으로 모달을 부르는 버튼의 width만큼 오른쪽으로 이동
    `}

  ${(props) => 
    props.displayCenter &&
    css`
      left : 50%;
      top : 50%;
      transform: translate(-50%, -50%);
      /* margin-left : ${(props) => -props.width / 2}px;
      margin-top : ${(props) => props.height / 3}px; */
  `}

  ${(props) => 
    props.displayCenterFromDetail &&
    css`
      left : 50%;
      top : 50%;
      /* transform: translate(-50%, -50%); */
      margin-left : ${(props) => -props.width / 2}px;
      margin-top : ${(props) => props.height / 3}px;
  `}

  .modal-title {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    padding: 5px 10px 8px;
    color: #666666;
  }
`;

export default Modal;
