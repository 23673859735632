import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { editorAction, editorSelector } from '../../../../../module/editorSlice';

import Modal from '../../../../../newComponents/Modal';

import { FiAlertTriangle as AlertIcon } from 'react-icons/fi';

const useCheckEditingMode = () => {
  const dispatch = useDispatch();

  const editingMode = useSelector(editorSelector.editingMode);

  const [showModal, setShowModal] = useState(false);
  const [afterFn, setAfterFn] = useState();

  const editingCheck = useCallback(
    afterAction => {
      if (editingMode) {
        setShowModal(true);
        setAfterFn(afterFn => afterAction);
      } else {
        return afterAction();
      }
    },
    [editingMode],
  );

  const onClickOk = useCallback(() => {
    setShowModal(false);
    dispatch(editorAction.editingModeLayerContents(false));
    afterFn();
  }, [dispatch, afterFn]);

  const onClickCancel = useCallback(() => {
    setShowModal(false);
    return false;
  }, []);

  const LayerContentsWarnModal = () => {
    return (
      <Modal
        title={<Title>레이어 콘텐츠 변경 안내</Title>}
        isLine
        onConfirm={onClickOk}
        onCancel={onClickCancel}
        onClose={onClickCancel}
        onBackdropClick={onClickCancel}
        visible={showModal}
        size="large"
      >
        <ModalBody>
          <div style={{ color: '#f05b5b', marginBottom: '15px' }}>
            <AlertIcon size={15} color="#f05b5b" style={{ marginRight: '10px' }} />
            경고
          </div>
          레이어 콘텐츠 내용에 변경 사항이 존재합니다.
          <br />
          저장하지 않은 상태에서 이동할 경우 변경 사항이 사라집니다.
          <br />
          그래도 진행하시겠습니까?
        </ModalBody>
      </Modal>
    );
  };

  return {
    editingCheck,
    LayerContentsWarnModal,
    isEditingChecking: showModal,
  };
};

const Title = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  color: #f05b5b;
  font-size: 14px;
  font-weight: 500;
`;

const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 10.4px 25.6px;
  font-size: 15px;
  line-height: 17px;
  & .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .mt {
    margin-top: 10px;
  }
`;

export default useCheckEditingMode;
