import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface FrameDuartionState {
  hour: string;
  min: string;
  sec: string;
}

interface FrameInfoState {
  frameId: string;
  frameDuration: FrameDuartionState;
  primaryYn: string;
  naviYn: string;
  hideYn: string;
  autoNextYn: string;
  frameOrder: string;
  playlistId: string;
  frameNm: string;
}

interface FrameState {
  frameList: FrameInfoState[];
  linkedFrameOrderList: array;
  isLoading: boolean;
  error: string | null;
}

interface UpdateFramePayload {
  frameId: string;
  frameInfo: FrameInfoState;
}

interface DurationPayload {
  index: number;
  key?: string;
  value?: string;
  frameDuration?: FrameDuartionState;
}

// 초기 상태
const FrameInitialState: FrameState = {
  frameList: [],
  linkedFrameOrderList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'frame',
  initialState: FrameInitialState,
  reducers: {
    initFrameState(state: FrameState) {
      state.frameList = [];
      state.linkedFrameOrderList = [];
      state.actionResult = '';
    },
    getFrameList(state: FrameState) {
      state.actionResult = 'LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getFrameListSuccess(state: FrameState, { payload: frameList }: PayloadAction<FrameState>) {
      state.frameList = frameList;

      if (frameList.length > 0) {
        state.actionResult = 'LIST_OK';
      } else {
        state.actionResult = 'LIST_NOT_EXIST';
      }
      state.isLoading = false;
      state.error = null;
    },
    getFrameListFailure(state: FrameState, { payload: error }: PayloadAction<string>) {
      state.frameList = [];

      state.actionResult = 'LIST_ERR';
      state.isLoading = false;
      state.error = error;
    },
    addFrame(state: FrameState, { payload: { initFrame: frame } }: PayloadAction<FrameInfoState>) {
      state.frameList.push(frame);

      state.actionResult = 'ADD_REQ';
      state.isLoading = true;
      state.error = null;
    },
    addFrameSuccess(state: FrameState) {
      state.actionResult = 'ADD_OK';
      state.isLoading = false;
      state.error = null;
    },
    addFrameFailure(state: FrameState, { payload: error }: PayloadAction<string>) {
      state.actionResult = 'ADD_ERR';
      state.isLoading = false;
      state.error = error;
    },
    removeFrame(state: FrameState, { payload: { frameId } }: PayloadAction<FrameState>) {
      const findIndex = state.frameList.findIndex(frame => frame.frameId === frameId);
      state.frameList.splice(findIndex, 1);

      state.actionResult = 'REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeMultiFrame(state: FrameState, { payload: { frameIdList } }: PayloadAction<FrameState>) {
      for (const frameIdObj of frameIdList) {
        const findIndex = state.frameList.findIndex(frame => frame.frameId === frameIdObj.frameId);
        state.frameList.splice(findIndex, 1);
      }

      state.actionResult = 'REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeFrameSuccess(state: FrameState, { payload: frameList }: PayloadAction<number>) {
      if (frameList.length > 0) {
        state.actionResult = 'REMOVE_OK';
      } else {
        state.actionResult = 'REMOVE_OK_LIST_NOT_EXIST';
      }

      state.isLoading = false;
      state.error = null;
    },
    removeFrameFailure(state: FrameState, { payload: error }: PayloadAction<string>) {
      state.actionResult = 'REMOVE_ERR';
      state.isLoading = false;
      state.error = error;
    },
    cloneFrame(state: FrameState, { payload: { cloneFrame } }: PayloadAction<FrameInfoState>) {
      state.actionResult = 'CLONE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    cloneMultiFrame(state: FrameState, { payload: { cloneFrameList } }: PayloadAction<FrameInfoState>) {
      state.actionResult = 'CLONE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    cloneFrameSuccess(state: FrameState, { payload: cloneFrameList }: PayloadAction<FrameInfoState>) {
      for (const cloneFrame of cloneFrameList) {
        state.frameList.push(cloneFrame);
      }

      state.actionResult = 'CLONE_OK';
      state.isLoading = false;
      state.error = null;
    },
    cloneFrameFailure(state: FrameState, { payload: error }: PayloadAction<string>) {
      state.actionResult = 'CLONE_ERR';
      state.isLoading = false;
      state.error = error;
    },
    updateFrame(state: FrameState, { payload }: PayloadAction<UpdateFramePayload>) {
      state.actionResult = 'UPDATE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    updateFrameSuccess(state: FrameState, { payload: { frameId, frameInfo } }: PayloadAction<UpdateFramePayload>) {
      const index = state.frameList.findIndex(frame => frame.frameId === frameId);
      state.frameList[index] = {
        ...state.frameList[index],
        ...frameInfo,
      };

      state.actionResult = 'UPDATE_OK';
      state.isLoading = false;
      state.error = null;
    },
    updateFrameFailure(state: FrameState, { payload: error }: PayloadAction<FrameInfoState>) {
      state.actionResult = 'UPDATE_ERR';
      state.isLoading = false;
      state.error = error;
    },
    changePrimaryFrame(state: FrameState, { payload: { frameId, primaryYn } }: PayloadAction<string>) {
      state.frameList.forEach(frame => {
        if (frame.frameId === frameId) {
          return (frame.primaryYn = primaryYn);
        } else {
          return (frame.primaryYn = 'N');
        }
      });
      state.isLoading = true;
      state.error = null;
    },
    changePrimaryFrameSuccess(state: FrameState) {
      state.isLoading = false;
      state.error = null;
    },
    changePrimaryFrameFailure(state: FrameState, { payload: error }: PayloadAction<FrameInfoState>) {
      state.isLoading = false;
      state.error = error;
    },
    changeDuration(state: FrameState, { payload: { index, key, value } }: PayloadAction<DurationPayload>) {
      state.frameList[index].frameDuration[key] = value;
    },
    clickFrame(state: FrameState) {
      state.actionResult = 'CLICK_REQ';
      state.isLoading = true;
      state.error = null;
    },
    clickFrameSuccess(state: FrameState, { payload: linkedFrameOrderList }: PayloadAction<array>) {
      state.linkedFrameOrderList = linkedFrameOrderList;

      state.actionResult = 'CLICK_OK';
      state.isLoading = false;
      state.error = null;
    },
    clickFrameFailure(state: FrameState, { payload: error }: PayloadAction<FrameInfoState>) {
      state.actionResult = 'CLICK_ERR';
      state.isLoading = false;
      state.error = error;
    },
    dragFrame(state: FrameState, { payload: result }: PayloadAction<FrameInfoState>) {
      const frameList = [...state.frameList];
      const [reorderedFrame] = frameList.splice(result.source.index, 1);
      frameList.splice(result.destination.index, 0, reorderedFrame);
      state.frameList = frameList;
    },
    moveFrame(state: FrameState, { payload: afterMoveFrameList }: PayloadAction<FrameInfoState>) {
      const newFrameList = [];
      afterMoveFrameList.forEach((item, index) => {
        newFrameList.push(state.frameList.find(frame => frame.frameId === item.frameId));
      });
      state.frameList = newFrameList;
    },
  },
});

// const selectFrameList = createDraftSafeSelector(
//   (state: any) => state,
//   state => {
//     let newFrameList = [];
//     for (const frame of state.frame.frameList) {
//       let activeFrameFilter = state.activeItem.activeFrameIdList.find(activeFrameId => activeFrameId === frame.frameId);

//       if (activeFrameFilter) {
//         newFrameList.push(produce(frame, draft => {
//           draft.isActive = true;
//         }));
//       } else {
//         newFrameList.push(frame);
//       }
//     }
//     console.log(newFrameList);
//     return newFrameList;
//   },
// );

const selectFrameList = createDraftSafeSelector(
  (state: FrameState) => state.frameList,
  frameList => frameList,
);

const selectFrame = createDraftSafeSelector(
  (state: any) => state,
  state => state.frame.frameList.find(frame => frame.frameId === state.activeItem.activeFrameId),
);

const selectLinkedFrameOrderList = createDraftSafeSelector(
  (state: FrameState) => state.linkedFrameOrderList,
  linkedFrameOrderList => linkedFrameOrderList,
);

const selectStatus = createDraftSafeSelector(
  (state: DeviceState) => state.actionResult,
  (state: FrameState) => state.isLoading,
  (state: FrameState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const frameSelector = {
  // frameList: state => selectFrameList(state),
  frameList: state => selectFrameList(state[FRAME]),
  frame: state => selectFrame(state),
  status: state => selectStatus(state[FRAME]),
  linkedFrameOrderList: state => selectLinkedFrameOrderList(state[FRAME]),
};

export const FRAME = slice.name;
export const frameReducer = slice.reducer;
export const frameAction = slice.actions;
