import styled from 'styled-components';

export const FlexItem = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  height: 30px;
  gap: 20px;

  .label {
    display: flex;
    align-items: center;
    height: 100%;
    color: gray;
    flex-basis: 100px;
    min-width: 100px;
    font-size: 14px;
    color: ${({ isError }) => (isError ? 'rgb(215 121 121)' : 'gray')};
    & span {
      color: rgb(215 121 121);
    }
  }
  .value {
    flex: 1;
    height: 100%;
    font-size: 15px;
    align-items: center;
    color: darkgray;
    white-space: pre;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .text {
    overflow: hidden;
    max-width: 208px;
    text-overflow: ellipsis;
    line-height: 27px;
  }
  .contents {
    width: 100%;
    height: 100%;
    min-width: 230px;
    max-width: 230px;
    overflow: hidden;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

// 콘텐츠 시간 엔터 이벤트
export const onEnterDuration = (typeRef, e) => {
  if (e.key === 'Enter') {
    typeRef.current.blur();
  }
};

// 콘텐츠 숨기기는 시간 포커스 안되도록 구현
export const onFocusDuration = (typeRef, isLock) => {
  if (isLock) {
    typeRef.current.blur();
  }
};

// 한자리 0붙이기함수
export const fnZeroAdd = value => {
  if ((value < 10 && typeof value === 'number') || (value.length === 1 && typeof value === 'string')) {
    value = '0' + value;
  }
  return String(value);
};

export const FrameDurationInput = styled.input`
  width: 40px;
  padding: 4px 5px;
  border: none;

  text-align: center;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 1px solid #2a91df;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  background: #ffffff;
  border-radius: 6px;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
`;

export const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const TableHead = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  background: #f9f9f9;
  border-top: 2px solid #cfcfcf;
  border-bottom: 2px solid #cfcfcf;
`;

export const TableBody = styled.div`
  width: 100%;
  min-height: ${props => (props.isMobile ? 'calc(100vw * 0.6)' : '500px')};
  font-size: 14px;
  color: #666666;
  overflow: hidden;
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
`;

export const TableRow = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  /* background-color: white; */
  align-items: center;
  border-bottom: 2px solid #eeeeee;
  background-color: rgb(255 255 255 / 85%);
  cursor: pointer;
  &:hover {
    background-color: #efeff4;
  }
  &:active {
    background: rgba(42, 145, 223, 0.1);
  }
`;

export const TableColumn = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  &.title {
    flex: ${props => (props.isMobile ? '4' : '3')};
  }
  &.number,
  &.category,
  &.status,
  &.reg-date {
    flex: 0.8;
  }
`;

export const Title = styled.div`
  height: 60px;
  padding: 5px 0;
  border-bottom: 2px solid #dddddd;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
`;
