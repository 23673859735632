import React, { useState, useCallback, memo, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { v4 } from 'uuid';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { ReactComponent as RemoveIcon } from '../assets/images/common/icon-close.svg';
import Viewer from './Viewer';

import { getDuration } from '../utils/file';

const useMultiContents = () => {
  const addFileList = useRef([]); // 새로 추가된 파일리스트
  const removeFileList = useRef([]); // 가존 파일리스트에서 제거된 파일 id 리스트
  const updateFileList = useRef([]); // 기존 파일에서 순서 변경된 파일 리스트
  const originFileList = useRef([]); // 원본 파일 리스트

  const [fileList, setFileList] = useState([]);

  const renderMultiContentsComponent = useCallback(
    (accept = 'image/jpeg, image/png', isError, multiple = true, mini = false) => {
      return (
        <MultiContents
          accept={accept}
          fileList={fileList}
          setFileList={setFileList}
          addFileList={addFileList.current}
          removeFileList={removeFileList.current}
          updateFileList={updateFileList.current}
          isError={isError}
          multiple={multiple}
          mini={mini}
        />
      );
    },
    [fileList],
  );

  const handleSetOriginFileList = useCallback(fileList => {
    originFileList.current = fileList;
    setFileList(fileList);
    removeFileList.current = [];
    addFileList.current = [];
    updateFileList.current = [];
  }, []);

  // 업데이트된 파일들 최신상태 갱신해주는 effect
  useEffect(() => {
    for (const originFile of originFileList.current) {
      const file = fileList.find(file => file.fileId === originFile.fileId);

      if (file) {
        const updateFileIndex = updateFileList.current.findIndex(updateFile => updateFile.fileId === file.fileId);

        if (updateFileIndex > -1) {
          if (file.fileOrder === originFile.fileOrder) {
            updateFileList.current.splice(updateFileIndex, 1);
          } else {
            updateFileList.current[updateFileIndex] = file;
          }
        } else {
          if (file.fileOrder !== originFile.fileOrder) {
            updateFileList.current.push(file);
          }
        }
      }
    }

    for (let index = 0; index < addFileList.current.length; index++) {
      const addFile = addFileList.current[index];
      const file = fileList.find(file => file.fileId === addFile.fileId);
      if (file) {
        addFileList.current[index] = file;
      }
    }
  }, [fileList]);

  return {
    fileList,
    handleSetOriginFileList: handleSetOriginFileList,
    addFileList: addFileList.current,
    removeFileList: removeFileList.current,
    updateFileList: updateFileList.current,
    originFileList: originFileList.current,
    renderMultiContentsComponent,
  };
};

// 다음 조건을 만족하면 리랜더링 안함
const multiContentsComponentRenderCondition = (prevProps, nextProps) => {
  return prevProps.fileList === nextProps.fileList && prevProps.isError === nextProps.isError;
};

const MultiContents = memo(({ accept, fileList, setFileList, addFileList, removeFileList, updateFileList, isError, multiple, mini }) => {
  const [localFileList, setLocalFileList] = useState(fileList);
  const [selectedFile, setSelectedFile] = useState({});
  const fileInputId = useRef(dayjs().unix() + v4().substr(0, 8));
  const dragFileId = useRef('');

  useEffect(() => {
    setLocalFileList(localFileList => fileList);
  }, [fileList]);

  // 이미지 추가
  const handleAddFile = useCallback(
    async (acceptedFileList, fileRejectionList) => {
      if (fileRejectionList && fileRejectionList.length !== 0) {
        toast.error('지원하지 않는 파일 형식입니다.');
      } else {
        if (acceptedFileList.length > 0) {
          const tempFileList = await Promise.all(
            acceptedFileList.map(async (file, index) => {
              const prevOrder = fileList.length > 0 ? fileList[fileList.length - 1].fileOrder : 0;
              const fileDuration = await getDuration(file);
              const data = {
                fileId: dayjs().unix() + v4().substr(0, 8),
                fileData: file,
                fileOrder: prevOrder + 1 + index,
                fileNm: file.name,
                fileSize: file.size,
                fileType: file.type,
                filePath: URL.createObjectURL(file),
                fileDuration,
              };

              return data;
            }),
          );

          const maxImgSize = 50 * 1024 * 1024;
          const maxVideoSize = 200 * 1024 * 1024;

          if (tempFileList.find(file => file.fileSize > maxImgSize && file.fileType.includes('image'))) {
            toast.error('이미지는 50MB 이하로 업로드 가능합니다.');
            return;
          }

          if (tempFileList.find(file => file.fileSize > maxVideoSize && file.fileType.includes('video'))) {
            toast.error('영상은 200MB 이하로 업로드 가능합니다.');
            return;
          }

          if (multiple) {
            addFileList.push(...tempFileList);

            setFileList(fileList => fileList.concat(tempFileList));
          } else {
            if (fileList.length > 0) {
              removeFileList[0] = fileList[0];
            }
            addFileList[0] = tempFileList[0];
            setFileList(fileList => tempFileList);
          }
        }
      }
    },
    [fileList, removeFileList, addFileList, setFileList, multiple],
  );

  const { isDragAccept, isDragReject, getRootProps } = useDropzone({
    onDrop: handleAddFile,
    accept,
    multiple,
  });

  // 이미지 선택
  const handleSelectFile = useCallback(file => {
    setSelectedFile(selectedFile => file);
  }, []);

  const handleSelectNextFile = useCallback(
    fileOrder => {
      setSelectedFile(selectedFile => localFileList[fileOrder]);
    },
    [localFileList],
  );

  // 드래그시작 이벤트
  const handleDragStartFile = useCallback((e, fileId) => {
    e.dataTransfer.effectAllowed = 'move';
    dragFileId.current = fileId;
  }, []);

  // 드래그 끝났을때 이벤트
  const handleDragEndFile = useCallback(
    e => {
      e.dataTransfer.dropEffect = 'move';
      dragFileId.current = '';
      setFileList(fileList => localFileList);
    },
    [setFileList, localFileList],
  );

  // 드래그도중 다른 파일 위에 있을때 순서 변경 이벤트
  const handleDragEnterFile = useCallback(
    (e, targetFileId) => {
      if (dragFileId.current && dragFileId.current !== targetFileId) {
        const fromIndex = localFileList.findIndex(localFile => localFile.fileId === dragFileId.current);
        const toIndex = localFileList.findIndex(localFile => localFile.fileId === targetFileId);

        let tempFileList = [...localFileList];
        const draggingFile = tempFileList.splice(fromIndex, 1)[0]; // 현재 드래그 중인 item을 list에서 제거하고 draggingItem에 담아둠

        tempFileList.splice(toIndex, 0, draggingFile); // 드래그가 끝났을 때 item이 위치해있는 인덱스(toIndex)에 draggingItem을 넣어줌

        tempFileList = tempFileList.map((tempFile, index) => ({ ...tempFile, fileOrder: index + 1 }));

        setLocalFileList(localFileList => tempFileList);
      }
    },
    [localFileList],
  );

  // 파일 지우는 이벤트
  const handleRemoveFile = useCallback(
    fileId => {
      setFileList(fileList => fileList.filter(file => file.fileId !== fileId).map((file, index) => ({ ...file, fileOrder: index + 1 })));

      const index = addFileList.findIndex(addFile => addFile.fileId === fileId);
      if (index > -1) {
        addFileList.splice(index, 1);
      } else {
        const file = fileList.find(originFile => originFile.fileId === fileId);
        removeFileList.push(file);
        const index = updateFileList.findIndex(updateFile => updateFile.fileId === fileId);

        if (index > -1) {
          updateFileList.splice(index, 1);
        }
      }
    },
    [setFileList, fileList, addFileList, updateFileList, removeFileList],
  );

  return (
    <>
      <Wrap {...getRootProps()} isError={isError} isDragAccept={isDragAccept} isDragReject={isDragReject}>
        {multiple ? (
          <>
            <Container>
              {localFileList.map((file, index) => (
                <FileCard
                  key={file.fileId}
                  file={file}
                  handleSelectFile={handleSelectFile}
                  handleRemoveFile={handleRemoveFile}
                  handleDragStartFile={handleDragStartFile}
                  handleDragEndFile={handleDragEndFile}
                  handleDragEnterFile={handleDragEnterFile}
                />
              ))}
            </Container>
            <FileAdd>
              <label htmlFor={fileInputId.current}>파일첨부</label>
              <span>이미지(jpg, png) 50M이하 {accept.includes('video') ? '동영상(mp4) 200M이하' : ''} 업로드 가능</span>
              <input
                type="file"
                id={fileInputId.current}
                multiple={multiple}
                onChange={e => {
                  handleAddFile(Array.prototype.slice.call(e.target.files));
                  e.target.value = '';
                }}
                accept={accept}
              />
            </FileAdd>
          </>
        ) : (
          <SoloFileForm>
            {localFileList && localFileList.length > 0 ? (
              localFileList.map((file, index) => (
                <FileCard
                  key={file.fileId}
                  file={file}
                  handleSelectFile={handleSelectFile}
                  handleRemoveFile={handleRemoveFile}
                  handleDragStartFile={handleDragStartFile}
                  handleDragEndFile={handleDragEndFile}
                  handleDragEnterFile={handleDragEnterFile}
                  mini={mini}
                />
              ))
            ) : mini ? (
              <FileMiniAdd>
                <label htmlFor={fileInputId.current}>파일 첨부</label>
                <input
                  type="file"
                  id={fileInputId.current}
                  multiple={multiple}
                  onChange={e => {
                    handleAddFile(Array.prototype.slice.call(e.target.files));
                    e.target.value = '';
                  }}
                  accept={accept}
                />
              </FileMiniAdd>
            ) : (
              <FileSoloAdd>
                <label htmlFor={fileInputId.current}>파일첨부</label>
                <span>이미지(jpg, png) 50M이하 {accept.includes('video') ? '동영상(mp4) 200M이하' : ''} 업로드 가능</span>
                <input
                  type="file"
                  id={fileInputId.current}
                  multiple={multiple}
                  onChange={e => {
                    handleAddFile(Array.prototype.slice.call(e.target.files));
                    e.target.value = '';
                  }}
                  accept={accept}
                />
              </FileSoloAdd>
            )}
          </SoloFileForm>
        )}
      </Wrap>
      {!isEmpty(selectedFile) && (
        <Viewer
          closeViewer={() => setSelectedFile({})}
          file={selectedFile}
          onPrev={selectedFile.fileOrder > 1 ? () => handleSelectNextFile(selectedFile.fileOrder - 2) : undefined}
          onNext={selectedFile.fileOrder < localFileList.length ? () => handleSelectNextFile(selectedFile.fileOrder) : undefined}
        />
      )}
    </>
  );
}, multiContentsComponentRenderCondition);

const FileCard = ({ file, handleSelectFile, handleRemoveFile, handleDragStartFile, handleDragEndFile, handleDragEnterFile, mini }) => {
  return (
    <FileBox
      draggable
      onDoubleClick={e => handleSelectFile(file)}
      onDragOver={e => e.preventDefault()}
      onDragStart={e => handleDragStartFile(e, file.fileId)}
      onDragEnd={e => handleDragEndFile(e, file.fileId)}
      onDragEnter={e => handleDragEnterFile(e, file.fileId)}
      mini={mini}
    >
      <RemoveBtn onClick={() => handleRemoveFile(file.fileId)}>
        <RemoveIcon />
      </RemoveBtn>

      <FileImg mini={mini}>
        {file.filePath.includes('blob') ? (
          file.fileType.includes('image') ? (
            <img alt="img" src={file.filePath} />
          ) : (
            <video muted alt="video" src={file.filePath} />
          )
        ) : (
          <img alt="img" src={process.env.REACT_APP_INTERACTION_CDN_URL + (file.thumbFilePath || file.filePath)} />
        )}
      </FileImg>

      <FileNm>{file.fileNm}</FileNm>
    </FileBox>
  );
};

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: #ffffff;
  border: ${({ isError, isDragAccept, isDragReject }) => (isError || isDragReject ? '1px dashed #f05b5b' : isDragAccept ? '1px dashed #6A8BFF' : '1px solid #ffffff')};
`;
const SoloFileForm = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FileAdd = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 32px;
  margin-top: 20px;

  & > label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #6a8bff;
    border-radius: 100px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #6a8bff;
    cursor: pointer;
  }

  & > span {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #939393;
  }
  & > input[type='file'] {
    display: none;
    width: 72px;
    height: 100%;
  }
`;

const FileMiniAdd = styled.div`
  & > label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 10px 0;
    background: #ffffff;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #6a8bff;
    cursor: pointer;
    text-align: center;
  }
  & > input[type='file'] {
    display: none;
    width: 100%;
    height: 100%;
  }
`;

const FileSoloAdd = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  & > label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 35px;
    margin: 10px 0;
    background: #ffffff;
    border: 1px solid #6a8bff;
    border-radius: 100px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #6a8bff;
    cursor: pointer;
  }

  & > span {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #939393;
    padding: 0 10px;
  }
  & > input[type='file'] {
    display: none;
    width: 72px;
    height: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const FileBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ mini }) => (mini ? '100%' : '100px')};
  height: ${({ mini }) => (mini ? '100%' : '120px')};
  background: ${({ isSelected }) => (isSelected ? 'rgba(65, 161, 234, 0.4)' : '#ffffff')};
`;

const FileImg = styled.div`
  width: ${({ mini }) => (mini ? '100%' : '100px')};
  height: ${({ mini }) => (mini ? '100%' : '100px')};
  border: 1px solid #eeeeee;
  border-radius: 5px;
  & > img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const FileNm = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #939393;
`;

const RemoveBtn = styled.button`
  position: absolute;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -12px;
  right: -12px;
  border: 1px solid #dedede;
  background: white;

  & path {
    fill: #848484;
  }
`;

export default useMultiContents;
