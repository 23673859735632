import React from 'react';
import Moveable from 'react-moveable';
import { useSelector } from 'react-redux';

import { editorSelector } from '../../module/editorSlice';

import { EDITOR_GUIDELINE_OFFSET } from './constants/editor';

const MoveableManager = ({
  moveableRef,
  selectoRef,
  lockMode,
  dragMode,
  targets,
  bounds,
  updateLayerState,
  updateLayerDB,
  elementGuidelines,
  verticalGuidelines,
  horizontalGuidelines,
  frameAngle,
  cursorType = 'SELECT',
  isAbleShow,
}) => {
  const { snapMode } = useSelector(editorSelector.canvasInfo);

  const onDragStart = e => {
    if (cursorType === 'LAYER-ADD' || cursorType === 'OVERLAY-ADD') {
      return false;
    }
    if (dragMode) {
      return false;
    }

    if (lockMode) {
      // handleNotifyLockMode();
      return false;
    }
  };
  const onDrag = e => {
    const { beforeTranslate, target } = e;
    const x = Math.round(beforeTranslate[0]);
    const y = Math.round(beforeTranslate[1]);

    updateLayerState({ x, y });
    target.style.transform = `translate(${x}px, ${y}px)`;
  };
  const onDragEnd = e => {
    const lastEvent = e.lastEvent;

    if (lastEvent) {
      const beforeTranslate = lastEvent.beforeTranslate;
      const target = lastEvent.target;
      const id = target.id;

      const x = Math.round(beforeTranslate[0]);
      const y = Math.round(beforeTranslate[1]);
      updateLayerState({ x, y });
      updateLayerDB([
        {
          id,
          updateInfo: {
            x,
            y,
          },
        },
      ]);
    }
  };

  const onResizeStart = e => {
    if (cursorType === 'LAYER-ADD' || cursorType === 'OVERLAY-ADD') {
      return false;
    }
    if (dragMode) {
      return false;
    }

    if (lockMode) {
      // handleNotifyLockMode();
      return false;
    }

    e.setMin([20, 20]);
  };
  const onResize = ({ offsetWidth, target, width, height, drag }) => {
    const beforeTranslate = drag.beforeTranslate;
    const _width = Math.round(width);
    const _height = Math.round(height);
    const x = Math.round(beforeTranslate[0]);
    const y = Math.round(beforeTranslate[1]);

    updateLayerState({ x, y, width: _width, height: _height });
    target.style.width = `${_width}px`;
    target.style.height = `${_height}px`;
    target.style.transform = `translate(${x}px, ${y}px)`;
  };
  const onResizeEnd = e => {
    const lastEvent = e.lastEvent;

    if (lastEvent) {
      const drag = lastEvent.drag;
      const width = lastEvent.width;
      const height = lastEvent.height;

      const beforeTranslate = drag.beforeTranslate;
      const target = drag.target;
      const id = target.id;

      const _width = Math.round(width);
      const _height = Math.round(height);
      const x = Math.round(beforeTranslate[0]);
      const y = Math.round(beforeTranslate[1]);

      updateLayerState({ x, y, width: _width, height: _height });
      updateLayerDB([
        {
          id,
          updateInfo: {
            x,
            y,
            width: _width,
            height: _height,
          },
        },
      ]);
    }
  };

  const onDragGroupStart = e => {
    if (cursorType === 'LAYER-ADD' || cursorType === 'OVERLAY-ADD') {
      return false;
    }
    if (dragMode) {
      return false;
    }

    if (lockMode) {
      // handleNotifyLockMode();
      return false;
    }
  };
  const onDragGroup = e => {
    const events = e.events;

    events.forEach(ev => {
      const target = ev.target;
      const transform = ev.transform;
      target.style.transform = transform;
    });
  };
  const onDragGroupEnd = e => {
    const isDrag = e.isDrag;

    if (isDrag) {
      const targets = e.targets;

      const updateList = [];

      targets.forEach(target => {
        const id = target.id;

        const style = window.getComputedStyle(target);
        const matrix = new DOMMatrixReadOnly(style.transform);

        updateList.push({
          id,
          updateInfo: {
            x: Math.round(matrix.m41),
            y: Math.round(matrix.m42),
          },
        });
      });
      updateLayerDB(updateList);
    }
  };

  const onResizeGroupStart = e => {
    if (cursorType === 'LAYER-ADD' || cursorType === 'OVERLAY-ADD') {
      return false;
    }
    if (dragMode) {
      return false;
    }

    if (lockMode) {
      // handleNotifyLockMode();
      return false;
    }

    const events = e.events;
    events.forEach(ev => ev.setMin([20, 20]));
  };
  const onResizeGroup = ({ events }) => {
    events.forEach(ev => {
      const target = ev.target;
      const width = ev.width;
      const height = ev.height;
      const drag = ev.drag;

      const transform = drag.transform;

      target.style.width = `${width}px`;
      target.style.height = `${height}px`;
      target.style.transform = transform;
    });
  };
  const onResizeGroupEnd = e => {
    const lastEvent = e.lastEvent;

    if (lastEvent) {
      const targets = e.targets;

      const updateList = [];

      targets.forEach(target => {
        const id = target.id;
        const offsetWidth = target.offsetWidth;
        const offsetHeight = target.offsetHeight;

        const style = window.getComputedStyle(target);
        const matrix = new DOMMatrixReadOnly(style.transform);

        updateList.push({
          id,
          updateInfo: {
            x: Math.round(matrix.m41),
            y: Math.round(matrix.m42),
            width: Math.round(offsetWidth),
            height: Math.round(offsetHeight),
          },
        });
      });
      updateLayerDB(updateList);
    }
  };

  return (
    <Moveable
      ref={moveableRef}
      targets={targets}
      draggable={!lockMode && !dragMode}
      resizable={!lockMode && !dragMode}
      snappable={snapMode && !lockMode && !dragMode}
      rotatable={false}
      origin={false}
      keepRatio={false}
      snapThreshold={EDITOR_GUIDELINE_OFFSET}
      ables={[Editable]}
      props={{ editable: isAbleShow, name: targets.length > 1 ? 'group' : targets.length === 1 ? targets[0].getAttribute('name') : '' }}
      onClickGroup={e => {
        selectoRef.current.clickTarget(e.inputEvent, e.inputTarget);
      }}
      bounds={bounds}
      elementGuidelines={elementGuidelines}
      verticalGuidelines={verticalGuidelines}
      horizontalGuidelines={horizontalGuidelines}
      renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
      onDragStart={e => onDragStart(e)}
      onDrag={e => onDrag(e)}
      onDragEnd={e => onDragEnd(e)}
      onResizeStart={e => onResizeStart(e)}
      onResize={e => onResize(e)}
      onResizeEnd={e => onResizeEnd(e)}
      onDragGroupStart={e => onDragGroupStart(e)}
      onDragGroup={e => onDragGroup(e)}
      onDragGroupEnd={e => onDragGroupEnd(e)}
      onResizeGroupStart={e => onResizeGroupStart(e)}
      onResizeGroup={e => onResizeGroup(e)}
      onResizeGroupEnd={e => onResizeGroupEnd(e)}
    />
  );
};

const Editable = {
  name: 'editable',
  props: { name: String },
  events: {},
  render(moveable, React) {
    const width = Math.floor(moveable.getRect().width);
    const height = Math.floor(moveable.getRect().height);

    const EditableViewer = moveable.useCSS(
      'div',
      `
      {
        position: absolute;
        top: 0;
        left: 0;
      }
      .name-able {
        position: absolute;
        top: -25px;
        left: 0px;
        font-size: 12px;
        height: 20px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #41A1Ea;
        display: flex; 
        align-items: center;
        justify-content: center;
      }
      .size-able {
        position: absolute;
        bottom: -${height + 25}px;
        left: 0;
        width: ${width}px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .size {
        background: #41A1EA;
        border-radius: 3px;
        padding: 3px 7px;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
      `,
    );

    return (
      <EditableViewer key="editable-viewer" className={'moveable-editable'}>
        <div className="name-able">{moveable.props.name}</div>
        <div className="size-able">
          <div className="size">
            <span>{width}</span>
            <span>x</span>
            <span>{height}</span>
          </div>
        </div>
      </EditableViewer>
    );
  },
};

export default MoveableManager;
