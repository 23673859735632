import * as apiLib from './api';

export const getTemplateList = async () => {
  try {
    const result = await apiLib.fetchInteractionApi('/template/template-list-get', {});

    if (result.resultFlag) {
      return result.templateList;
    } else {
      throw Object.assign(new Error('getTemplateList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const addTemplateFrame = async ({ frame, templateId }) => {
  try {
    const data = {
      templateId,
      frame
    };

    const result = await apiLib.fetchInteractionApi('/template/template-frame-add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getTemplateList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const addTemplate = async ({ frameId, templateNm, horizonResolution, verticalResolution }) => {
  try {
    const data = {
      frameId,
      templateNm,
      horizonResolution,
      verticalResolution,
    };

    const result = await apiLib.fetchInteractionApi('/template/template-add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addTemplate error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}