import * as apiLib from './api';

export const addArchivePackage = async data => {
  try {
    const result = await apiLib.fetchInteractionApi('/package/add-archive-package', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const addStorePackage = async data => {
  try {
    const result = await apiLib.fetchInteractionApi('/package/add-store-package', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}