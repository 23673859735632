import { all, fork, call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { ACTIVEITEM, activeItemAction } from './activeItemSlice';
import { LAYER, layerAction } from './layerSlice';
import { layerHistoryAction } from './layerHistorySlice';

import * as layerApiLib from '../lib/layerApi';
import { layerContentsAction } from './layerContentsSlice';

function* getLayerListSaga({ payload: frameId }) {
  try {
    const data = {
      // indexType: 'frame',
      indexId: frameId,
    };

    const layerList = yield call(layerApiLib.getLayerList, data);
    const baseLayer = layerList.find(layer => layer.baseYn === 'Y');
    yield put(activeItemAction.setActiveLayerId(baseLayer.layerId));
    yield put(activeItemAction.setLayerType('CONTENTS'));
    yield put(layerAction.getLayerListSuccess({ layerList }));
    yield put(layerContentsAction.getLayerContents(baseLayer.layerId));
  } catch (error) {
    console.log(error);
    yield put(layerAction.getLayerListFailure('Layer List Error'));
  }
}

function* addLayerSaga({ payload: { activeFrameId, layer }}) {
  try {
    const activeItem = yield select(state => state[ACTIVEITEM]);
    const data = {
      ...layer,
      playlistId: activeItem.activePlaylistId,
      frameId: activeFrameId,
    };

    yield call(layerApiLib.getAddLayer, data);
    yield put(layerAction.addLayerSuccess());
    yield put(activeItemAction.setActiveLayerId(layer.layerId));
    yield put(activeItemAction.setLayerType('CONTENTS'));

    // const layerState = yield call(layerApiLib.getLayerList, { indexType: 'frame', indexId: activeFrameId });
    const layerState = yield select(state => state[LAYER].layerList);
    const historyData = {
      activeLayerId: layer.layerId,
      layerState,
    };
    yield put(layerHistoryAction.addLayerHistory(historyData));

    yield put(layerContentsAction.getLayerContents(layer.layerId));
  } catch (error) {
    yield put(layerAction.addLayerFailure('Add Layer Error'));
  }
}

function* pasteLayerSaga({ payload: { pasteLayer, pasteLayerContents }}) {
  console.log('사가 실행');
  // yield delay(150);

  try {
    const data = {
      pasteLayer,
      pasteLayerContents,
    };

    yield call(layerApiLib.getPasteLayer, data);

    yield put(activeItemAction.setActiveLayerId(pasteLayer.layerId));
    
    const layerState = yield select(state => state[LAYER].layerList);
    const historyData = {
      activeLayerId: pasteLayer.layerId,
      layerState,
    };
    yield put(layerHistoryAction.addLayerHistory(historyData));

    yield put(layerAction.pasteLayerSuccess());

    yield put(layerContentsAction.getLayerContents(pasteLayer.layerId));
  } catch (error) {
    yield put(layerAction.addLayerFailure('Paste Layer Error'));
  }
}

function* updateLayerSaga({ payload: { activeLayerId, layerInfo }}) {
  try {
    const data = {
      layerId: activeLayerId,
      layerInfo,
    }

    yield call(layerApiLib.getUpdateLayer, data);
    yield put(layerAction.updateLayerSuccess());

    // const activeFrameId = yield select(state => state[ACTIVEITEM].activeFrameId);
    // const layerState = yield call(layerApiLib.getLayerList, { indexType: 'frame', indexId: activeFrameId });

    const layerState = yield select(state => state[LAYER].layerList);
    const historyData = {
      activeLayerId,
      layerState,
    };
    yield put(layerHistoryAction.addLayerHistory(historyData));
  } catch (error) {
    yield put(layerAction.updateLayerFailure('Update Layer Error'));
  }
}

function* removeLayerSaga({ payload }) {
  try {
    yield call(layerApiLib.getRemoveLayer, payload);
    yield put(layerAction.removeLayerSuccess());
    yield put(activeItemAction.setActiveLayerId(''));
    yield put(layerContentsAction.initLayerContentsState());

    const layerState = yield select(state => state[LAYER].layerList);
    for (let i = 0; i < layerState.length; i++) {
      yield call(layerApiLib.getUpdateLayer, { layerId: layerState[i].layerId, layerInfo: { layerOrder: i }});
    }

    // const activeFrameId = yield select(state => state[ACTIVEITEM].activeFrameId);
    // const layerState = yield call(layerApiLib.getLayerList, { indexType: 'frame', indexId: activeFrameId });
    const historyData = {
      activeLayerId: '',
      layerState,
    };
    yield put(layerHistoryAction.addLayerHistory(historyData));

    yield put(layerContentsAction.initLayerContentsState());
  } catch (error) {
    yield put(layerAction.removeLayerFailure('Remove Layer Error'));
  }
}

function* moveToLayerSaga({ payload: { activeLayerId, where }}) {
  try {
    const layerState = yield select(state => state[LAYER].layerList);
    for (let i = 0; i < layerState.length; i++) {
      yield call(layerApiLib.getUpdateLayer, { layerId: layerState[i].layerId, layerInfo: { layerOrder: i }});
    }
    yield put(layerAction.moveToLayerSuccess());

    // const activeFrameId = yield select(state => state[ACTIVEITEM].activeFrameId);
    // const layerState = yield call(layerApiLib.getLayerList, { indexType: 'frame', indexId: activeFrameId });
    const historyData = {
      activeLayerId,
      layerState,
    };
    yield put(layerHistoryAction.addLayerHistory(historyData));
  } catch (error) {
    yield put(layerAction.moveToLayerFailure('Move To Layer Error'));
  }
}

// function* sortLayerSaga({ payload }) {
//   try {
//     yield call(layerApiLib.getSortLayer, payload);
//     yield put(layerAction.sortLayerSuccess());
//   } catch (error) {
//     yield put(layerAction.sortLayerFailure('Sort Layer Error'));
//   }
// }

function* setBaseLayerSaga({ payload: { activeFrameId, activeLayerId }}) {
  try {
    const data = {
      // indexType: 'frame',
      indexId: activeFrameId,
    };
    
    const layerList = yield call(layerApiLib.getLayerList, data);
    const oldBaseLayer = layerList.find(layer => layer.baseYn === 'Y');
    
    yield call(layerApiLib.getUpdateLayer, { layerId: oldBaseLayer.layerId, layerInfo: { baseYn: 'N' }});
    yield call(layerApiLib.getUpdateLayer, { layerId: activeLayerId, layerInfo: { baseYn: 'Y' }});
    yield put(layerAction.setBaseLayerSuccess());
    
    // const layerState = yield call(layerApiLib.getLayerList, { indexType: 'frame', indexId: activeFrameId });
    const layerState = yield select(state => state[LAYER].layerList);
    const historyData = {
      activeLayerId,
      layerState,
    };
    yield put(layerHistoryAction.addLayerHistory(historyData));
  } catch (error) {
    yield put(layerAction.setBaseLayerFailure('Set Base Layer Error'));
  }
}

function* setLayerHistorySaga({ payload: { layerList, newLayerList }}) {
  try {
    yield call(layerApiLib.getRemoveLayerBatch, { layerList });

    const activeItem = yield select(state => state[ACTIVEITEM]);
    const newLayerListData = newLayerList.map(layer => ({
      ...layer,
      playlistId: activeItem.activePlaylistId,
      frameId: activeItem.activeFrameId,
    }));
    yield call(layerApiLib.getAddLayerBatch, { newLayerListData });

    // const layerList = yield call(layerApiLib.getLayerList);
    yield put(layerAction.setLayerHistorySuccess(newLayerList));
  } catch (error) {
    console.log(error);
    yield put(layerAction.setLayerHistoryFailure('Set Layer History Error'));
  }
}

export function* watchGetLayerList() {
  yield takeLatest(layerAction.getLayerList, getLayerListSaga);
}

export function* watchAddLayer() {
  yield takeEvery(layerAction.addLayer, addLayerSaga);
}

export function* watchPasteLayer() {
  yield takeEvery(layerAction.pasteLayer, pasteLayerSaga);
  // yield takeLatest(layerAction.pasteLayer, pasteLayerSaga);
  // yield throttle(200, layerAction.pasteLayer, pasteLayerSaga);
}

export function* watchUpdateLayer() {
  yield takeEvery(layerAction.updateLayer, updateLayerSaga);
}

export function* watchRemoveLayer() {
  yield takeEvery(layerAction.removeLayer, removeLayerSaga);
}

export function* watchMoveToLayer() {
  yield takeEvery(layerAction.moveToLayer, moveToLayerSaga);
}

// export function* watchSortLayer() {
//   yield takeEvery(layerAction.sortLayer, sortLayerSaga);
// }

export function* watchSetBaseLayer() {
  yield takeEvery(layerAction.setBaseLayer, setBaseLayerSaga);
}

export function* watchSetLayerHistory() {
  yield takeEvery(layerAction.setLayerHistory, setLayerHistorySaga);
}

function* rootSaga() {
  yield all([
    fork(watchGetLayerList),
    fork(watchAddLayer),
    fork(watchPasteLayer),
    fork(watchUpdateLayer),
    fork(watchRemoveLayer),
    fork(watchMoveToLayer),
  //   fork(watchSortLayer),
    fork(watchSetBaseLayer),
    fork(watchSetLayerHistory),
  ]);
}

export default rootSaga;
