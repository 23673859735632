import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import Loading from './Loading';

import {
  FiArrowLeft as CloseIcon,
  FiImage as ImageIcon,
  FiFilm as VideoIcon,
  FiDownload as DownloadIcon,
  FiChevronLeft as PrevIcon,
  FiChevronRight as NextIcon,
  FiInfo as InfoIcon,
  // FiMinus as ZoomOutIcon,
  // FiPlus as ZoomInIcon,
  // FiMaximize as ZoomFitIcon,
} from 'react-icons/fi';

import { formatBytes } from '../utils/file';

const Viewer = ({ file, closeViewer, onNext, onPrev }) => {
  const headerHeight = useRef(56);
  const footerHeight = useRef(56);
  const initFileOriginInfo = useRef({
    width: 0,
    height: 0,
    type: '',
  });

  const [fileOriginInfo, setFileOriginInfo] = useState(initFileOriginInfo.current);
  const [fileElSize, setFileElSize] = useState({ width: 0, height: 0 });
  const [isLoading, setIsLoading] = useState(true);
  // const [ratio, setRatio] = useState(1);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const clearViewer = useCallback(() => {
    setIsLoading(true);
    setFileElSize({ width: 0, height: 0 });
    setFileOriginInfo(fileOriginInfo => initFileOriginInfo.current);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const { width: fileOriginWidth, height: fileOriginHeight, type: fileType } = fileOriginInfo;

      const marginRow = 12;
      const marginCol = headerHeight.current + footerHeight.current;

      if (fileType === 'image') {
        const windowWidth = window.innerWidth - marginRow;
        const windowHeight = window.innerHeight - marginCol;
        const scalex = windowWidth / fileOriginWidth;
        const scaley = windowHeight / fileOriginHeight;
        const ratio = scalex < scaley ? scalex : scaley; // 현재 화면에 맞는 비율 정보
        const ratioWidth = Math.round(ratio * fileOriginWidth);
        const ratioHeight = Math.round(ratio * fileOriginHeight);

        if (windowHeight < fileOriginHeight || windowWidth < fileOriginWidth) {
          setFileElSize(fileElSize => ({ ...fileElSize, width: ratioWidth, height: ratioHeight }));
        } else {
          setFileElSize(fileElSize => ({ ...fileElSize, width: fileOriginWidth, height: fileOriginHeight }));
        }
      } else if (fileType === 'video') {
        const windowWidth = window.innerWidth - marginRow;
        const windowHeight = window.innerHeight - marginCol - 20;
        const scalex = windowWidth / fileOriginWidth;
        const scaley = windowHeight / fileOriginHeight;
        const ratio = scalex < scaley ? scalex : scaley; // 현재 화면에 맞는 비율 정보
        const ratioWidth = Math.round(ratio * fileOriginWidth);
        const ratioHeight = Math.round(ratio * fileOriginHeight);

        const horizonList = [7680, 5120, 3840, 2560, 1920, 1600, 1440, 1280, 640, 480, 320];
        const verticalList = [4320, 2880, 2160, 1440, 1080, 900, 810, 720, 360, 270, 180];

        if (fileOriginHeight > windowHeight || fileOriginWidth > windowWidth) {
          setFileElSize(fileElSize => ({ ...fileElSize, width: ratioWidth, height: ratioHeight }));
        } else {
          const index = verticalList.findIndex(vertical => vertical <= fileOriginHeight);
          setFileElSize(fileElSize => ({ ...fileElSize, width: horizonList[index], height: verticalList[index] }));
        }
      } else {
        clearViewer();
      }
    };

    if (fileOriginInfo.type) {
      window.addEventListener('resize', handleResize);
      handleResize(fileOriginInfo);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [fileOriginInfo, clearViewer]);

  return (
    <Wrap>
      <Header height={headerHeight.current}>
        <CloseBtn onClick={() => closeViewer()}>
          <CloseIcon color="#FFFFFF" size="22px" />
        </CloseBtn>
        <Name>
          {file.fileType?.includes('video') ? <VideoIcon size="20px" /> : <ImageIcon size="20px" />}
          {file.fileNm}
        </Name>
        <DownloadBtn>
          <DownloadIcon color="white" size="21px" />
        </DownloadBtn>
        <FileInfoBtn onMouseEnter={() => setIsInfoModalOpen(true)} onMouseLeave={() => setIsInfoModalOpen(false)}>
          <InfoIcon color="#FFFFFF" size="22px" />
          <FileInfoModal
            isInfoModalOpen={isInfoModalOpen}
            setIsInfoModalOpen={setIsInfoModalOpen}
            isVideo={file.fileType?.includes('video')}
            size={file.fileSize}
            resolution={`${fileOriginInfo.width} * ${fileOriginInfo.height}`}
          />
        </FileInfoBtn>
      </Header>
      <Container onClick={() => closeViewer()}>
        <ButtonArea style={{ left: 0, paddingLeft: '12px' }} onClick={e => e.stopPropagation()}>
          {onPrev && (
            <PrevBtn
              onClick={e => {
                e.stopPropagation();
                clearViewer();
                onPrev();
              }}
            >
              <PrevIcon color="#ffffff" size="21px" />
            </PrevBtn>
          )}
        </ButtonArea>
        {isLoading && <Loading />}
        <FileBox
          isLoading={isLoading}
          width={fileElSize.width}
          height={fileElSize.height}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {!isEmpty(file) && <File file={file} setIsLoading={setIsLoading} setFileOriginInfo={setFileOriginInfo} />}
        </FileBox>
        <ButtonArea style={{ right: 0, paddingRight: '12px' }} onClick={e => e.stopPropagation()}>
          {onNext && (
            <NextBtn
              onClick={e => {
                e.stopPropagation();
                clearViewer();
                onNext();
              }}
            >
              <NextIcon color="#ffffff" size="21px" />
            </NextBtn>
          )}
        </ButtonArea>
      </Container>
      <Footer height={footerHeight.current}>
        {/* {fileOriginInfo.type !== 'video' && <ZoomToolBox ratio={ratio} setRatio={setRatio} />} */}
      </Footer>
    </Wrap>
  );
};

const File = React.memo(({ file, setIsLoading, setFileOriginInfo }) => {
  const handleLoadData = useCallback(
    (e, type) => {
      const fileEl = e.target;

      if (type === 'video') {
        setFileOriginInfo(fileOriginInfo => ({ ...fileOriginInfo, width: fileEl.videoWidth, height: fileEl.videoHeight, type: 'video' }));
      } else {
        setFileOriginInfo(fileOriginInfo => ({
          ...fileOriginInfo,
          width: fileEl.naturalWidth,
          height: fileEl.naturalHeight,
          type: 'image',
        }));
      }
      setIsLoading(false);
    },
    [setFileOriginInfo, setIsLoading],
  );

  return (
    <>
      {file.fileType?.includes('video') ? (
        <video
          preload="auto"
          controls
          src={file?.filePath?.includes('blob') ? file?.filePath : process.env.REACT_APP_ARCHIVE_CDN_URL + file?.filePath || ''}
          alt=""
          onLoadedMetadata={e => handleLoadData(e, 'video')}
        />
      ) : (
        <img
          src={file?.filePath?.includes('blob') ? file?.filePath : process.env.REACT_APP_ARCHIVE_CDN_URL + file?.filePath || ''}
          alt=""
          onLoad={e => handleLoadData(e, 'image')}
          draggable={false}
        />
      )}
    </>
  );
});

// const ZoomToolBox = React.memo(({ ratio, setRatio }) => {
//   const handleWheel = useCallback(
//     e => {
//       setRatio(ratio => {
//         if (e.wheelDelta > 0 && ratio < 10) {
//           // zoom in
//           return Math.round((ratio + 0.2) * 10) / 10;
//         } else if (e.wheelDelta < 0 && ratio > 1) {
//           // zoom out
//           return Math.round((ratio - 0.2) * 10) / 10;
//         } else {
//           return ratio;
//         }
//       });
//     },
//     [setRatio],
//   );

//   useEffect(() => {
//     window.addEventListener('wheel', handleWheel);
//     return () => {
//       window.removeEventListener('wheel', handleWheel);
//     };
//   }, [handleWheel]);

//   return (
//     <ZoomTool>
//       <ZoomToolBtn disabled={ratio === 1}>
//         <ZoomOutIcon color={ratio === 1 ? '#333333' : '#ffffff'} size="20px" />
//       </ZoomToolBtn>
//       <ZoomToolBtn disabled={ratio === 1}>
//         <ZoomFitIcon color={ratio === 1 ? '#333333' : '#ffffff'} size="20px" />
//       </ZoomToolBtn>
//       <ZoomToolBtn>
//         <ZoomInIcon color="#ffffff" size="20px" />
//       </ZoomToolBtn>
//     </ZoomTool>
//   );
// });

const FileInfoModal = React.memo(({ isInfoModalOpen, setIsInfoModalOpen, isVideo, resolution, size }) => {
  const formatSize = useMemo(() => {
    return formatBytes(size, 2);
  }, [size]);

  return (
    <FileInfoBox
    isInfoModalOpen={isInfoModalOpen}
      onMouseEnter={e => {
        e.stopPropagation();
        setIsInfoModalOpen(true);
      }}
    >
      <FileInfoBoxHeader>상세정보</FileInfoBoxHeader>
      <FileInfoBoxBody>
        <FileInfoRow>
          <FileInfoTitle>유형</FileInfoTitle>
          <FileInfo>{isVideo ? '동영상' : '이미지'}</FileInfo>
        </FileInfoRow>
        <FileInfoRow>
          <FileInfoTitle>크기</FileInfoTitle>
          <FileInfo>{resolution}</FileInfo>
        </FileInfoRow>
        <FileInfoRow>
          <FileInfoTitle>크기</FileInfoTitle>
          <FileInfo>{formatSize}</FileInfo>
        </FileInfoRow>
      </FileInfoBoxBody>
    </FileInfoBox>
  );
});

const Wrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  color: #ffffff;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height}px;
  padding: 8px 12px 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
`;

const CloseBtn = styled.div`
  width: 40px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  cursor: pointer;
  transition: background-color 0.1s, opacity 0.1s;
  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0 auto;
  gap: 10px;
`;

const DownloadBtn = styled.button`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  visibility: hidden;

  pointer-events: none;
`;

const FileInfoBtn = styled.button`
  position: relative;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
`;

const Container = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const FileBox = styled.div`
  overflow: hidden;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  @keyframes fileFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  & img,
  video {
    width: 100%;
    height: 100%;
    ${({ isLoading }) =>
      isLoading
        ? css`
            position: absolute;
            z-index: -1;
          `
        : css`
            animation-duration: 1.5s;
            animation-timing-function: ease;
            animation-name: fileFadeIn;
            animation-fill-mode: forwards;
          `}
  }
`;

const FileInfoBox = styled.div`
  position: absolute;
  top: 44px;
  right: 0;
  min-width: 344px;
  background-color: #323232;
  display: ${({ isInfoModalOpen }) => (isInfoModalOpen ? 'flex' : 'none')};
  flex-direction: column;
  color: #ffffff;
  z-index: 1;
`;

const FileInfoBoxHeader = styled.div`
  box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  padding: 0 16px;
`;

const FileInfoBoxBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
`;

const FileInfoRow = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`;

const FileInfoTitle = styled.div`
  font-size: 12px;
  flex: 1;
  color: rgba(255, 255, 255, 0.57);
`;
const FileInfo = styled.div`
  font-size: 12px;
  flex: 2;
`;

const ButtonArea = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 52px;
  height: 100%;
`;

const NextBtn = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.75);
  transition: background 0.2s, opacity 0.34s, transform 0.34s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  cursor: pointer;
  z-index: 10;

  & > svg {
    position: absolute;
    top: 9px;
    right: 9px;
  }

  &:hover {
    background-color: #4285f4;
  }
`;

const PrevBtn = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.75);
  transition: background 0.2s, opacity 0.34s, transform 0.34s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  z-index: 10;
  cursor: pointer;

  & > svg {
    position: absolute;
    top: 9px;
    left: 9px;
  }

  &:hover {
    background-color: #4285f4;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => height}px;
`;

// const ZoomTool = styled.div`
//   margin: 0 auto;
//   height: 40px;
//   background: rgba(0, 0, 0, 0.75);
// `;

// const ZoomToolBtn = styled.button`
//   width: 40px;
//   height: 40px;
//   padding: 8px;
//   transition: background-color 0.1s, opacity 0.1s;
//   background: inherit;

//   &:hover {
//     background: rgba(255, 255, 255, 0.25);
//   }

//   pointer-events: ${({ disabled }) => disabled && 'none'};
// `;

export default React.memo(Viewer);
