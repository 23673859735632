import * as apiLib from './api';

export const list = async placeId => {
  try {
    const result = await apiLib.fetchInteractionApi('/channel-contents/list', placeId);
    if (result.resultFlag) {
      return result.channelList;
    } else {
      throw Object.assign(new Error('getChannelList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const listAll = async placeId => {
  try {
    const result = await apiLib.fetchInteractionApi('/channel-contents/list-all', placeId);
    console.log(result);
    if (result.resultFlag) {
      return result.resultList;
    } else {
      throw Object.assign(new Error('getChannelList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchInteractionApi('/channel-contents/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addChannel error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async channelContentsList => {
  try {
    const data = {
      channelContentsList,
    };
    const result = await apiLib.fetchInteractionApi('/channel-contents/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('removeFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
