import { interactionBaseApi } from './interactionBaseApi';

export const {
  endpoints,
  useFolderListQuery,
  useFolderAddMutation,
  useFolderEditMutation,
  useFolderRemoveMutation,
  useFolderMoveMutation,
} = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    folderList: build.query({
      query: data => ({ url: '/contents/storage-folder-list', data }),
      transformResponse: response => {
        const folderList = response.folderList;
        const sortList = [...folderList];
        sortList.sort((lhs, rhs) => {
          return lhs.folderNm?.toLowerCase() === rhs.folderNm?.toLowerCase()
            ? 0
            : lhs.folderNm?.toLowerCase() > rhs.folderNm?.toLowerCase()
            ? 1
            : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ folderId }) => ({ type: 'FOLDER', id: folderId })),
        { type: 'FOLDER', id: 'LIST' },
      ],
      keepUnusedDataFor: 0,
    }),
    folderAdd: build.mutation({
      query: data => ({ url: '/contents/storage-folder-add', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'FOLDER', id: payload.folderId }],
    }),
    folderEdit: build.mutation({
      query: data => ({ url: '/contents/storage-folder-edit', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'FOLDER', id: payload.folderId }],
    }),
    folderRemove: build.mutation({
      query: data => ({ url: '/contents/storage-folder-remove', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'FOLDER', id: payload.folderId }],
    }),
    folderMove: build.mutation({
      query: data => ({ url: '/contents/storage-folder-move', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'FOLDER', id: payload.folderId }],
    }),
  }),
  overrideExisting: false,
});
