import { frameApi } from './frameApi';
import { interactionBaseApi } from './interactionBaseApi';
import * as apiLib from '../../src/lib/api';

export const touchFrameApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    touchFrameList: build.query({
      query: data => ({ url: '/touch-frame/list', data }),
      transformResponse: response => {
        const frameList = response.frameList;
        const sortFrameList = frameList.sort((lhs, rhs) => lhs.frameOrder - rhs.frameOrder);
        return sortFrameList;
      },
      providesTags: (result, error, payload) => [...result.map(({ frameId }) => ({ type: 'TOUCH-FRAME', id: frameId })), { type: 'TOUCH-FRAME', id: 'LIST' }],
      keepUnusedDataFor: 0,
    }),
    touchFrameLinkList: build.query({
      query: data => ({ url: '/touch-frame/link', data }),
      transformResponse: response => {
        const frameLinkList = response.frameLinkList;
        return frameLinkList;
      },
    }),
    touchFrameAdd: build.mutation({
      query: data => ({ url: '/touch-frame/add', data }),
      invalidatesTags: [{ type: 'TOUCH-FRAME', id: 'LIST' }],
    }),
    touchFrameUpdate: build.mutation({
      query: data => ({ url: '/touch-frame/update', data }),
      invalidatesTags: [{ type: 'TOUCH-FRAME', id: 'LIST' }],
    }),
    touchFrameReOrder: build.mutation({
      query: data => ({ url: '/touch-frame/order', data }),
      invalidatesTags: [{ type: 'TOUCH-FRAME', id: 'LIST' }],
      async onQueryStarted({ updateList }, { dispatch, queryFulfilled, getState }) {
        const playlistId = getState().editor.playlistId;
        const patchResult = dispatch(
          touchFrameApi.util.updateQueryData('touchFrameList', { playlistId }, draft => {
            for (const update of updateList) {
              const frameId = update.frameId;
              const frameOrder = update.frameOrder;
              const index = draft.findIndex(frame => frame.frameId === frameId);
              draft[index].frameOrder = frameOrder;
            }
            draft = draft.sort((lhs, rhs) => lhs.frameOrder - rhs.frameOrder);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    touchFrameRemove: build.mutation({
      async queryFn({ frameId }, { dispatch, getState }, _extraOptions, fetchInteractionApi) {
        let patchResult = undefined;
        try {
          const playlistId = getState().editor.playlistId;
          let orderUpdateList = [];
          patchResult = dispatch(
            frameApi.util.updateQueryData('touchFrameList', { playlistId }, draft => {
              const index = draft.findIndex(frame => frame.frameId === frameId);
              draft.splice(index, 1);
              orderUpdateList = draft.reduce((target, newFrame, index) => {
                if (newFrame.frameOrder !== index) {
                  draft[index].frameOrder = index;
                  target.push({
                    frameId: newFrame.frameId,
                    frameOrder: index,
                  });
                }
                return target;
              }, []);

              draft = draft.sort((lhs, rhs) => lhs.frameOrder - rhs.frameOrder);
            }),
          );
          if (orderUpdateList.length > 0) {
            await fetchInteractionApi({ url: '/touch-frame/order', data: { updateList: orderUpdateList } });
          }
          await fetchInteractionApi({ url: '/touch-frame/remove', data: { frameId } });
          const result = await fetchInteractionApi({ url: '/touch-frame/list', data: { playlistId } });
          return result;
        } catch (error) {
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
      invalidatesTags: [
        { type: 'TOUCH-FRAME', id: 'LIST' },
        { type: 'FRAME', id: 'LIST' },
      ],
    }),
    uploadTouchThumbNail: build.mutation({
      async queryFn({ addFileList, frameId }, { dispatch, queryFulfilled, getState }, _extraOptions, fetchInteractionApi) {
        const placeId = getState().editor.placeId;
        const playlistId = getState().editor.playlistId;
        try {
          let fileList = addFileList;
          const uploadFileList = [];
          const file = fileList[0];
          const thumbPath = `contents/${placeId}-frame/${file.fileNm}`;
          await fetchInteractionApi({ url: '/touch-frame/update', data: { frameId, frameInfo: { thumbPath } } }).then(res => {
            apiLib.fetchUploadApi(res.data.url, file.fileData, null);
          });
          dispatch(
            touchFrameApi.util.updateQueryData('touchFrameList', { playlistId }, draft => {
              const frameIndex = draft.findIndex(frame => frame.frameId === frameId);
              draft[frameIndex] = { ...draft[frameIndex], ...{ thumbPath: thumbPath } };
            }),
          );
          return uploadFileList;
        } catch (error) {
          throw error;
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useTouchFrameListQuery,
  useTouchFrameAddMutation,
  useTouchFrameRemoveMutation,
  useTouchFrameUpdateMutation,
  useTouchFrameReOrderMutation,
  useTouchFrameLinkListQuery,
  useUploadTouchThumbNailMutation,
} = touchFrameApi;
