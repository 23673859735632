/**
 * 한자리 0붙이기함수
 * @param {string | number} value
 * @return ex) 09
 */
export const zeroAdd = value => {
  if ((value < 10 && typeof value === 'number') || (value.length === 1 && typeof value === 'string')) {
    value = '0' + value;
  }
  return String(value);
};

/**
 * 시분초 -> 초 변환 함수
 * @param {{ hour: string, minute: string, second: string }}
 * @return second
 */
export const changeFromTimeToSecond = ({ hour, minute, second }) => {
  return String(Number(hour) * 3600 + Number(minute) * 60 + Number(second));
};

/**
 * 초 -> 시분초 변환 함수
 * @param {string} second
 * @return {{ hour: string, minute: string, second: string }}
 */
export const changeFromSecondToTime = second => {
  const duration = Number(second);

  return {
    hour: zeroAdd(Math.floor(duration / 3600)),
    minute: zeroAdd(Math.floor((duration / 60) % 60)),
    second: zeroAdd(Math.floor(duration % 60)),
  };
};
