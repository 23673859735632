import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { homeAction } from './homeSlice';

import * as homeApiLib from '../lib/homeApi';

function* handleContact({ payload }) {
  try {
    yield call(homeApiLib.contact, payload);

    yield put(homeAction.contactSuccess());
  } catch (err) {
    yield put(homeAction.contactFailure('Contact Error'));
  }
}

function* handleNewsList({ payload }) {
  try {
    const newsList = yield call(homeApiLib.getNewsList, payload);

    yield put(homeAction.newsListSuccess({ newsList }));
  } catch (err) {
    yield put(homeAction.newsListFailure('news-list Error'));
  }
}

function* handleFaqList({ payload }) {
  try {
    const faqList = yield call(homeApiLib.getFaqList, payload);

    yield put(homeAction.faqListSuccess({ faqList }));
  } catch (err) {
    yield put(homeAction.faqListFailure('faq-list Error'));
  }
}

export function* watchContact() {
  yield takeLatest(homeAction.contact, handleContact);
}

export function* watchNewsList() {
  yield takeLatest(homeAction.newsList, handleNewsList);
}

export function* watchFaqList() {
  yield takeLatest(homeAction.faqList, handleFaqList);
}

function* rootSaga() {
  yield all([fork(watchContact), fork(watchNewsList), fork(watchFaqList)]);
}

export default rootSaga;
