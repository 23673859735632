import { interactionBaseApi, fetchUploadApi } from './interactionBaseApi';
import { getVideoThumb, getImageThumb, getVideoDuration } from 'utils/thumbnailUtils';

export const {
  endpoints,
  useFileListQuery,
  useFileAddMutation,
  useFileRemoveMutation,
  useFileMoveMutation,
  useFileDownloadMutation,
} = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    fileList: build.query({
      query: data => ({ url: '/contents/storage-file-list', data }),
      transformResponse: response => {
        const fileList = response.fileList;
        const sortList = [...fileList];
        sortList.sort((lhs, rhs) => {
          return lhs.fileNm?.toLowerCase() === rhs.fileNm?.toLowerCase()
            ? 0
            : lhs.fileNm?.toLowerCase() > rhs.fileNm?.toLowerCase()
            ? 1
            : -1;
        });
        return sortList;
      },
      providesTags: (result, error, payload) => [
        ...result.map(({ folderId }) => ({ type: 'FILE', id: folderId })),
        { type: 'FILE', id: 'LIST' },
      ],
      keepUnusedDataFor: 0,
    }),
    fileAdd: build.mutation({
      async queryFn(arg, { dispatch, getState }, extraOptions, baseQuery) {
        try {
          let duration = 0;
          let fileThumbId = '';
          let fileThumb = '';
          if (arg.uploadFile.type.includes('video/')) {
            duration = await getVideoDuration(arg.uploadFile);
            const videoThumb = await getVideoThumb(arg.uploadFile);
            if (videoThumb) {
              const data = {
                folderId: arg.folderId,
                thumbNm: videoThumb.name,
                thumbType: videoThumb.type,
              };
              const response = await baseQuery({ url: '/contents/storage-thumb-presign-upload', data });
              const result = response.data;
              if (result.resultFlag) {
                const presignUrl = result.url;

                const uploadData = new FormData();
                uploadData.append('file', videoThumb, videoThumb.name);
                await fetchUploadApi(presignUrl, videoThumb, null);

                fileThumbId = result.thumbInfo.thumbId;
                fileThumb = result.thumbInfo.thumbPath;
              }
            }
          } else if (arg.uploadFile.type.includes('image/')) {
            const imgThumb = await getImageThumb(arg.uploadFile);
            if (imgThumb) {
              const data = {
                folderId: arg.folderId,
                thumbNm: imgThumb.name,
                thumbType: imgThumb.type,
              };
              const response = await baseQuery({ url: '/contents/storage-thumb-presign-upload', data });
              const result = response.data;
              if (result.resultFlag) {
                const presignUrl = result.url;

                const uploadData = new FormData();
                uploadData.append('file', imgThumb, imgThumb.name);
                await fetchUploadApi(presignUrl, imgThumb, null);

                fileThumbId = result.thumbInfo.thumbId;
                fileThumb = result.thumbInfo.thumbPath;
              }
            }
          }

          const data = {
            placeId: arg.placeId,
            folderId: arg.folderId,
            fileNm: arg.uploadFile.name,
            fileSize: arg.uploadFile.size,
            fileType: arg.uploadFile.type,
            fileDetail: arg.fileDetail,
            fileDuration: duration,
            fileThumbId: fileThumbId,
            fileThumb: fileThumb,
          };

          const response = await baseQuery({ url: '/contents/storage-file-presign-upload', data });
          const result = response.data;
          if (result.resultFlag) {
            const presignUrl = result.url;

            const uploadData = new FormData();
            uploadData.append('file', arg.uploadFile, arg.uploadFile.name);
            await fetchUploadApi(presignUrl, arg.uploadFile, arg.onUploadProgress, arg.uploadIndex);

            return { data: true };
          } else {
            throw new Error('file add error.');
          }
        } catch (error) {
          return { error: error.toString() };
        }
      },
      invalidatesTags: (result, error, payload) => [{ type: 'FILE', id: payload.folderId }],
    }),
    fileRemove: build.mutation({
      query: data => ({ url: '/contents/storage-file-remove', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'FILE', id: payload.folderId }],
    }),
    fileMove: build.mutation({
      query: data => ({ url: '/contents/storage-file-move', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'FILE', id: payload.folderId }],
    }),
    fileDownload: build.mutation({
      query: data => ({ url: '/contents/storage-file-presign-download', data }),
      transformResponse: response => {
        return response.url;
      },
      invalidatesTags: (result, error, payload) => [{ type: 'FILE', id: payload.folderId }],
    }),
  }),
  overrideExisting: false,
});
