import React, { useCallback } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Modal from '../../../newComponents/Modal';
import BackgroundLoading from '../../../newComponents/BackgroundLoading';

import { ReactComponent as WarnIcon } from '../../../assets/images/common/icon-alert-triangle-red.svg';

import { usePlaylistRemoveMutation } from '../../../rtk/playlistApi';

const PlaylistRemoveModal = ({ isOpen, closeModal, playlistIdList }) => {
  const navigate = useNavigate();
  const [removePlaylist, { isLoading }] = usePlaylistRemoveMutation();

  const handleRemove = useCallback(() => {
    removePlaylist({ playlistIdList }).then(({ data }) => {
      if (data.resultFlag) {
        navigate('/playlist');
      } else {
        toast.error('삭제에 실패하였습니다');
        closeModal();
      }
    });
  }, [navigate, closeModal, removePlaylist, playlistIdList]);

  return (
    <>
      <Modal
        title={
          <Title>
            <WarnIcon />
            경고
          </Title>
        }
        onConfirm={handleRemove}
        confirmColor="red"
        confirmText="삭제"
        onCancel={closeModal}
        onBackdropClick={closeModal}
        onClose={closeModal}
        visible={isOpen}
        size="large"
        isLine
      >
        <Notice>
          {playlistIdList.length > 1 && (
            <>
              재생목록 {playlistIdList.length} 개가 선택되었습니다.
              <br />
            </>
          )}
          삭제된 재생목록은 복구되지 않습니다
          <br />
          재생목록을 삭제 하시겠습니까?
        </Notice>
      </Modal>
      {isLoading && <BackgroundLoading />}
    </>
  );
};

const Title = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  color: #f05b5b;
  font-size: 14px;
  font-weight: 500;
`;

const Notice = styled.div`
  font-size: 14px;
  color: #333333;
`;

export default React.memo(PlaylistRemoveModal);
