import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { groupAction } from './groupSlice';

import * as groupApiLib from '../lib/groupApi';

function* groupList({ payload }) {
  try {
    const groupList = yield call(groupApiLib.list, payload);

    yield put(groupAction.groupListSuccess({ groupList }));
  } catch (err) {
    yield put(groupAction.groupListFailure('Group List Error'));
  }
}

function* groupInfo({ payload }) {
  try {
    const groupInfo = yield call(groupApiLib.detail, payload);

    yield put(groupAction.groupInfoSuccess({ groupInfo }));
  } catch (err) {
    yield put(groupAction.groupInfoFailure('Group Info Error'));
  }
}

function* groupSave({ payload }) {
  try {
    for (let group of payload.groupList) {
      if (group.isRemove && !group.isNew) {
        yield call(groupApiLib.remove, group);
      } else if (group.isNew && !group.isRemove) {
        yield call(groupApiLib.add, group);
      } else if (group.isEdit) {
        yield call(groupApiLib.edit, group);
      }
    }

    yield put(groupAction.groupSaveSuccess());
  } catch (err) {
    yield put(groupAction.groupSaveFailure('Group Save Error'));
  }
}

function* groupAdd({ payload }) {
  try {
    yield call(groupApiLib.add, payload);

    yield put(groupAction.groupAddSuccess());
  } catch (err) {
    yield put(groupAction.groupAddFailure('Group Add Error'));
  }
}

function* groupRemove({ payload }) {
  try {
    yield call(groupApiLib.remove, payload);

    yield put(groupAction.groupRemoveSuccess());
  } catch (err) {
    yield put(groupAction.groupRemoveFailure('Group Remove Error'));
  }
}

function* groupSort({ payload }) {
  try {
    yield call(groupApiLib.sort, payload);

    yield put(groupAction.groupSortSuccess());
  } catch (err) {
    yield put(groupAction.groupSortFailure('Group Remove Error'));
  }
}

export function* watchGroupList() {
  yield takeLatest(groupAction.groupList, groupList);
}

export function* watchGroupInfo() {
  yield takeLatest(groupAction.groupInfo, groupInfo);
}

export function* watchGroupSave() {
  yield takeLatest(groupAction.groupSave, groupSave);
}

export function* watchGroupAdd() {
  yield takeLatest(groupAction.groupAdd, groupAdd);
}

export function* watchGroupRemove() {
  yield takeLatest(groupAction.groupRemove, groupRemove);
}

export function* watchGroupSort() {
  yield takeLatest(groupAction.groupSort, groupSort);
}

function* rootSaga() {
  yield all([
    fork(watchGroupList),
    fork(watchGroupInfo),
    fork(watchGroupSave),
    fork(watchGroupAdd),
    fork(watchGroupRemove),
    fork(watchGroupSort),
  ]);
}

export default rootSaga;
