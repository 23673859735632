import React from 'react';
import styled from 'styled-components';
import FadeLoader from "react-spinners/FadeLoader";

const BackgroundLoading = () => {
  return (
    <Background>
      <FadeLoader color={"#41a1ea"} loading={true} height={25} width={8} margin={20} />
    </Background>
  )
};

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`;

export default BackgroundLoading;
