import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface ChannelContentsInfoState {
  channelId: String;
  contentsOrder: string;
  contentsNm: string;
  filePath: string;
  playTime: string;
  tot_fileSize: string;
  regDt: string;
  regId: string;
  modId: string;
  modDt: string;
}

interface ChannelContentsState {
  channelContentsList: ChannelContentsInfoState[];
  channelContentsTotalList: [];
  isLoading: Boolean;
  error: string | null;
  actionResult: String;
}

// 초기 상태
const ChannelContentsInitialState: ChannelContentsState = {
  channelContentsList: [],
  channelContentsTotalList: [],
  isLoading: false,
  error: null,
  actionResult: '',
};

const slice = createSlice({
  name: 'channelContents',
  initialState: ChannelContentsInitialState,
  reducers: {
    initChannelState(state: ChannelContentsState) {
      state.actionResult = '';
    },
    getChannelContentsList(state: ChannelContentsState) {
      state.actionResult = 'LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getChannelContentsListSuccess(state: ChannelContentsState, { payload: channelContentsList }: PayloadAction<ChannelContentsState>) {
      state.channelContentsList = channelContentsList;
      state.actionResult = 'LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    getChannelContentsListFailure(state: ChannelContentsState, { payload: error }: PayloadAction<string>) {
      state.channelContentsList = [];
      state.actionResult = 'LIST_ERR';
      state.isLoading = false;
      state.error = error;
    },
    getChannelContentsTotalList(state: ChannelContentsState) {
      state.actionResult = 'TOTAL_LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getChannelContentsTotalListSuccess(state: ChannelContentsState, { payload: channelContentsList }: PayloadAction<ChannelContentsState>) {
      state.channelContentsTotalList = channelContentsList;
      state.actionResult = 'TOTAL_LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    getChannelContentsTotalListFailure(state: ChannelContentsState, { payload: error }: PayloadAction<string>) {
      state.channelContentsTotalList = [];
      state.actionResult = 'TOTAL_LIST_ERR';
      state.isLoading = false;
      state.error = error;
    },

    addChannelContentsInfo(state: ChannelContentsState) {
      state.actionResult = 'ADD_REQ';
      state.isLoading = true;
      state.error = null;
    },
    addChannelContentsInfoSuccess(state: ChannelContentsState, { payload: channelInfo }: PayloadAction<ChannelContentsState>) {
      state.channelInfo = channelInfo;
      state.actionResult = 'ADD_OK';
      state.isLoading = false;
      state.error = null;
    },
    addChannelContentsInfoFailure(state: ChannelContentsState, { payload: error }: PayloadAction<string>) {
      state.channelContentsList = [];
      state.actionResult = 'ADD_ERR';
      state.isLoading = false;
      state.error = error;
    },
    removeChannelInfo(state: ChannelContentsState) {
      state.actionResult = 'REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeChannelContentsInfoSuccess(state: ChannelContentsState, { payload: channelInfo }: PayloadAction<ChannelContentsState>) {
      state.actionResult = 'REMOVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    removeChannelContentsInfoFailure(state: ChannelContentsState, { payload: error }: PayloadAction<string>) {
      state.channelContentsList = [];
      state.actionResult = 'REMOVE_ERR';
      state.isLoading = false;
      state.error = error;
    },
  },
});
const selectChannelContentsList = createDraftSafeSelector(
  (state: ChannelContentsState) => state.channelContentsList,
  channelContentsList => channelContentsList,
);
const selectChannelContentsTotalList = createDraftSafeSelector(
  (state: ChannelContentsState) => state.channelContentsTotalList,
  channelContentsTotalList => channelContentsTotalList,
);

const selectStatus = createDraftSafeSelector(
  (state: ChannelContentsState) => state.actionResult,
  (state: ChannelContentsState) => state.isLoading,
  (state: ChannelContentsState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const channelContentsSelector = {
  channelContentsTotalList: state => selectChannelContentsTotalList(state[CHANNELCONTENTS]),
  channelContentsList: state => selectChannelContentsList(state[CHANNELCONTENTS]),
  status: state => selectStatus(state[CHANNELCONTENTS]),
};

export const CHANNELCONTENTS = slice.name;
export const channelContentsReducer = slice.reducer;
export const channelContentsAction = slice.actions;
